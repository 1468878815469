import React from "react";
import { inject, observer } from "mobx-react";
import Modal from "../../common/modal/Modal";
import ModalFooter from "../../common/modal/ModalFooter";
import ModalBody from "../../common/modal/ModalBody";
import ModalHeaderWithTitleAndClose from "../../common/modal/ModalHeaderWithTitleAndClose";
import ZeLightButton from "../../elements/form/ZeLightButton";
import GameViewerUmpireStanceOption from "./GameViewerUmpireStanceOption";

function GameViewerUmpireStancesModal(props) {
  const stances = ["Square", "Slot", "Scissors", "Knee"];
  const { gameViewerStore } = props.rootStore;

  function closeModal(event) {
    event.preventDefault();
    gameViewerStore.closeUmpireStances();
  }

  function saveStances(event) {
    event.preventDefault();
    gameViewerStore.updateUmpireStances();
  }

  return (
    <Modal show={gameViewerStore.showUmpireStances} onClose={() => {}} size="sm">
      <ModalHeaderWithTitleAndClose title="Umpire Stances" close={closeModal} />

      <ModalBody>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <div className="row">
                {stances.map(s => (
                  <GameViewerUmpireStanceOption key={s} stance={s} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </ModalBody>

      <ModalFooter>
        <button className="btn btn-primary" onClick={saveStances}>
          Save
        </button>
        <ZeLightButton onClick={closeModal}>Cancel</ZeLightButton>
      </ModalFooter>
    </Modal>
  );
}

export default inject("rootStore")(observer(GameViewerUmpireStancesModal));
