import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

const LinkButton = props => {
  let date = moment(props.date).format("YYYY-MM-DD");
  let includeUnreleased = props.includeUnreleased;
  return (
    <Link
      to={"/gameSummary?date=" + date + "&includeUnreleased=" + includeUnreleased}
      className="btn btn-primary mr-2"
      target="_blank"
    >
      Open Report
    </Link>
  );
};

export default LinkButton;
