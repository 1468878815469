import { action, extendObservable } from "mobx";

class CommonStore {
  constructor() {
    this.defaults = {
      showHotkeysModal: false
    };

    extendObservable(this, {
      showHotkeysModal: this.defaults["showHotkeysModal"],
      setShowHotkeysModal: action(showHotkeysModal => {
        this.showHotkeysModal = showHotkeysModal;
      })
    });
  }
}

export default CommonStore;
