import React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";

const Holder = styled.div`
  &&& {
    border-right: 1px solid #d2d2d2;
    padding-left: 0.425rem;
    padding-right: 0.425rem;
  }
`;

const Label = styled.div.attrs({
  className: "text-center"
})`
  &&& {
    color: #666666;
    font-size: 0.6875rem;
    font-weight: bold;
  }
`;

const CheckboxHolder = styled.div`
  margin: auto;
`;

const GameViewerFilterCallCorrectness = ({ rootStore: { gameViewerStore }, value, label }) => {
  const onChange = () => {
    let correctnessFilter = Object.assign({}, gameViewerStore.correctnessFilter);
    switch (label) {
      case "Incorrect":
        correctnessFilter.incorrect = !correctnessFilter.incorrect;
        break;
      case "Acceptable":
        correctnessFilter.acceptable = !correctnessFilter.acceptable;
        break;
      case "Correct":
        correctnessFilter.correct = !correctnessFilter.correct;
        break;
      case "Edge":
        correctnessFilter.edge = !correctnessFilter.edge;
        break;
      default:
        break;
    }
    gameViewerStore.setCorrectnessFilter(correctnessFilter);
  };

  return (
    <Holder>
      <div className={"d-flex flex-column justify-content-center"}>
        <CheckboxHolder>
          <input checked={value} id={label} onChange={onChange} type="checkbox" />
        </CheckboxHolder>
        <Label>{label}</Label>
      </div>
    </Holder>
  );
};

export default inject("rootStore")(observer(GameViewerFilterCallCorrectness));
