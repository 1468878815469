import React from "react";
import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import NavigationLink from "./NavigationLink";
import { AuthConstants } from "../login/AuthConstants";
import mlbLogo from "../../assets/logo/mlb-logo@2x.png";
import navlogo from "../../assets/logo/simple-logo@2x.png";

const env = process.env.REACT_APP_ENV;

const Icon = styled.img.attrs({
  alt: "ZE Logo",
  height: "39px;",
  width: "35px;"
})`
  position: relative;
  z-index: 1000;
`;

const NavBarStyled = styled.nav.attrs({
  className: "navbar fixed-top navbar-expand-sm navbar-light bg-faded"
})`
  padding-top: 4.5px !important;
  padding-bottom: 4.5px !important;
  min-height: 30px;
  background: ${props => props.backgroundColor} no-repeat right -4px top -1px;
  background-image: url(${mlbLogo});
  background-size: 113px 59px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  margin-top: ${props => (props.loggedIn ? "22px;" : "0px;")};
  border-top: 1px solid #002e6d;
  border-bottom: 1px solid #002e6d;
`;

const navBarColorDev = "#e1003d";
const navBarColorStg = "#711755";
const navBarColorProd = "#002e6D";

const NavLogoHolder = styled.div`
  height: 39px;
  width: 35px;
`;

const Hamburger = styled.button.attrs({
  id: "navButton",
  className: "navbar-toggler navbar-toggler-right custom-toggler",
  type: "button",
  "data-toggle": "collapse",
  "data-target": "#navbarSupportedContent",
  "aria-controls": "navbarSupportedContent",
  "aria-expanded": "false",
  "aria-label": "Toggle navigation"
})`
  margin-right: 95px;
`;

const Navbar = styled.ul.attrs({
  className: "navbar-nav mr-auto"
})`
  @media (max-width: 575px) {
    margin-top: 10px;
  }
`;

const ExternalLink = styled.a.attrs({ className: "ml-3" })`
  color: white;
  font-size: 14px;
  font-weight: 400;
  height: 17px;
  padding: 0 !important;

  &:hover {
    color: white;
  }
`;

class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.hasReportsPermission = this.hasReportsPermission.bind(this);
  }

  hasDisputesPermission() {
    const authStore = this.props.rootStore.authStore;
    return authStore.containsAuthorities([
      AuthConstants.USER_ROLES.ZE_SUPER_ADMIN,
      AuthConstants.USER_ROLES.ZE_AUDITOR_ADMIN,
      AuthConstants.USER_ROLES.ZE_BOC_ADMIN
    ]);
  }

  hasReportsPermission() {
    const authStore = this.props.rootStore.authStore;
    return authStore.containsAuthorities([
      AuthConstants.USER_ROLES.ZE_SUPER_ADMIN,
      AuthConstants.USER_ROLES.ZE_AUDITOR_ADMIN,
      AuthConstants.USER_ROLES.ZE_BOC_ADMIN
    ]);
  }

  hasSuperUmpireView() {
    const authStore = this.props.rootStore.authStore;
    return authStore.containsAuthorities([
      AuthConstants.USER_ROLES.ZE_SUPER_ADMIN,
      AuthConstants.USER_ROLES.ZE_AUDITOR_ADMIN,
      AuthConstants.USER_ROLES.ZE_BOC_ADMIN,
      AuthConstants.USER_ROLES.ZE_BOC_USER,
      AuthConstants.USER_ROLES.ZE_UMPIRE_SUPERVISOR
    ]);
  }

  render() {
    const {
      rootStore: { authStore, routerStore }
    } = this.props;

    let navBarColor = navBarColorProd;
    if (env === "dev") {
      navBarColor = navBarColorDev;
    } else if (env === "stg") {
      navBarColor = navBarColorStg;
    }

    return (
      <NavBarStyled backgroundColor={navBarColor} loggedIn={authStore.isLoggedIn}>
        <Link className="navbar-brand" to="/">
          {authStore.isLoggedIn ? <Icon src={navlogo} /> : <NavLogoHolder />}
        </Link>

        <Hamburger>
          <span className="navbar-toggler-icon" />
        </Hamburger>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          {authStore.isLoggedIn ? (
            <Navbar>
              <NavigationLink to="/" name="Schedule" isActive={routerStore.isScheduleTab} />
              {this.hasDisputesPermission() ? (
                <NavigationLink to="/dispute" name="Disputes" isActive={routerStore.isDisputesTab} />
              ) : null}
              {this.hasReportsPermission() ? (
                <NavigationLink to="/reports" name="Reports / Batches" isActive={routerStore.isReportsTab} />
              ) : null}
              {/* {this.hasSuperUmpireView() ? (
                <NavigationLink to="/superUmpire" name="Umpire" isActive={routerStore.isSuperUmpireTab} />
              ) : null} */}
              {this.hasReportsPermission() ? (
                <ExternalLink href="https://prod-useast-b.online.tableau.com/#/site/mlb/workbooks/1242015/views" target="_blank">
                  Tableau
                </ExternalLink>
              ) : null}
            </Navbar>
          ) : null}
        </div>
      </NavBarStyled>
    );
  }
}

export default inject("rootStore")(observer(Navigation));
