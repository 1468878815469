import React from "react";
import styled from "styled-components";
import ZeLightButton from "../../elements/form/ZeLightButton";
import VideoSeekBar from "./VideoSeekBar";
import Moment from "moment/moment";
import { VideoConstants } from "../constants/VideoConstants";

const ControlsHolder = styled.div.attrs({
  className: "mt-1"
})``;

const TimeHolder = styled.div.attrs({
  className: "align-self-center"
})`
  font-size: 0.8125rem;
  letter-spacing: -0.4px;
  max-width: 90px;
  min-width: 90px;
`;

const SeekHolder = styled.div`
  width: 100%;
`;

class VideoControls extends React.Component {
  constructor(props) {
    super(props);
    this.playPause = this.playPause.bind(this);
    this.frameForward = this.frameForward.bind(this);
    this.frameBackward = this.frameBackward.bind(this);
  }

  playPause() {
    this.props.videoRef.paused ? this.props.videoRef.play() : this.props.videoRef.pause();
  }

  frameForward() {
    let { currentTime, duration } = this.props.videoRef;
    if (this.props.videoRef && !!this.props.videoRef.duration) {
      this.props.videoRef.currentTime = Math.min(currentTime + VideoConstants.ONE_FRAME, duration);
    }
  }

  frameBackward() {
    this.props.videoRef.currentTime = Math.max(this.props.videoRef.currentTime - VideoConstants.ONE_FRAME, 0);
  }

  formatSeconds(currentTime) {
    let d = new Date();
    d.setSeconds(Math.floor(currentTime));
    d.setMilliseconds(Math.floor((currentTime % 1) * 1000));
    let moment = Moment(d);
    return moment.format("s.SSS");
  }

  render() {
    const duration = !!this.props.duration ? this.props.duration : 0;
    const currentTime = !!this.props.currentTime ? this.props.currentTime : 0;
    return (
      <ControlsHolder>
        {this.props.videoRef ? (
          <div className="d-flex">
            <ZeLightButton onClick={this.playPause} className="mr-2">
              {this.props.videoRef.paused ? <i className="fa fa-play" /> : <i className="fa fa-pause" />}
            </ZeLightButton>
            <ZeLightButton onClick={this.frameBackward} className="mr-2">
              <i className="fa fa-backward" />
            </ZeLightButton>
            <ZeLightButton onClick={this.frameForward} className="mr-1">
              <i className="fa fa-forward" />
            </ZeLightButton>
            {this.time()}
            <SeekHolder>
              <VideoSeekBar
                videoRef={this.props.videoRef}
                currentTime={currentTime}
                duration={duration}
                onChange={this.props.onChange}
              />
            </SeekHolder>
            {this.sizeButton()}
            {this.toggleCanvasButton()}
          </div>
        ) : null}
      </ControlsHolder>
    );
  }

  sizeButton() {
    const { expanded, toggleSize } = this.props;
    return toggleSize ? (
      <ZeLightButton onClick={this.props.toggleSize} className="ml-1">
        {expanded ? <i className="fa fa-compress" /> : <i className="fa fa-expand" />}
      </ZeLightButton>
    ) : null;
  }

  toggleCanvasButton() {
    const { showCanvas, toggleShowCanvas } = this.props;
    return (
      <ZeLightButton onClick={toggleShowCanvas} className="ml-1">
        <>Graphics</>
        {showCanvas ? <i className="fa fa-solid fa-toggle-on ml-2"  style={{ color: 'green' }} /> : <i className="fa fa-solid fa-toggle-off ml-2" />}
      </ZeLightButton>
    );
  }

  time() {
    const { currentTime, duration, showTime } = this.props;
    if (showTime) {
      return (
        <TimeHolder>
          <span>{this.formatSeconds(currentTime)}</span> / <span>{this.formatSeconds(duration)}</span>
        </TimeHolder>
      );
    } else {
      return null;
    }
  }
}

export default VideoControls;
