import React from "react";
import ReactAnimationFrame from "react-animation-frame";
import styled from "styled-components";
import { inject, observer } from "mobx-react";
import { CanvasUtilities } from "../../../../utilities/CanvasUtilities";

const CanvasHolder = styled.div`
  position: relative;
  z-index: 1;
`;

const Canvas = styled.canvas.attrs({
  style: props => ({
    top: props.panDown + "px",
    bottom: props.panDown + "px",
    left: props.panRight + "px",
    right: props.panRight + "px"
  })
})`
  position: absolute;
  z-index: 1;
`;

class GameViewerPitchcastCanvas extends React.Component {
  clear() {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
  }

  componentDidMount() {
    this.ctx = this.canvas.getContext("2d");

    setTimeout(this.draw(), 2000);
  }

  componentWillUnmount() {
    this.props.endAnimation();
  }

  draw() {
    const { functions, selectedPitch } = this.props.rootStore.gameViewerStore;
    const scale = this.props.width / 1920;
    let index, nearestIndex;
    if (selectedPitch && selectedPitch.xyCoordinatesMap) {
      index = functions.getFrameIndex();
      nearestIndex = functions.getClosestFrameIndexWithCoordinates(selectedPitch.xyCoordinatesMap, index);
    }
    if (this.ctx && typeof nearestIndex === "number") {
      this.drawPitch(nearestIndex, scale);
      this.drawStrikeZone(nearestIndex, scale);
    }
  }

  drawPitch(index, scale) {
    const { currentVideo, selectedPitch } = this.props.rootStore.gameViewerStore;
    if (!(currentVideo && currentVideo.typeName === "Pitchcast" && selectedPitch && selectedPitch.xyCoordinatesMap)) {
      return;
    }
    const xyzCoordinates = selectedPitch.xyzCoordinates;
    const xyCoordinatesMap = selectedPitch.xyCoordinatesMap;
    const xyCoordinates = xyCoordinatesMap[index];
    if (xyCoordinates) {
      for (let idx = 0; idx < xyCoordinates.length - 1; idx++) {
        const xy1 = CanvasUtilities.scaleCoordinateXy(xyCoordinates[idx], scale);
        const xy2 = CanvasUtilities.scaleCoordinateXy(xyCoordinates[idx + 1], scale);
        const color = this.getPitchArcColor(xyzCoordinates.positions[idx]);
        CanvasUtilities.drawLinePointToPointXY(this.ctx, xy1, xy2, color, 2);
      }
    }
  }

  drawStrikeZone(index, scale) {
    const { currentVideo, selectedPitch } = this.props.rootStore.gameViewerStore;
    if (!(currentVideo && currentVideo.typeName === "Pitchcast" && selectedPitch && selectedPitch.xySzCoordinatesMap)) {
      return;
    }
    const xySzCoordinatesMap = selectedPitch.xySzCoordinatesMap;
    if (!xySzCoordinatesMap) {
      return;
    }
    const xySzCoordinates = xySzCoordinatesMap[index];
    if (xySzCoordinates) {
      const { bottomLeft, topLeft, topRight, bottomRight } = xySzCoordinates;
      const bottomLeftScaled = CanvasUtilities.scaleCoordinateXy(bottomLeft, scale);
      const topLeftScaled = CanvasUtilities.scaleCoordinateXy(topLeft, scale);
      const topRightScaled = CanvasUtilities.scaleCoordinateXy(topRight, scale);
      const bottomRightScaled = CanvasUtilities.scaleCoordinateXy(bottomRight, scale);

      CanvasUtilities.drawLinePointToPointXY(this.ctx, bottomLeftScaled, topLeftScaled, CanvasUtilities.COLORS.GREEN);
      CanvasUtilities.drawLinePointToPointXY(this.ctx, topLeftScaled, topRightScaled, CanvasUtilities.COLORS.GREEN);
      CanvasUtilities.drawLinePointToPointXY(this.ctx, topRightScaled, bottomRightScaled, CanvasUtilities.COLORS.GREEN);
      CanvasUtilities.drawLinePointToPointXY(
        this.ctx,
        bottomRightScaled,
        bottomLeftScaled,
        CanvasUtilities.COLORS.GREEN
      );
    }
  }

  getPitchArcColor(xyz) {
    if (xyz.y <= 0) {
      return CanvasUtilities.COLORS.RED;
    } else if (xyz.y <= 1.417) {
      return CanvasUtilities.COLORS.YELLOW;
    } else {
      return CanvasUtilities.COLORS.CYAN;
    }
  }

  onAnimationFrame(time) {
    if (this.ctx) {
      this.clear();
      this.draw();
    }
  }

  render() {
    const { selectedPitch } = this.props.rootStore.gameViewerStore;
    // this scale is relative to the audit tool video player default dimensions
    const scale = this.props.width / 960;
    let panDown = selectedPitch && selectedPitch.keyframe ? selectedPitch.keyframe.calibrationPanDown : 0;
    if (Number.isNaN(panDown)) {
      panDown = 0;
    }
    let panRight = selectedPitch && selectedPitch.keyframe ? selectedPitch.keyframe.calibrationPanRight : 0;
    if (Number.isNaN(panRight)) {
      panRight = 0;
    }
    return (
      <CanvasHolder>
        <Canvas
          {...this.props}
          innerRef={c => {
            this.canvas = c;
          }}
          panDown={panDown * scale}
          panRight={panRight * scale}
          {...{
            onClick: this.clickStrikeZone,
            onMouseMove: this.onCanvasMouseMove
          }}
        />
      </CanvasHolder>
    );
  }
}

export default inject("rootStore")(ReactAnimationFrame(observer(GameViewerPitchcastCanvas)));
