import React from "react";
import styled from "styled-components";
import { inject, observer } from "mobx-react";

const UpdatedHolder = styled.div`
  background-color: white;
  height: 47px;
  border: 1px solid #d2d2d2;
  padding: 5px;
  width: 100%;
  min-width: 270px;
  margin-right: 10px;
`;

const UpdatedTitle = styled.div`
  font-size: 13px;
  font-weight: bold;
`;

const UpdateInfo = styled.div`
  font-size: 11px;
`;

class AuditStrikeZoneInfo extends React.Component {
  render() {
    const auditStore = this.props.rootStore.auditStore;
    return (
      <UpdatedHolder>
        <div className="row">
          <div className="col-6">
            <UpdatedTitle>Updated On</UpdatedTitle>
            <UpdateInfo>{auditStore.selectedPitch.updatedOn}</UpdateInfo>
          </div>
          <div className="col-6">
            <UpdatedTitle>Updated By</UpdatedTitle>
            <UpdateInfo>{auditStore.selectedPitch.updatedBy}</UpdateInfo>
          </div>
        </div>
      </UpdatedHolder>
    );
  }
}

export default inject("rootStore")(observer(AuditStrikeZoneInfo));
