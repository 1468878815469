import React from "react";
import styled from "styled-components";

const On = styled.span`
  color: green;
  font-weight: bold;
`;

const Off = styled.span`
  color: red;
  font-weight: bold;
`;

class OnOff extends React.Component {
  render() {
    return this.props.check ? <On>On</On> : <Off>Off</Off>;
  }
}

export default OnOff;
