import React from "react";
import { inject, observer } from "mobx-react";

class ScheduleUmpireStanceOption extends React.Component {
  constructor(props) {
    super(props);
    this.updateStance = this.updateStance.bind(this);
  }

  updateStance(event) {
    this.props.rootStore.scheduleStore.selectedUmpireStances[this.props.stance] = event.target.checked;
  }

  render() {
    const { scheduleStore } = this.props.rootStore;
    return (
      <div className="col-12">
        <div className="form-check form-check-inline">
          <label className="form-check-label">
            <input
              className="form-check-input"
              type="checkbox"
              checked={scheduleStore.selectedUmpireStances[this.props.stance]}
              onChange={this.updateStance}
            />
            {this.props.stance}
          </label>
        </div>
      </div>
    );
  }
}

export default inject("rootStore")(observer(ScheduleUmpireStanceOption));
