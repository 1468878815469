import React from "react";
import styled from "styled-components";
import GameViewBatterSmallScreen from "./GameViewBatterSmallScreen";
import GameViewBatterLargeScreen from "./GameViewBatterLargeScreen";
import HrTitle from "../../../common/title/HrTitle";

const Batter = styled.div`
  min-width: 235px;
`;

class GameViewerContentBatter extends React.Component {
  render() {
    const small = !!this.props.small;
    return (
      <Batter>
        <div className="d-flex">
          <div className="flex-column">
            <HrTitle title="batter/catcher graphics" />
            {small ? <GameViewBatterSmallScreen /> : <GameViewBatterLargeScreen />}
          </div>
        </div>
      </Batter>
    );
  }
}

export default GameViewerContentBatter;
