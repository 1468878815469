import React from "react";
import { inject, observer } from "mobx-react";
import ScoresheetBodyHeader from "../ScoresheetBodyHeader";
import ScoresheetSubHeader from "../../header/ScoresheetSubHeader";
import NoBold from "../../../elements/NoBold";
import ScoresheetBodyViewItem from "./ScoresheetBodyViewItem";
import ZeCorrectText from "../../../elements/form/ZeCorrectText";
import ZeIncorrectText from "../../../elements/form/ZeIncorrectText";
import ZeFieldText from "../../../elements/form/ZeFieldText";

class ScoresheetStrikesAsBalls extends React.Component {
  bodyViewItem(title, incorrectCalls, count, matchingCall, correct, desc) {
    const pitchNumbers = incorrectCalls
      ? incorrectCalls.filter(ic => ic.call && ic.call.startsWith(matchingCall)).map(ic => ic.pitchNumber)
      : [];
    return (
      <ScoresheetBodyViewItem title={title} desc={desc} pitchNumbers={pitchNumbers}>
        {correct ? (
          <ZeCorrectText>{count}</ZeCorrectText>
        ) : count ? (
          <ZeIncorrectText>{count}</ZeIncorrectText>
        ) : (
          <ZeFieldText>{count}</ZeFieldText>
        )}
      </ScoresheetBodyViewItem>
    );
  }

  render() {
    const {
      rootStore: { scoreSheetStore }
    } = this.props;
    const scoresheet = scoreSheetStore.scoreSheet;
    let callQualitySummary = scoresheet.gameAnalysis.callQualitySummary;
    let {
      adjCatcherInfluence,
      adjLowCatch,
      calledBallsHigh,
      calledBallsInside,
      calledBallsLow,
      calledBallsOutside,
      incorrectCalls,
      questionableCalledBalls
    } = callQualitySummary;
    return (
      <div className="card">
        <ScoresheetBodyHeader>
          Strikes <NoBold>called as</NoBold> Balls
        </ScoresheetBodyHeader>

        <div className="card-body">
          <ScoresheetSubHeader>
            <ZeFieldText className="col">Umpire ruled pitch...</ZeFieldText>
          </ScoresheetSubHeader>

          {this.bodyViewItem("Outside", incorrectCalls, calledBallsOutside, "ball_outside")}
          {this.bodyViewItem("Inside", incorrectCalls, calledBallsInside, "ball_inside")}
          {this.bodyViewItem("High", incorrectCalls, calledBallsHigh, "ball_high")}
          {this.bodyViewItem("Low", incorrectCalls, calledBallsLow, "ball_low")}
          {this.bodyViewItem("Low Adjustment", incorrectCalls, adjLowCatch, "ball_lowCatch", true, `Pitch(es): `)}
          {this.bodyViewItem(
            "Catcher Influence",
            incorrectCalls,
            adjCatcherInfluence,
            "ball_catcherInfluence",
            true,
            `Pitch(es): `
          )}
          {this.bodyViewItem("Total", incorrectCalls, questionableCalledBalls, "ball")}
        </div>
      </div>
    );
  }
}

export default inject("rootStore")(observer(ScoresheetStrikesAsBalls));
