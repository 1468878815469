import { action, autorun, computed, extendObservable, observable, reaction } from "mobx";
import queryString from "query-string";
import Moment from "moment";
import { AlertConstants } from "../components/common/alert/AlertConstants";
import _ from "lodash";
import { DateConstants } from "../components/common/constants/DateConstants";
import DateUtil from "../utilities/DateUtil";

class ScheduleStore {
  constructor(routerStore, zeApi, loadingStore, authStore, alertStore) {
    this.routerStore = routerStore;
    this.zeApi = zeApi;
    this.loadingStore = loadingStore;
    this.authStore = authStore;
    this.alertStore = alertStore;

    this.defaults = {
      date: Moment()
        .add(-19, "hours")
        .format(DateConstants.SCHEDULE_DATE_FORMAT),
      games: [],
      releaseErrorGamePk: -1,
      releaseErrorUmpireId: -1,
      releaseErrorPitches: [],
      pitchList: [],
      season: new Date().getFullYear(),
      selectedGame: observable.map(),
      selectedGamePk: -1,
      selectedUmpireStances: observable.map(),
      showAnalyzePitches: false,
      showUmpireStances: false,
      showReleaseErrorsModal: false,
      sort: { col: "date", asc: true },
      umpireOptions: [],
      umpires: [],
      displayGameStatusCol: false
    };

    extendObservable(this, {
      games: this.defaults["games"],
      date: this.defaults["date"],
      releaseErrorUmpireId: this.defaults["releaseErrorUmpireId"],
      releaseErrorGamePk: this.defaults["releaseErrorGamePk"],
      releaseErrorPitches: this.defaults["releaseErrorPitches"],
      selectedGame: this.defaults["selectedGame"],
      showUmpireStances: this.defaults["showUmpireStances"],
      showAnalyzePitches: this.defaults["showAnalyzePitches"],
      showReleaseErrorsModal: this.defaults["showReleaseErrorsModal"],
      selectedUmpireStances: this.defaults["selectedUmpireStances"],
      selectedGamePk: this.defaults["selectedGamePk"],
      sort: this.defaults["sort"],
      season: this.defaults["season"],
      pitchList: this.defaults["pitchList"],
      umpireOptions: this.defaults["umpireOptions"],
      umpires: this.defaults["umpires"],
      displayGameStatusCol: DateUtil.getIsYesterdayOrTodayDate(this.defaults["date"]),
      updateUrl: action(date => {
        const formattedDate = Moment(date).format(DateConstants.SCHEDULE_DATE_FORMAT);
        const search = "date=" + formattedDate;
        this.routerStore.history.push({
          pathname: this.routerStore.location.pathname,
          search: search
        });
      }),
      updateUrlForUmpire: action(umpireId => {
        let search = "";
        if (this.season.value) {
          search += "&season=" + this.season.value;
        }
        if (umpireId) {
          search += "&umpires=" + umpireId;
        }
        search += "&sortCol=date&asc=true";
        this.routerStore.history.push({
          pathname: "/superUmpire",
          search: search
        });
      }),
      setGames: action(games => {
        this.games = games;
      }),
      setReleaseErrorPitches: action(value => {
        this.releaseErrorPitches = value;
      }),
      setReleaseErrorGamePk: action(value => {
        this.releaseErrorGamePk = value;
      }),
      setReleaseErrorUmpireId: action(value => {
        this.releaseErrorUmpireId = value;
      }),
      setSeason: action(season => {
        if (season.value) {
          this.season = season;
        } else {
          this.season = {
            label: season.toString(),
            value: season
          };
        }
      }),
      setSort: action(col => {
        if (col === this.sort.col) {
          if (this.sort.asc) {
            // sort descending
            this.sort.asc = false;
          } else {
            // clear sort
            this.sort.asc = true;
            this.sort.col = "";
          }
        } else {
          this.sort.asc = true;
          this.sort.col = col;
        }
        this.updateUmpireUrl();
      }),
      setShowAnalyzePitches: action(value => {
        this.showAnalyzePitches = value;
      }),
      setSelectedGamePk: action(value => {
        this.selectedGamePk = value;
      }),
      setPitchList: action(value => {
        this.pitchList = value;
      }),
      setUmpireOptions: action(values => {
        this.umpireOptions = values;
      }),
      setUmpires: action(values => {
        this.umpires = values;
      }),
      selectedPitchList: computed(() => {
        let list = [];
        this.pitchList.forEach(p => {
          if (p.selected) {
            list.push(p);
          }
        });
        return list;
      }),
      pitchListAllSelected: computed(() => {
        let all = true;
        if (!this.pitchList || this.pitchList.length === 0) {
          return false;
        }
        this.pitchList.forEach(p => {
          if (!p.selected) {
            all = false;
          }
        });
        return all;
      }),
      sortedGames: computed(() => {
        return this.games
          ? this.games.concat().sort(function(a, b) {
              let timeDiff = Moment(a.displayTime).valueOf() - Moment(b.displayTime).valueOf();
              if (timeDiff !== 0) {
                return timeDiff;
              }
              return a.displayName.localeCompare(b.displayName);
            })
          : [];
      }),
      togglePitchListSelected: action(() => {
        let selected = !this.pitchListAllSelected;
        this.pitchList.forEach(p => {
          p.selected = selected;
        });
      }),
      toggleReleaseErrorsModal: action(() => {
        this.showReleaseErrorsModal = !this.showReleaseErrorsModal;
      }),
      updateUmpireUrl: action(() => {
        let search = "season=" + this.season.value + "&sortCol=" + this.sort.col + "&asc=" + this.sort.asc;
        if (this.umpires.length) {
          search += "&umpires=" + this.umpires.map(u => u.value).join(",");
        }
        this.routerStore.history.push({
          pathname: this.routerStore.location.pathname,
          search: search
        });
      }),
      updateFromUrlParams: action(search => {
        const params = queryString.parse(search);
        if (params["date"]) {
          this.date = params["date"];
        } else {
          this.date = this.defaults["date"];
        }
        this.setSeason(params["season"] || this.defaults["season"]);
        this.sort.col = params["sortCol"] || this.defaults["sort"].col;
        let ascParam = params["asc"] || "true";
        this.sort.asc = ascParam === "true";
        this.getGames();
        if (this.routerStore.isSuperUmpireTab) {
          this.getUmpireOptions();
        }
      }),
      initializeGame: action(gamePk => {
        let gamesList = this.games.filter(g => g.gamePk === gamePk);
        gamesList.forEach(g => {
          g.status = "Initializing";
          g.videoStage = "Retrieving";
        });
        this.zeApi.initializeGame(gamePk).then(data => {
          this.replaceGamesApi(gamePk);
        });
        this.alertStore.addAlert({
          type: AlertConstants.TYPES.SUCCESS,
          text: "Request sent to initialize gamePk " + gamePk
        });
      }),
      moveBackToInitialized: action(gamePk => {
        this.zeApi.moveBackToInitialized(gamePk).then(data => {
          this.alertStore.addAlert({
            type: AlertConstants.TYPES.SUCCESS,
            text: "Successfully moved gamePk " + gamePk + " back to Initialized"
          });
          this.replaceGamesApi(gamePk);
        });
      }),
      releaseGame: action((gamePk, umpireId, override) => {
        this.alertStore.addAlert({
          type: AlertConstants.TYPES.SUCCESS,
          text: "Request sent to release gamePk " + gamePk + ", umpireId " + umpireId
        });
        this.zeApi.releaseGame(gamePk, umpireId, override).then(data => {
          if (data && data.errors.length > 0) {
            this.toggleReleaseErrorsModal();
            this.setReleaseErrorPitches(data.entity);
            this.setReleaseErrorGamePk(gamePk);
            this.setReleaseErrorUmpireId(umpireId);
          } else {
            this.showReleaseErrorsModal = false;
            this.alertStore.addAlert({
              type: AlertConstants.TYPES.SUCCESS,
              text: "Successfully released gamePk " + gamePk + ", umpireId " + umpireId
            });
            this.replaceGamesApi(gamePk);
          }
        });
      }),
      replaceGamesApi: action(gamePk => {
        this.zeApi.getGames(gamePk).then(data => {
          this.replaceGames(gamePk, data.entities);
        });
      }),
      replaceGames: action((gamePk, newGames) => {
        let gamesList = this.games.filter(g => g.gamePk !== gamePk);
        // FIXME -- Temporary fix to use old game status after resetting or replacing games.
        newGames.forEach(newGame => {
          const oldGame = this.games.find(g => g.gamePk === newGame.gamePk);
          if (oldGame && oldGame.gameStatus) {
            newGame.gameStatus = oldGame.gameStatus;
          }
        });
        gamesList.push(...newGames);
        this.setGames(gamesList);
      }),
      analyzePitchesModal: action(gamePk => {
        this.setSelectedGamePk(gamePk);
        this.loadingStore.setLoading(true, "Loading", "Loading Pitch List", 75);
        this.setPitchList(this.defaults["pitchList"]);
        this.zeApi.getPitchList(gamePk).then(data => {
          this.setShowAnalyzePitches(true);
          let pitches = data.pitches;
          pitches.forEach(p => {
            p.selected = false;
          });
          this.setPitchList(pitches);
          this.loadingStore.setLoading(false);
        });
      }),
      analyzeAllPitches: action(game => {
        let gamePk = game.gamePk;
        game.status = "ANALYZING";
        this.alertStore.addAlert({
          type: AlertConstants.TYPES.SUCCESS,
          text: "Request sent to analyze pitches for gamePk " + gamePk
        });
        this.zeApi.analyzeAllPitches(gamePk).then(data => {
          if (!data.errors) {
            this.alertStore.addAlert({
              type: AlertConstants.TYPES.SUCCESS,
              text: "All pitches analyzed for gamePk " + gamePk
            });
            game.status = "INITIALIZED";
          }
          this.replaceGamesApi(gamePk);
        });
      }),
      analyzePitches: action(() => {
        this.loadingStore.setLoading(true, "Schedule", "Analyzing Game", 75);
        let gamePk = this.selectedGamePk;
        let numSelectedPitches = this.selectedPitchList.length;
        this.zeApi.analyzePitches(gamePk, this.selectedPitchList).then(data => {
          this.loadingStore.setLoading(false);
          this.setShowAnalyzePitches(false);
          this.setSelectedGamePk(this.defaults["selectedGamePk"]);
          this.setPitchList(this.defaults["pitchList"]);
          if (!data.errors) {
            this.alertStore.addAlert({
              type: AlertConstants.TYPES.SUCCESS,
              text: numSelectedPitches + " pitches analyzed for gamePk " + gamePk
            });
          }
          this.replaceGamesApi(gamePk);
        });
      }),
      retrieveVideo: action(game => {
        let gamePk = game.gamePk;
        game.videoStage = "RETRIEVING";
        this.alertStore.addAlert({
          type: AlertConstants.TYPES.SUCCESS,
          text: "Request sent to retrieve video for gamePk " + gamePk
        });
        this.zeApi.retrieveVideo(gamePk).then(data => {
          this.alertStore.addAlert({
            type: AlertConstants.TYPES.SUCCESS,
            text: "Video retrieved successfully for gamePk " + gamePk
          });
          game.videoStage = "COMPLETE";
          this.replaceGamesApi(gamePk);
        });
      }),
      unreleaseGame: action((gamePk, umpireId) => {
        this.alertStore.addAlert({
          type: AlertConstants.TYPES.SUCCESS,
          text: "Request sent to unrelease gamePk " + gamePk + ", umpireId " + umpireId
        });
        this.zeApi.unreleaseGame(gamePk, umpireId).then(data => {
          this.alertStore.addAlert({
            type: AlertConstants.TYPES.SUCCESS,
            text: "Successfully unreleased gamePk " + gamePk + ", umpireId " + umpireId
          });
          this.replaceGamesApi(gamePk);
        });
      }),
      updateUmpireStances: action(() => {
        this.showUmpireStances = false;
        this.loadingStore.setLoading(true, "Schedule", "Updating Umpire Stances", 75);
        let umpireStances = [];
        Object.entries(this.selectedUmpireStances).forEach(([key, value]) => {
          if (value) {
            umpireStances.push(key.toUpperCase());
          }
        });
        this.selectedGame.umpireStances = umpireStances;
        let game = this.selectedGame;
        this.zeApi.updateUmpireStances(game, game.umpire.id).then(data => {
          this.loadingStore.setLoading(false);
          game.umpireStances = data.entity.umpireStances;
        });
      }),
      showUmpireStancesAction: action(game => {
        this.showUmpireStances = true;
        this.selectedGame = observable(game);
        let umpireStances = {
          Square: this.doesStanceExist("Square", game),
          Knee: this.doesStanceExist("Knee", game),
          Scissors: this.doesStanceExist("Scissors", game),
          Slot: this.doesStanceExist("Slot", game)
        };
        this.selectedUmpireStances = observable(umpireStances);
      }),
      closeUmpireStances: action(() => {
        this.showUmpireStances = this.defaults["showUmpireStances"];
        this.selectedGame = this.defaults["selectedGame"];
        this.selectedUmpireStances = this.defaults["selectedUmpireStances"];
      }),
      cutKeyframes: action(gamePk => {
        this.zeApi.cutAllFrames(gamePk).then(data => {
          this.loadingStore.setLoading(false);
          this.alertStore.addAlert({
            type: AlertConstants.TYPES.SUCCESS,
            text: "Successfully Cut Frames!"
          });
        });
        this.alertStore.addAlert({
          type: AlertConstants.TYPES.SUCCESS,
          text: "Frame cutting initiated. This may take several minutes to complete."
        });
      }),
      setDisplayGameStatusCol: action(value => {
        this.displayGameStatusCol = value;
      })
    });

    autorun(() => {
      const params = queryString.parse(this.routerStore.location.search);
      if (params["umpires"]) {
        const umpires = params["umpires"].split(",");
        this.setUmpires(this.umpireOptions.filter(u => umpires.includes(u.value)));
      }
    });

    autorun(() => {
      if (authStore.isLoggedIn && (this.routerStore.isScheduleTab || this.routerStore.isSuperUmpireTab)) {
        this.updateFromUrlParams(this.routerStore.location.search);
      } else {
        this.date = this.defaults["date"];
      }
    });

    reaction(
      () => this.date,
      () => {
        this.displayGameStatusCol = DateUtil.getIsYesterdayOrTodayDate(this.date);
      }
    );
  }

  analyzeReleaseErrors = () => {
    let playIds = this.releaseErrorPitches.map(p => p.playId);
    this.toggleReleaseErrorsModal();
    this.loadingStore.setLoading(true, "Schedule", "Analyzing Game", 75);
    this.zeApi
      .analyzePitchesById(this.releaseErrorGamePk, playIds)
      .then(() => {
        this.loadingStore.setLoading(false);
        this.alertStore.addAlert({
          type: AlertConstants.TYPES.SUCCESS,
          text: "Successfully reanalyzed pitches"
        });
      })
      .catch(() => {
        this.toggleReleaseErrorsModal();
        this.alertStore.addAlert({
          type: AlertConstants.TYPES.DANGER,
          text: "Error reanalyzing pitches"
        });
      });
  };

  doesStanceExist(stance, game) {
    return game.umpireStances.indexOf(stance.toUpperCase()) >= 0;
  }

  getGames() {
    this.loadingStore.setLoading(true, "Schedule", "Loading Schedule", 75);
    this.setGames(this.defaults["games"]);
    if (!this.authStore.isUmpire && !this.routerStore.isSuperUmpireTab) {
      this.zeApi.getSchedule(this.date, DateUtil.getIsYesterdayOrTodayDate(this.date)).then(data => {
        this.setGames(data);
        this.loadingStore.setLoading(false);
      });
    } else if (this.authStore.isSuperUmpire || this.routerStore.isSuperUmpireTab) {
      const params = queryString.parse(this.routerStore.location.search);
      if (params["umpires"]) {
        this.zeApi.getUmpireSchedule(this.season.value, params["umpires"], this.sort.col, this.sort.asc).then(data => {
          this.setGames(data);
          this.loadingStore.setLoading(false);
        });
      } else {
        this.setGames([]);
        this.loadingStore.setLoading(false);
      }
    } else {
      const params = queryString.parse(this.routerStore.location.search);
      let umpireIds = params["umpires"] ? params["umpires"] : null;
      this.zeApi.getUmpireSchedule(this.season.value, umpireIds, this.sort.col, this.sort.asc).then(data => {
        this.setGames(data);
        this.loadingStore.setLoading(false);
      });
    }
  }

  getUmpireOptions() {
    this.loadingStore.setLoading(true, "Umpire", "Retrieving schedule", 75);
    this.zeApi.getUmpires(this.season.value).then(data => {
      let umpires = _.map(data, d => {
        if (d && d.id && d.name) {
          return {
            value: d.id.toString(),
            label: d.name.toString()
          };
        }
      });
      this.loadingStore.setLoading(false);
      this.setUmpireOptions(umpires);
    });
  }
}

export default ScheduleStore;
