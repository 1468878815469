import React from "react";
import { inject, observer } from "mobx-react";
import styled, { css } from "styled-components";
import GameViewerVideoCanvas from "./GameViewerVideoCanvas";
import GameViewerPitchcastCanvas from "./GameViewerPitchcastCanvas";
import { Button } from "reactstrap";

const ContentHolder = styled.div`
  &&& {
    height: ${props => (props.expanded ? "540px" : "270px")};
    width: ${props => (props.expanded ? "960px" : "480px")};
    position: relative;
    overflow: hidden;
  }
`;

const Video = styled.video.attrs({
  playsInline: true
})`
  &&& {
    ${props =>
      props.zoomed &&
      css`
        position: absolute;
        top: ${props => props.zoomTopAdj + "px"};
        left: ${props => props.zoomLeftAdj + "px"};
        transform: scale(${props => props.zoomFactor});
      `};
  }
`;

class GameViewerVideo extends React.Component {
  render() {
    const { expanded, rootStore } = this.props;
    const { gameViewerStore } = rootStore;
    const { currentVideo, isZoomed, showCanvas, videoWidth, videoZoomFactor } = gameViewerStore;
    const viewerWidth = expanded ? 960 : 480;
    const viewerHeight = expanded ? 540 : 270;
    let scale;
    if (currentVideo.typeName === "Centerfield") {
      scale = viewerWidth / 960;
    } else {
      scale = viewerWidth / videoWidth;
    }
    const pan = gameViewerStore.functions.getCurrentVideoPan();
    return (
      <div className="flex-column">
        <ContentHolder expanded={expanded}>
          {showCanvas ? <GameViewerVideoCanvas id="c" height={viewerHeight} width={viewerWidth} /> : null}
          {currentVideo && showCanvas && currentVideo.typeName === "Pitchcast" ? (
            <GameViewerPitchcastCanvas id="c" height={viewerHeight} width={viewerWidth} />
          ) : null}
          {currentVideo && currentVideo.show ? (
            <Video
              muted
              src={currentVideo.link}
              zoomed={isZoomed}
              zoomTopAdj={pan.up * scale}
              zoomLeftAdj={pan.left * scale}
              zoomFactor={videoZoomFactor}
              innerRef={v => gameViewerStore.setVideoRef(v)}
              height={viewerHeight}
              width={viewerWidth}
              preload="auto"
            />
          ) : null}
        </ContentHolder>
      </div>
    );
  }
}

export default inject("rootStore")(observer(GameViewerVideo));
