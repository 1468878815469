import React from "react";
import ScoresheetHeader from "./header/ScoresheetHeader";
import ScoresheetBody from "./body/ScoresheetBody";
import ScoresheetActions from "./ScoresheetActions";

class Scoresheet extends React.Component {
  render() {
    return (
      <div>
        <ScoresheetHeader />
        <ScoresheetBody />
        <ScoresheetActions />
      </div>
    );
  }
}

export default Scoresheet;
