import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { LinkConstants } from "../../../constants/LinkConstants";
const NavHolder = styled.div.attrs({
  className: "mr-2"
})`
  float: right;
`;

const NavItem = styled(NavLink).attrs({
  className: "ml-5"
})`
  float: left;
  text-decoration: none;
  color: #2b77eb;

  &:hover {
    cursor: pointer;
    color: black;
    text-decoration: none;
  }

  &.active {
    color: black;
    border-bottom: solid 3px #002e6d;
  }
`;

class AuditNavigation extends React.Component {
  render() {
    const {
      rootStore: { auditStore, routerStore },
      match
    } = this.props;

    return (
      <NavHolder>
        <NavItem
          to={`${match.url}/strike-zone?gamePk=${auditStore.gamePk}`}
          className={routerStore.isAuditStrikeZoneTab ? "active" : ""}
        >
          Strike Zones
        </NavItem>
        <NavItem
          to={`${match.url}/camera-calibration?gamePk=${auditStore.gamePk}`}
          className={routerStore.isAuditCameraCalibrationTab ? "active" : ""}
        >
          Camera Calibration
        </NavItem>
        <NavItem
          to={`${match.url}/strike-zone?gamePk=${auditStore.gamePk}`}
          target="_blank"
          onClick={() => {
            window.open(
              LinkConstants.RESEARCH_TOOL + auditStore.gamePk + "/plays/" + auditStore.selectedPitch.playId,
              "_blank"
            );
          }}
          className={""}
        >
          Research
        </NavItem>
      </NavHolder>
    );
  }
}

export default inject("rootStore")(observer(AuditNavigation));
