export const CallCorrectnessConstants = {
  CORRECT: "CORRECT",
  INCORRECT: "INCORRECT",
  ADJUSTED: "ADJUSTED",
  CATCHER_INFLUENCE: "CATCHER_INFLUENCE",
  LOW_BUFFER: "LOW_BUFFER",
  LOW_CATCH: "LOW_CATCH",
  HIGH_BUFFER: "HIGH_BUFFER",
  UNKNOWN: "UNKNOWN"
};
