import React from "react";
import styled from "styled-components";

const True = styled.span`
  color: green;
  font-weight: bold;
`;

const False = styled.span`
  color: red;
  font-weight: bold;
`;

class TrueFalse extends React.Component {
  render() {
    return this.props.check ? <True>True</True> : <False>False</False>;
  }
}

export default TrueFalse;
