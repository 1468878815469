import React from "react";
import { inject, observer } from "mobx-react";

function GameViewerUmpireStanceOption(props) {
  const { gameViewerStore } = props.rootStore;
  const { stance } = props;

  function updateStance(event) {
    props.rootStore.gameViewerStore.selectedUmpireStances[stance] = event.target.checked;
  }

  return (
    <div className="col-12">
      <div className="form-check form-check-inline">
        <label className="form-check-label">
          <input
            className="form-check-input"
            type="checkbox"
            checked={gameViewerStore.selectedUmpireStances[stance]}
            onChange={updateStance}
          />
          {stance}
        </label>
      </div>
    </div>
  );
}

export default inject("rootStore")(observer(GameViewerUmpireStanceOption));
