import React from "react";
import { inject, observer } from "mobx-react";
import { Popover, PopoverHeader, PopoverBody } from "reactstrap";
import styled from "styled-components";
import { CallCorrectnessConstants } from "../../../common/constants/CallCorrectnessConstants";
import StringUtil from "../../../../utilities/StringUtil";

const ActionButton = styled.button.attrs({
  className: "btn"
})`
  border: solid 1px #d2d2d2 !important;
  font-size: 11px !important;
  font-weight: bold !important;
  letter-spacing: -0.4px !important;
  max-height: 22px !important;
  min-height: 22px !important;
  max-width: ${props => props.width + "px"};
  min-width: ${props => props.width + "px"};
  padding: 0 !important;
  ${props => (props.selected ? "background-color: #fdec98 !important;" : "")};
`;

const AdjustPopover = styled(Popover)`
  max-width: 237px !important;
  min-width: 237px !important;
`;

const AdjustPopoverBody = styled(PopoverBody).attrs({
  className: "p-2"
})``;

const Correct = styled(ActionButton).attrs({
  className: "btn-success"
})`
  width: 107px;
`;

const DisputeComments = styled.textarea.attrs({
  className: "form-control",
  rows: "3"
})`
  &&& {
    font-size: 0.75rem;
    padding: 0.375rem;
    resize: none;
  }
`;

const Incorrect = styled(ActionButton).attrs({
  className: "btn-danger"
})`
  width: 107px;
`;

const KeyframeOffset = styled.input.attrs({
  className: "form-control",
  min: "-9",
  max: "9",
  type: "number"
})`
  font-size: 11px !important;
  font-weight: bold !important;
  letter-spacing: -0.4px !important;
  max-height: 22px !important;
  min-height: 22px !important;
  max-width: ${props => props.width + "px"};
  min-width: ${props => props.width + "px"};
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  ${props => (props.selected ? "background-color: #fdec98 !important;" : "")};
`;

const InfoRow = styled.div.attrs({
  className: "d-flex flex-row justify-content-between mt-1"
})`
  width: 100%;
`;

const Plain = styled(ActionButton).attrs({
  className: "mt-1"
})`
  width: 107px;
`;

const Secondary = styled(ActionButton).attrs({
  className: "btn-secondary mt-1"
})`
  width: 107px;
`;

const Submit = styled(Plain).attrs({
  className: "btn-primary mt-1"
})`
  width: 220px;
`;

const GameViewBatterSelectedPitch = props => {
  const { authStore, gameViewerStore } = props.rootStore;
  const { isAuditorAdmin, isUmpire, isUmpireSupervisor } = authStore;
  const { game, selectedPitch, isMilbGame, showFeedbackPopover } = gameViewerStore;
  const { absMode } = game;
  const hasDispute = selectedPitch && selectedPitch.dispute;

  const adjustButton = () => {
    return (
      <ActionButton
        disabled={!gameViewerStore.selectedPitch}
        id="popoverButton"
        onClick={gameViewerStore.toggleAdjustPitchPopover}
        width={115}
      >
        Adjust
      </ActionButton>
    );
  };

  const getCall = call => {
    let umpireCall = call ? call.toUpperCase() : "";
    switch (umpireCall) {
      case "BLOCKED_BALL":
      case "BALL":
        return "Ball";
      case "CALLED_STRIKE":
        return "Strike";
      case "SWINGING_STRIKE":
      case "SWINGING_STRIKE_BLOCKED":
      default:
        return "No Call";
    }
  };

  const adjustPopover = () => {
    const call = getCall(selectedPitch?.umpireCall);
    return (
      <AdjustPopover
        placement="bottom-start"
        isOpen={gameViewerStore.adjustPitchPopoverOpen}
        target="popoverButton"
        toggle={gameViewerStore.toggleAdjustPitchPopover}
      >
        <PopoverHeader>
          Pitch Number: {gameViewerStore.selectedPitch ? gameViewerStore.selectedPitch.pitchNumber : 0}
        </PopoverHeader>
        <AdjustPopoverBody>
          <div className="d-flex flex-row justify-content-between">
            <div className="d-flex flex-column justify-content-center">
              {correct("Correct", CallCorrectnessConstants.CORRECT, gameViewerStore.updatePitchCallCorrectness)}
              {call !== "Strike" &&
                plain("Low Catch", CallCorrectnessConstants.LOW_CATCH, gameViewerStore.updatePitchCallCorrectness)}
              {call !== "Ball" &&
                plain("Low Buffer", CallCorrectnessConstants.LOW_BUFFER, gameViewerStore.updatePitchCallCorrectness)}
            </div>
            <div className="d-flex flex-column justify-content-center">
              {incorrect("Incorrect", CallCorrectnessConstants.INCORRECT, gameViewerStore.updatePitchCallCorrectness)}
              {call !== "Strike" &&
                plain(
                  "Catcher Influence",
                  CallCorrectnessConstants.CATCHER_INFLUENCE,
                  gameViewerStore.updatePitchCallCorrectness
                )}
              {call !== "Ball" &&
                plain("High Buffer", CallCorrectnessConstants.HIGH_BUFFER, gameViewerStore.updatePitchCallCorrectness)}
            </div>
          </div>
        </AdjustPopoverBody>
      </AdjustPopover>
    );
  };

  const analyzeButton = () => {
    return (
      <ActionButton disabled={!gameViewerStore.selectedPitch} onClick={gameViewerStore.analyzePitch} width={115}>
        Analyze
      </ActionButton>
    );
  };

  const correct = (label, value, fn) => {
    return <Correct onClick={() => fn(value)}>{label}</Correct>;
  };

  const disputeButton = width => {
    const disputed = selectedPitch && selectedPitch.dispute;
    const resolution = disputed && selectedPitch.dispute.resolution;
    let label = "";

    if (resolution) {
      label = "View Dispute Resolution";
    } else if (disputed) {
      if (!selectedPitch.dispute.reviewed) {
        label = "Edit Dispute Reason";
      } else {
        label = "View Dispute Reason";
      }
    } else {
      label = "Dispute";
    }

    return (
      <ActionButton
        disabled={!(selectedPitch && (disputed || selectedPitch.callCorrectness === "INCORRECT"))}
        id={"popoverButton"}
        onClick={gameViewerStore.toggleDisputePopover}
        width={width}
      >
        {label}
      </ActionButton>
    );
  };

  const disputePopover = () => {
    if (!selectedPitch) {
      return null;
    }

    const disabled = selectedPitch.dispute && selectedPitch.dispute.disputeId;
    const editable = selectedPitch.dispute && selectedPitch.dispute.disputeId && !selectedPitch.dispute.reviewed;

    return (
      <AdjustPopover
        placement="top-start"
        isOpen={gameViewerStore.disputePopoverOpen}
        target="popoverButton"
        toggle={gameViewerStore.toggleDisputePopover}
      >
        <PopoverHeader>
          Pitch Number: {gameViewerStore.selectedPitch ? gameViewerStore.selectedPitch.pitchNumber : 0}
        </PopoverHeader>
        <AdjustPopoverBody>
          <DisputeComments
            disabled={disabled && !editable}
            onChange={updateDisputeReasonEvent}
            value={disputeText(selectedPitch)}
          />
          {!disabled || editable ? (
            <React.Fragment>
              <div className="d-flex flex-row justify-content-between">
                {plain("Wrong Call", "The wrong call is listed for this pitch.", updateDisputeReason)}
                {plain("Wrong Zone", "The strike zone for this pitch is set in the wrong spot.", updateDisputeReason)}
              </div>
              <div className="d-flex flex-row justify-content-between">
                {plain(
                  "Catcher Influence",
                  "This pitch should be adjusted due to catcher influence.",
                  updateDisputeReason
                )}
                {plain("Clear", "", updateDisputeReason)}
              </div>
              <div className="d-flex flex-row justify-content-between">
                {submit(gameViewerStore.selectedPitch, gameViewerStore.functions.disputePitch)}
              </div>
            </React.Fragment>
          ) : null}
        </AdjustPopoverBody>
      </AdjustPopover>
    );
  };

  const disputeText = pitch => {
    if (!pitch) {
      return "";
    } else if (!pitch.dispute) {
      return gameViewerStore.disputeReason;
    } else if (pitch.dispute.resolution && pitch.dispute.comments) {
      return pitch.dispute.comments.length ? pitch.dispute.comments[0].message : "";
    } else if (pitch.dispute && !pitch.dispute.resolution) {
      return gameViewerStore.disputeReason;
    } else {
      return pitch.dispute.disputeReason;
    }
  };

  const feedbackButton = () => {
    return (
      <ActionButton
        disabled={!selectedPitch}
        id={"popoverButton"}
        onClick={gameViewerStore.toggleFeedbackPopover}
        width={235}
      >
        {"ABS Feedback" + (selectedPitch?.absFeedback?.id ? " Submitted" : "")}
      </ActionButton>
    );
  };

  const feedbackPopover = () => {
    if (!selectedPitch) {
      return null;
    }

    const reasons = gameViewerStore.absFeedback.reasons.slice();
    const reasons2D = [];
    for (let i = 0; i < reasons.length; i += 2) {
      if (i + 1 < reasons.length) {
        reasons2D.push([reasons[i], reasons[i + 1]]);
      } else {
        reasons2D.push([reasons[i]]);
      }
    }

    return (
      <AdjustPopover
        placement="top-start"
        isOpen={gameViewerStore.showFeedbackPopover}
        target="popoverButton"
        toggle={gameViewerStore.toggleFeedbackPopover}
      >
        <PopoverHeader>Pitch Number: {gameViewerStore.selectedPitch?.pitchNumber ?? 0}</PopoverHeader>
        <AdjustPopoverBody>
          <DisputeComments
            disabled={!isUmpire}
            onChange={event => gameViewerStore.setAbsFeedback("description", event.target.value)}
            placeholder="Enter feedback here..."
            value={feedbackText(selectedPitch)}
          />
          <React.Fragment>
            {reasons2D.map(reasonsRow => (
              <div className="d-flex flex-row justify-content-between">
                {reasonsRow.map(reason => feedbackSelect(selectedPitch, reason))}
              </div>
            ))}
            <div className="d-flex flex-row justify-content-between">{feedbackSubmitBtn(selectedPitch)}</div>
          </React.Fragment>
        </AdjustPopoverBody>
      </AdjustPopover>
    );
  };

  const feedbackSelect = (pitch, reason) => {
    const feedbackReasonId = gameViewerStore.absFeedback?.reasonId ?? pitch?.absFeedback?.reasonId ?? null;
    const BtnType = feedbackReasonId === reason.id ? Secondary : Plain;
    const fn = () => {
      gameViewerStore.setAbsFeedback("reasonId", reason.id);
      gameViewerStore.setAbsFeedback("description", reason.description ?? "");
    };
    return (
      <BtnType disabled={!isUmpire} onClick={fn}>
        {reason.label}
      </BtnType>
    );
  };

  const feedbackSubmitBtn = pitch => {
    const enabled =
      isUmpire && gameViewerStore.absFeedback.reasonId && !StringUtil.isBlank(gameViewerStore.absFeedback.description);
    return (
      <Submit disabled={!enabled} onClick={gameViewerStore.functions.saveAbsFeedback}>
        {pitch?.absFeedback ? `Update` : `Submit`}
      </Submit>
    );
  };

  const feedbackText = pitch => {
    return gameViewerStore.absFeedback?.description ?? pitch?.absFeedback?.description ?? "";
  };

  const incorrect = (label, value, fn) => {
    return <Incorrect onClick={() => fn(value)}>{label}</Incorrect>;
  };

  const keyframeButton = () => {
    return (
      <React.Fragment>
        <ActionButton
          disabled={!gameViewerStore.selectedPitch}
          id="popoverButton"
          onClick={gameViewerStore.functions.moveKeyframe}
          width={115}
        >
          Keyframe
        </ActionButton>
        <span style={{ maxWidth: "115px" }}>
          <KeyframeOffset value={gameViewerStore.keyframeOffset} onChange={gameViewerStore.setKeyframeOffset} />
        </span>
      </React.Fragment>
    );
  };

  const plain = (label, value, fn) => {
    return <Plain onClick={() => fn(value)}>{label}</Plain>;
  };

  const submit = (value, fn) => {
    return <Submit onClick={() => fn(value)}>Submit</Submit>;
  };

  const updateDisputeReason = value => {
    gameViewerStore.setDisputeReason(value);
  };

  const updateDisputeReasonEvent = event => {
    updateDisputeReason(event.target.value);
  };

  return (
    <React.Fragment>
      <InfoRow>
        {isAuditorAdmin && !isMilbGame
          ? selectedPitch && selectedPitch.dispute
            ? disputeButton(115)
            : adjustButton()
          : null}
        {isAuditorAdmin && !isMilbGame ? analyzeButton() : null}
        {isAuditorAdmin && !isMilbGame ? (hasDispute ? disputePopover() : adjustPopover()) : null}
        {isUmpire && !isMilbGame ? disputeButton(235) : null}
        {isUmpireSupervisor && !isMilbGame && hasDispute ? disputeButton(235) : null}
        {isUmpire && !isMilbGame ? disputePopover() : null}
        {isUmpireSupervisor && !isMilbGame && hasDispute ? disputePopover() : null}
      </InfoRow>
      {absMode ? (
        <InfoRow>
          {showFeedbackPopover && feedbackPopover()}
          {(isUmpire || selectedPitch?.absFeedback) && feedbackButton()}
        </InfoRow>
      ) : null}
      {isAuditorAdmin && !isMilbGame ? <InfoRow>{keyframeButton()}</InfoRow> : null}
    </React.Fragment>
  );
};

export default inject("rootStore")(observer(GameViewBatterSelectedPitch));
