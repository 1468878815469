import React from "react";
import { inject, observer } from "mobx-react";

import ZeFieldText from "../../../elements/form/ZeFieldText";
import ScoresheetBodyViewItem from "./ScoresheetBodyViewItem";
import ZeCorrectText from "../../../elements/form/ZeCorrectText";
import ZeIncorrectText from "../../../elements/form/ZeIncorrectText";
import NoBold from "../../../elements/NoBold";
import ScoresheetBodyHeader from "../ScoresheetBodyHeader";
import ScoresheetSubHeader from "../../header/ScoresheetSubHeader";

class ScoresheetBallsAsStrikes extends React.Component {
  bodyViewItem(title, incorrectCalls, count, matchingCall, correct, desc) {
    const pitchNumbers = incorrectCalls
      ? incorrectCalls.filter(ic => ic.call && ic.call.startsWith(matchingCall)).map(ic => ic.pitchNumber)
      : [];
    return (
      <ScoresheetBodyViewItem title={title} desc={desc} pitchNumbers={pitchNumbers}>
        {correct ? (
          <ZeCorrectText>{count}</ZeCorrectText>
        ) : count ? (
          <ZeIncorrectText>{count}</ZeIncorrectText>
        ) : (
          <ZeFieldText>{count}</ZeFieldText>
        )}
      </ScoresheetBodyViewItem>
    );
  }

  render() {
    const {
      rootStore: { scoreSheetStore }
    } = this.props;
    const scoresheet = scoreSheetStore.scoreSheet;
    const {
      gameAnalysis: {
        averageErrorDistances: { OUTSIDE, INSIDE },
        callQualitySummary
      }
    } = scoresheet;
    let {
      adjHighBuffer,
      adjLowBuffer,
      calledStrikesHigh,
      calledStrikesInside,
      calledStrikesLow,
      calledStrikesOutside,
      incorrectCalls,
      questionableCalledStrikes
    } = callQualitySummary;

    return (
      <div className="card">
        <ScoresheetBodyHeader>
          Balls <NoBold>called as</NoBold> Strikes
        </ScoresheetBodyHeader>

        <div className="card-body">
          <ScoresheetSubHeader>
            <ZeFieldText className="col">ZE tracked pitch as being..</ZeFieldText>
          </ScoresheetSubHeader>

          {this.bodyViewItem("Outside", null, calledStrikesOutside, null, false, "Avg. Dist. Out: " + OUTSIDE)}
          {this.bodyViewItem("Inside", null, calledStrikesInside, null, false, "Avg. Dist. In: " + INSIDE)}
          {this.bodyViewItem("High", null, calledStrikesHigh, null)}
          {this.bodyViewItem("High Buffer", incorrectCalls, adjHighBuffer, "strike_highBuffer", true, "Pitch(es): ")}
          {this.bodyViewItem("Low", null, calledStrikesLow, null)}
          {this.bodyViewItem("Low Buffer", incorrectCalls, adjLowBuffer, "strike_lowBuffer", true, "Pitch(es): ")}
          {this.bodyViewItem("Total", incorrectCalls, questionableCalledStrikes, "strike")}
        </div>
      </div>
    );
  }
}

export default inject("rootStore")(observer(ScoresheetBallsAsStrikes));
