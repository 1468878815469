import React from "react";
import { inject, observer } from "mobx-react";
import ZeLightButton from "../../../elements/form/ZeLightButton";

class GameViewBatterSideButtons extends React.Component {
  render() {
    const { gameViewerStore } = this.props.rootStore;
    return (
      <div className="flex-column">
        <ZeLightButton
          isActive={gameViewerStore.handFilter === "All"}
          onClick={() => {
            gameViewerStore.setHandFilter("All");
          }}
          className="btn-sm mr-1"
        >
          All
        </ZeLightButton>
        <ZeLightButton
          isActive={gameViewerStore.handFilter === "Right"}
          onClick={() => {
            gameViewerStore.setHandFilter("Right");
          }}
          className="btn-sm mr-1"
        >
          Right Handed
        </ZeLightButton>
        <ZeLightButton
          isActive={gameViewerStore.handFilter === "Left"}
          onClick={() => {
            gameViewerStore.setHandFilter("Left");
          }}
          className="btn-sm mr-1"
        >
          Left Handed
        </ZeLightButton>
      </div>
    );
  }
}

export default inject("rootStore")(observer(GameViewBatterSideButtons));
