import React from "react";
import ZeDropdownItem from "../../DropdownItem";
import ZeLightButton from "../../form/ZeLightButton";

const DISPUTE_ACTIONS = {
  NONE: "None",
  DENY: "Deny",
  CORRECT: "Correct",
  CATCHER_INFLUENCE: "Catcher Influence",
  HIGH_BUFFER: "High Buffer",
  LOW_BUFFER: "Low Buffer",
  LOW_CATCH: "Low Catch"
};

class DropDownCellFormatter extends React.Component {
  dropdownItem(label, resolution) {
    const { setDisputeResolution } = this.props.dependentValues;

    return <ZeDropdownItem onClick={() => setDisputeResolution(resolution)}>{label}</ZeDropdownItem>;
  }

  render() {
    const { resolution } = this.props.dependentValues;

    return (
      <div>
        <ZeLightButton
          className="dropdown-toggle"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          grid="true"
        >
          {DISPUTE_ACTIONS[resolution]}
        </ZeLightButton>
        <div className="dropdown-menu pull-left" aria-labelledby="dropdownMenuButton">
          {this.dropdownItem("Correct", "CORRECT")}
          {this.dropdownItem("Catcher Influence", "CATCHER_INFLUENCE")}
          {this.dropdownItem("Deny", "DENY")}
          {this.dropdownItem("High Buffer", "HIGH_BUFFER")}
          {this.dropdownItem("Low Buffer", "LOW_BUFFER")}
          {this.dropdownItem("Low Catch", "LOW_CATCH")}
          {this.dropdownItem("None", "NONE")}
        </div>
      </div>
    );
  }
}

export default DropDownCellFormatter;
