// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml;charset=utf8,%3Csvg viewBox=%270 0 32 32%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cpath stroke=%27#002e6d%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 d=%27M4 8h24M4 16h24M4 24h24%27/%3E%3C/svg%3E", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0 !important;
  padding: 0 !important;
  font-family: sans-serif !important;
  background-color: #f7f7f7 !important;
}

.col-form-label {
  font-weight: bold !important;
}

.custom-toggler.navbar-toggler {
    border-color: #002e6d !important;
    background-color: white !important;
}

.custom-toggler .navbar-toggler-icon {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,qBAAqB;EACrB,kCAAkC;EAClC,oCAAoC;AACtC;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;IACI,gCAAgC;IAChC,kCAAkC;AACtC;;AAEA;IACI,yDAA0P;AAC9P","sourcesContent":["body {\n  margin: 0 !important;\n  padding: 0 !important;\n  font-family: sans-serif !important;\n  background-color: #f7f7f7 !important;\n}\n\n.col-form-label {\n  font-weight: bold !important;\n}\n\n.custom-toggler.navbar-toggler {\n    border-color: #002e6d !important;\n    background-color: white !important;\n}\n\n.custom-toggler .navbar-toggler-icon {\n    background-image: url(\"data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#002e6d' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E\");\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
