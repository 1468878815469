import styled from "styled-components";
import { Link } from "react-router-dom";

const ZeDropdownItemLink = styled(Link).attrs({
  className: "dropdown-item"
})`
  background-color: #ffffff !important;
  ${props => (props.color ? "color: " + props.color + "!important;" : null)}
  &:hover {
    cursor: pointer;
    color: #2b77eb !important;
  }
`;

export default ZeDropdownItemLink;
