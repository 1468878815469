import { action, extendObservable, autorun, observable, computed } from "mobx";
import { DisputeConstants } from "../components/dispute/DisputeConstants";
import _ from "lodash";
import queryString from "query-string";
import { AuthConstants } from "../components/login/AuthConstants";

const DISPUTE_TABLE_SCROLL_LOCAL_STORAGE = "disputeTableScrollPos";

class DisputeStore {
  getDisputeUrl(url, disputeFilters) {
    if (disputeFilters[DisputeConstants.KEYS.SEASON]) {
      url += "season=" + disputeFilters[DisputeConstants.KEYS.SEASON];
    }
    if (disputeFilters[DisputeConstants.KEYS.ACTIVE_RESOLVED]) {
      url += "&state=" + disputeFilters[DisputeConstants.KEYS.ACTIVE_RESOLVED];
    }
    if (disputeFilters[DisputeConstants.KEYS.RESOLUTION]) {
      url += "&resolution=" + disputeFilters[DisputeConstants.KEYS.RESOLUTION];
    }
    if (this.authStore.isUmpire && !this.authStore.isSuperUmpire) {
      url += "&umpire=" + localStorage.getItem(AuthConstants.KEYS.UMPIRE_ID);
    } else {
      if (disputeFilters[DisputeConstants.KEYS.UMPIRE]) {
        url += "&umpire=" + disputeFilters[DisputeConstants.KEYS.UMPIRE];
      }
    }
    url += "&admin=" + this.isAdmin;
    return url;
  }

  updateUrlParam(params, key) {
    if (params && params[key]) {
      this.disputeFilters.set(key, params[key]);
    } else if (this.defaults[key]) {
      this.disputeFilters.set(key, this.defaults[key]);
    }
  }

  constructor(routerStore, zeApi, loadingStore, authStore) {
    this.routerStore = routerStore;
    this.zeApi = zeApi;
    this.loadingStore = loadingStore;
    this.authStore = authStore;

    // bind functions
    this.resolveDisputes = this.resolveDisputes.bind(this);
    this.unresolveDisputes = this.unresolveDisputes.bind(this);

    this.defaults = {
      isAdmin: false,
      sort: { col: "", asc: true },
      commentModalOpen: false,
      disputes: [],
      disputeCount: {
        active: 0,
        resolved: 0
      },
      disputeFilters: observable.map(),
      umpireOptions: [],
      seasonOptions: [],
      resolutionOptions: [
        { value: "ACCEPTABLE", label: "Acceptable" },
        { value: "CATCHER_INFLUENCE", label: "Catcher Influence" },
        { value: "CORRECT", label: "Correct" },
        { value: "DENY", label: "Deny" },
        { value: "HIGH_BUFFER", label: "High Buffer" },
        { value: "LOW_BUFFER", label: "Low Buffer" },
        { value: "LOW_CATCH", label: "Low Catch" }
      ],
      selectedDispute: observable({}),
      activeResolvedOptions: [
        { value: "all", label: "All" },
        { value: "active", label: "Active" },
        { value: "resolved", label: "Resolved" }
      ],
      confirmationModalTitle: "Resolve Disputes",
      modalAction: null,
      selectedResolution: null,
      showConfirmationModal: false,
      refreshDisputeList: true,
      disputeTableScrollPos: 0
    };
    this.defaults[DisputeConstants.KEYS.SEASON] =
      new Date().getMonth() < 3 ? (new Date().getFullYear() - 1).toString() : new Date().getFullYear().toString();
    this.defaults[DisputeConstants.KEYS.ACTIVE_RESOLVED] = "active";

    extendObservable(this, {
      isAdmin: this.defaults["isAdmin"],
      sort: observable(Object.assign(this.defaults["sort"])),
      commentModalOpen: this.defaults["commentModalOpen"],
      disputes: this.defaults["disputes"],
      disputeCount: this.defaults["disputeCount"],
      disputeFilters: this.defaults["disputeFilters"],
      umpireOptions: this.defaults["umpireOptions"],
      seasonOptions: this.defaults["seasonOptions"],
      resolutionOptions: this.defaults["resolutionOptions"],
      selectedDispute: this.defaults["selectedDispute"],
      activeResolvedOptions: this.defaults["activeResolvedOptions"],
      confirmationModalTitle: this.defaults["confirmationModalTitle"],
      modalAction: this.defaults["modalAction"],
      refreshDisputeList: this.defaults["refreshDisputeList"],
      selectedResolution: this.defaults["selectedResolution"],
      showConfirmationModal: this.defaults["showConfirmationModal"],
      disputeTableScrollPos: this.defaults["disputeTableScrollPos"],
      setCommentModalOpen: action(value => {
        this.commentModalOpen = value;
      }),
      setConfirmationModalTitle: action(value => {
        this.confirmationModalTitle = value;
      }),
      setDisputeCount: action(value => {
        this.disputeCount = value;
      }),
      setDisputes: action(value => {
        this.disputes = value;
      }),
      setDisputeResolution: action((dispute, resolution) => {
        dispute.resolution = resolution;
      }),
      setDisputeSelected: action((dispute, selected) => {
        dispute.selected = selected;
      }),
      setModalAction: action(value => {
        this.modalAction = value;
      }),
      setRefreshDisputeList: action(value => {
        this.refreshDisputeList = value;
      }),
      setSeasonOptions: action(values => {
        this.seasonOptions = values;
      }),
      setSelectedResolution: action(value => {
        this.selectedResolution = value;
      }),
      setShowConfirmationModal: action(value => {
        this.showConfirmationModal = value;
      }),
      setTeamOptions: action(values => {
        this.teamOptions = values;
      }),
      setUmpireOptions: action(values => {
        this.umpireOptions = values;
      }),
      updateDisputeFilter: action((key, value) => {
        if (!value || value.length === 0) {
          this.disputeFilters.delete(key);
        } else {
          this.disputeFilters.set(key, value);
        }

        this.setRefreshDisputeList(true);
        this.routerStore.history.push({
          pathname: this.routerStore.location.pathname,
          search: this.urlParams
        });
      }),
      updateSelectedDispute: action(value => {
        for (let i = 0; i < this.disputes.length; i++) {
          if (this.disputes[i] && this.disputes[i].disputeId === value.disputeId) {
            this.disputes[i] = value;
            this.selectedDispute = value;
          }
        }
      }),
      updateDisputes: action(values => {
        let updatedValues = values.map(dispute => {
          if (!dispute.resolution) {
            dispute.resolution = "NONE";
          }
          dispute.selected = false;
          return dispute;
        });
        this.disputes = updatedValues;
      }),
      setSelectedDispute: action(value => {
        this.selectedDispute = observable(value);
      }),
      setSort: action(col => {
        if (col === this.sort.col) {
          if (this.sort.asc) {
            // sort descending
            this.sort.asc = false;
          } else {
            // clear sort
            this.sort.asc = true;
            this.sort.col = "";
          }
        } else {
          this.sort.asc = true;
          this.sort.col = col;
        }
        this.routerStore.history.push({
          pathname: this.routerStore.location.pathname,
          search: this.urlParams
        });
      }),
      updateFromUrlParams: action(search => {
        const params = queryString.parse(search);
        this.updateUrlParam(params, DisputeConstants.KEYS.SEASON);
        this.updateUrlParam(params, DisputeConstants.KEYS.UMPIRE);
        this.updateUrlParam(params, DisputeConstants.KEYS.ACTIVE_RESOLVED);
        this.updateUrlParam(params, DisputeConstants.KEYS.RESOLUTION);
        const season = this.disputeFilters.get(DisputeConstants.KEYS.SEASON);
        if (this.refreshDisputeList) {
          this.loadingStore.setLoading(true, "Loading", "Loading Disputes", 75);
          this.getUmpires(season);
          this.getDisputes();
          this.getDisputeCount();
          this.setRefreshDisputeList(false);
        }
        let sortCol = params[DisputeConstants.KEYS.SORT_COL];
        if (sortCol) {
          if (
            (sortCol === "resolution" || sortCol === "resolvedDateAsString") &&
            this.disputeFilters.get(DisputeConstants.KEYS.ACTIVE_RESOLVED) === "all"
          ) {
            this.sort = Object.assign(this.defaults["sort"]);
            this.routerStore.history.push({
              pathname: this.routerStore.location.pathname,
              search: this.urlParams
            });
          } else {
            this.sort.col = params[DisputeConstants.KEYS.SORT_COL];
            let asc = params[DisputeConstants.KEYS.ASC];
            this.sort.asc = asc === "true";
          }
        }
      }),
      getUmpires: action(season => {
        this.zeApi.getUmpiresWithDisputes(season).then(data => {
          let umpires = _.map(data, d => {
            if (d && d.id && d.name) {
              return {
                value: d.id.toString(),
                label: d.name.toString()
              };
            }
          });
          this.setUmpireOptions(umpires);
        });
      }),
      fillSeasons: action(() => {
        let years = [];
        for (let i = 2011; i <= new Date().getFullYear(); i++) {
          years.push({ value: i.toString(), label: i.toString() });
        }
        years.reverse();
        this.setSeasonOptions(years);
      }),
      saveDisputeComment: action(comment => {
        this.loadingStore.setLoading(true, "Saving", "Saving Dispute Comment", 75);
        this.zeApi.saveDisputeComment(comment, this.selectedDispute).then(data => {
          this.updateSelectedDispute(data);
          this.loadingStore.setLoading(false);
        });
      }),
      getDisputes: action(() => {
        this.setDisputes(this.defaults["disputes"]);
        let disputeUrl = "/dispute?";
        const url = this.getDisputeUrl(disputeUrl, this.disputeFilters.toJS());
        this.zeApi.getDisputes(url).then(data => {
          if (data) {
            if (!data.error) {
              this.updateDisputes(data);
            } else {
              console.log(data);
            }
          }
          this.loadingStore.setLoading(false);
        });
      }),
      getDisputeCount: action(() => {
        let disputeUrl = "/dispute/count?";
        this.setDisputeCount(this.defaults["disputeCount"]);
        const url = this.getDisputeUrl(disputeUrl, this.disputeFilters.toJS());
        this.zeApi.getDisputesCount(url).then(data => {
          this.disputeCount = data;
        });
      }),
      setDisputeTableScrollPos: action(scrollPos => {
        this.disputeTableScrollPos = scrollPos;
      }),
      getFilterValue: function(key) {
        let filterValues = this.disputeFilters.get(key);
        if (!filterValues) {
          return null;
        } else if (!Array.isArray(filterValues)) {
          filterValues = filterValues.split(",");
        }
        return this[key + "Options"].filter(elem => filterValues.includes(elem.value));
      },
      // computeds
      disputeRows: computed(() => {
        let _this = this;
        return this.sortedDisputes.map(d => {
          let row = Object.assign({}, d);
          row.pitch = { gamePk: row.gamePk, pitchNumber: row.pitchNumber, playId: row.playId, umpire: row.umpire };
          row.setDisputeResolution = resolution => _this.setDisputeResolution(d, resolution);
          row.setDisputeSelected = selected => _this.setDisputeSelected(d, selected);
          return row;
        });
      }),
      isAnyActionSelected: computed(() => {
        return !!this.selectedDisputes.length;
      }),
      isAnyResolvedDisputeSelected: computed(() => {
        return !!this.selectedResolvedDisputes.length;
      }),
      resolvedCounts: computed(() => {
        let counts = {};
        this.selectedResolvedDisputes.forEach(d => {
          let key = DisputeConstants.DISPUTE_ACTIONS[d.resolution];
          counts[key] ? counts[key]++ : (counts[key] = 1);
        });
        let countsArray = [];
        Object.keys(counts).forEach(key =>
          countsArray.push({
            key: key,
            value: counts[key]
          })
        );
        return countsArray;
      }),
      selectedDisputes: computed(() => {
        return this.disputes.filter(d => !d.resolvedDateAsString && d.resolution !== "NONE");
      }),
      selectedResolvedDisputes: computed(() => {
        return this.disputes.filter(d => d.resolvedDateAsString && d.selected);
      }),
      selectableDisputes: computed(() => {
        return this.disputes.filter(d => {
          return !d.resolution;
        });
      }),
      sortedDisputes: computed(() => {
        if (!(this.disputes && this.sort)) {
          return this.disputes;
        }
        let sortedDisputes = this.disputes.slice();
        let { asc, col } = this.sort;
        return sortedDisputes.sort((a, b) => this.compareDisputes(a, b, col, asc));
      }),
      unresolvedCounts: computed(() => {
        let counts = {};
        this.selectedDisputes.forEach(d => {
          let key = DisputeConstants.DISPUTE_ACTIONS[d.resolution];
          counts[key] ? counts[key]++ : (counts[key] = 1);
        });
        let countsArray = [];
        Object.keys(counts).forEach(key =>
          countsArray.push({
            key: key,
            value: counts[key]
          })
        );
        return countsArray;
      }),
      urlParams: computed(() => {
        let params = "";

        if (this.disputeFilters.has(DisputeConstants.KEYS.SEASON)) {
          params += `&${DisputeConstants.KEYS.SEASON}=${this.disputeFilters.get(DisputeConstants.KEYS.SEASON)}`;
        }

        if (this.disputeFilters.has(DisputeConstants.KEYS.UMPIRE)) {
          params += `&${DisputeConstants.KEYS.UMPIRE}=${this.disputeFilters.get(DisputeConstants.KEYS.UMPIRE)}`;
        }

        if (this.disputeFilters.has(DisputeConstants.KEYS.ACTIVE_RESOLVED)) {
          params += `&${DisputeConstants.KEYS.ACTIVE_RESOLVED}=${this.disputeFilters.get(
            DisputeConstants.KEYS.ACTIVE_RESOLVED
          )}`;
        }

        if (this.disputeFilters.has(DisputeConstants.KEYS.RESOLUTION)) {
          params += `&${DisputeConstants.KEYS.RESOLUTION}=${this.disputeFilters.get(DisputeConstants.KEYS.RESOLUTION)}`;
        }

        if (this.sort.col) {
          params += `&${DisputeConstants.KEYS.SORT_COL}=${this.sort.col}`;
          params += `&${DisputeConstants.KEYS.ASC}=${this.sort.asc}`;
        }

        return params;
      })
    });

    autorun(() => {
      this.isAdmin = this.authStore.containsAuthorities([
        AuthConstants.USER_ROLES.ZE_SUPER_ADMIN,
        AuthConstants.USER_ROLES.ZE_AUDITOR_ADMIN,
        AuthConstants.USER_ROLES.ZE_BOC_ADMIN
      ]);
      if (authStore.isLoggedIn && this.routerStore.isDisputesTab) {
        this.updateFromUrlParams(this.routerStore.location.search);
      }
    });
  }

  compareDisputes(disputeA, disputeB, col, asc) {
    switch (col) {
      case "pitch":
        if (disputeA.pitchNumber > disputeB.pitchNumber) {
          return asc ? 1 : -1;
        } else if (disputeB.pitchNumber > disputeA.pitchNumber) {
          return asc ? -1 : 1;
        } else {
          return 0;
        }
      case "umpire":
        if (disputeA.umpire.name > disputeB.umpire.name) {
          return asc ? -1 : 1;
        } else if (disputeB.umpire.name > disputeA.umpire.name) {
          return asc ? 1 : -1;
        } else {
          return 0;
        }
      default:
        if (col && col.length) {
          if (disputeA[col] && disputeB[col]) {
            if (disputeA[col].toLowerCase() > disputeB[col].toLowerCase()) {
              return asc ? 1 : -1;
            } else if (disputeB[col].toLowerCase() > disputeA[col].toLowerCase()) {
              return asc ? -1 : 1;
            } else {
              return 0;
            }
          }
        }
        return 0;
    }
  }

  getTeams() {
    this.zeApi.getTeams().then(data => {
      let teams = _.map(data, d => {
        if (d && d.id && d.name) {
          return {
            value: d.id.toString(),
            label: d.name.toString()
          };
        }
      });
      this.setTeamOptions(teams);
    });
  }

  resolveDisputes() {
    let disputes = this.selectedDisputes.map(d => {
      d.resolvedById = this.authStore.username;
      d.resolvedByName = this.authStore.name;
      return d;
    });
    this.zeApi.resolveDisputes(disputes).then(d => {
      this.setShowConfirmationModal(false);
      this.getDisputes();
      this.getDisputeCount();
      localStorage.setItem(DISPUTE_TABLE_SCROLL_LOCAL_STORAGE, this.disputeTableScrollPos);
    });
  }

  unresolveDisputes() {
    this.zeApi.unresolveDisputes(this.selectedResolvedDisputes).then(d => {
      this.setShowConfirmationModal(false);
      this.getDisputes();
      this.getDisputeCount();
    });
  }
}

export default DisputeStore;
