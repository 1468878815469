import React from "react";
import Select from "react-select";

class YearPickerSelect extends React.Component {
  constructor(props) {
    super(props);
    this.calculateYears = this.calculateYears.bind(this);
  }

  calculateYears() {
    let years = [];
    let start = this.props.startYear || 1900;
    let end = this.props.endYear || new Date().getFullYear();
    for (let i = start; i <= end; i++) {
      years.push({ value: i, label: i });
    }
    return years;
  }

  render() {
    let years = this.calculateYears();
    return (
      <Select
        name="year-picker"
        value={this.props.value}
        onChange={this.props.onChange}
        options={years}
        disabled={this.props.isDisabled}
      />
    );
  }
}

export default YearPickerSelect;
