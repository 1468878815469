import React from "react";
import Media from "react-media";
import { inject, observer } from "mobx-react";
import GameViewerContentLargeScreen from "./GameViewerContentLargeScreen";
import GameViewerContentSmallScreen from "./GameViewerContentSmallScreen";
import GameViewerContentTallScreen from "./GameViewerContentTallScreen";
import GameViewerUmpireStancesModal from "./GameViewerUmpireStancesModal";

class GameViewerContent extends React.Component {
  render() {
    const { videoExpanded } = this.props.rootStore.gameViewerStore;
    return (
      <div>
        <GameViewerUmpireStancesModal />
        <Media query="(min-width: 1520px)">
          {matches =>
            matches ? (
              <GameViewerContentLargeScreen />
            ) : videoExpanded ? (
              <GameViewerContentTallScreen />
            ) : (
              <GameViewerContentSmallScreen />
            )
          }
        </Media>
      </div>
    );
  }
}

export default inject("rootStore")(observer(GameViewerContent));
