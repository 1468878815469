import React from "react";
import { inject, observer } from "mobx-react";
import GameViewFilterDropdown from "./GameViewFilterDropdown";
import styled from "styled-components";
import GameViewerCartoonControls from "./GameViewerCartoonControls";

const GAME_CONTEXT = process.env.REACT_APP_GAMEVIEWER_GAME_CONTEXT === "true";

const DropdownFilter = styled.div.attrs({
  className: "d-flex flex-column"
})``;

const FlexRow = styled.div.attrs({
  className: "d-flex flex-row justify-content-between"
})`
  ${props => (props.marginTop ? "margin-top: " + props.marginTop + "px !important" : "")};
`;

const GameViewerDropdownFilters = ({ rootStore: { gameViewerStore } }) => {
  const {
    batterFilter,
    batterOptions,
    pitcherFilter,
    pitcherOptions,
    setBatterFilter,
    setPitcherFilter,
    gameContextOptions,
    setGameContexts,
    gameContexts
  } = gameViewerStore;

  return (
    <DropdownFilter>
      <FlexRow>
        <GameViewFilterDropdown
          multi={true}
          onChange={setPitcherFilter.bind(gameViewerStore)}
          options={pitcherOptions}
          placeholder="Pitcher(s)"
          value={pitcherFilter}
        />
      </FlexRow>
      <FlexRow marginTop={7}>
        <GameViewFilterDropdown
          multi={true}
          onChange={setBatterFilter.bind(gameViewerStore)}
          options={batterOptions}
          placeholder="Batter(s)"
          value={batterFilter}
        />
      </FlexRow>
      {GAME_CONTEXT && (
        <FlexRow marginTop={7}>
          <GameViewFilterDropdown
            multi={true}
            onChange={setGameContexts}
            options={gameContextOptions}
            placeholder="Game Context"
            value={gameContexts}
          />
        </FlexRow>
      )}
      <GameViewerCartoonControls />
    </DropdownFilter>
  );
};

export default inject("rootStore")(observer(GameViewerDropdownFilters));
