import { extendObservable, action, computed, autorun } from "mobx";
import { AlertConstants } from "../components/common/alert/AlertConstants";

class ReportStore {
  constructor(routerStore, zeApi, loadingStore, alertStore) {
    this.routerStore = routerStore;
    this.zeApi = zeApi;
    this.loadingStore = loadingStore;
    this.alertStore = alertStore;

    this.includeUnreleasedOptions = [
      {
        label: "Yes",
        value: true
      },
      {
        label: "No",
        value: false
      }
    ];
    this.useHawkeyeOptions = this.includeUnreleasedOptions;

    let date = new Date();
    date.setDate(date.getDate() - 1);
    this.defaults = {
      date: date,
      dateRange: {
        start: date,
        end: date
      },
      emailUsers: [],
      gamePks: [],
      includeUnreleased: {
        label: "No",
        value: false
      },
      reports: [],
      selectedReport: {
        label: "Game Summary Report",
        value: 1
      },
      users: [],
      useHawkeye: {
        label: "No",
        value: false
      },
      userStr: ""
    };

    extendObservable(this, {
      date: this.defaults["date"],
      dateRange: this.defaults["dateRange"],
      emailUsers: this.defaults["emailUsers"],
      gamePks: this.defaults["gamePks"],
      includeUnreleased: this.defaults["includeUnreleased"],
      reports: this.defaults["reports"],
      selectedReport: this.defaults["selectedReport"],
      users: this.defaults["users"],
      useHawkeye: this.defaults["useHawkeye"],
      userStr: this.defaults["userStr"],
      userEmailSplit: computed(() => {
        let split = this.userStr.split(",");
        let users = [];
        split.forEach(u => {
          if (u && u.length > 0 && u.indexOf("@") >= 0) {
            users.push(u);
          }
        });
        return users;
      }),
      setUsers: action(event => {
        this.userStr = event.target.value;
      }),
      setDate: action(event => {
        this.date = event._d;
      }),
      runReport: action(() => {
        this.loadingStore.setLoading(true, "Report", "Running Report", 75);
        switch (this.selectedReport.value) {
          case 1:
          case 2:
            this.zeApi
              .sendReport(this.selectedReport.value, this.userEmailSplit, this.date, this.includeUnreleased.value)
              .then(data => {
                this.loadingStore.setLoading(false);
                alertStore.addAlert({
                  type: AlertConstants.TYPES.SUCCESS,
                  text: "Successfully ran report!"
                });
              });
            break;
          case 3:
          case 4:
          case 6:
          case 7:
          case 8:
          case 9:
            this.zeApi
              .sendBatch(
                this.selectedReport.value,
                this.userEmailSplit,
                this.dateRange,
                this.gamePksSplit,
                this.useHawkeye.value
              )
              .then(data => {
                this.loadingStore.setLoading(false);
                alertStore.addAlert({
                  type: AlertConstants.TYPES.SUCCESS,
                  text: "Successfully ran batch!"
                });
              });
            break;
          case 5:
            this.zeApi.sendReport(this.selectedReport.value, this.userEmailSplit, this.date).then(data => {
              this.loadingStore.setLoading(false);
              alertStore.addAlert({
                type: AlertConstants.TYPES.SUCCESS,
                text: "Successfully ran report!"
              });
            });
            break;
          default:
            break;
        }
      }),
      setEndDate: action(event => {
        this.dateRange.end = event._d;
      }),
      setGamePks: action(event => {
        this.gamePks = event.target.value;
      }),
      setSelectedReport: action(event => {
        this.selectedReport = event;
      }),
      setStartDate: action(event => {
        this.dateRange.start = event._d;
      }),
      setEmailUsers: action(users => {
        this.emailUsers = users;
      }),
      setIncludeUnreleased: action(value => {
        this.includeUnreleased = value;
      }),
      setReports: action(data => {
        this.reports = data;
      }),
      setUseHawkeye: action(value => {
        this.useHawkeye = value;
      }),
      resetFilters: action(() => {
        this.userStr = this.defaults["userStr"];
        this.users = this.defaults["users"];
        this.date = this.defaults["date"];
        this.includeUnreleased = this.defaults["includeUnreleased"];
        this.selectedReport = this.defaults["selectedReport"];
      }),
      getReports: action(() => {
        this.loadingStore.setLoading(true, "Reports", "Retrieving Reports", 75);
        this.zeApi.getReports().then(data => {
          this.setReports(data);
          this.loadingStore.setLoading(false);
        });
      }),
      getUsers: action(() => {
        this.zeApi.getUsers().then(data => {
          this.setEmailUsers(data);
        });
      }),
      gamePksSplit: computed(() => {
        let gamePks = [];
        if (!this.gamePks || !this.gamePks.length) {
          return gamePks;
        }
        let split = this.gamePks.split(",");
        split.forEach(g => {
          if (g && g.length > 0) {
            const num = Number.parseInt(g, 10);
            if (Number.isInteger(num)) {
              gamePks.push(num);
            }
          }
        });
        return gamePks;
      }),
      isValidSearch: computed(() => {
        if (this.selectedReport.value <= 0) {
          return false;
        }
        if (this.selectedReport.value < 7 && this.userEmailSplit.length <= 0) {
          return false;
        }
        switch (this.selectedReport.value) {
          case 1:
          case 2:
          case 5:
          case 6:
            return this.date;
          case 3:
          case 4:
            return this.gamePks || (this.dateRange.start && this.dateRange.end);
          case 7:
          case 8:
          case 9:
            return this.dateRange.start && this.dateRange.end;
          default:
            return false;
        }
      }),
      selectOptions: computed(() => {
        let options = [];
        this.reports.forEach(r => {
          options.push({ value: r.id, label: r.name });
        });
        return options;
      })
    });

    autorun(() => {
      if (this.routerStore.isReportsTab) {
        this.getUsers();
        this.getReports();
      }
    });
  }
}

export default ReportStore;
