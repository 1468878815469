import React from "react";
import { inject, observer } from "mobx-react/index";
import ZeLightButton from "../elements/form/ZeLightButton";
import LinkButton from "./LinkButton";

const GameSummaryFooterButtons = props => {
  let reportStore = props.rootStore.reportStore;
  return (
    <div className="row">
      <div className="col">
        <div className="float-right">
          <LinkButton
            to={"/gameSummary"}
            isActive={true}
            date={reportStore.date}
            includeUnreleased={reportStore.includeUnreleased.value}
          >
            Open Report
          </LinkButton>
          <button className="btn btn-primary mr-2" type="submit" disabled={!reportStore.isValidSearch}>
            Send Report
          </button>
          <ZeLightButton
            onClick={event => {
              event.preventDefault();
              reportStore.resetFilters();
            }}
          >
            Reset Filters
          </ZeLightButton>
        </div>
      </div>
    </div>
  );
};

export default inject("rootStore")(observer(GameSummaryFooterButtons));
