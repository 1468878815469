import React from "react";
import NavBarSignout from "./NavBarSignout";
import Navigation from "./Navigation";

class Navbar extends React.Component {
  render() {
    return (
      <div>
        <NavBarSignout />
        <Navigation />
      </div>
    );
  }
}

export default Navbar;
