import React from "react";
import GameViewBatterSideButtons from "./GameViewBatterSideButtons";
import GameViewBatterPitchInfo from "./GameViewBatterPitchInfo";
import GameViewBatterSelectedPitch from "./GameViewBatterSelectedPitch";
import GameViewerBatterGraphic from "./GameViewerBatterGraphic";
import GameViewCameraButtons from "./GameViewCameraButtons";

class GameViewBatterLargeScreen extends React.Component {
  render() {
    return (
      <div className="d-flex flex-row">
        <div className="flex-column">
          <div className="d-flex justify-content-center ">
            <GameViewCameraButtons />
          </div>

          <div className="d-flex justify-content-center mt-2">
            <div className="flex-column">
              <GameViewerBatterGraphic />
            </div>
          </div>

          <div className="d-flex justify-content-center mt-2">
            <GameViewBatterSideButtons />
          </div>

          <div className="d-flex mt-2">
            <GameViewBatterPitchInfo />
          </div>

          <div className="d-flex mt-2">
            <GameViewBatterSelectedPitch />
          </div>
        </div>
      </div>
    );
  }
}

export default GameViewBatterLargeScreen;
