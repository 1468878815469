import React from "react";
import styled from "styled-components";
import ZeTableHeader from "../elements/table/ZeTableHeader";
import { inject, observer } from "mobx-react";
import Protected from "../protected/Protected";
import { Link } from "react-router-dom";

const GameSummaryTableStyled = styled.table.attrs({
  className: "w-100 table table-hover"
})`
  border: 1px solid rgba(0, 0, 0, 0.125);

  th {
    padding: 0.5rem !important;
  }
`;

const GameSummaryTable = props => {
  let gameSummaryStore = props.rootStore.gameSummaryStore;
  return (
    <div>
      <div className="mb-2">Below is a summary of games played on {gameSummaryStore.date} as scored in ZE.</div>
      <GameSummaryTableStyled>
        <ZeTableHeader>
          <tr>
            <th>Date</th>
            <th>Time</th>
            <th>Away</th>
            <th>Home</th>
            <th>Venue</th>
            <th>Umpire</th>
            <th>Total Pitches</th>
            <th>Correct</th>
            <th>Acceptable</th>
            <th>Incorrect</th>
            <th>Adjusted</th>
            <th>Correct Score</th>
            <th>Raw Score</th>
            <th>Adjusted Score</th>
          </tr>
        </ZeTableHeader>
        <tbody>
          {gameSummaryStore.gameRows.map((row, index) => {
            return (
              <tr key={index}>
                <td>{row.date}</td>
                <td>
                  <Link to={"/gameviewer?gamePk=" + row.gamePk + "&umpireId=" + row.umpireId} target="_blank">
                    {row.time}
                  </Link>
                </td>
                <td>{row.away}</td>
                <td>{row.home}</td>
                <td>{row.venue}</td>
                <td>{row.umpire}</td>
                <td>{row.total}</td>
                <td>{row.correct}</td>
                <td>{row.acceptable}</td>
                <td>{row.incorrect}</td>
                <td>{row.adjusted}</td>
                <td>{row.correctScore}</td>
                <td>{row.rawScore}</td>
                <td>{row.adjustedScore}</td>
              </tr>
            );
          })}
        </tbody>
      </GameSummaryTableStyled>
    </div>
  );
};

export default inject("rootStore")(Protected(observer(GameSummaryTable)));
