import axios from "axios";

class BaseApi {
  axiosWrapper = null;
  constructor() {
    this.axiosWrapper = axios.create();
    this.axiosWrapper.interceptors.request.use(config => {
      const oktaTokenStr = localStorage.getItem("okta-token-storage") || '{"accessToken": "", "idToken": "" }';

      const { accessToken = {}, idToken = {} } = JSON.parse(oktaTokenStr);

      if (config.url.indexOf("/oauth/token") < 0 || config.headers.addToken) {
        config.headers.Authorization = `Bearer ${accessToken.accessToken}`;
        config.headers["x-id-token"] = idToken.idToken;
      }
      return config;
    });

    this.axiosWrapper?.interceptors.response.use(
      resp => {
        if (!resp) return null;
        return resp;
      },
      err => {
        if (!!err.response && (err.response.status === 401 || err.response.status === 403)) {
          logout();
        }
        return Promise.reject(err);
      }
    );
  }
}

export default BaseApi;
