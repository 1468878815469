import { observer } from "mobx-react";
import React from "react";
import styled, { css } from "styled-components";
import PitchCanvas from "../strikeZone/PitchCanvas";
import ZeLightButton from "../../elements/form/ZeLightButton";
import Accordion from "../../common/accordion/Accordion";
import RmseDisplay from "./RmseDisplay";
import OnOff from "../../common/display/OnOff";
import {CalibrationStatus} from "../../../stores/audit/AuditStoreConstants";

const Wrapper = styled.div`
  width: 960px;
  height: 540px;
`;
const ContentWrapper = styled.div`
  width: 960px;
  height: 540px;
  position: relative;
  overflow: hidden;
`;
const VideoToggleZoom = styled.video`
  position: absolute;
  left: 0;
  background-color: #666666;
  ::-webkit-media-controls-mute-button {
    display: none;
  }
  ::-webkit-media-controls-volume-slider {
    display: none;
  }
  ${props =>
    props.zoomed &&
    css`
      top: ${props => props.zoomTopAdj};
      left: ${props => props.zoomLeftAdj};
      transform: scale(${props => props.zoomFactor});
      ::-webkit-media-controls-panel {
        position: relative;
        bottom: ${props => props.webkitControlsBottom};
        left: ${props => props.webkitControlsLeft};
        width: 50%;
        height: 50%;
      }
      ::-webkit-media-controls-play-button {
        height: 16px;
        width: 16px;
        background-size: 16px;
        z-index: 5;
      }
      ::-webkit-media-controls-current-time-display {
        font-size: 6px;
        z-index: 5;
      }
      ::-webkit-media-controls-time-remaining-display {
        font-size: 6px;
        padding-left: 2px;
        z-index: 5;
      }
      ::-webkit-media-controls-timeline {
        margin-left: 9px;
        margin-right: 9px;
        z-index: 5;
      }
    `};
`;

const VideoInfo = styled.div`
  left: 15px;
  top: 0;
  z-index: 3;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 10px;
  color: white;
  font-size: 12px;
`;

const FlagInfo = styled.div`
  left: 850px;
  top: 0;
  z-index: 3;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 10px;
  font-size: 12px;
  color: white;
`;

const Loading = styled.div`
  z-index: 4;
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
`;

const PitchInfo = styled.div`
  color: ${props => props.color}
  font-weight: bold
`;

const Spinner = styled.i.attrs({
  className: "fa fa-3x fa-pulse fa-spinner"
})`
  left: 492px;
  top: 222px;
  color: #ffffff;
  position: absolute;
`;

const StepIcon = ZeLightButton.extend.attrs({
  className: "btn-sm"
})`
  z-index: 3;
  font-color: #2b77eb !important;
  position: absolute;
  bottom: 10px;
  right: 70px;
  color: #2b77eb;
`;

const ToggleStrikeZone = ZeLightButton.extend.attrs({
  className: "btn-sm"
})`
  z-index: 3;
  font-color: #2b77eb !important;
  position: absolute;
  bottom: 10px;
  right: 170px;
  color: #2b77eb;
`;

const ZoomButton = ZeLightButton.extend.attrs({
  className: "btn-sm"
})`
  z-index: 3;
  font-color: #2b77eb !important;
  position: absolute;
  color: #2b77eb;
  bottom: 10px;
  right: 10px;
`;
const Flag = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

class AuditPitchVideo extends React.Component {
  render() {
    const { auditStore, routerStore, videoRef } = this.props;
    const { vertical, horizontal } = auditStore.getTranslation(auditStore.selectedPitch, true);
    let videoZoomTop = auditStore.videoZoomTop;
    let videoZoomLeft = auditStore.videoZoomLeft;
    if (auditStore.selectedPitch && auditStore.selectedPitch.cameraPan) {
      videoZoomTop = auditStore.selectedPitch.cameraPan.videoZoomTop;
      videoZoomLeft = auditStore.selectedPitch.cameraPan.videoZoomLeft;
    }

    let calibrationTitle;
    switch(auditStore.calibrationStatus) {
        case CalibrationStatus.SAVING:
            calibrationTitle = (
                <div>
                  <div className="h5">Saving... <i className="fa fa-pulse fa-spinner"/></div>
                  <div>Saving Calibration</div>
                </div>
            );
            break;
        case CalibrationStatus.GENERATING:
            calibrationTitle = (
                <div>
                    <div className="h5">Generating... <i className="fa fa-pulse fa-spinner"/></div>
                    <div>Generating images for 1 pitch</div>
                </div>
            );
            break;
        default:
            calibrationTitle = <div className="h5">RMSE</div>
            break;
    }

    const height = auditStore.controls ? 500 : 540;
    return (
      <Wrapper>
        <VideoInfo>
          {routerStore.isAuditCameraCalibrationTab ? (
            <Accordion
              title={
                <div>
                   {calibrationTitle}
                  <RmseDisplay title="Calibration" value={auditStore.cameraCalibration.rMSE} color={auditStore.rMSEColor}/>
                  <RmseDisplay
                    title={auditStore.worstCalibrationRMSE.name}
                    value={auditStore.worstCalibrationRMSE.value}
                  />
                </div>
              }
              padding={false}
            >
              <div className="d-flex">
                <div className="p-2">
                  {auditStore.calibrationRMSEList.map(err => (
                    <RmseDisplay title={err.name} value={err.value} key={err.name} />
                  ))}
                </div>
              </div>
            </Accordion>
          ) : null}
          {routerStore.isAuditStrikeZoneTab ? (
            <div>
              <div>SZ Top: {auditStore.selectedPitch.szTop}</div>
              <div>SZ Bottom: {auditStore.selectedPitch.szBottom}</div>
            </div>
          ) : null}
          <div>
            <div>Cal vertical: {vertical / 2}px</div>
            <div>Cal horizontal: {horizontal / 2}px</div>
          </div>
        </VideoInfo>
        <FlagInfo
          onClick={event => {
            event.preventDefault();
            event.stopPropagation();
          }}
        >
          {auditStore.strikeZoneEditable && <Flag onClick={auditStore.toggleDoAutoSave}>
            Auto Save: <OnOff check={auditStore.doAutoSave} />
          </Flag>}
          <Flag onClick={auditStore.toggleAutoPaste}>
            Auto Paste: <OnOff check={auditStore.autoPaste} />
          </Flag>
          <Flag onClick={auditStore.togglePreload}>
            Preload: <OnOff check={auditStore.preload} />
          </Flag>
          {auditStore.selectedPitch.accuracy.tracked ? null : <PitchInfo color="#ff0000">Untracked</PitchInfo>}
          {auditStore.selectedPitch.accuracy.cfVideo ? null : <PitchInfo color="#ff0000">No CF Video</PitchInfo>}
          {auditStore.selectedPitch.accuracy.opticalTracked ? null : <PitchInfo color="#ffff00">No Optical</PitchInfo>}
          {!auditStore.selectedPitch.accuracy.spin ? <PitchInfo color="#ffff00">No Spin</PitchInfo> : null}
        </FlagInfo>
        <ContentWrapper>
          {auditStore.loading ? (
            <Loading>
              <Spinner />
            </Loading>
          ) : null}
          <PitchCanvas
            id="c"
            height={height}
            width="960"
            fieldTranslationVertical={vertical}
            fieldTranslationHorizontal={horizontal}
            loading={auditStore.loading}
            cameraCalTab={routerStore.isAuditCameraCalibrationTab}
          />

          {auditStore.selectedPitch.cfVideoUrl ? (
            <VideoToggleZoom
              controls={false}
              zoomed={auditStore.videoZoomed}
              zoomTopAdj={videoZoomTop + "px"}
              zoomLeftAdj={videoZoomLeft + "px"}
              webkitControlsBottom={119 + videoZoomTop / 2 + "px"}
              webkitControlsLeft={240 - videoZoomLeft / 2 + "px"}
              zoomFactor={auditStore.videoZoomFactor}
              innerRef={videoRef}
              src={auditStore.selectedPitch.cfVideoUrl}
              timeupdate={auditStore.updateProgressBar}
              height="540"
              width="960"
            />
          ) : null}
          <StepIcon onClick={auditStore.increaseStepSize}>{auditStore.stepSizeZoomAdjusted}px Step</StepIcon>
          {routerStore.isAuditCameraCalibrationTab ? (
            <ToggleStrikeZone onClick={auditStore.toggleCameraCalStrikeZone}>Toggle Strike Zone</ToggleStrikeZone>
          ) : null}
          {auditStore.enableZoom && routerStore.isAuditStrikeZoneTab ? (
            <ZoomButton onClick={auditStore.toggleIsVideoZoomed}>
              {auditStore.videoZoomed ? <i className="fa fa-search-minus" /> : <i className="fa fa-search-plus" />}
            </ZoomButton>
          ) : null}
        </ContentWrapper>
        {auditStore.preloadVideos.map(url => (
          <video src={url} preload="auto" hidden key={url} />
        ))}
      </Wrapper>
    );
  }
}

export default observer(AuditPitchVideo);
