import React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import { Progress } from "reactstrap";
import Modal from "../modal/Modal";
import ModalHeader from "../modal/ModalHeader";
import ModalFooter from "../modal/ModalFooter";
import ModalBody from "../modal/ModalBody";

const LoadingProgress = styled(Progress).attrs({
  animated: true,
  striped: false
})`
  height: 2px !important;
`;

const LoadingDiv = styled.div.attrs({
  className: "loading"
})`
  font-size: 30px;

  &.loading:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4, end) 900ms infinite;
    animation: ellipsis steps(4, end) 900ms infinite;
    content: "\\2026";
    width: 0;
  }

  @keyframes ellipsis {
    to {
      width: 1.25em;
    }
  }

  @-webkit-keyframes ellipsis {
    to {
      width: 1.25em;
    }
  }
`;

class LoadingModal extends React.Component {
  modalClosed() {}

  render() {
    const {
      rootStore: { loadingStore }
    } = this.props;
    return (
      <Modal show={loadingStore.loading} onClose={this.modalClosed} size="lg">
        <ModalHeader>
          <h4 className="modal-title">
            <LoadingDiv>
              {loadingStore.title && loadingStore.title.length > 0 ? loadingStore.title : "Loading"}
            </LoadingDiv>
          </h4>
        </ModalHeader>

        <LoadingProgress value={loadingStore.percent || 0} />

        <ModalBody>{loadingStore.body && loadingStore.body.length > 0 ? loadingStore.body : null}</ModalBody>

        <ModalFooter />
      </Modal>
    );
  }
}

export default inject("rootStore")(observer(LoadingModal));
