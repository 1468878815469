export class CoordinateUtility {
  static adjustCoordinatesArrayForZoom(coordinatePairs, videoZoomFactor, videoCenter, left, top) {
    if (coordinatePairs) {
      let adjustedCoordinates = [];
      for (let i = 0; i < coordinatePairs.length; i++) {
        adjustedCoordinates.push(
          this.adjustCoordinatesForZoom(coordinatePairs[i], videoZoomFactor, videoCenter, left, top)
        );
      }

      return adjustedCoordinates;
    } else {
      return coordinatePairs;
    }
  }

  static adjustCoordinatesForZoom(coordinatePair, videoZoomFactor, videoCenter, left, top) {
    let adjustedCoordinates = [0, 0];
    adjustedCoordinates[0] = videoCenter[0] + (coordinatePair[0] - videoCenter[0]) * videoZoomFactor + top;
    adjustedCoordinates[1] = videoCenter[1] + (coordinatePair[1] - videoCenter[1]) * videoZoomFactor + left;
    return adjustedCoordinates;
  }
}
