import React from "react";
import styled from "styled-components";

const ScoresheetRow = styled.div.attrs({
  className: "row"
})`
  margin-bottom: 3px;
  margin-left: 0;
`;

const ScoresheetLabel = styled.div.attrs({
  className: props => (props.thinData ? "col-md-6" : "col-md-5")
})`
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  padding-top: 4px;
`;

const ScoreheetLabelContent = styled.div.attrs({
  className: "align-middle"
})``;

const ScoresheetData = styled.div.attrs({
  className: props => (props.thinData ? "col-md-6" : "col-md-7")
})`
  height: 30px;
  &.ze-field-box-edit {
    background-color: #fffff;
  }
`;

const ScoreheetDataContent = styled.div.attrs({
  className: "align-middle"
})`
  height: 30px;
  background-color: #f7f7f7;
  &.ze-field-box-edit {
    background-color: #fffff;
  }
`;

const ScoresheetDataItem = styled.div`
  ${props => (props.alignRight ? `text-align:right;` : "")};
  padding-top: 4px;
  padding-left: 10px;
  padding-right: 10px;
`;

class ScoresheetHeaderItem extends React.Component {
  render() {
    return (
      <ScoresheetRow>
        <ScoresheetLabel thinData={this.props.thinData}>
          <ScoreheetLabelContent>{this.props.label}</ScoreheetLabelContent>
        </ScoresheetLabel>
        <ScoresheetData thinData={this.props.thinData}>
          {this.props.isInput ? (
            <div>{this.props.children}</div>
          ) : (
            <ScoreheetDataContent>
              <ScoresheetDataItem alignRight={this.props.alignRight}>{this.props.children}</ScoresheetDataItem>
            </ScoreheetDataContent>
          )}
        </ScoresheetData>
      </ScoresheetRow>
    );
  }
}

export default ScoresheetHeaderItem;
