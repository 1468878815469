import React from "react";
import styled from "styled-components";
import GameViewerFilterPitchList from "./filter/pitchList/GameViewerFilterPitchList";
import GameViewerContentVideo from "./video/GameViewerContentVideo";
import GameViewerContentAdditionalInfo from "./info/GameViewerContentAdditionalInfo";
import GameViewerBatterGraphic from "./batter/GameViewerBatterGraphic";
import GameViewBatterPitchInfo from "./batter/GameViewBatterPitchInfo";
import GameViewBatterSelectedPitch from "./batter/GameViewBatterSelectedPitch";
import HideVideoPopover from "./video/HideVideoPopover";
import MarkButton from "./info/MarkButton";
import ShowVideoButton from "./video/ShowVideoButton";
import GameViewerCallFilters from "./filter/GameViewerCallFilters";
import GameViewerDropdownFilters from "./filter/GameViewerDropdownFilters";
import GameViewerBatterSideFilter from "./filter/GameViewerBatterSideFilter";

const ContentColumn = styled.div.attrs({
  className: "flex-column"
})`
  margin-top: 10px;
  margin-right: 10px;
`;
const GrowContentColumn = styled(ContentColumn) `
  flex-grow: 1;
`;

const GameViewerContentSmallScreen = () => (
  <div className="d-flex flex-start mx-3">
    <ContentColumn>
      <div className={"d-flex"}>
        <div className={"flex-column mr-2"}>
          <GameViewerCallFilters />
          <div className={"mt-2"}>
            <GameViewerFilterPitchList height={400} width={235} />
          </div>
        </div>
        <div className={"flex-column"}>
          <GameViewerDropdownFilters />
          <GameViewerBatterGraphic />
          <GameViewerBatterSideFilter />
          <GameViewBatterPitchInfo />
          <GameViewBatterSelectedPitch />
        </div>
      </div>
    </ContentColumn>
    <GrowContentColumn>
      <GameViewerContentVideo />
      <GameViewerContentAdditionalInfo />
      <div className="d-flex">
        <HideVideoPopover />
        <MarkButton />
        <ShowVideoButton />
      </div>
    </GrowContentColumn>
  </div>
);

export default GameViewerContentSmallScreen;
