import React from "react";
import { inject, observer } from "mobx-react";
import ZeTable from "../../../elements/table/ZeTable";
import styled from "styled-components";

const TableCell = styled.td.attrs({
  className: "text-right"
})`
  font-size: 10px;
  font-weight: 300;
  height: 22px;
  padding: 2px 4px !important;
  width: ${props => props.width}px;
`;

const TableHeader = styled.th`
  font-size: 10px;
  font-weight: 300;
  height: 22px;
  padding: 2px 4px !important;
  width: ${props => props.width}px;
`;

const TableHeaderTop = styled(TableHeader).attrs({
  className: "text-right"
})``;

const TableHeaderTopLeft = styled(TableHeader)`
  font-size: 11px;
`;

class GameInfoAverageDistance extends React.Component {
  render() {
    const { rootStore, width } = this.props;
    const { gameViewerStore } = rootStore;
    const { outsideRh, insideRh, outsideLh, insideLh, high, low } = gameViewerStore.gameInfo.errorBreakdown;

    return (
      <ZeTable className="table-sm">
        <thead>
          <tr>
            <TableHeaderTopLeft width={width}>Breakdown</TableHeaderTopLeft>
            <TableHeaderTop width={width}>Number/Avg</TableHeaderTop>
          </tr>
        </thead>
        <tbody>
          {this.row(outsideRh, "Outside 1B")}
          {this.row(insideLh, "Inside 1B")}
          {this.row(outsideLh, "Outside 3B")}
          {this.row(insideRh, "Inside 3B")}
          {this.row(high, "High")}
          {this.row(low, "Low")}
        </tbody>
      </ZeTable>
    );
  }

  row(category, label) {
    if (!category.number) {
      return;
    }
    const { width } = this.props;
    return (
      <tr>
        <TableHeader width={width}>{label}</TableHeader>
        <TableCell className="text-right" width={width}>
          {category.number + " / " + category.average}&nbsp;in
        </TableCell>
      </tr>
    );
  }
}

export default inject("rootStore")(observer(GameInfoAverageDistance));
