import React from "react";
import ReactAnimationFrame from "react-animation-frame";
import styled from "styled-components";
import { inject, observer } from "mobx-react";
import { CanvasUtilities } from "../../../../utilities/CanvasUtilities";

const CanvasHolder = styled.div`
  position: relative;
  z-index: 1;
`;

const Canvas = styled.canvas`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
`;

class GameViewerVideoCanvas extends React.Component {
  clear() {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
  }

  componentDidMount() {
    this.ctx = this.canvas.getContext("2d");

    setTimeout(this.draw(), 2000);
  }

  draw() {
    const { rootStore, width } = this.props;
    const {
      auditConfiguration,
      currentCenterField,
      currentSideView,
      currentSzTopLeft,
      currentSzBottomRight,
      currentVideo
    } = rootStore.gameViewerStore;
    if (currentVideo) {
      let scale;
      if (currentVideo.typeName === "Centerfield" || currentVideo.cameraAngle === auditConfiguration.auditCameraAngle) {
        scale = width / 960;
        this.drawCenterField(currentCenterField, scale);
        this.drawCenterFieldStrikeZone(currentSzTopLeft, currentSzBottomRight, scale);
      } else if (currentVideo.typeName === "Side") {
        scale = width / 1920;
        this.drawSideView(currentSideView, scale);
      }
    }
  }

  drawCenterFieldStrikeZone(topLeft, bottomRight, scale) {
    if (topLeft && topLeft.length > 1 && bottomRight && bottomRight.length > 1) {
      const width = bottomRight[1] - topLeft[1];
      const height = bottomRight[0] - topLeft[0];
      this.ctx.save();
      this.ctx.strokeStyle = CanvasUtilities.COLORS.GREEN;
      this.ctx.strokeRect(topLeft[1] * scale, topLeft[0] * scale, width * scale, height * scale);
      this.ctx.restore();
    }
  }

  drawCenterField(coordinates, scale) {
    if (coordinates) {
      const { before, over, after } = coordinates;
      if (before && before.length > 1) {
        for (let idx = 1; idx < before.length; idx++) {
          CanvasUtilities.drawLinePointToPointScaled(
            this.ctx,
            before[idx - 1],
            before[idx],
            CanvasUtilities.COLORS.CYAN,
            2,
            0,
            scale
          );
        }
      }
      //Drawing from the last before point to the first over point to ensure no gaps in the line
      if (before && before.length > 1 && over && over.length > 1) {
        CanvasUtilities.drawLinePointToPointScaled(
          this.ctx,
          before[before.length - 1],
          over[0],
          CanvasUtilities.COLORS.CYAN,
          2,
          0,
          scale
        );
      }
      if (over && over.length > 1) {
        for (let idx = 1; idx < over.length; idx++) {
          CanvasUtilities.drawLinePointToPointScaled(
            this.ctx,
            over[idx - 1],
            over[idx],
            CanvasUtilities.COLORS.YELLOW,
            2,
            0,
            scale
          );
        }
      }
      //Drawing from the last over point to the first after point to ensure no gaps in the line
      if (over && over.length > 1 && after && after.length > 1) {
        CanvasUtilities.drawLinePointToPointScaled(
          this.ctx,
          over[over.length - 1],
          after[0],
          CanvasUtilities.COLORS.YELLOW,
          2,
          0,
          scale
        );
      }
      if (after && after.length > 1) {
        for (let idx = 1; idx < after.length; idx++) {
          CanvasUtilities.drawLinePointToPointScaled(
            this.ctx,
            after[idx - 1],
            after[idx],
            CanvasUtilities.COLORS.RED,
            2,
            0,
            scale
          );
        }
      }
    }
  }

  drawSideView(coordinates, scale) {
    if (coordinates) {
      const { ballTrack, strikeZoneVertices } = coordinates;
      if (ballTrack && ballTrack.length > 1) {
        for (let idx = 1; idx < ballTrack.length; idx++) {
          CanvasUtilities.drawLinePointToPointScaled(
            this.ctx,
            ballTrack[idx - 1],
            ballTrack[idx],
            CanvasUtilities.COLORS.BLUE,
            1,
            0,
            scale
          );
        }
      }
      this.drawStrikeZoneSideView(strikeZoneVertices, scale);
    }
  }

  drawStrikeZoneSideView(coordinates, scale) {
    if (coordinates && coordinates.length > 3) {
      const length = coordinates.length;
      for (let idx = 0; idx < length; idx++) {
        CanvasUtilities.drawLinePointToPointScaled(
          this.ctx,
          coordinates[idx],
          coordinates[(idx + 1) % length],
          CanvasUtilities.COLORS.YELLOW,
          1,
          0,
          scale
        );
      }
    }
  }

  onAnimationFrame(time) {
    if (this.ctx) {
      this.clear();
      this.draw();
    }
  }

  render() {
    return (
      <CanvasHolder>
        <Canvas
          {...this.props}
          innerRef={c => {
            this.canvas = c;
          }}
        />
      </CanvasHolder>
    );
  }
}

export default inject("rootStore")(ReactAnimationFrame(observer(GameViewerVideoCanvas)));
