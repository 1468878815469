import React from "react";
import styled from "styled-components";

const Required = styled.span`
  color: red;
`;

class RequiredFieldLabel extends React.Component {
  render() {
    return <Required>&nbsp;*</Required>;
  }
}

export default RequiredFieldLabel;
