import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import { DateConstants } from "../../common/constants/DateConstants";
import Moment from "moment-timezone";
import GameViewerHeaderBoxScore from "./GameViewerHeaderBoxScore";
import BadgeButton from "../../common/form/BadgeButton";
import { Tooltip } from "reactstrap";
import StringUtil from "../../../utilities/StringUtil";

const BoxScoreHolder = styled.div.attrs({
  className: "row"
})`
  margin-top: 7px;
`;

const Game = styled.div`
  font-weight: bold;
  float: left;
`;

const GameInfoHolder = styled.div.attrs({
  className: "d-flex flex-row justify-content-left mx-2"
})`
  float: left;
`;

const Roof = styled.div.attrs({
  className: "align-middle badge badge-light ml-2"
})`
  margin-top: 3px;
`;

const RowHolder = styled.div`
  margin: 17px 0 0px 12px;
  min-width: 410px;
`;

const TeamsHolder = styled.div.attrs({
  className: "align-middle"
})`
  font-size: 16px;
  font-weight: 300;
  height: 21px;
`;

const Time = styled.div.attrs({
  className: "align-middle ml-2"
})`
  color: #666666;
  font-size: 0.75rem;
  font-weight: 300;
  height: 18px;
  letter-spacing: normal;
  margin-top: 3px;
`;

const UpdatePitches = styled.button.attrs({
  className: "btn btn-sm btn-primary pull-left ml-2"
})`
  &&& {
    font-size: 12px;
    font-weight: bold;
    padding: 2px;
    float: left;
  }
`;

class GameViewerHeaderGameInfo extends React.Component {
  render() {
    let { gameViewerStore, authStore } = this.props.rootStore;
    let { game, isMilbGame } = gameViewerStore;
    let { absMode, displayName, displayTime, venueTimeZone, weather, zoneBasis } = game;
    let condition = null;
    let absString = null;
    if (weather) {
      condition = weather.condition;
      if (condition !== "Roof Closed" && condition !== "Dome") {
        condition = "Open Air";
      }
    }
    if (absMode) {
      if (absMode === "noABS") {
        absString = "No ABS";
      } else if (absMode === "challengeABS") {
        absString = "Challenge ABS";
      } else if (absMode === "fullABS") {
        absString = "Full ABS";
      }
    }

    return (
      <RowHolder>
        <div className="row">
          <div className="col-12">
            <Game>Game</Game>
            <GameInfoHolder>
              <TeamsHolder>{displayName ? displayName : ""}</TeamsHolder>
              <Time>
                {game && displayTime && venueTimeZone
                  ? Moment(displayTime)
                      .tz(venueTimeZone)
                      .format(DateConstants.DATE_FORMAT_WITH_TIME)
                  : ""}
              </Time>
              {condition ? <Roof>{condition}</Roof> : null}
              {absString ? <Roof>{absString}</Roof> : null}
              {zoneBasis ? (
                <>
                  {/* Note as of 3/19/24 only 2 options for sz calculation */}
                  <Roof id="zoneBasis">
                    {zoneBasis === "2.0" ? "Pose Zone" : zoneBasis === "1.0" ? "Manual Rolling Average" : ""}
                  </Roof>
                </>
              ) : null}
            </GameInfoHolder>
            {authStore.isSuperAdmin && authStore.isAuditorAdmin && !isMilbGame && (
              <React.Fragment>
                <BadgeButton
                  onClick={gameViewerStore.toggleCheckPitches}
                  active={gameViewerStore.checkPitches}
                  title={"Check Pitches"}
                  count={gameViewerStore.updatedPitches.length}
                  styles={{ fontSize: "12px", fontWeight: "bold", padding: "2px", float: "left" }}
                />
                {gameViewerStore.updatedPitches.length ? (
                  <UpdatePitches onClick={gameViewerStore.updatePitches}>Update Pitches</UpdatePitches>
                ) : null}
              </React.Fragment>
            )}
          </div>
        </div>
        <BoxScoreHolder>
          <div className="col-12">
            <GameViewerHeaderBoxScore />
          </div>
        </BoxScoreHolder>
      </RowHolder>
    );
  }
}

export default inject("rootStore")(observer(GameViewerHeaderGameInfo));
