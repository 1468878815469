import React from "react";
import styled from "styled-components";
import { inject, observer } from "mobx-react";

const Item = styled.div.attrs({
  className: ""
})`
  width: 72px;
  height: 46px;
  border-radius: 4px;
  border: solid 1px #d2d2d2;
  margin-right: 5px;
  margin-bottom: 5px;
  ${props =>
    !props.visible
      ? "background-color: #ececec;"
      : props.selected
      ? "background-color: #fdec98;"
      : "background-color: white;"};
  &:hover {
    cursor: pointer;
    i {
      display: inline-block !important;ac
    }
  }

  i.fa-eye {
    display: none;
  }
`;

const EyeHolder = styled.div`
  height: 18px;
  width: 100%;
`;

const Eye = styled.i.attrs({
  className: "fa fa-eye"
})`
  font-size: 13px !important;
  margin-top: 1px;
  margin-left: 3px;
  &:hover {
    cursor: pointer;
    color: #292929;
  }
  float: left;
`;

const IconHolder = styled.div`
  height: 100%;
  width: 100%;
`;

const Icon = styled.img`
  height: 21px;
  display: block;
  margin: auto;
`;

const Name = styled.div`
  font-size: 11px;
  width: 100%;
  height: 25px;
  overflow-y: scroll;
  text-align: center;
`;

class AuditCameraCalActionItem extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false
    };
  }

  toggle() {
    this.setState({ tooltipOpen: !this.state.tooltipOpen });
  }

  render() {
    const auditStore = this.props.rootStore.auditStore;
    const marker = auditStore.cameraCalMarkers.get(this.props.id);
    const Img = !!marker.img ? require("../../../" + marker.img) : null;
    return (
      <Item
        selected={auditStore.selectedCameraCal === this.props.id}
        visible={marker.visible}
        onClick={event => {
          auditStore.setCameraCalSelectedMarker(this.props.id, true);
        }}
        title={marker.desc}
      >
        <EyeHolder>
          <Eye
            onClick={event => {
              event.preventDefault();
              event.stopPropagation();
              auditStore.toggleCameraMarker(this.props.id);
            }}
          />
        </EyeHolder>
        <IconHolder>{!!Img ? <Icon src={Img} alt={marker.desc} /> : <Name>{marker.name}</Name>}</IconHolder>
      </Item>
    );
  }
}

export default inject("rootStore")(observer(AuditCameraCalActionItem));
