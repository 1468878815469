import React from "react";
import { inject, observer } from "mobx-react";
import Select from "react-select";

class DisputeFilter extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(value) {
    const updateDisputeFilter = this.props.rootStore.disputeStore.updateDisputeFilter;
    if (value) {
      if (value.value) {
        updateDisputeFilter(this.props.cacheKey, value.value);
      } else {
        updateDisputeFilter(this.props.cacheKey, value.map(elem => elem.value).join(","));
      }
    } else {
      updateDisputeFilter(this.props.cacheKey, null);
    }
  }

  render() {
    const {
      rootStore: { disputeStore },
      cacheKey,
      isClearable,
      isDisabled,
      isMulti,
      selectOptions,
      title,
      colWidth
    } = this.props;

    let colWidthClasses = "col-xs-" + colWidth * 2 + " col-md-" + colWidth;

    return (
      <div className={colWidthClasses}>
        <div className="row">
          <label className="col-12 col-form-label">{title}</label>
          <div className="col-12">
            <Select
              name="season"
              value={disputeStore.getFilterValue(cacheKey)}
              onChange={this.onChange}
              options={selectOptions}
              isClearable={isClearable}
              isMulti={isMulti}
              disabled={isDisabled}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default inject("rootStore")(observer(DisputeFilter));
