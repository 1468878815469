import React from "react";
import styled from "styled-components";
import GameViewerFilterPitchList from "./filter/pitchList/GameViewerFilterPitchList";
import GameViewerContentVideo from "./video/GameViewerContentVideo";
import GameViewerContentAdditionalInfo from "./info/GameViewerContentAdditionalInfo";
import GameViewerBatterGraphic from "./batter/GameViewerBatterGraphic";
import GameViewBatterPitchInfo from "./batter/GameViewBatterPitchInfo";
import GameViewBatterSelectedPitch from "./batter/GameViewBatterSelectedPitch";
import HideVideoPopover from "./video/HideVideoPopover";
import MarkButton from "./info/MarkButton";
import ShowVideoButton from "./video/ShowVideoButton";
import GameViewerCallFilters from "./filter/GameViewerCallFilters";
import GameViewerDropdownFilters from "./filter/GameViewerDropdownFilters";
import GameViewerBatterSideFilter from "./filter/GameViewerBatterSideFilter";

const ContentColumn = styled.div.attrs({
  className: "flex-column justify-content-between"
})`
  ${props => (props.width ? "width: " + props.minWidth + "px !important" : null)};
`;

const ContentRow = styled.div.attrs({
  className: "d-flex flex-row"
})``;

const VideoRow = styled(ContentRow).attrs({
  className: "justify-content-center"
})`
  margin-top: 10px;
`;

const GameViewerContentTallScreen = () => (
  <div className="d-flex justify-content-center mx-3">
    <ContentColumn width={960}>
      <VideoRow>
        <GameViewerContentVideo />
      </VideoRow>
      <ContentRow className="justify-content-center mt-3">
        <ContentColumn>
          <div className={"d-flex"}>
            <div className={"flex-column"}>
              <GameViewerCallFilters />
              <div className={"mt-2"}>
                <GameViewerFilterPitchList height={400} width={235} />
              </div>
            </div>
            <div className={"flex-column"}>
              <GameViewerDropdownFilters />
              <GameViewerBatterGraphic />
              <GameViewerBatterSideFilter />
              <GameViewBatterPitchInfo />
              <GameViewBatterSelectedPitch />
            </div>
          </div>
        </ContentColumn>
        <ContentColumn>
          <GameViewerContentAdditionalInfo />
          <div className="d-flex">
            <HideVideoPopover />
            <MarkButton />
            <ShowVideoButton />
          </div>
        </ContentColumn>
      </ContentRow>
    </ContentColumn>
  </div>
);

export default GameViewerContentTallScreen;
