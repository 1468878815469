import React from "react";
import DisputeCommentBubble from "../../../dispute/DisputeCommentBubble";

class DisputeCommentCellFormatter extends React.Component {
  render() {
    return <DisputeCommentBubble comments={this.props.value} />;
  }
}

export default DisputeCommentCellFormatter;
