import styled from "styled-components";

const ZeFieldBox = styled.div`
  background-color: #f7f7f7;
  height: 30px;
  &.ze-field-box-edit {
    background-color: #ffffff;
  }
`;

export default ZeFieldBox;
