import React from "react";
import HotKeyWrapper from "../../audit/common/HotKeyWrapper";
import HrTitle from "../../common/title/HrTitle";

class AuditPcVideoHotKeys extends React.Component {
  render() {
    return (
      <div>
        <HrTitle title="Audit Functions" bg="FFFFFF" />
        <HotKeyWrapper hotkey="-">
          Move <strong>Top</strong> of Strike Zone <strong>Up</strong>
        </HotKeyWrapper>
        <HotKeyWrapper hotkey="[">
          Move <strong>Top</strong> of Strike Zone <strong>Down</strong>
        </HotKeyWrapper>
        <HotKeyWrapper hotkey="=">
          Move <strong>Bottom</strong> of Strike Zone <strong>Up</strong>
        </HotKeyWrapper>
        <HotKeyWrapper hotkey="]">
          Move <strong>Bottom</strong> of Strike Zone <strong>Down</strong>
        </HotKeyWrapper>
        <HotKeyWrapper hotkey={<i className="fa fa-caret-up" />}>
          Move <strong>Calibration Up</strong>
        </HotKeyWrapper>
        <HotKeyWrapper hotkey={<i className="fa fa-caret-down" />}>
          Move <strong>Calibration Down</strong>
        </HotKeyWrapper>
        <HotKeyWrapper hotkey={<i className="fa fa-caret-left" />}>
          Move <strong>Calibration Left</strong>
        </HotKeyWrapper>
        <HotKeyWrapper hotkey={<i className="fa fa-caret-right" />}>
          Move <strong>Calibration Right</strong>
        </HotKeyWrapper>
      </div>
    );
  }
}

export default AuditPcVideoHotKeys;
