import React from "react";
import Media from "react-media";
import GameInfoAverageDistance from "./GameInfoAverageDistance";
import GameInfoHandinessTotals from "./GameInfoHandinessTotals";
import { inject, observer } from "mobx-react";

class GameViewerContentAdditionalInfo extends React.Component {
  column() {
    return (
      <div className="d-flex flex-column ml-2">
        <div>
          <GameInfoHandinessTotals cellWidth={72} headerWidth={72} />
        </div>
        <div className="mt-3">
          <GameInfoAverageDistance width={108} />
        </div>
      </div>
    );
  }

  row() {
    return (
      <Media query="(max-width: 1520px)">
        {matches => (
          <div className="d-flex justify-content-between my-2">
            <div>
              <GameInfoHandinessTotals cellWidth={matches ? 100 : 78} headerWidth={matches ? 87 : 78} />
            </div>
            <div className="mr-2">
              <GameInfoAverageDistance width={matches ? 87 : 117} />
            </div>
          </div>
        )}
      </Media>
    );
  }

  render() {
    const tall = this.props.rootStore.gameViewerStore.videoExpanded;

    return tall ? this.column() : this.row();
  }
}

export default inject("rootStore")(observer(GameViewerContentAdditionalInfo));
