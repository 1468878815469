import React from "react";
import { inject, observer } from "mobx-react";
import ScoresheetBallsAsStrikes from "./ScoresheetBallsAsStrikes";
import ScoresheetStrikesAsBalls from "./ScoresheetStrikesAsBalls";

class ScoresheetBodyView extends React.Component {
  render() {
    const {
      rootStore: { scoreSheetStore }
    } = this.props;
    const scoresheet = scoreSheetStore.scoreSheet;
    return (
      <div>
        {scoresheet.gameAnalysis ? (
          <div className="row card-columns">
            <div className="col">
              <ScoresheetBallsAsStrikes />
            </div>

            <div className="col">
              <ScoresheetStrikesAsBalls />
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default inject("rootStore")(observer(ScoresheetBodyView));
