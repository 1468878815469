import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const NavLink = styled(Link).attrs({ className: "nav-link" })`
  color: white !important;
  padding: 8px 16px;
  background-color: ${props => (props.isActive ? "#f7f7f7 !important" : null)};
  color: ${props => (props.isActive ? "#002e6d !important" : null)};

  @media (max-width: 575px) {
    padding-left: 10px !important;
  }
`.withComponent(({ isActive, ...props }) => <Link {...props} />);

class NavigationLink extends React.Component {
  render() {
    const { isActive, name, to } = this.props;

    return (
      <li className="nav-item mx-1">
        <NavLink to={to} isActive={isActive}>
          {name}
        </NavLink>
      </li>
    );
  }
}

export default NavigationLink;
