import React from "react";
import { inject, observer } from "mobx-react";
import AuditSelectedPitch from "../common/AuditSelectedPitch";
import AuditVideoContainer from "../common/AuditVideoContainer";
import AuditPitchList from "../common/AuditPitchList";
import AuditSelectedCamCal from "./AuditSelectedCamCal";
import AuditCameraCalActionIcons from "./AuditCameraCalActionIcons";
import AuditStrikeZoneFooterButtons from "../common/AuditFooterButtons";
import AuditCalibrationSettings from "./AuditCalibrationSettings";
import AuditCameraCalHotKeys from "./AuditCameraCalHotKeys";

class AuditCameraCalContainer extends React.Component {
  render() {
    const auditStore = this.props.rootStore.auditStore;
    return (
      <div>
        <div className="d-flex flex-row justify-content-center">
          <AuditSelectedCamCal />
          <AuditVideoContainer>
            <AuditSelectedPitch videoRef={v => auditStore.setVideoRef(v)} />
          </AuditVideoContainer>
        </div>
        <div className="d-flex flex-row mt-3">
          <AuditPitchList pitches={auditStore.sortedPitches} theight={276} />
          <AuditVideoContainer>
            <AuditCameraCalActionIcons />
            <AuditCalibrationSettings />
            <AuditCameraCalHotKeys />
          </AuditVideoContainer>
        </div>
        <div className="d-flex justify-content-end flex-row mt-3">
          <AuditStrikeZoneFooterButtons />
        </div>
      </div>
    );
  }
}

export default inject("rootStore")(observer(AuditCameraCalContainer));
