import React from "react";

class AuditHeaderBadgeButton extends React.Component {
  render() {
    return (
      <button
        type="button"
        className={"btn btn-sm " + (this.props.active ? "btn-primary" : "btn-light")}
        onClick={this.props.onClick}
        disabled={this.props.disabled}
      >
        {this.props.title} <span className="badge badge-light mr-1">{this.props.total}</span>
        {this.props.total !== "all" && (this.props.called || this.props.called === 0) ? (
          <span className="badge badge-light">{this.props.called}</span>
        ) : null}
      </button>
    );
  }
}

export default AuditHeaderBadgeButton;
