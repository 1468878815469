import React from "react";
import HrTitle from "../../common/title/HrTitle";
import HotKeyWrapper from "../common/HotKeyWrapper";
import ComboHotKeyWrapper from "../common/ComboHotKeyWrapper";

class AuditCalAdjustmentHotKeys extends React.Component {
  render() {
    return (
      <div>
        <HrTitle title="Calibration Functions" bg="FFFFFF" />
        <HotKeyWrapper hotkey="Q">
          <strong>Previous</strong> Camera Calibration Marker
        </HotKeyWrapper>
        <HotKeyWrapper hotkey="W">
          <strong>Next</strong> Camera Calibration Marker
        </HotKeyWrapper>
        <HotKeyWrapper hotkey="J">
          Toggle <strong>Show Strike Zone</strong>
        </HotKeyWrapper>
        <HotKeyWrapper hotkey={<i className="fa fa-caret-up" />}>
          Move Marker <strong>Up</strong>
        </HotKeyWrapper>
        <HotKeyWrapper hotkey={<i className="fa fa-caret-down" />}>
          Move Marker <strong>Down</strong>
        </HotKeyWrapper>
        <HotKeyWrapper hotkey={<i className="fa fa-caret-right" />}>
          Move Marker <strong>Right</strong>
        </HotKeyWrapper>
        <HotKeyWrapper hotkey={<i className="fa fa-caret-left" />}>
          Move Marker <strong>Left</strong>
        </HotKeyWrapper>
        <ComboHotKeyWrapper hotkey="CTRL + SHIFT + S">
          <strong>Save</strong> calibration for this pitch
        </ComboHotKeyWrapper>
      </div>
    );
  }
}

export default AuditCalAdjustmentHotKeys;
