import React from "react";
import YearPickerSelect from "../../common/form/YearPickerSelect";
import { inject, observer } from "mobx-react";

class ScheduleUmpireFilters extends React.Component {
  constructor(props) {
    super(props);
    this.yearUpdated = this.yearUpdated.bind(this);
  }

  yearUpdated(event) {
    this.props.rootStore.scheduleStore.setSeason(event);
    this.props.rootStore.scheduleStore.updateUmpireUrl();
  }

  render() {
    const {
      rootStore: { scheduleStore }
    } = this.props;
    return (
      <div className="row">
        <div className="col-md-3 col-sm-6 col-xs-12">
          <label className="col-form-label">Season</label>
          <YearPickerSelect
            startYear={2011}
            value={scheduleStore.season}
            disabled={false}
            onChange={this.yearUpdated}
          />
        </div>
      </div>
    );
  }
}

export default inject("rootStore")(observer(ScheduleUmpireFilters));
