import React from "react";
import { observer, inject } from "mobx-react";
import { Alert } from "reactstrap";
import { AlertConstants } from "./AlertConstants";

class SelfClosingAlert extends React.Component {
  constructor(props) {
    super(props);
    this.startTimer = this.startTimer.bind(this);
    this.removeAlert = this.removeAlert.bind(this);
    this.removeAlertWithId = this.removeAlertWithId.bind(this);
  }

  componentDidMount() {
    let time = null;
    if (this.props.type) {
      switch (this.props.type) {
        case AlertConstants.TYPES.SUCCESS:
          time = 1000;
          break;
        case AlertConstants.TYPES.INFO:
          time = 5000;
          break;
        case AlertConstants.TYPES.WARNING:
          time = 10000;
          break;
        case AlertConstants.TYPES.DANGER:
        default:
          break;
      }
      this.startTimer(time);
    }
  }

  startTimer(time) {
    if (time) {
      this.delayedRemoval(this.props.id, time);
    }
  }

  delayedRemoval(id, time) {
    setTimeout(
      function() {
        this.removeAlertWithId(id);
      }.bind(this),
      time
    );
  }

  removeAlert() {
    this.removeAlertWithId(this.props.id);
  }

  removeAlertWithId(id) {
    let alertStore = this.props.rootStore.alertStore;
    alertStore.removeAlert(id);
  }

  render() {
    return (
      <Alert color={this.props.type} toggle={this.removeAlert}>
        {this.props.text}
      </Alert>
    );
  }
}

export default inject("rootStore")(observer(SelfClosingAlert));
