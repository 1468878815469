import React from "react";
import styled from "styled-components";
import ZeTableHeader from "../../elements/table/ZeTableHeader";
import ZeTable from "../../elements/table/ZeTable";

const TableHolder = styled.div`
  max-height: 450px;
  overflow: auto;
`;

const HighlightTd = styled.td`
  ${props => (props.highlight ? "background-color: #fcf2c0 !important;" : null)};
`;

const SzEdgeTd = styled(HighlightTd)`
  ${props => (props.highlight ? "font-weight: bold;" : null)};
`;

class AuditQuestionableSzTable extends React.Component {
  render() {
    const { oobPitches, players } = this.props;
    if (!oobPitches || !oobPitches.length) {
      return null;
    }
    return (
      <TableHolder>
        <ZeTable>
          <ZeTableHeader>
            <tr>
              <th>Sequence</th>
              <th>Batter</th>
              <th>Call</th>
              <th>Sz Top Current</th>
              <th>Sz Top Min</th>
              <th>Sz Top Max</th>
              <th>Sz Bottom Current</th>
              <th>Sz Bottom Min</th>
              <th>Sz Bottom Max</th>
            </tr>
          </ZeTableHeader>
          <tbody>
            {oobPitches.map(pitch => (
              <tr key={pitch.pitchNumber}>
                <td>
                  {pitch.topOfInning ? "T" : "B"}
                  {pitch.inning}&nbsp;&nbsp;{pitch.atBatNumber}-{pitch.pitchOfAtBat}
                </td>
                <td>{pitch.batterName}</td>
                <td>{pitch.umpireCallCode}</td>
                <SzEdgeTd highlight={pitch.topFlag}>{pitch.szTop.toFixed(3)}</SzEdgeTd>
                <HighlightTd highlight={pitch.topFlag}>
                  {players[pitch.batterId].strikeZoneTopMin.toFixed(3)}
                </HighlightTd>
                <HighlightTd highlight={pitch.topFlag}>
                  {players[pitch.batterId].strikeZoneTopMax.toFixed(3)}
                </HighlightTd>
                <SzEdgeTd highlight={pitch.bottomFlag}>{pitch.szBottom.toFixed(3)}</SzEdgeTd>
                <HighlightTd highlight={pitch.bottomFlag}>
                  {players[pitch.batterId].strikeZoneBottomMin.toFixed(3)}
                </HighlightTd>
                <HighlightTd highlight={pitch.bottomFlag}>
                  {players[pitch.batterId].strikeZoneBottomMax.toFixed(3)}
                </HighlightTd>
              </tr>
            ))}
          </tbody>
        </ZeTable>
      </TableHolder>
    );
  }
}

export default AuditQuestionableSzTable;
