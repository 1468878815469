import React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import ZeFieldText from "../../../elements/form/ZeFieldText";

const CallHolder = styled.div.attrs({
  className: "row m-0"
})`
  border-top: 1px solid #d2d2d2;
  ${props => (props.uncategorized ? "background-color: #fdec98" : "")};
`;

const BorderLeft = styled.div`
  border-left: 1px solid #d2d2d2;
`;

const EditRow = styled.div.attrs({
  className: "row"
})`
  padding-top: 10px;
  padding-bottom: 11px;
`;

const DataItem = styled.div.attrs({
  className: "col-md-2 text-center"
})``;

class ScoresheetCallEdit extends React.Component {
  constructor(props) {
    super(props);
    this.updateCall = this.updateCall.bind(this);
  }

  editable(call) {
    switch (call.call) {
      case "ball_outside":
      case "ball_inside":
      case "ball_high":
      case "ball_low":
        return true;
      default:
        return this.uncategorized(call);
    }
  }

  matchesCall(call, type) {
    return call === type;
  }

  uncategorized(incorrectCall) {
    return !incorrectCall.call;
  }

  updateCall(type) {
    this.props.scoreSheetStore.updateCall(type, this.props.index);
  }

  render() {
    const call = this.props.call;
    const editable = this.editable(call);
    return (
      <CallHolder uncategorized={this.uncategorized(call)}>
        <div className="col-md-2">
          <EditRow className="row">
            <ZeFieldText className="col-md-6 text-center">{call.pitchNumber}</ZeFieldText>
            <ZeFieldText className="col-md-6 text-center">{call.batterSide}</ZeFieldText>
          </EditRow>
        </div>
        <BorderLeft className="col-md-5 border-left-1">
          <EditRow>
            <DataItem>
              <input
                type="radio"
                name={`call${this.props.index}`}
                checked={this.matchesCall(call.call, "strike_outside")}
                onChange={event => this.updateCall("strike_outside")}
                disabled
              />
            </DataItem>
            <DataItem>
              <input
                type="radio"
                name={`call${this.props.index}`}
                checked={this.matchesCall(call.call, "strike_inside")}
                onChange={event => this.updateCall("strike_inside")}
                disabled
              />
            </DataItem>
            <DataItem>
              <input
                type="radio"
                name={`call${this.props.index}`}
                checked={this.matchesCall(call.call, "strike_high")}
                onChange={event => this.updateCall("strike_high")}
                disabled
              />
            </DataItem>
            <DataItem>
              <input
                type="radio"
                name={`call${this.props.index}`}
                checked={this.matchesCall(call.call, "strike_low")}
                onChange={event => this.updateCall("strike_low")}
                disabled
              />
            </DataItem>
            <DataItem>
              <input
                type="radio"
                name={`call${this.props.index}`}
                checked={this.matchesCall(call.call, "strike_highBuffer")}
                onChange={event => this.updateCall("strike_highBuffer")}
                disabled
              />
            </DataItem>
            <DataItem>
              <input
                type="radio"
                name={`call${this.props.index}`}
                checked={this.matchesCall(call.call, "strike_lowBuffer")}
                onChange={event => this.updateCall("strike_lowBuffer")}
                disabled
              />
            </DataItem>
          </EditRow>
        </BorderLeft>
        <BorderLeft className="col-md-5 border-left-1">
          <EditRow>
            <DataItem>
              <input
                type="radio"
                name={`call${this.props.index}`}
                checked={this.matchesCall(call.call, "ball_outside")}
                onChange={event => this.updateCall("ball_outside")}
                disabled={!editable}
              />
            </DataItem>
            <DataItem>
              <input
                type="radio"
                name={`call${this.props.index}`}
                checked={this.matchesCall(call.call, "ball_inside")}
                onChange={event => this.updateCall("ball_inside")}
                disabled={!editable}
              />
            </DataItem>
            <DataItem>
              <input
                type="radio"
                name={`call${this.props.index}`}
                checked={this.matchesCall(call.call, "ball_high")}
                onChange={event => this.updateCall("ball_high")}
                disabled={!editable}
              />
            </DataItem>
            <DataItem>
              <input
                type="radio"
                name={`call${this.props.index}`}
                checked={this.matchesCall(call.call, "ball_low")}
                onChange={event => this.updateCall("ball_low")}
                disabled={!editable}
              />
            </DataItem>
            <DataItem>
              <input
                type="radio"
                name={`call${this.props.index}`}
                checked={this.matchesCall(call.call, "ball_catcherInfluence")}
                onChange={event => this.updateCall("ball_catcherInfluence")}
                disabled
              />
            </DataItem>
            <DataItem>
              <input
                type="radio"
                name={`call${this.props.index}`}
                checked={this.matchesCall(call.call, "ball_lowCatch")}
                onChange={event => this.updateCall("ball_lowCatch")}
                disabled
              />
            </DataItem>
          </EditRow>
        </BorderLeft>
      </CallHolder>
    );
  }
}

export default inject("rootStore")(observer(ScoresheetCallEdit));
