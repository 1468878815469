import React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import ShowUmpireStancesModalButton from "../content/UmpireUpdateLink";

const Label = styled.div`
  font-weight: bold;
  margin-right: 10px;
`;

const ScoreLabel = styled.div.attrs({
  className: "align-self-center"
})`
  font-size: 0.75rem;
  font-weight: 300;
  width: 55px;
`;

const ScoreHolder = styled.div`
  width: 300px;
`;

const Score = styled.div`
  padding: 5px;
  background-color: #002e6d;
  color: white;
  width: 75px;
  height: 35px;
  text-align: center;
`;

const Umpire = styled.div`
  font-size: 1rem;
  font-weight: 300;
`;

const GameViewerHeaderUmpireDetails = ({ rootStore: { gameViewerStore } }) => {
  const { game, umpire, gameScores, isMilbGame } = gameViewerStore;
  const name = umpire ? umpire.name : "";
  const jerseyNumber = umpire ? umpire.jerseyNumber : "";
  const hasNumber = jerseyNumber === "" ? false : true;
  
  return (
    <div>
      <div className="d-flex">
        <Label>Umpire</Label>
        {hasNumber ? <Umpire>{name} - #{jerseyNumber}</Umpire> : <Umpire>{name}</Umpire>}
      </div>
      <div className={"mb-1"} hidden={isMilbGame}>
        <ShowUmpireStancesModalButton stances={game.umpireStances} />
      </div>
      <ScoreHolder>
        <div className={"d-flex"}>
          <div className={"mr-3"}>
            <ScoreLabel>Raw</ScoreLabel>
            <Score>{gameScores.raw}%</Score>
          </div>
          <div hidden={isMilbGame}>
            <ScoreLabel>Adjusted</ScoreLabel>
            <Score>{gameScores.adjusted}%</Score>
          </div>
        </div>
      </ScoreHolder>
    </div>
  );
};

export default inject("rootStore")(observer(GameViewerHeaderUmpireDetails));
