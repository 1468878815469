import styled from "styled-components";

const ZeLabel = styled.div`
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
`;

export default ZeLabel;
