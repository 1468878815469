import React from "react";

class ZeNumberFormatter extends React.Component {
  render() {
    const { percentage, precision, value } = this.props;
    const adjustedValue = this.adjustPercentage(value, percentage);
    const preciseValue = adjustedValue ? adjustedValue.toPrecision(precision) : 0.0;
    return <span>{preciseValue + (percentage ? "%" : "")}</span>;
  }

  adjustPercentage(value, percentage) {
    return percentage ? value * 100 : value;
  }
}

export default ZeNumberFormatter;
