import React from "react";
import styled from "styled-components";
import GameViewerBatterCanvas from "./GameViewerBatterCanvas";
import highFirst from "../../../../assets/cartoon/high-first.png";
import highHome from "../../../../assets/cartoon/high-home.png";
import lefty from "../../../../assets/cartoon/lefty.png";
import righty from "../../../../assets/cartoon/righty.png";
import { inject, observer } from "mobx-react/index";

const BatterGraphicHolder = styled.div`
  position: relative;
  height: ${props => props.height}px;
  width: ${props => props.width}px;
  background-image: url(${props => props.bgImage});
  background-size: contain;
`;

class GameViewerBatterGraphic extends React.Component {
  bgImage() {
    const { cartoonAngle, handFilter, selectedPitch } = this.props.rootStore.gameViewerStore;
    if (cartoonAngle === "high_first") {
      return highFirst;
    } else if (cartoonAngle === "high_home") {
      return highHome;
    } else if (selectedPitch) {
      if (selectedPitch.batterSide === "L" || selectedPitch.batterSide === "Left") {
        return lefty;
      } else if (selectedPitch.batterSide === "R" || selectedPitch.batterSide === "Right") {
        return righty;
      }
    } else if (handFilter) {
      if (handFilter === "Left") {
        return lefty;
      } else if (handFilter === "Right") {
        return righty;
      }
    }
    return null;
  }

  render() {
    const graphicHeight = 260;
    const graphicWidth = 235;

    return (
      <BatterGraphicHolder
        {...this.props}
        innerRef={c => (this.canvas = c)}
        height={graphicHeight}
        width={graphicWidth}
        bgImage={this.bgImage()}
      >
        <GameViewerBatterCanvas height={graphicHeight} width={graphicWidth} />
      </BatterGraphicHolder>
    );
  }
}

export default inject("rootStore")(observer(GameViewerBatterGraphic));
