import React from "react";
import styled from "styled-components";

const Header = styled.div.attrs({
  className: "card-header",
  "aria-expanded": "false",
  "data-toggle": "collapse"
})`
  width: 100%;
  background-color: ${props => (props.headerColor ? props.headerColor : "#ffffff !important")};
  min-height: 46px;

  &:hover {
    cursor: pointer;
  }
  border: 1px solid #d2d2d2; !important;
`;

const Title = styled.span`
  color: #000000;
  font-size: 15px;
  min-height: 23px;
`;

const TitleHolder = styled.div.attrs({
  className: "d-flex align-items-center"
})`
  min-height: 23px;
`;

const ToggleIconOpen = styled.i.attrs({
  className: "fa fa-angle-down"
})`
  font-size: 22px !important;
`;

const ToggleIconClosed = styled.i.attrs({
  className: "fa fa-angle-up"
})`
  font-size: 22px !important;
`;

class AccordionHeader extends React.Component {
  render() {
    return (
      <Header
        data-target={`#${this.props.title}`}
        aria-controls={this.props.title}
        headerColor={this.props.headerColor}
        onClick={this.props.onClick}
      >
        <Title>
          <div className="d-flex justify-content-between">
            <TitleHolder className="d-flex align-items-center">{this.props.title}</TitleHolder>
            <TitleHolder className="d-flex align-items-center">
              {this.props.open ? <ToggleIconClosed /> : <ToggleIconOpen />}
            </TitleHolder>
          </div>
        </Title>
      </Header>
    );
  }
}

export default AccordionHeader;
