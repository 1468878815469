import React from "react";
import { inject, observer } from "mobx-react";
import DisputeResultsAdmin from "./admin/DisputeResultsAdmin";
import DisputeResultsUmpire from "./umpire/DisputeResultsUmpire";

class DisputeResults extends React.Component {
  render() {
    const { authStore } = this.props.rootStore;
    return <div>{authStore.isDisputesAdmin ? <DisputeResultsAdmin /> : <DisputeResultsUmpire />}</div>;
  }
}

export default inject("rootStore")(observer(DisputeResults));
