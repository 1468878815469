import React from "react";
import HotKeyWrapper from "../../audit/common/HotKeyWrapper";
import HrTitle from "../../common/title/HrTitle";

class AuditPcVideoHotKeys extends React.Component {
  render() {
    return (
      <div>
        <HrTitle title="Other" bg="FFFFFF" />
        <HotKeyWrapper hotkey="V">
          Toggle <strong>Auto-Paste</strong> On/Off
        </HotKeyWrapper>
        <HotKeyWrapper hotkey="B">
          Toggle <strong>Dirty Pitch</strong> On/Off
        </HotKeyWrapper>
        <HotKeyWrapper hotkey="/">
          Toggle <strong>Pitch Arc</strong> On/Off
        </HotKeyWrapper>
      </div>
    );
  }
}

export default AuditPcVideoHotKeys;
