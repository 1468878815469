import React from "react";
import styled from "styled-components";

const ModalFooterDiv = styled.div.attrs({
  className: "modal-footer"
})`
  background-color: #ececec;
`;

class ModalFooter extends React.Component {
  render() {
    return <ModalFooterDiv>{this.props.children}</ModalFooterDiv>;
  }
}

export default ModalFooter;
