import React from "react";
import ModalHeader from "./ModalHeader";

class ModalHeaderWithTitleAndClose extends React.Component {
  render() {
    return (
      <ModalHeader>
        <h3>{this.props.title}</h3>
        <button type="button" className="close" onClick={this.props.close}>
          <span aria-hidden="true">&times;</span>
        </button>
      </ModalHeader>
    );
  }
}

export default ModalHeaderWithTitleAndClose;
