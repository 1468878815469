import styled from "styled-components";

const RedLED = styled.div.attrs(() => ({}))`
  width: 26px;
  height: 26px;
  background-color: red;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 3px 1px, inset red 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 5px;
`;

const GreenLED = styled.div.attrs(() => ({}))`
  width: 26px;
  height: 26px;
  background-color: #abff00;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 3px 1px, inset green 0 -1px 9px, #89ff00 0 2px 5px;
`;

const BlueLED = styled.div.attrs(() => ({}))`
  width: 26px;
  height: 26px;
  background-color: #24e0ff;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 3px 1px, inset blue 0 -1px 9px, #3f8cff 0 2px 5px;
`;

const YellowLED = styled.div.attrs(() => ({}))`
  width: 26px;
  height: 26px;
  background-color: yellow;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 3px 1px, inset orange 0 -1px 9px, yellow 0 2px 5px;
`;

const GrayLED = styled.div.attrs(() => ({}))`
  width: 26px;
  height: 26px;
  background-color: darkgray;
  border-radius: 50%;
`;

export { RedLED, GreenLED, BlueLED, YellowLED, GrayLED };
