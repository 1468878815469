import React from "react";
import { inject, observer } from "mobx-react";
import ZeLightButton from "../../elements/form/ZeLightButton";
import CancelModal from "../../common/action-modals/CancelModal";
import ModalHeaderWithTitleAndClose from "../../common/modal/ModalHeaderWithTitleAndClose";
import ModalBody from "../../common/modal/ModalBody";
import ModalFooter from "../../common/modal/ModalFooter";
import Modal from "../../common/modal/Modal";
import ZeDropdownItem from "../../elements/DropdownItem";
import AuditQuestionableSzTable from "./AuditQuestionableSzTable";

class AuditFooterButtons extends React.Component {
  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
  }

  backupButton() {
    const { auditStore } = this.props.rootStore;
    return (
      <ZeLightButton
        className="float-right mr-2"
        disabled={auditStore.dirtyPitches.length === 0}
        onClick={auditStore.autoSave}
      >
        Backup
      </ZeLightButton>
    );
  }

  calibrationButton() {
    const { auditStore, routerStore } = this.props.rootStore;
    return (
      <div className="dropdown float-right mr-2">
        <ZeLightButton
          className="dropdown-toggle"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Save Calibration
        </ZeLightButton>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <ZeDropdownItem
            onClick={() => {
              routerStore.isAuditCameraCalibrationTab
                ? auditStore.saveCalibration(false)
                : auditStore.persistFieldOutline(auditStore.selectedPitch, false);
            }}
          >
            For This Pitch
          </ZeDropdownItem>
          <ZeDropdownItem
            onClick={() => {
              routerStore.isAuditCameraCalibrationTab
                ? auditStore.saveCalibration(true)
                : auditStore.persistFieldOutline(auditStore.selectedPitch, true);
            }}
          >
            As Venue Default
          </ZeDropdownItem>
        </div>
      </div>
    );
  }

  cancelButton() {
    const { auditStore } = this.props.rootStore;
    return (
      <ZeLightButton className="float-right" onClick={() => (auditStore.showCancelModal = true)}>
        Cancel
      </ZeLightButton>
    );
  }

  closeModal(event) {
    event.preventDefault();
    const { auditStore } = this.props.rootStore;
    auditStore.setShowConfirmationModal(false);
  }

  questionablePitches() {
    const { auditStore } = this.props.rootStore;
    const { oobPitches, players } = auditStore;

    return oobPitches.length ? (
      <div>
        <div>One or more pitches has an unusual strike zone.</div>
        <AuditQuestionableSzTable oobPitches={oobPitches} players={players} />
      </div>
    ) : null;
  }

  refreshButton() {
    const { auditStore } = this.props.rootStore;
    return (
      <ZeLightButton className="float-right" onClick={auditStore.refreshPitch}>
        Refresh Pitch
      </ZeLightButton>
    );
  }

  restoreButton() {
    const { auditStore } = this.props.rootStore;
    return (
      <ZeLightButton className="float-right mr-2" onClick={auditStore.restore}>
        Restore
      </ZeLightButton>
    );
  }

  saveAuditButton() {
    const { auditStore } = this.props.rootStore;
    const { dirtyPitches, selectedPitch } = auditStore;
    return (
      <button
        className="btn btn-primary float-right mr-2"
        disabled={dirtyPitches.length === 0}
        onClick={() => {
          if (selectedPitch.dirty) {
            auditStore.saveCurrentPitch(selectedPitch);
            auditStore.setLoading(false);
            auditStore.setShowConfirmationModal(true);
          } else {
            auditStore.setShowConfirmationModal(true);
          }
        }}
      >
        Finalize Audit
      </button>
    );
  }

  saveCalOnlyButton() {
    const { auditStore } = this.props.rootStore;
    return (
        <button
            className="btn btn-primary float-right mr-2"
            onClick={() => {
              auditStore.saveCalOnly();
            }}
        >
          Finalize Calibrations
        </button>
    )
  }

  saveMessage() {
    const { auditStore } = this.props.rootStore;
    return (
      <div>
        You are saving strike zone changes for <b key="body">{auditStore.dirtyPitches.length}</b> pitches.
      </div>
    );
  }

  renderConfirmationModal() {
    const { auditStore } = this.props.rootStore;
    let oobPitchCount = auditStore.oobPitches.length;

    return (
      <Modal show={auditStore.showConfirmationModal} onClose={this.closeModal} size="lg">
        <ModalHeaderWithTitleAndClose title="Finalize Audit" close={this.closeModal} />

        <ModalBody>
          <div className="row">
            <div className="col">
              <div className="form-group">
                <div className="row" />
                {this.saveMessage()}
                {this.questionablePitches()}
              </div>
            </div>
          </div>
        </ModalBody>

        <ModalFooter>
          {oobPitchCount ? (
            <button
              className="btn btn-primary"
              onClick={() => auditStore.saveAuditPitches(true)}
              disabled={!auditStore.dirtyPitches}
            >
              Exclude and Finalize
            </button>
          ) : null}
          <button
            className={oobPitchCount ? "btn btn-secondary" : "btn btn-primary"}
            onClick={() => auditStore.saveAuditPitches(false)}
            disabled={!auditStore.dirtyPitches}
          >
            Finalize All
          </button>
          <ZeLightButton onClick={this.closeModal}>Cancel</ZeLightButton>
        </ModalFooter>
      </Modal>
    );
  }

  render() {
    const { auditStore } = this.props.rootStore;
    return (
      <div className="row">
        <div className="col">
          {this.renderConfirmationModal()}
          <CancelModal
            show={auditStore.showCancelModal}
            closeFunc={() => (auditStore.showCancelModal = false)}
            cancelFunc={() => auditStore.cancelAudit()}
          />
          <div className="float-right">
            {auditStore.strikeZoneEditable && this.cancelButton()}
            {this.calibrationButton()}
            {this.refreshButton()}
            {auditStore.strikeZoneEditable && this.saveAuditButton()}
            {auditStore.auditEnabled && !auditStore.strikeZoneEditable && this.saveCalOnlyButton()}
            {auditStore.strikeZoneEditable && this.restoreButton()}
            {auditStore.strikeZoneEditable && this.backupButton()}
          </div>
        </div>
      </div>
    );
  }
}

export default inject("rootStore")(observer(AuditFooterButtons));
