import React from "react";
import styled from "styled-components";
import GameViewerFilterBatterSide from "./GameViewerFilterBatterSide";

const BatterSideFilterHolder = styled.div.attrs({
  className: "d-flex flex-row justify-content-between"
})`
  margin-top: 6px;
`;

const GameViewerBatterSideFilter = () => (
  <BatterSideFilterHolder>
    <GameViewerFilterBatterSide label="All" value="All" width={47} />
    <GameViewerFilterBatterSide label="Right Handed" value="Right" width={86} />
    <GameViewerFilterBatterSide label="Left Handed" value="Left" width={86} />
  </BatterSideFilterHolder>
);

export default GameViewerBatterSideFilter;
