import React from "react";
import { Link } from "react-router-dom";

class ZE2LinkCellFormatter extends React.Component {
  render() {
    const { value } = this.props;
    return (
      <Link
        to={"/gameviewer?gamePk=" + value.gamePk + "&umpireId=" + value.umpire.id + "&pitchNumber=" + value.pitchNumber}
      >
        {value.pitchNumber}
      </Link>
    );
  }
}

export default ZE2LinkCellFormatter;
