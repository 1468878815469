import React from "react";
import styled from "styled-components";
import { inject, observer } from "mobx-react";
import ScoresheetHeaderItem from "./ScoresheetHeaderItem";
import Moment from "moment";
import ZeIncorrectText from "../../elements/form/ZeIncorrectText";
import ZeCorrectText from "../../elements/form/ZeCorrectText";
import { DateConstants } from "../../common/constants/DateConstants";

const ScoresheetSummary = styled.div.attrs({
  className: "row"
})`
  padding: 0 0 15px 0;
  margin-right: 0;
`;

const ScoresheetInput = styled.input`
  height: 30px !important;
`;

class ScoresheetHeader extends React.Component {
  constructor(props) {
    super(props);
    this.updateUntrackedPitchCount = this.updateUntrackedPitchCount.bind(this);
    this.updateCameraMissedPitchCount = this.updateCameraMissedPitchCount.bind(this);
  }

  updateUntrackedPitchCount(event) {
    this.props.rootStore.scoreSheetStore.scoreSheet.untrackedPitchCount = event.target.value;
  }

  updateCameraMissedPitchCount(event) {
    this.props.rootStore.scoreSheetStore.scoreSheet.cameraMissedPitchCount = event.target.value;
  }

  render() {
    const {
      rootStore: { scoreSheetStore }
    } = this.props;
    const scoreSheet = scoreSheetStore.scoreSheet;
    const scores = scoreSheetStore.scores;
    return (
      <ScoresheetSummary>
        <div className="col">
          <ScoresheetHeaderItem label="Umpire">
            {scoreSheet && scoreSheet.umpire ? scoreSheet.umpire.name : null}
          </ScoresheetHeaderItem>
          <ScoresheetHeaderItem label="Date(Local Time)">
            {scoreSheet.displayGameTime
              ? Moment(scoreSheet.displayGameTime).format(DateConstants.DATE_FORMAT_WITH_TIME)
              : null}
          </ScoresheetHeaderItem>
          <ScoresheetHeaderItem label="Location">{scoreSheet.gameLocation}</ScoresheetHeaderItem>
          <ScoresheetHeaderItem label="Visitor">{scoreSheet.awayTeam}</ScoresheetHeaderItem>
          <ScoresheetHeaderItem label="Home">{scoreSheet.homeTeam}</ScoresheetHeaderItem>
          <ScoresheetHeaderItem label="Stance">
            {scoreSheet && scoreSheet.umpireStances ? scoreSheet.umpireStances.join(",") : null}
          </ScoresheetHeaderItem>
        </div>
        <div className="col-md-3">
          <ScoresheetHeaderItem label="Total Pitches" alignRight={true} thinData={true}>
            {scoreSheet.gameAnalysis ? scoreSheet.gameAnalysis.callQualitySummary.totalPitchesCalled : null}
          </ScoresheetHeaderItem>
          <ScoresheetHeaderItem label="Correct" alignRight={true} thinData={true}>
            {scoreSheet.gameAnalysis ? scoreSheet.gameAnalysis.callQualitySummary.correctPitchesCalled : null}
          </ScoresheetHeaderItem>
          <ScoresheetHeaderItem label="Acceptable" alignRight={true} thinData={true}>
            {scoreSheet.gameAnalysis ? scoreSheet.gameAnalysis.callQualitySummary.acceptablePitchesCalled : null}
          </ScoresheetHeaderItem>
          <ScoresheetHeaderItem label="Incorrect" alignRight={true} thinData={true}>
            {scoreSheet.gameAnalysis ? scoreSheet.gameAnalysis.callQualitySummary.incorrectPitchesCalled : null}
          </ScoresheetHeaderItem>
          <ScoresheetHeaderItem label="Total Adjustments" alignRight={true} thinData={true}>
            <ZeCorrectText>
              {scoreSheet.gameAnalysis ? scoreSheet.gameAnalysis.callQualitySummary.pitchesAdjusted : null}
            </ZeCorrectText>
          </ScoresheetHeaderItem>
          <ScoresheetHeaderItem label="Total Missed" alignRight={true} thinData={true}>
            <ZeIncorrectText>
              {scoreSheet.gameAnalysis
                ? scoreSheet.gameAnalysis.callQualitySummary.incorrectPitchesCalled -
                  scoreSheet.gameAnalysis.callQualitySummary.pitchesAdjusted
                : null}
            </ZeIncorrectText>
          </ScoresheetHeaderItem>
        </div>
        <div className="col">
          <ScoresheetHeaderItem label="Correct Raw">{scores ? scores.raw : null}%</ScoresheetHeaderItem>
          <ScoresheetHeaderItem label="Correct Adjusted">{scores ? scores.adjusted : null}%</ScoresheetHeaderItem>
          <ScoresheetHeaderItem label="Record Date">
            {scoreSheet.modifiedDate ? Moment(scoreSheet.modifiedDate).format(DateConstants.DATE_FORMAT) : null}
          </ScoresheetHeaderItem>
          <ScoresheetHeaderItem label="Untracked Pitches" isInput={scoreSheetStore.editing}>
            {scoreSheetStore.editing ? (
              <ScoresheetInput
                className="form-control"
                type="text"
                value={scoreSheet.untrackedPitchCount}
                onChange={this.updateUntrackedPitchCount}
              />
            ) : (
              scoreSheet.untrackedPitchCount
            )}
          </ScoresheetHeaderItem>
          <ScoresheetHeaderItem label="Pitches w/o Video" isInput={scoreSheetStore.editing}>
            {scoreSheetStore.editing ? (
              <ScoresheetInput
                className="form-control"
                type="text"
                value={scoreSheet.cameraMissedPitchCount}
                onChange={this.updateCameraMissedPitchCount}
              />
            ) : (
              scoreSheet.cameraMissedPitchCount
            )}
          </ScoresheetHeaderItem>
          <ScoresheetHeaderItem label="Scoresheet Status">
            {scoreSheet.released ? "Released" : "Not Released"}
          </ScoresheetHeaderItem>
        </div>
      </ScoresheetSummary>
    );
  }
}

export default inject("rootStore")(observer(ScoresheetHeader));
