import React from "react";
import { inject, observer } from "mobx-react";
import { Popover, PopoverHeader, PopoverBody } from "reactstrap";
import styled from "styled-components";

const ActionButton = styled.button.attrs({
  className: "btn mr-1"
})`
  border: solid 1px #d2d2d2 !important;
  font-size: 11px !important;
  font-weight: bold !important;
  letter-spacing: -0.4px !important;
  max-height: 22px !important;
  min-height: 22px !important;
  max-width: ${props => props.width + "px"};
  min-width: ${props => props.width + "px"};
  padding: 0 !important;
  ${props => (props.selected ? "background-color: #fdec98 !important;" : "")};
`;

class HideVideoPopover extends React.Component {
  render() {
    const { gameViewerStore, authStore } = this.props.rootStore;
    if (!authStore.isAuditorAdmin) {
      return null;
    }
    let pitchesWithHiddenVideos = gameViewerStore.hiddenVideos;
    return (
      <div>
        <ActionButton width={100} id="HideVideo" type="button" onClick={gameViewerStore.toggleHideVideoPopover}>
          {gameViewerStore.selectedPitch ? "Hide/Show Videos" : "Hidden videos"}
        </ActionButton>
        <Popover
          placement="bottom-start"
          isOpen={gameViewerStore.hideVideoPopoverOpen}
          target="HideVideo"
          toggle={gameViewerStore.toggleHideVideoPopover}
        >
          <PopoverHeader>
            {gameViewerStore.selectedPitch
              ? "Pitch Number: " + gameViewerStore.selectedPitch.pitchNumber
              : "Hidden videos"}
          </PopoverHeader>
          <PopoverBody>
            <div className="d-flex flex-column justify-content-between">
              {gameViewerStore.selectedPitch && gameViewerStore.selectedPitch.videos 
                ? gameViewerStore.selectedPitch.videos.map((v, index) => {
                    return (
                      <div className="mb-2" key={index}>
                        {this.convertName(v.typeName)}{" "}
                        <span>
                          <input type={"checkbox"} checked={v.show} onClick={() => (v.show = !v.show)} />
                        </span>
                        <br />
                      </div>
                    );
                  })
                : pitchesWithHiddenVideos.map((pitchWithHiddenVideos, index) => {
                    return (
                      <div className="mb-2" key={index}>
                        {pitchWithHiddenVideos.pitchNumber}
                        {": "} {this.hiddenVideos(pitchWithHiddenVideos)}
                        <br />
                      </div>
                    );
                  })}
            </div>
            {gameViewerStore.selectedPitch ? (
              <ActionButton width={50} id={"saveHiddenVideo"} type="button" onClick={gameViewerStore.saveHiddenVideos}>
                Submit
              </ActionButton>
            ) : null}
          </PopoverBody>
        </Popover>
      </div>
    );
  }

  convertName(name) {
    if (name === "Centerfield") {
      return "CF";
    }
    if (name === "Pitchcast") {
      return "PC";
    }
    return name;
  }

  hiddenVideos(pitch) {
    return pitch.videos
      .filter(v => !v.show)
      .map(v => this.convertName(v.typeName))
      .join(", ");
  }
}

export default inject("rootStore")(observer(HideVideoPopover));
