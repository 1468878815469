import React from "react";
import styled from "styled-components";
import { inject, observer } from "mobx-react";
import { CanvasUtilities } from "../../../../utilities/CanvasUtilities";

const CanvasHolder = styled.div`
  position: relative;
  z-index: 1;
`;

const ContentHolder = styled.div`
  &&& {
    height: ${props => props.height}px;
    width: ${props => props.width}px;
    position: relative;
    overflow: hidden;
  }
`;

const SzHolder = styled.div`
  height: ${props => props.height}px;
  width: ${props => props.width}px;
`;

const SZ = styled.img`
  &&& {
    height: ${props => props.height}px;
    width: ${props => props.width}px;
    position: absolute;
    top: ${props => props.zoomTopAdj};
    left: ${props => props.zoomLeftAdj};
    transform: scale(${props => props.zoomFactor});
  }
`;

const Canvas = styled.canvas`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
`;

class GameViewerSz extends React.Component {
  constructor(props) {
    super(props);
    this.draw = this.draw.bind(this);
  }

  componentDidMount() {
    this.ctx = this.canvas.getContext("2d");
    setTimeout(this.draw(), 2000);
  }

  draw() {
    const { expanded, rootStore } = this.props;
    const { gameViewerStore } = rootStore;
    const width = expanded ? 960 : 480;
    const scale = width / 960;
    CanvasUtilities.drawLinePointToPointScaled(
      this.ctx,
      gameViewerStore.szKneeRow[0],
      gameViewerStore.szKneeRow[1],
      CanvasUtilities.COLORS.RED,
      1,
      0,
      scale
    );
    CanvasUtilities.drawLinePointToPointScaled(
      this.ctx,
      gameViewerStore.szWaistRow[0],
      gameViewerStore.szWaistRow[1],
      CanvasUtilities.COLORS.RED,
      1,
      0,
      scale
    );
  }

  componentDidUpdate() {
    this.clear();
    this.draw();
  }

  clear() {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
  }

  render() {
    const { expanded, keyframeUpdateTs, rootStore } = this.props;
    const { gameViewerStore } = rootStore;
    const width = expanded ? 960 : 480;
    const height = expanded ? 540 : 270;
    const scale = width / 960;
    return (
      <div>
        <CanvasHolder width={width} height={height}>
          <Canvas
            {...this.props}
            innerRef={c => {
              this.canvas = c;
            }}
            height={height}
            width={width}
          />
          {gameViewerStore.selectedPitch && gameViewerStore.selectedPitch.pathToSZKeyframe ? (
            <ContentHolder width={width} height={height}>
              <SZ
                src={gameViewerStore.selectedPitch.pathToSZKeyframe + "?nocache=" + Date.now()}
                keyframeUpdateTs={keyframeUpdateTs}
                height={height}
                width={width}
                zoomFactor={gameViewerStore.videoZoomFactor}
                zoomTopAdj={gameViewerStore.currentVideoZoomTop * scale + "px"}
                zoomLeftAdj={gameViewerStore.currentVideoZoomLeft * scale + "px"}
              />
            </ContentHolder>
          ) : (
            <SzHolder />
          )}
        </CanvasHolder>
      </div>
    );
  }
}

export default inject("rootStore")(observer(GameViewerSz));
