import React from "react";
import HotKeyWrapper from "./HotKeyWrapper";
import { inject, observer } from "mobx-react";
import HrTitle from "../../common/title/HrTitle";

class VideoHotKeys extends React.Component {
  render() {
    const routerStore = this.props.rootStore.routerStore;
    return (
      <div>
        <HrTitle title="Navigation" bg="FFFFFF" />
        <HotKeyWrapper hotkey="`">
          Change <strong>Tab</strong>
        </HotKeyWrapper>
        <HotKeyWrapper hotkey="S">
          <strong>Next</strong> Pitch
        </HotKeyWrapper>
        <HotKeyWrapper hotkey="A">
          <strong>Previous</strong> Pitch
        </HotKeyWrapper>
        <HotKeyWrapper hotkey="space">
          <strong>Play/Pause</strong> video
        </HotKeyWrapper>
        <HotKeyWrapper hotkey="X">
          Step <strong>Forward</strong> One Frame
        </HotKeyWrapper>
        <HotKeyWrapper hotkey="Z">
          Step <strong>Backwards</strong> One Frame
        </HotKeyWrapper>
        {routerStore.isAuditStrikeZoneTab ? (
          <div>
            <HotKeyWrapper hotkey="D">
              Snap to <strong>Key Frame</strong>
            </HotKeyWrapper>
            <HotKeyWrapper hotkey="F">
              Snap to <strong>Plate</strong>
            </HotKeyWrapper>
          </div>
        ) : null}
      </div>
    );
  }
}

export default inject("rootStore")(observer(VideoHotKeys));
