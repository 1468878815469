import React from "react";
import Moment from "moment";
import { inject, observer } from "mobx-react";
import ModalHeader from "../../common/modal/ModalHeader";
import ModalFooter from "../../common/modal/ModalFooter";
import ModalBody from "../../common/modal/ModalBody";
import styled from "styled-components";
import ZeLightButton from "../../elements/form/ZeLightButton";
import ZeTableHeader from "../../elements/table/ZeTableHeader";
import { DateConstants } from "../../common/constants/DateConstants";

const DisputeCommentTable = styled.table.attrs({
  className: "w-100 table table-bordered table-hover"
})``;

const DisputeTableHolder = styled.div`
  max-height: 40vh;
  overflow: scroll;
`;

const columnWidths = {
  date: "175px",
  name: "175px",
  comment: "325px",
  visible: "75px"
};

class DisputeCommentFormUmpire extends React.Component {
  render() {
    const {
      rootStore: { disputeStore },
      toggleModal
    } = this.props;
    const selectedDispute = disputeStore.selectedDispute;
    const comments = selectedDispute.comments;

    return (
      <form onSubmit={this.saveComment}>
        <ModalHeader>
          <h4 className="modal-title">
            <strong>Dispute Comments</strong> {comments ? comments.length : 0}
          </h4>
          <button type="button" className="close" onClick={toggleModal}>
            <span aria-hidden="true">&times;</span>
          </button>
        </ModalHeader>

        <ModalBody>
          <div className="row">
            <div className="col">
              <DisputeTableHolder>
                <DisputeCommentTable>
                  <ZeTableHeader>
                    <tr>
                      <th width={columnWidths.date}>Date</th>
                      <th width={columnWidths.comment}>Comment</th>
                    </tr>
                  </ZeTableHeader>
                  <tbody>
                    {comments
                      ? comments
                          .sort((a, b) => {
                            return new Date(a.createdDateAsString) < new Date(b.createdDateAsString);
                          })
                          .map(comment => (
                            <tr key={comment.disputeCommentId}>
                              <td width={columnWidths.date}>
                                {Moment(comment.createdDateAsString).format(DateConstants.DATE_FORMAT_WITH_TIME)}
                              </td>
                              <td width={columnWidths.comment}>{comment.message}</td>
                            </tr>
                          ))
                      : null}
                  </tbody>
                </DisputeCommentTable>
              </DisputeTableHolder>
            </div>
          </div>
        </ModalBody>

        <ModalFooter>
          <ZeLightButton onClick={toggleModal}>Close</ZeLightButton>
        </ModalFooter>
      </form>
    );
  }
}

export default inject("rootStore")(observer(DisputeCommentFormUmpire));
