import React from "react";
import ComponentContainer from "./ComponentContainer";
import Protected from "../components/protected/Protected";
import HrTitle from "../components/common/title/HrTitle";
import ReportFilters from "../components/reports/ReportFilters";
import ReportContent from "../components/reports/ReportContent";

class ReportsContainer extends React.Component {
  componentDidMount() {
    this.props.rootStore.loadingStore.setWindowTitle("ZE - Reports");
  }

  componentWillUnmount() {
    this.props.rootStore.loadingStore.setWindowTitle("Zone Evaluator");
  }

  render() {
    return (
      <ComponentContainer>
        <ReportFilters />
        <HrTitle title="filters" bg="FFFFFF" />
        <ReportContent />
      </ComponentContainer>
    );
  }
}

export default Protected(ReportsContainer);
