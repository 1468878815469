import React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";

const TextFilter = styled.input.attrs({
  className: "form-control",
  placeholder: "type to filter results"
})`
  &&& {
    width: 235px;
    &::placeholder {
      color: #d2d2d2;
      font-style: italic;
      font-weight: 300;
    }
  }
  max-height: 36px;
`;

class GameViewFilterTextBox extends React.Component {
  onChange(event) {
    let gameViewerStore = this.props.rootStore.gameViewerStore;
    gameViewerStore.setPitchListFilter(event.target.value);
  }

  render() {
    let gameViewerStore = this.props.rootStore.gameViewerStore;
    return <TextFilter value={gameViewerStore.pitchListFilter} onChange={this.onChange.bind(this)} />;
  }
}

export default inject("rootStore")(observer(GameViewFilterTextBox));
