import React from "react";
import { inject, observer } from "mobx-react";
import Select from "react-select";
import RequiredFieldLabel from "../common/form/RequiredFieldLabel";

class ReportFilters extends React.Component {
  render() {
    const reportStore = this.props.rootStore.reportStore;

    return (
      <div className="row">
        <div className="col-xs-12 col-md-4">
          <label className="col-form-label">
            Report
            <RequiredFieldLabel />
          </label>
          <Select
            name="report"
            value={reportStore.selectedReport}
            onChange={reportStore.setSelectedReport}
            options={reportStore.selectOptions}
          />
        </div>
      </div>
    );
  }
}

export default inject("rootStore")(observer(ReportFilters));
