import React from "react";
import { inject, observer } from "mobx-react";
import ScheduleAdmin from "./admin/ScheduleAdmin";
import ScheduleSuperUmpire from "./superUmpire/ScheduleSuperUmpire";
import ScheduleUmpire from "./umpire/ScheduleUmpire";

class Schedule extends React.Component {
  render() {
    return <div></div>;
  }
}

export default inject("rootStore")(observer(Schedule));
