import React from "react";
import { inject, observer } from "mobx-react";
import ZeLabel from "../../elements/form/ZeLabel";
import ZeLightButton from "../../elements/form/ZeLightButton";
import { EnvConstants } from "../../common/constants/EnvConstants";
import Slider from "react-rangeslider";
import styled from "styled-components";

const env = process.env.REACT_APP_ENV;
const SeekHolder = styled.div`
  width: 100%;
  height: 20px;

  .rangeslider {
    cursor: pointer;
    margin: 13px 0 13px 0px;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
  }

  .rangeslider__fill {
    background-color: #2b77eb !important;
  }
`;
class AuditPcOffsetControls extends React.Component {
  render() {
    const { auditPcStore } = this.props.rootStore;
    return (
      <div className="row">
        <div className="col pl-1">
          <div className="form-group">
            <ZeLabel>Frame Offset</ZeLabel>
            <input
              type="number"
              className="form-control"
              value={auditPcStore.keyframeOffset}
              onChange={auditPcStore.setKeyframeOffset}
              min="-999"
              max="999"
            />
          </div>
        </div>
        {env !== EnvConstants.PROD ? (
          <div className="col pl-1">
            <div className="form-group">
              <ZeLabel>Target Depth</ZeLabel>
              <input
                type="number"
                className="form-control"
                value={auditPcStore.targetDepth}
                onChange={auditPcStore.setTargetDepth}
                min="1"
                max="50"
                step="1"
              />
            </div>
          </div>
        ) : null}
        <div className="col pl-1 mt-3">
          <div className="form-group">
            <ZeLabel />
            <ZeLightButton onClick={() => auditPcStore.backupPitchOffsets(false)}>Backup</ZeLightButton>
          </div>
        </div>
        <div className="col pl-1 mt-3">
          <div className="form-group">
            <ZeLabel />
            <ZeLightButton onClick={auditPcStore.restorePitchOffsets}>Restore</ZeLightButton>
          </div>
        </div>
        <div className="col pl-1 mt-3">
          <div className="form-group">
            <ZeLabel />
            <ZeLightButton
              disabled={
                !(
                  auditPcStore.selectedPitch &&
                  auditPcStore.selectedPitch.videos &&
                  auditPcStore.selectedPitch.videos[0]
                )
              }
              onClick={() => window.open(auditPcStore.selectedPitch.videos[0].link, "_blank")}
            >
              Show Video
            </ZeLightButton>
          </div>
        </div>
        <div className="col-12 pl-1 mb-1">
          <ZeLabel>Smoothing: {auditPcStore.smoothing * 10}%</ZeLabel>
          <SeekHolder>
            <Slider min={0} max={10} value={auditPcStore.smoothing}           tooltip={false}
                    onChange={auditPcStore.onSmoothingChange}/>
          </SeekHolder>
        </div>
          <div className="col-12 pl-1 mb-1">
            <ZeLabel>Roll: {auditPcStore.roll}</ZeLabel>
            <SeekHolder>
              <Slider min={-3} max={3} step={0.01} value={auditPcStore.roll}
                      tooltip={false}
                      onChange={auditPcStore.onRollChange}/>
            </SeekHolder>
          </div>
      </div>
    );
  }
}

export default inject("rootStore")(observer(AuditPcOffsetControls));
