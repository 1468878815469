import React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";

const ActionButton = styled.button.attrs({
  className: "btn"
})`
  border: solid 1px #d2d2d2 !important;
  font-size: 11px !important;
  font-weight: bold !important;
  letter-spacing: -0.4px !important;
  max-height: 22px !important;
  min-height: 22px !important;
  max-width: ${props => props.width + "px"};
  min-width: ${props => props.width + "px"};
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 1 !important;
  ${props => (props.selected ? "background-color: #fdec98 !important;" : "")};
`;

class ShowVideoButton extends React.Component {
  render() {
    const { gameViewerStore } = this.props.rootStore;
    const { currentVideo } = gameViewerStore;
    return (
      <ActionButton
        width={100}
        disabled={!currentVideo.link}
        id="showVideo"
        type="button"
        onClick={() => window.open(currentVideo.link, "_blank")}
      >
        Show Video
      </ActionButton>
    );
  }
}

export default inject("rootStore")(observer(ShowVideoButton));
