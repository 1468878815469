import React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import GameViewerBatterCameraAngle from "../batter/GameViewerBatterCameraAngle";

const FlexRow = styled.div.attrs({
  className: "d-flex flex-row justify-content-between"
})`
  ${props => (props.marginTop ? "margin-top: " + props.marginTop + "px !important" : "")};
`;

const GameViewerCartoon = ({ rootStore: { gameViewerStore } }) => {
  const { videoExpanded } = gameViewerStore;

  return videoExpanded ? null : (
    <FlexRow marginTop={7}>
      <GameViewerBatterCameraAngle label="Center Field" value="centerfield" width={72} />
      <GameViewerBatterCameraAngle label="High First" value="high_first" width={72} />
      <GameViewerBatterCameraAngle label="High Home" value="high_home" width={72} />
    </FlexRow>
  );
};

export default inject("rootStore")(observer(GameViewerCartoon));
