import React from "react";
import { inject, observer } from "mobx-react/index";
import ZeLightButton from "../elements/form/ZeLightButton";

const ReportFooterButtons = ({ rootStore: { reportStore } }) => (
  <div className="row">
    <div className="col">
      <div className="float-right">
        <button className="btn btn-primary mr-2" type="submit" disabled={!reportStore.isValidSearch}>
          Run Report
        </button>
        <ZeLightButton
          onClick={event => {
            event.preventDefault();
            reportStore.resetFilters();
          }}
        >
          Reset Filters
        </ZeLightButton>
      </div>
    </div>
  </div>
);

export default inject("rootStore")(observer(ReportFooterButtons));
