import React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import SelfClosingAlert from "./SelfClosingAlert";

const MessageWrapper = styled.div`
  pointer-events: all;
  position: fixed;
  text-align: center;
  width: 50%;
  left: 25%;
  top: 75px;
  z-index: 1050;
`;

const Message = styled.div`
  display: inline-block;
  text-align: left;
  font-weight: @font-weight-light;
  font-size: 16px;
  margin-bottom: 10px;
  width: 95%;
`;

class AlertHolder extends React.Component {
  render() {
    const {
      rootStore: { alertStore }
    } = this.props;
    return (
      <div>
        {alertStore.alerts.length > 0 ? (
          <MessageWrapper>
            <Message>
              {alertStore.alerts.map((alert, index) => (
                <SelfClosingAlert key={index} type={alert.type} text={alert.text} id={alert.id} />
              ))}
            </Message>
          </MessageWrapper>
        ) : null}
      </div>
    );
  }
}

export default inject("rootStore")(observer(AlertHolder));
