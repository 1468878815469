import React from "react";
import styled from "styled-components";

const ScoresheetIncorrectHeader = styled.div.attrs({
  className: "card-header"
})`
  font-size: 18px;
  font-weight: bold;
`;

class ScoresheetBodyHeader extends React.Component {
  render() {
    return <ScoresheetIncorrectHeader>{this.props.children}</ScoresheetIncorrectHeader>;
  }
}

export default ScoresheetBodyHeader;
