import React from "react";
import styled from "styled-components";
import Slider from "react-rangeslider";

const SeekHolder = styled.div.attrs({
  className: "pr-2"
})`
  width: 100%;
  height: 20px;

  .rangeslider {
    margin: 13px 0 13px 13px;
  }

  .rangeslider__fill {
    background-color: #2b77eb !important;
  }
`;

class VideoSeekBar extends React.Component {
  render() {
    const min = 0;
    const max = !!this.props.duration ? this.props.duration * 1000 : 0;
    const value = !!this.props.currentTime ? this.props.currentTime * 1000 : 0;
    return (
      <SeekHolder>
        <Slider min={min} max={max} value={value} tooltip={false} onChange={this.props.onChange} />
      </SeekHolder>
    );
  }
}

export default VideoSeekBar;
