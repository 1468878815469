import React from "react";
import ScheduleFilters from "./ScheduleFilters";
import ScheduleAdminResults from "./ScheduleAdminResults";

class ScheduleAdmin extends React.Component {
  render() {
    return <div></div>;
  }
}

export default ScheduleAdmin;
