import React from "react";
import styled from "styled-components";
import Media from "react-media";
import GameViewerHeaderLargeScreen from "./GameViewerHeaderLargeScreen";
import GameViewerHeaderSmallScreen from "./GameViewerHeaderSmallScreen";

const Header = styled.div.attrs({
  className: "d-flex flex-row justify-content-between"
})`
  border-bottom: 1px solid #d2d2d2;
  background-color: #ffffff;
  height: 166px;
`;

class GameViewerHeader extends React.Component {
  render() {
    return (
      <div>
        <Media query="(min-width: 1520px)">
          {matches =>
            matches ? (
              <GameViewerHeaderLargeScreen />
            ) : (
              <GameViewerHeaderSmallScreen />
            )
          }
        </Media>
      </div>
    );
  }
}

export default GameViewerHeader;
