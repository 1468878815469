import React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import HrTitle from "../../../common/title/HrTitle";
import ZeLabel from "../../../elements/form/ZeLabel";

const UpdateButton = styled.button.attrs({
  className: "btn btn-sm btn-primary pull-left"
})``;

class AuditAdvOptInterface extends React.Component {
  render() {
    const auditStore = this.props.rootStore.auditStore;
    return (
      <div>
        <HrTitle title="Interface Preferences" bg="FFFFFF" />
        <div className="row">
          <div className="col">
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <ZeLabel>Timing Offset</ZeLabel>
                  <input
                    className="form-control"
                    value={auditStore.timingOffset}
                    onChange={auditStore.setTimingOffset}
                  />
                </div>
              </div>
            </div>
            <div className="row mt-1">
              <div className="col">
                <div className="form-group">
                  <UpdateButton onClick={auditStore.setTimingOffsetFromVideo}>
                    Update Timing Offset From Video
                  </UpdateButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default inject("rootStore")(observer(AuditAdvOptInterface));
