import React from "react";
import { inject, observer } from "mobx-react";
import ZeTable from "../../../elements/table/ZeTable";
import styled from "styled-components";

const TableCell = styled.td.attrs({
  className: "text-right"
})`
  font-size: 10px;
  font-weight: 300;
  height: 22px;
  padding: 2px 4px !important;
  width: ${props => props.width}px;
`;

const TableHeader = styled.th`
  font-size: 10px;
  font-weight: 300;
  height: 22px;
  padding: 2px 4px !important;
  width: ${props => props.width}px;
`;

const TableHeaderTopLeft = styled(TableHeader)`
  font-size: 11px;
`;

class GameInfoHandinessTotals extends React.Component {
  calculatePercent(amt, total) {
    return ((amt / total) * 100).toFixed(2);
  }
  
  render() {
    const { gameViewerStore } = this.props.rootStore;
    const { isMilbGame } = gameViewerStore;
    const { cellWidth, headerWidth } = this.props;
    const lh = gameViewerStore.gameInfo.leftHanded;
    const rh = gameViewerStore.gameInfo.rightHanded;
    return (
      <ZeTable className="table-sm">
        <thead>
          <tr>
            <TableHeaderTopLeft width={headerWidth}>Handedness</TableHeaderTopLeft>
            <TableHeader className="text-right" width={cellWidth}>
              Right Handed
            </TableHeader>
            <TableHeader className="text-right" width={cellWidth}>
              Left Handed
            </TableHeader>
          </tr>
        </thead>
        <tbody>
          <tr>
            <TableHeader width={headerWidth}>Correct</TableHeader>
            {this.tableCell(rh.correct, rh.correct, rh.total, cellWidth)}
            {this.tableCell(lh.correct, lh.correct, lh.total, cellWidth)}
          </tr>
          { !isMilbGame && (
            <tr>
            <TableHeader width={headerWidth}>Acceptable</TableHeader>
            {this.tableCell(rh.acceptable, rh.acceptable, rh.total, cellWidth)}
            {this.tableCell(lh.acceptable, lh.acceptable, lh.total, cellWidth)}
          </tr>
          )}
          <tr>
            <TableHeader width={headerWidth}>Incorrect</TableHeader>
            {this.tableCell(rh.incorrect + rh.adjusted, rh.incorrect + rh.adjusted, rh.total, cellWidth)}
            {this.tableCell(lh.incorrect + lh.adjusted, lh.incorrect + lh.adjusted, lh.total, cellWidth)}
          </tr>
          <tr>
            <TableHeader width={headerWidth}>Total</TableHeader>
            {this.tableCell(rh.total, rh.correct + rh.acceptable, rh.total, cellWidth)}
            {this.tableCell(lh.total, lh.correct + lh.acceptable, lh.total, cellWidth)}
          </tr>
          { !isMilbGame && (
            <tr>
            <TableHeader width={headerWidth}>Adjusted</TableHeader>
            {this.tableCell(rh.adjusted)}
            {this.tableCell(lh.adjusted)}
          </tr>
          )}
        </tbody>
      </ZeTable>
    );
  }

  tableCell(val, pctNumerator, pctDenominator, width) {
    return (
      <TableCell width={width}>
        {val}
        {pctNumerator ? " / " + this.calculatePercent(pctNumerator, pctDenominator) + "%" : null}
      </TableCell>
    );
  }
}

export default inject("rootStore")(observer(GameInfoHandinessTotals));
