import React from "react";
import styled from "styled-components";
import { inject, observer } from "mobx-react";
import ZeTable from "../../../elements/table/ZeTable";
import ZeNumberFormatter from "../../../common/ZeNumberFormatter";
import { CallCorrectnessConstants } from "../../../common/constants/CallCorrectnessConstants";

const Table = styled(ZeTable).attrs({
  className: "mt-1"
})``;

const TableCell = styled.td.attrs({
  className: "text-right"
})`
  font-size: 10px;
  font-weight: 300;
  height: 22px;
  padding: 2px 4px !important;
  width: 144px;
`;

const AdjustmentCell = styled(TableCell)`
  &&& {
    background: yellow;
  }
`;

const TableHeader = styled.th`
  font-size: 10px;
  font-weight: 300;
  height: 22px;
  padding: 2px 4px !important;
  width: 87px;
`;

const WhiteSpaceSpan = styled.span`
  white-space: pre;
`;

class GameViewBatterPitchInfo extends React.Component {
  adjustment(pitchInfo) {
    return (
      <tr>
        <TableHeader>Adjustment</TableHeader>
        <AdjustmentCell>{pitchInfo.adjustment}</AdjustmentCell>
      </tr>
    );
  }

  distance(pitchInfo) {
    return (
      <tr>
        <TableHeader>Distance</TableHeader>
        <TableCell>
          {pitchInfo.location && pitchInfo.location.length ? (
            <WhiteSpaceSpan>{this.distanceString(pitchInfo)}</WhiteSpaceSpan>
          ) : null}
        </TableCell>
      </tr>
    );
  }

  distanceString(pitchInfo) {
    const { gameViewerStore } = this.props.rootStore;
    const { isMilbGame } = gameViewerStore;
    let distance = pitchInfo.location[0] + " " + pitchInfo.distance[0] + " inches";
    let distanceLocation = "";
    let distanceString = pitchInfo.distance[0] + " inches";
    if (isMilbGame) {
      if (pitchInfo.batterSide === "Left") {
        switch (pitchInfo.location[0]) {
          case "1B":
            distanceLocation = "Inside";
            break;
          case "3B":
            distanceLocation = "Outside";
            break;
          case "top":
            distanceLocation = "High";
            break;
          case "bottom":
            distanceLocation = "Low";
            break;
          case "3B_bottom":
            distanceLocation = "Low Outside";
            break;
          case "1B_bottom":
            distanceLocation = "Low Inside";
            break;
          case "3B_top":
            distanceLocation = "High Outside";
            break;
          case "1B_top":
            distanceLocation = "High Inside";
            break;
          default:
            break;
        }
      } else {
        switch (pitchInfo.location[0]) {
          case "1B":
            distanceLocation = "Outside";
            break;
          case "3B":
            distanceLocation = "Inside";
            break;
          case "top":
            distanceLocation = "High";
            break;
          case "bottom":
            distanceLocation = "Low";
            break;
          case "3B_bottom":
            distanceLocation = "Low Inside";
            break;
          case "1B_bottom":
            distanceLocation = "Low Outside";
            break;
          case "3B_top":
            distanceLocation = "High Inside";
            break;
          case "1B_top":
            distanceLocation = "High Outside";
            break;
          default:
            break;
        }
      }
      if (pitchInfo.isStrike) {
        distance = distanceString + " " + distanceLocation;
      } else {
        distance = distanceString + " from " + distanceLocation;
      }
    } else if (pitchInfo.location.length > 1) {
      distance = distance + "\n" + pitchInfo.location[1] + " " + pitchInfo.distance[1] + " inches";
    }
    return distance;
  }

  render() {
    const { authStore, gameViewerStore } = this.props.rootStore;
    const { isAdmin } = authStore;
    const { pitchInfo, isMilbGame, game } = gameViewerStore;
    const { absMode } = game;
    const predict = this.szPredict();
    return (
      <Table>
        <tbody>
          <tr>
            <TableHeader>Pitcher</TableHeader>
            <TableCell>{pitchInfo.pitcher}</TableCell>
          </tr>
          {pitchInfo.adjustment ? this.adjustment(pitchInfo) : this.distance(pitchInfo)}
          <tr>
            <TableHeader>Speed</TableHeader>
            <TableCell>{pitchInfo.speed ? <span>{pitchInfo.speed}&nbsp;mph</span> : null}</TableCell>
          </tr>
          <tr>
            <TableHeader>Pitch Type</TableHeader>
            <TableCell>{pitchInfo.type}</TableCell>
          </tr>
          {isAdmin && !isMilbGame ? (
            <tr>
              <TableHeader>{"Strike% (Ovr%)"}</TableHeader>
              <TableCell>
                {predict ? (
                  <React.Fragment>
                    <ZeNumberFormatter value={predict.predict} precision="3" percentage={true} />
                    <span>{" ("}</span>
                    <ZeNumberFormatter value={predict.overallPredict} precision="3" percentage={true} />
                    <span>{")"}</span>
                  </React.Fragment>
                ) : null}
              </TableCell>
            </tr>
          ) : null}
          {absMode ? (
            <tr>
              <TableHeader>Challenged</TableHeader>
              <TableCell>{pitchInfo.challenged}</TableCell>
            </tr>
          ) : null}
        </tbody>
      </Table>
    );
  }

  szPredict() {
    const { selectedPitch, szPredict } = this.props.rootStore.gameViewerStore;
    if (!selectedPitch || !selectedPitch.playId || !szPredict.has(selectedPitch.playId)) {
      return null;
    }
    return szPredict.get(selectedPitch.playId);
  }
}

export default inject("rootStore")(observer(GameViewBatterPitchInfo));
