import React from "react";
import styled from "styled-components";

const Heading = styled.th`
  ${props => (props.colWidth ? `min-width: ${props.colWidth}` : "min-width: 60px;")};
  ${props => (props.colWidth ? `max-width: ${props.colWidth}` : "max-width: 60px;")};
  font-size: 11px;
  color: black;
  height: 32px;
  text-align: left;
`;

export default class AuditPitchColumnHeader extends React.Component {
  getSort(sort) {
    if (sort.col === this.props.name) {
      if (sort.asc) {
        return (
          <span>
            {"\u00A0"}
            <i className="fa fa-caret-up" />
          </span>
        );
      } else {
        return (
          <span>
            {"\u00A0"}
            <i className="fa fa-caret-down" />
          </span>
        );
      }
    }
  }

  render() {
    const { name, onClickHandler, sort, width } = this.props;

    return (
      <Heading onClick={e => onClickHandler(name, e)} colWidth={width}>
        {name}
        {this.getSort(sort)}
      </Heading>
    );
  }
}
