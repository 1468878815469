export class CanvasUtilities {
  static COLORS = {
    GREY: "#D2D2D2",
    BLACK: "#000000",
    BLUE: "#0000FF",
    CYAN: "#00FFFF",
    YELLOW: "#FFFF00",
    RED: "#FF0000",
    LIGHT_RED: "#FFCCCB",
    GREEN: "#22ff07",
    WHITE: "#FFFFFF",
    PURPLE: "#FF00FF"
  };

  static distanceBetweenPoints(pointA, pointB) {
    if (!(pointA && pointB)) {
      return -1;
    }
    const rowDist = pointB.row - pointA.row;
    const colDist = pointB.col - pointA.col;
    return Math.sqrt(Math.pow(rowDist, 2) + Math.pow(colDist, 2));
  }

  static drawCircle(ctx, row, col, radius, color, fillColor) {
    ctx.save();
    if (row === null || col === null) {
      return;
    }
    ctx.beginPath();
    ctx.arc(col, row, radius, 0, 2 * Math.PI);
    if (fillColor) {
      ctx.fillStyle = fillColor;
      ctx.fill();
    }
    ctx.strokeStyle = color ? color : this.COLORS.BLACK;
    ctx.stroke();
    ctx.restore();
  }

  static drawCircleXy(ctx, xy, radius, color) {
    ctx.save();
    ctx.beginPath();
    ctx.arc(xy.x, xy.y, radius, 0, 2 * Math.PI);
    ctx.strokeStyle = color ? color : this.COLORS.BLACK;
    ctx.stroke();
    ctx.restore();
  }

  static drawDashedLine(ctx, col, row, lineLength, color) {
    CanvasUtilities.drawLine(ctx, col, row, lineLength, color, true);
  }

  static drawDashedLinePointToPointXY(ctx, pointA, pointB, color) {
    CanvasUtilities.drawLinePointToPointXY(ctx, pointA, pointB, color, 1, true);
  }

  static drawEquilateralTriangle(ctx, row, col, radius, color, fillColor, rotDeg) {
    if (row === null || col === null) {
      return;
    }
    ctx.save();
    let rotRad = this.toRadians(rotDeg);
    ctx.beginPath();
    ctx.moveTo(col + Math.cos(rotRad) * radius, row - Math.sin(rotRad) * radius);
    for (let idx = 0; idx < 3; idx++) {
      rotRad += (2 * Math.PI) / 3;
      ctx.lineTo(col + Math.cos(rotRad) * radius, row - Math.sin(rotRad) * radius);
    }
    if (fillColor) {
      ctx.fillStyle = fillColor;
      ctx.fill();
    }
    ctx.strokeStyle = color ? color : this.COLORS.BLACK;
    ctx.stroke();
    ctx.restore();
  }

  static drawLine(ctx, col, row, lineLength, color, dashed) {
    if (row === null) {
      return;
    }
    ctx.save();
    ctx.beginPath();
    if (dashed) {
      ctx.setLineDash([5, 5]);
    }
    ctx.moveTo(col, row);
    ctx.lineTo(col + lineLength, row);
    ctx.strokeStyle = color ? color : this.COLORS.BLACK;
    ctx.stroke();
    ctx.restore();
  }

  static drawLinePointToPoint(ctx, pointA, pointB, color, width, dashSize) {
    if (!width) {
      width = 1;
    }
    this.drawLinePointToPointScaled(ctx, pointA, pointB, color, width, dashSize, 1);
  }

  static drawLinePointToPointRowCol(ctx, pointA, pointB, color, width, dashSize) {
    const pointARowCol = [pointA.row, pointA.col];
    const pointBRowCol = [pointB.row, pointB.col];
    this.drawLinePointToPointScaled(ctx, pointARowCol, pointBRowCol, color, width, dashSize, 1);
  }

  static drawLinePointToPointScaled(ctx, pointA, pointB, color, width, dashSize, scaled) {
    if (!width) {
      width = 1;
    }
    if (!scaled) {
      scaled = 1;
    }
    ctx.save();
    ctx.beginPath();
    if (dashSize) {
      ctx.setLineDash([dashSize, dashSize]);
    }
    ctx.moveTo(pointA[1] * scaled, pointA[0] * scaled);
    ctx.lineTo(pointB[1] * scaled, pointB[0] * scaled);
    ctx.lineWidth = width ? width : 1;
    ctx.strokeStyle = color ? color : this.COLORS.BLACK;
    ctx.stroke();
    ctx.restore();
  }

  static drawLinePointToPointXY(ctx, pointA, pointB, color, width, dashed) {
    ctx.save();
    ctx.beginPath();
    if (dashed) {
      ctx.setLineDash([5, 5]);
    }
    ctx.moveTo(pointA.x, pointA.y);
    ctx.lineTo(pointB.x, pointB.y);
    ctx.lineWidth = width ? width : 1;
    ctx.strokeStyle = color ? color : this.COLORS.BLACK;
    ctx.stroke();
    ctx.restore();
  }

  static drawLinePointToPointXYScaled(ctx, pointA, pointB, color, dashed, scaled) {
    if (!scaled) {
      scaled = 1;
    }
    ctx.save();
    ctx.beginPath();
    if (dashed) {
      ctx.setLineDash([5, 5]);
    }
    ctx.moveTo(pointA.x * scaled, pointA.y * scaled);
    ctx.lineTo(pointB.x * scaled, pointB.y * scaled);
    ctx.strokeStyle = color ? color : this.COLORS.BLACK;
    ctx.stroke();
    ctx.restore();
  }

  static fillPolygon(ctx, points, fillColor, alpha) {
    ctx.save();
    ctx.fillStyle = fillColor;
    ctx.globalAlpha = alpha;
    ctx.beginPath();
    ctx.moveTo(points[0].x, points[0].y);
    for (let idx = 1; idx <= points.length; idx++) {
      const point = points[idx % points.length];
      ctx.lineTo(point.x, point.y);
    }
    ctx.fill();
    ctx.restore();
  }

  static fillRect(ctx, col, row, length, height, color, alpha) {
    ctx.save();
    ctx.fillStyle = color;
    ctx.globalAlpha = alpha;
    ctx.fillRect(col, row, length, height);
    ctx.restore();
  }

  static scaleCoordinateXy(xy, scaleFactor) {
    return {
      x: xy.x * scaleFactor,
      y: xy.y * scaleFactor
    };
  }

  static translateCoordinateXy(xy, translateX, translateY) {
    return {
      x: xy.x + translateX,
      y: xy.y + translateY
    };
  }

  static toRadians(degrees) {
    return degrees / (180 / Math.PI);
  }
}
