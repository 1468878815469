import React from "react";
import Accordion from "../../common/accordion/Accordion";
import styled from "styled-components";
import VideoHotKeys from "../common/VideoHotKeys";
import AuditMiscHotKeys from "../common/AuditMiscHotKeys";
import AuditAdjustmentHotKeys from "./AuditAdjustmentHotKeys";

const Body = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
`;

class AuditStrikeZoneHotKeys extends React.Component {
  render() {
    return (
      <div className="mt-1">
        <Accordion title="Hot Keys" padding={false}>
          <Body>
            <div className="row">
              <div className="col-4">
                <VideoHotKeys />
              </div>
              <div className="col-4">
                <AuditAdjustmentHotKeys />
              </div>
              <div className="col-4">
                <AuditMiscHotKeys />
              </div>
            </div>
          </Body>
        </Accordion>
      </div>
    );
  }
}

export default AuditStrikeZoneHotKeys;
