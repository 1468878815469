import React from "react";
import styled from "styled-components";
import { inject, observer } from "mobx-react";
import AuditCameraCalActionItem from "./AuditCameraCalActionItem";

const Wrapper = styled.div.attrs({
  className: "d-flex flex-wrap"
})`
  width: 960px;
`;

const ToggleButton = styled.button`
  width: 72px;
  height: 46px;
  border-radius: 4px;
  border: solid 1px #d2d2d2;
  font-size: 13px;
  margin-right: 5px;
  margin-bottom: 5px;
  ${props => (props.visible ? "background-color: #FFFFFF" : "background-color: #ececec")};
  &:hover {
    cursor: pointer;
    background-color: #fefae7;
  }
`;

class AuditCameraCalActionIcons extends React.Component {
  constructor(props) {
    super(props);
    this.toggleAll = this.toggleAll.bind(this);
  }

  toggleAll(toggle) {
    this.props.rootStore.auditStore.toggleAllCameraCalibrationMarkers(toggle);
  }

  render() {
    let auditStore = this.props.rootStore.auditStore;
    return (
      <Wrapper>
        {auditStore.cameraCalMarkersSequence.map(id => (
          <AuditCameraCalActionItem id={id} key={id} />
        ))}
        <ToggleButton onClick={auditStore.togglePlateMarkers} visible={auditStore.plateMarkersEnabled}>
          Plate
        </ToggleButton>
        <ToggleButton onClick={auditStore.toggleMoundAnd2BMarkers} visible={auditStore.moundMarkersEnabled || auditStore.secondBaseMarkersEnabled}>
          Mound<br/>2B
        </ToggleButton>
      </Wrapper>
    );
  }
}

export default inject("rootStore")(observer(AuditCameraCalActionIcons));
