import React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";

const CameraAngle = styled.button.attrs({
  className: "btn"
})`
  border: solid 1px #d2d2d2 !important;
  font-size: 11px !important;
  font-weight: bold !important;
  letter-spacing: -0.4px !important;
  max-height: 22px !important;
  min-height: 22px !important;
  max-width: ${props => props.width + "px"};
  min-width: ${props => props.width + "px"};
  padding: 0 !important;
  ${props => (props.selected ? "background-color: #fdec98 !important;" : "")};
`;

class GameViewerBatterCameraAngle extends React.Component {
  onChange(value) {
    if (!this.props.selected) {
      const gameViewerStore = this.props.rootStore.gameViewerStore;
      gameViewerStore.setCartoonAngle(this.props.value);
    }
  }

  render() {
    const { label, rootStore, value, width } = this.props;
    const gameViewerStore = rootStore.gameViewerStore;
    const { cartoonAngle, selectedPitch } = gameViewerStore;
    const disabled = !(selectedPitch || value === "centerfield");
    return (
      <CameraAngle
        disabled={disabled}
        onClick={this.onChange.bind(this)}
        selected={cartoonAngle === value}
        width={width}
      >
        {label}
      </CameraAngle>
    );
  }
}

export default inject("rootStore")(observer(GameViewerBatterCameraAngle));
