import React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";

const CanvasHolder = styled.div`
  position: relative;
  height: ${props => props.height}px;
  width: ${props => props.width}px;
`;

const CrossHairCanvas = styled.canvas`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
`;

const VideoCanvas = styled.canvas`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
`;

const Selected = styled.div`
  overflow: hidden;
  margin-right: 20px;
  border: 1px solid #d2d2d2;
  height: 540px;
  width: ${props => props.width};
  float: left;
`;

const TitleHolder = styled.div`
  height: 34px;
  background-color: #ffffff;
  border-top: solid 1px #d2d2d2;
  padding: 5px;
`;

const Title = styled.span`
  font-family: Helvetica;
  font-size: 14px;
  font-weight: 300;
  text-align: left;
  color: #666666;
  padding: 5px;
`;

const RED = "#FF0000";
const WHITE = "#FFFFFF";
const CANVAS_WIDTH = 378;
const CANVAS_HEIGHT = 504;

class AuditSelectedCamCal extends React.Component {
  constructor(props) {
    super(props);
    this.scale = CANVAS_HEIGHT / props.rootStore.auditStore.marker.zoom.height;
    this.canvasWidth = CANVAS_WIDTH;
    this.canvasHeight = CANVAS_HEIGHT;
    this.draw = this.draw.bind(this);
  }

  componentDidMount() {
    this.crosshairCtx = this.chCanvas.getContext("2d");
    this.videoCtx = this.canvas.getContext("2d");
    this.draw();
  }

  componentDidUpdate() {
    this.clear();
    this.draw();
  }

  clear() {
    this.videoCtx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.crosshairCtx.clearRect(0, 0, this.canvas.width, this.canvas.height);
  }

  draw() {
    const auditStore = this.props.rootStore.auditStore;
    this.drawCrossHairs();
    this.drawVideo(auditStore);
  }

  drawCrossHairs() {
    this.crosshairCtx.beginPath();
    let xMid = this.canvasWidth / 2;
    let yMid = this.canvasHeight / 2;
    let pointerSize = 0;

    this.crosshairCtx.moveTo(xMid, 0);
    this.crosshairCtx.lineTo(xMid, yMid - pointerSize / 2);
    this.crosshairCtx.strokeStyle = RED;
    this.crosshairCtx.stroke();
    this.crosshairCtx.moveTo(xMid, yMid + pointerSize / 2);
    this.crosshairCtx.lineTo(xMid, this.canvasHeight);
    this.crosshairCtx.strokeStyle = RED;
    this.crosshairCtx.stroke();

    this.crosshairCtx.moveTo(0, yMid);
    this.crosshairCtx.lineTo(xMid - pointerSize / 2, yMid);
    this.crosshairCtx.strokeStyle = RED;
    this.crosshairCtx.stroke();
    this.crosshairCtx.moveTo(xMid + pointerSize / 2, yMid);
    this.crosshairCtx.lineTo(this.canvasWidth, yMid);
    this.crosshairCtx.strokeStyle = RED;
    this.crosshairCtx.stroke();
  }

  drawVideo(auditStore) {
    const videoRef = auditStore.videoRef;
    if (videoRef) {
      let coordinates = auditStore.selectedMarkerCoordinates;
      let scale = videoRef.videoWidth / videoRef.width;
      if (coordinates.length > 1 && (coordinates[0] !== 0 && coordinates[1] !== 0)) {
        let y = coordinates[0] - auditStore.marker.zoom.height / 2;
        let x = coordinates[1] - auditStore.marker.zoom.width / 2;
        this.videoCtx.drawImage(
          videoRef,
          x * scale,
          y * scale,
          auditStore.marker.zoom.width * scale,
          auditStore.marker.zoom.height * scale,
          0,
          0,
          this.canvasWidth,
          this.canvasHeight
        );
        this.drawSecondBaseLine(auditStore, coordinates[1], coordinates[0]);
      }
    }
  }

  drawSecondBaseLine(auditStore, markerCol, markerRow) {
    let selectedMarker = auditStore.cameraCalMarkers.get(auditStore.selectedCameraCal);
    if (!selectedMarker || selectedMarker.key !== "moundCircle" || selectedMarker.keyIndex !== 1) {
      return;
    }
    let lineEquation = auditStore.secondBaseLineEquation;
    let intersectionCoords = this.calculateIntersections(
      lineEquation,
      markerCol,
      markerRow,
      auditStore.marker.zoom.width,
      auditStore.marker.zoom.height
    );

    if (intersectionCoords.length !== 2) {
      return;
    }

    this.crosshairCtx.moveTo(intersectionCoords[0][1], intersectionCoords[0][0]);
    this.crosshairCtx.lineTo(intersectionCoords[1][1], intersectionCoords[1][0]);
    this.crosshairCtx.strokeStyle = WHITE;
    this.crosshairCtx.stroke();
  }

  calculateIntersections(lineEquation, rectCenterCol, rectCenterRow, width, height) {
    let intersectionCoords = [];
    let topLeft = [rectCenterRow - height / 2, rectCenterCol - width / 2];
    let topRight = [rectCenterRow - height / 2, rectCenterCol + width / 2];
    let bottomLeft = [rectCenterRow + height / 2, rectCenterCol - width / 2];
    let bottomRight = [rectCenterRow + height / 2, rectCenterCol + width / 2];

    this.calculateIntersection(lineEquation, intersectionCoords, false, topLeft, topRight);
    this.calculateIntersection(lineEquation, intersectionCoords, true, topRight, bottomRight);
    this.calculateIntersection(lineEquation, intersectionCoords, false, bottomRight, bottomLeft);
    this.calculateIntersection(lineEquation, intersectionCoords, true, bottomLeft, topLeft);

    return intersectionCoords.map(coord => {
      return this.translateIntersection(coord, topLeft);
    });
  }

  calculateIntersection(lineEquation, intersectionCoords, vertical, segmentStart, segmentEnd) {
    if (vertical) {
      let col = segmentStart[1];
      let row = lineEquation.slope * col + lineEquation.intersect;
      if (row <= Math.max(segmentStart[0], segmentEnd[0]) && row >= Math.min(segmentStart[0], segmentEnd[0])) {
        intersectionCoords.push([row, col]);
      }
    } else {
      let row = segmentStart[0];
      let col = (row - lineEquation.intersect) / lineEquation.slope;
      if (col <= Math.max(segmentStart[1], segmentEnd[1]) && col >= Math.min(segmentStart[1], segmentEnd[1])) {
        intersectionCoords.push([row, col]);
      }
    }
  }

  translateIntersection(coordinate, topLeft) {
    return [(coordinate[0] - topLeft[0]) * this.scale, (coordinate[1] - topLeft[1]) * this.scale];
  }

  render() {
    const auditStore = this.props.rootStore.auditStore;
    const selectedCam =
      auditStore.selectedCameraCal > -1 ? auditStore.cameraCalMarkers.get(auditStore.selectedCameraCal) : null;
    const videoCurrentTime = auditStore.videoCurrentTime;

    return (
      <Selected width={this.canvasWidth}>
        <CanvasHolder height={this.canvasHeight} width={this.canvasWidth} videoCurrentTime={videoCurrentTime}>
          <CrossHairCanvas
            {...this.props}
            innerRef={c => (this.chCanvas = c)}
            height={this.canvasHeight}
            width={this.canvasWidth}
          />
          <VideoCanvas
            {...this.props}
            selectedMarkerCoordinates={auditStore.selectedMarkerCoordinates[0]}
            innerRef={c => (this.canvas = c)}
            height={this.canvasHeight}
            width={this.canvasWidth}
          />
        </CanvasHolder>
        <TitleHolder>
          <Title>{selectedCam ? selectedCam.desc : ""}</Title>
        </TitleHolder>
      </Selected>
    );
  }
}

export default inject("rootStore")(observer(AuditSelectedCamCal));
