import React from "react";
import { inject, observer } from "mobx-react";

import ReportFooterButtons from "./ReportFooterButtons";
import RequiredFieldLabel from "../common/form/RequiredFieldLabel";
import DatePickerInput from "../common/form/DatePickerInput";

class ExportAuditResultsContent extends React.Component {
  render() {
    let reportStore = this.props.rootStore.reportStore;
    return (
      <div className="row">
        <div className="col">
          <form
            onSubmit={event => {
              event.preventDefault();
              reportStore.runReport();
            }}
          >
            <div className="row">
              <div className="col-md-2 col-xs-4">
                <label className="col-form-label">
                  Game Date <RequiredFieldLabel />
                </label>
                <DatePickerInput date={reportStore.date} onChange={reportStore.setDate} />
              </div>
              <div className="col-md-8 col-xs-4">
                <label className="col-form-label">
                  Send To The Following Email
                  <RequiredFieldLabel />
                </label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Comma separated email addresses"
                  value={reportStore.userStr}
                  onChange={reportStore.setUsers}
                />
              </div>
            </div>
            <ReportFooterButtons />
          </form>
        </div>
      </div>
    );
  }
}

export default inject("rootStore")(observer(ExportAuditResultsContent));
