import React from "react";
import styled from "styled-components";
import Accordion from "../../common/accordion/Accordion";
import AuditAdvOptCalibration from "./advanced-options/AuditAdvOptCalibration";
import AuditAdvOptInterface from "./advanced-options/AuditAdvOptInterface";
import AuditGeneralSettings from "./advanced-options/AuditGeneralSettings";
import AuditVideoSettings from "./advanced-options/AuditZoomSettings";
import {inject, observer} from "mobx-react";

const SettingsBody = styled.div.attrs({
  className: "row"
})`
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
`;

class AuditCalibrationSettings extends React.Component {
  render() {
    const auditStore = this.props.rootStore.auditStore;

    return (
      <Accordion title="Advanced Options" padding={false} open={true}>
        <SettingsBody>
          <div className="col-md-6">
            <AuditAdvOptCalibration />
          </div>
          {auditStore.strikeZoneEditable && <div className="col-md-6">
            <AuditAdvOptInterface />
          </div>}
          <div className="col-md-6">
            <AuditGeneralSettings />
          </div>
          {auditStore.enableZoom && <div className="col-md-6">
            <AuditVideoSettings />
          </div>}
        </SettingsBody>
      </Accordion>
    );
  }
}

export default inject("rootStore")(observer(AuditCalibrationSettings));
