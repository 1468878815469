import React from "react";
import styled from "styled-components";

const TitleHolder = styled.div`
  font-size: 11px;
  margin-bottom: 2px;
  text-align: right;
  padding-right: 6px;
  padding-top: 3px;
`;

const Item = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

class StrikeZoneActionButton extends React.Component {
  render() {
    return (
      <div>
        <TitleHolder>{this.props.title}</TitleHolder>
        <Item src={this.props.src} alt={this.props.alt} />
      </div>
    );
  }
}

export default StrikeZoneActionButton;
