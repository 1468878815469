// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-grid-HeaderCell {
    background: #f7f7f7 !important;
    color: #002e6d !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: 1px solid #d2d2d2 !important;
}

/* Sorting Arrow */
.react-grid-HeaderCell-sortable > span {
    color: red !important;
    padding: 4px !important;
    font-size: .75rem !important;
}

.react-grid-Cell {
    border-left: none !important;
    border-right: none !important;
    border-bottom: 1px solid #ececec !important;
}

.react-grid-Cell--locked:last-of-type {
    border-right: 1px solid #ddd !important;
    -webkit-box-shadow: 4px 0 5px 0 rgba(210,210,210,1) !important;
    -moz-box-shadow: 4px 0 5px 0 rgba(210,210,210,1) !important;
    box-shadow: 4px 0 5px 0 rgba(210,210,210,1) !important;
}

.react-grid-Cell.sorted-col {
    background: #f2f3f4 !important;
    border-bottom: none !important;
}

.react-grid-HeaderCell-sortable {
    display: flex;
}

.react-grid-HeaderCell-sortable > span {
    order: 1;
}`, "",{"version":3,"sources":["webpack://./src/styles/React-Data-Grid-Overrides.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,yBAAyB;IACzB,4BAA4B;IAC5B,6BAA6B;IAC7B,2CAA2C;AAC/C;;AAEA,kBAAkB;AAClB;IACI,qBAAqB;IACrB,uBAAuB;IACvB,4BAA4B;AAChC;;AAEA;IACI,4BAA4B;IAC5B,6BAA6B;IAC7B,2CAA2C;AAC/C;;AAEA;IACI,uCAAuC;IACvC,8DAA8D;IAC9D,2DAA2D;IAC3D,sDAAsD;AAC1D;;AAEA;IACI,8BAA8B;IAC9B,8BAA8B;AAClC;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,QAAQ;AACZ","sourcesContent":[".react-grid-HeaderCell {\n    background: #f7f7f7 !important;\n    color: #002e6d !important;\n    border-left: none !important;\n    border-right: none !important;\n    border-bottom: 1px solid #d2d2d2 !important;\n}\n\n/* Sorting Arrow */\n.react-grid-HeaderCell-sortable > span {\n    color: red !important;\n    padding: 4px !important;\n    font-size: .75rem !important;\n}\n\n.react-grid-Cell {\n    border-left: none !important;\n    border-right: none !important;\n    border-bottom: 1px solid #ececec !important;\n}\n\n.react-grid-Cell--locked:last-of-type {\n    border-right: 1px solid #ddd !important;\n    -webkit-box-shadow: 4px 0 5px 0 rgba(210,210,210,1) !important;\n    -moz-box-shadow: 4px 0 5px 0 rgba(210,210,210,1) !important;\n    box-shadow: 4px 0 5px 0 rgba(210,210,210,1) !important;\n}\n\n.react-grid-Cell.sorted-col {\n    background: #f2f3f4 !important;\n    border-bottom: none !important;\n}\n\n.react-grid-HeaderCell-sortable {\n    display: flex;\n}\n\n.react-grid-HeaderCell-sortable > span {\n    order: 1;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
