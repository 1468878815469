import React from "react";
import Media from "react-media";
import styled from "styled-components";
import GameViewerHeaderGameInfo from "./GameViewerHeaderGameInfo";
import GameViewerHeaderUmpireInfo from "./GameViewerHeaderUmpireInfo";
import GameInfoHandinessTotals from "../content/info/GameInfoHandinessTotals";
import GameInfoAverageDistance from "../content/info/GameInfoAverageDistance";

const Header = styled.div.attrs({
  className: "d-flex flex-row justify-content-between"
})`
  border-bottom: 1px solid #d2d2d2;
  background-color: #ffffff;
`;

class GameViewerHeaderLargeScreen extends React.Component {
  render() {
    return (
      <Header>
        <GameViewerHeaderGameInfo />
        <Media query="(max-width: 1520px)">
        {matches => (
          <div className="d-flex justify-content-between my-2">
            <GameViewerHeaderUmpireInfo />
            <div className= "mr-3 my-2">
              <GameInfoHandinessTotals cellWidth={matches ? 100 : 78} headerWidth={matches ? 87 : 78} />
            </div>
            <div className= "mr-2 my-2">
              <GameInfoAverageDistance width={87} />
            </div>
          </div>
        )}
      </Media>
      </Header>
    );
  }
}

export default GameViewerHeaderLargeScreen;
