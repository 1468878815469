import React from "react";
import { inject, observer } from "mobx-react";
import { getApplicationKeyMap } from "react-hotkeys";
import Modal from "../common/modal/Modal";
import ModalFooter from "../common/modal/ModalFooter";
import ModalBody from "../common/modal/ModalBody";
import ModalHeaderWithTitleAndClose from "../common/modal/ModalHeaderWithTitleAndClose";
import ZeLightButton from "../elements/form/ZeLightButton";

class HotkeysModal extends React.Component {
  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
  }

  closeModal(event) {
    this.props.rootStore.commonStore.setShowHotkeysModal(false);
  }

  render() {
    if (!this.props.rootStore.commonStore.showHotkeysModal) {
      return null;
    }
    const keyMap = getApplicationKeyMap();
    return (
      <Modal show={true} onClose={() => {}} size="md">
        <ModalHeaderWithTitleAndClose title="Available Hotkeys" close={this.closeModal} />

        <ModalBody>
          <div className="row">
            <div className="col">
              <div className="form-group">
                {Object.keys(keyMap)
                  .sort()
                  .map(actionName => (
                    <div className="row">
                      <div className="col">{actionName}</div>
                      <div className="col">
                        {keyMap[actionName].map(keySequence => (
                          <span key={keySequence}>{keySequence}</span>
                        ))}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </ModalBody>

        <ModalFooter>
          <ZeLightButton onClick={this.closeModal}>Close</ZeLightButton>
        </ModalFooter>
      </Modal>
    );
  }
}

export default inject("rootStore")(observer(HotkeysModal));
