import _ from "lodash";
import React from "react";
import styled from "styled-components";
import { inject, observer } from "mobx-react";
import AuditPcPitchVideo from "./AuditPcPitchVideo";
import VideoControls from "../../common/video/VideoControls";

const Wrapper = styled.div`
  width: 960px;
  height: 540px;
`;

const NoPitchSelected = styled.div`
  font-weight: bold;
  font-size: 18px;
  margin: auto;
  text-align: center;
  height: 540px;
  line-height: 540px;
  vertical-align: middle;
`;

class AuditPcSelectedPitch extends React.Component {
  render() {
    const {
      rootStore: { auditPcStore, routerStore },
      videoRef
    } = this.props;
    const cameraCalMarkers = auditPcStore.cameraCalMarkers;

    return (
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="col-12">
              <Wrapper>
                {_.isEmpty(auditPcStore.selectedPitch) ? (
                  <NoPitchSelected>No pitch is selected</NoPitchSelected>
                ) : (
                  <AuditPcPitchVideo
                    auditPcStore={auditPcStore}
                    routerStore={routerStore}
                    videoRef={videoRef}
                    cameraCalMarkers={cameraCalMarkers}
                  />
                )}
              </Wrapper>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <VideoControls
                videoRef={auditPcStore.videoRef}
                currentTime={auditPcStore.videoCurrentTime}
                duration={auditPcStore.videoDuration}
                onChange={auditPcStore.onSeekChange}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default inject("rootStore")(observer(AuditPcSelectedPitch));
