import { action, extendObservable } from "mobx";

class LoadingStore {
  constructor() {
    this.defaults = {
      loading: false,
      title: "",
      body: "",
      percent: 0,
      windowTitle: "Zone Evaluator"
    };

    extendObservable(this, {
      loading: this.defaults["loading"],
      title: this.defaults["title"],
      body: this.defaults["body"],
      percent: this.defaults["percent"],
      windowTitle: this.defaults["windowTitle"],
      setLoading: action((loading, title, body, percent) => {
        this.loading = loading;
        document.title = title ? title : this.windowTitle;
        if (loading) {
          this.title = title;
          document.title = title;
          this.body = body;
          if (percent !== 0) {
            this.simulation = setInterval(() => {
              if (percent > this.percent) {
                this.percent += 5;
              } else {
                clearInterval(this.simulation);
              }
            }, 20);
          } else {
            this.percent = percent || 0;
          }
        } else {
          this.loading = this.defaults["loading"];
          this.title = this.defaults["title"];
          this.body = this.defaults["body"];
        }
      }),
      setWindowTitle: action(title => {
        this.windowTitle = title;
      })
    });
  }
}

export default LoadingStore;
