import React from "react";
import { inject, observer } from "mobx-react";
import GameSummaryReportContent from "./GameSummaryReportContent";
import ProblemPitchReportContent from "./ProblemPitchReportContent";
import UpdateS3DataFormatBatchContent from "./GamesAndDateRangeBatchContent";
import ExportAuditResultsContent from "./ExportAuditResultsContent";
import ResetAuditResultContent from "./ResetAuditResultContent";
import ReportDateRange from "./ReportDateRange";

const ReportContent = ({ rootStore: { reportStore } }) => {
  switch (reportStore.selectedReport.value) {
    case 1:
      return <GameSummaryReportContent />;
    case 2:
      return <ProblemPitchReportContent />;
    case 3:
    case 4:
      return <UpdateS3DataFormatBatchContent />;
    case 5:
      return <ExportAuditResultsContent />;
    case 6:
      return <ResetAuditResultContent />;
    case 7:
    case 8:
    case 9:
      return <ReportDateRange />;
    default:
      return null;
  }
};

export default inject("rootStore")(observer(ReportContent));
