import React from "react";
import ModalBody from "../modal/ModalBody";
import ModalFooter from "../modal/ModalFooter";
import Modal from "../modal/Modal";
import ZeLightButton from "../../elements/form/ZeLightButton";
import ModalHeaderWithTitleAndClose from "../modal/ModalHeaderWithTitleAndClose";

class CancelModal extends React.Component {
  constructor(props) {
    super(props);
    this.onClose = this.onClose.bind(this);
  }

  onClose() {
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  render() {
    return (
      <Modal show={this.props.show} onClose={this.onClose} size="lg">
        <ModalHeaderWithTitleAndClose title="Cancel Changes?" close={this.props.closeFunc} />
        <ModalBody>Are you sure you want to cancel the changes?</ModalBody>
        <ModalFooter>
          <button className="btn btn-danger" onClick={this.props.cancelFunc}>
            Yes
          </button>
          <ZeLightButton onClick={this.props.closeFunc}>No</ZeLightButton>
        </ModalFooter>
      </Modal>
    );
  }
}

export default CancelModal;
