import React from "react";
import ComponentContainer from "./ComponentContainer";
import ScheduleSuperUmpire from "../components/schedule/superUmpire/ScheduleSuperUmpire";
import Protected from "../components/protected/Protected";

class SuperUmpireContainer extends React.Component {
  componentDidMount() {
    this.props.rootStore.loadingStore.setWindowTitle("ZE - Umpire");
  }

  componentWillUnmount() {
    this.props.rootStore.loadingStore.setWindowTitle("Zone Evaluator");
  }
  // removing this component from the path 2/7/24
  // cohesive schedule screen in new app handles the capabilities of this page

  render() {
    return (
      <ComponentContainer>
        <ScheduleSuperUmpire />
      </ComponentContainer>
    );
  }
}

export default Protected(SuperUmpireContainer);
