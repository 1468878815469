import React from "react";
import styled from "styled-components";

const ModalBodyDiv = styled.div.attrs({
  className: "modal-body"
})`
  max-height: 75vh;
`;

class ModalBody extends React.Component {
  render() {
    return <ModalBodyDiv>{this.props.children}</ModalBodyDiv>;
  }
}

export default ModalBody;
