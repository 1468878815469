import React from "react";
import styled from "styled-components";
import GameViewerHeaderGameInfo from "./GameViewerHeaderGameInfo";
import GameViewerHeaderUmpireInfo from "./GameViewerHeaderUmpireInfo";
import GameViewerContentAdditionalInfo from "../content/info/GameViewerContentAdditionalInfo";

const Header = styled.div.attrs({
  className: "d-flex flex-row justify-content-between"
})`
  border-bottom: 1px solid #d2d2d2;
  background-color: #ffffff;
  height: 166px;
`;

class GameViewerHeaderSmallScreen extends React.Component {
  render() {
    return (
      <Header>
        <GameViewerHeaderGameInfo />
        <GameViewerHeaderUmpireInfo />
      </Header>
    );
  }
}

export default GameViewerHeaderSmallScreen;
