import React from "react";
import styled from "styled-components";
import { inject, observer } from "mobx-react";
import DropdownItem from "../elements/DropdownItem";

const NavBarSignoutStyle = styled.div`
  background-color: #ffffff;
  z-index: 1031 !important;
  height: 22px;
`;

const NavBarSignoutLink = DropdownItem.extend`
  z-index: 1051 !important;
`;

class NavBarSignout extends React.Component {
  reportABug() {}

  settings() {}

  render() {
    const {
      rootStore: { authStore }
    } = this.props;

    if (authStore.isLoggedIn) {
      return (
        <NavBarSignoutStyle className="d-flex flex-row info-bar fixed-top justify-content-end align-items-center">
          <button
            type="button"
            className="btn btn-sm btn-link"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {authStore.name}&nbsp; <i className="fa fa-angle-down" />
          </button>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
            <NavBarSignoutLink onClick={this.settings}>Settings</NavBarSignoutLink>
            <NavBarSignoutLink onClick={this.reportABug}>Report a bug</NavBarSignoutLink>
            <NavBarSignoutLink onClick={authStore.logout}>Sign Out</NavBarSignoutLink>
          </div>
        </NavBarSignoutStyle>
      );
    } else {
      return "";
    }
  }
}

export default inject("rootStore")(observer(NavBarSignout));
