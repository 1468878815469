import React from "react";
import styled from "styled-components";

const ScoresheetSubHead = styled.div.attrs({
  className: "row"
})`
  padding: 0 0 15px 0;
`;

class ScoresheetSubHeader extends React.Component {
  render() {
    return <ScoresheetSubHead>{this.props.children}</ScoresheetSubHead>;
  }
}

export default ScoresheetSubHeader;
