import React from "react";
import HotKeyWrapper from "../../audit/common/HotKeyWrapper";
import HrTitle from "../../common/title/HrTitle";
import { inject, observer } from "mobx-react";

class AuditMiscHotKeys extends React.Component {
  render() {
    const routerStore = this.props.rootStore.routerStore;
    return (
      <div>
        <HrTitle title="Other" bg="FFFFFF" />
        <HotKeyWrapper hotkey="V">
          Toggle <strong>Auto-Paste</strong> On/Off
        </HotKeyWrapper>
        <HotKeyWrapper hotkey="G">
          Toggle <strong>Auto-Save</strong> On/Off
        </HotKeyWrapper>
        <HotKeyWrapper hotkey="C">
          Toggle <strong>Preload</strong> On/Off
        </HotKeyWrapper>
        {routerStore.isAuditStrikeZoneTab ? (
          <HotKeyWrapper hotkey="H">
            Toggle <strong>Hover & Click</strong> On/Off
          </HotKeyWrapper>
        ) : null}
        <HotKeyWrapper hotkey="B">
          Toggle <strong>Dirty Pitch</strong> On/Off
        </HotKeyWrapper>
        <HotKeyWrapper hotkey="/">
          Toggle <strong>Pitch Arc</strong> On/Off
        </HotKeyWrapper>
        {routerStore.isAuditStrikeZoneTab ? (
          <HotKeyWrapper hotkey={<i className="fa fa-arrow-up" />}>
            Toggle <strong>Zoom</strong>
          </HotKeyWrapper>
        ) : null}
      </div>
    );
  }
}

export default inject("rootStore")(observer(AuditMiscHotKeys));
