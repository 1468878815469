import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const NavLinkDiv = styled.span`
  padding: 0 0 0 24px;
`;

const NavLink = styled(Link)`
  color: white;
  font-size: 14px;
  font-weight: 400;
  height: 17px;
  margin: 0 !important;
  padding: 0 !important;

  &:hover {
    color: white;
  }
`;

const NavLink2 = styled.a`
  &&& {
    color: white;
    font-size: 14px;
    font-weight: 400;
    height: 17px;
    margin: 0 !important;
    padding: 0 !important;

    &:hover {
      color: white;
      cursor: pointer;
    }
  }
`;

const NavigationLinkNarrow = ({ name, to }) => {
  return (
    <NavLinkDiv>
      <NavLink to={to}>{name}</NavLink>
    </NavLinkDiv>
  );
};

const NewNavigationLinkNarrow = ({ name, to }) => {
  return (
    <NavLinkDiv>
      <NavLink2
        onClick={() => {
          window.location.href = window.location.origin + "/v2" + to;
        }}
      >
        {name}
      </NavLink2>
    </NavLinkDiv>
  );
};

export { NavigationLinkNarrow, NewNavigationLinkNarrow };
