import React from "react";
import ScheduleSuperUmpireFilters from "./ScheduleSuperUmpireFilters";
import ScheduleSuperUmpireResults from "./ScheduleSuperUmpireResults";

class ScheduleSuperUmpire extends React.Component {
  render() {
    return (
      <div className="row">
        <div className="col">
          <ScheduleSuperUmpireFilters />
          <ScheduleSuperUmpireResults />
        </div>
      </div>
    );
  }
}

export default ScheduleSuperUmpire;
