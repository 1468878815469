import React from "react";
import styled from "styled-components";
import Protected from "../components/protected/Protected";
import Scoresheet from "../components/scoresheet/Scoresheet";

const ScoresheetCard = styled.div.attrs({
  className: "card"
})`
  position: static !important;
`;

const ScoresheetHeader = styled.div.attrs({
  className: "card-header"
})`
  font-size: 14px;
  font-weight: 300;
  height: 21px;
  padding: 0 !important;
  position: static !important;
  text-align: center;
`;

class ScoresheetContainer extends React.Component {
  componentWillUnmount() {
    this.props.rootStore.loadingStore.setWindowTitle("Zone Evaluator");
  }

  render() {
    return (
      <ScoresheetCard>
        <ScoresheetHeader>Scoresheet</ScoresheetHeader>
        <div className="card-body">
          <div className="row">
            <div className="col-12">
              <Scoresheet />
            </div>
          </div>
        </div>
      </ScoresheetCard>
    );
  }
}

export default Protected(ScoresheetContainer);
