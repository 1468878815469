import { extendObservable, computed } from "mobx";
import { RouterStore } from "mobx-react-router";

class ZeRouterStore extends RouterStore {
  constructor() {
    super();

    extendObservable(this, {
      isLoginTab: computed(() => this.location.pathname === "/login"),
      isScheduleTab: computed(() => this.location.pathname === "/schedule" || this.location.pathname === "/"),
      isDisputesTab: computed(() => this.location.pathname === "/dispute"),
      isAuditTab: computed(
        () =>
          this.location.pathname === "/audit/strike-zone" ||
          this.location.pathname === "/audit/camera-calibration" ||
          this.location.pathname === "/audit/research"
      ),
      isAuditTabV2: computed(() => this.location.pathname === "/auditv2/strike-zone"),
      isAuditStrikeZoneTab: computed(() => this.location.pathname === "/audit/strike-zone"),
      isAuditV2StrikeZoneTab: computed(() => this.location.pathname === "/auditv2/strike-zone"),
      isAuditCameraCalibrationTab: computed(() => this.location.pathname === "/audit/camera-calibration"),
      isAuditResearchTab: computed(() => this.location.pathname === "/audit/research"),
      isGameViewerTab: computed(() => this.location.pathname === "/gameviewer"),
      isReportsTab: computed(() => this.location.pathname === "/reports"),
      isSuperUmpireTab: computed(() => this.location.pathname === "/superUmpire"),
      isGameSummaryTab: computed(() => this.location.pathname === "/gameSummary")
    });
  }
}

export default ZeRouterStore;
