import React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import { Popover, PopoverHeader, PopoverBody } from "reactstrap";
import MarkedPitchesPopover from "./MarkedPitchesPopover";

const ActionButton = styled.button.attrs({
  className: "btn mr-1"
})`
  border: solid 1px #d2d2d2 !important;
  font-size: 11px !important;
  font-weight: bold !important;
  letter-spacing: -0.4px !important;
  max-height: 22px !important;
  min-height: 22px !important;
  max-width: ${props => props.width + "px"};
  min-width: ${props => props.width + "px"};
  padding: 0 !important;
  ${props => (props.selected ? "background-color: #fdec98 !important;" : "")};
`;

class MarkButton extends React.Component {
  render() {
    const { gameViewerStore, authStore } = this.props.rootStore;
    if (!authStore.isAuditorAdmin) {
      return null;
    }

    if (gameViewerStore.selectedPitch) {
      return (
        <div>
          <ActionButton
            width={100}
            disabled={!gameViewerStore.selectedPitch}
            id="markPitch"
            type="button"
            onClick={gameViewerStore.toggleMarkPitchPopoverOpen}
          >
            {gameViewerStore.selectedPitch && gameViewerStore.selectedPitch.deleted ? "Un-Mark Pitch" : "Mark Pitch"}
          </ActionButton>

          <Popover
            placement="bottom-start"
            isOpen={gameViewerStore.markPitchPopoverOpen}
            target="markPitch"
            toggle={gameViewerStore.toggleMarkPitchPopoverOpen}
          >
            <PopoverHeader>
              Pitch Number: {gameViewerStore.selectedPitch ? gameViewerStore.selectedPitch.pitchNumber : 0}
            </PopoverHeader>
            <PopoverBody>
              <div className="d-flex flex-column justify-content-between">
                Are you sure you want to{" "}
                {gameViewerStore.selectedPitch && gameViewerStore.selectedPitch.deleted ? "un-mark" : "mark"} this
                pitch?
              </div>
              <ActionButton width={50} id={"marKPitchButton"} type="button" onClick={gameViewerStore.markPitch}>
                Save
              </ActionButton>
            </PopoverBody>
          </Popover>
        </div>
      );
    } else {
      return <MarkedPitchesPopover />;
    }
  }
}

export default inject("rootStore")(observer(MarkButton));
