import React from "react";
import Media from "react-media";
import styled from "styled-components";
import GameViewerVideoButtons from "./GameViewerVideoButtons";
import GameViewerDisplay from "./GameViewerDisplay";
import { inject, observer } from "mobx-react/index";

const Spacer = styled.div`
  width: ${props => (props.expanded ? "960px" : "480px")};
  height: ${props => (props.expanded ? "582px" : "312px")};
`;

const Video = styled.div`
  width: ${props => (props.expanded ? "960px" : "480px")};
`;

const ToggleButton = styled.button`
  border-radius: 4px;
  border: solid 1px #d2d2d2;
  font-weight: bold;
  font-size: 11px;
  margin-right: 5px;
  margin-bottom: 5px;
  &:hover {
    cursor: pointer;
    background-color: #fefae7;
  }
`;

const Label = styled.div`  
  text-align: center;
  font-weight: bold;
`;

const LabelContainer = styled.div`
  justify-content: center;
  height: 100%;
  display: flex;
  place-items: center;
`;

class GameViewerContentVideo extends React.Component {
  render() {
    const { selectedPitch, videoExpanded, videoRef } = this.props.rootStore.gameViewerStore;
    return ((selectedPitch) && !selectedPitch.videos) ?
    <LabelContainer><Label>video not available</Label></LabelContainer> : 
        <Media query="(max-width: 1520px)">
          {matches => (
            <Video expanded={matches ? videoExpanded : true}>
              <div className="d-flex flex-row">
                <div className="flex-column">
                  <div className="d-flex flex-row justify-content-between">
                    <GameViewerVideoButtons />
                  </div>
                  <div className="d-flex flex-row mt-2">{this.videoContent(selectedPitch)}</div>
                </div>
              </div>
            </Video>
          )}
        </Media> 
  }

  spacerLarge() {
    return <Spacer expanded={true} />;
  }

  spacerSmall() {
    const { videoExpanded } = this.props.rootStore.gameViewerStore;
    return <Spacer expanded={videoExpanded} />;
  }

  videoContent(selectedPitch) {
    if (selectedPitch) {
      return <GameViewerDisplay />;
    } else {
      return (
        <Media query="(max-width: 1520px)">{matches => (matches ? this.spacerSmall() : this.spacerLarge())}</Media>
      );
    }
  }
}

export default inject("rootStore")(observer(GameViewerContentVideo));
