import React from "react";
import Moment from "moment";
import { inject, observer } from "mobx-react";
import ModalHeader from "../../common/modal/ModalHeader";
import ModalFooter from "../../common/modal/ModalFooter";
import ModalBody from "../../common/modal/ModalBody";
import styled from "styled-components";
import ZeLightButton from "../../elements/form/ZeLightButton";
import ZeTableHeader from "../../elements/table/ZeTableHeader";
import { DateConstants } from "../../common/constants/DateConstants";

const DisputeCommentTable = styled.table.attrs({
  className: "w-100 table table-bordered table-hover"
})``;

const DisputeTableHolder = styled.div`
  max-height: 40vh;
  overflow: scroll;
`;

const VisibleToUmpireCheckbox = styled.input.attrs({
  className: "form-check-input",
  type: "checkbox"
})``;

const columnWidths = {
  date: "175px",
  name: "175px",
  comment: "325px",
  visible: "75px"
};

class DisputeCommentFormAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.saveComment = this.saveComment.bind(this);
    this.toggleVisibleToUmpire = this.toggleVisibleToUmpire.bind(this);
    this.state = { text: "", isOpen: true, visibleToUmpire: false };
  }

  onChange(event) {
    this.setState({ text: event.target.value });
  }

  getCloseButtonText(commentText) {
    return commentText && commentText.length > 0 ? "Close" : "Cancel";
  }

  saveComment(event) {
    event.preventDefault();
    let comment = this.createDisputeComment();
    this.props.rootStore.disputeStore.saveDisputeComment(comment);
  }

  toggleVisibleToUmpire(event) {
    this.setState({
      visibleToUmpire: !this.state.visibleToUmpire
    });
  }

  createDisputeComment() {
    const { authStore } = this.props.rootStore;
    return {
      disputeCommentId: null,
      createdDate: null,
      createdById: authStore.umpireId,
      createdByName: authStore.name,
      message: this.state.text,
      visibleToUmpire: this.state.visibleToUmpire
    };
  }

  render() {
    const {
      rootStore: { disputeStore },
      toggleModal,
      text
    } = this.props;
    const selectedDispute = disputeStore.selectedDispute;
    const comments = selectedDispute.comments;

    return (
      <form onSubmit={this.saveComment}>
        <ModalHeader>
          <h4 className="modal-title">
            <strong>Dispute Comments</strong> {comments ? comments.length : 0}
          </h4>
          <button type="button" className="close" onClick={toggleModal}>
            <span aria-hidden="true">&times;</span>
          </button>
        </ModalHeader>

        <ModalBody>
          <div className="row">
            <div className="col">
              <DisputeTableHolder>
                <DisputeCommentTable>
                  <ZeTableHeader>
                    <tr>
                      <th width={columnWidths.date}>Date</th>
                      <th width={columnWidths.name}>Comment By</th>
                      <th width={columnWidths.comment}>Comment</th>
                      <th width={columnWidths.visible}>Visible</th>
                    </tr>
                  </ZeTableHeader>
                  <tbody>
                    {comments
                      ? comments
                          .sort((a, b) => {
                            return new Date(a.createdDateAsString) < new Date(b.createdDateAsString);
                          })
                          .map(comment => (
                            <tr key={comment.disputeCommentId}>
                              <td width={columnWidths.date}>
                                {Moment(comment.createdDateAsString).format(DateConstants.DATE_FORMAT_WITH_TIME)}
                              </td>
                              <td width={columnWidths.name}>{comment.createdByName}</td>
                              <td width={columnWidths.comment}>{comment.message}</td>
                              <td width={columnWidths.visible}>{comment.visibleToUmpire ? "Yes" : "No"}</td>
                            </tr>
                          ))
                      : null}
                  </tbody>
                </DisputeCommentTable>
              </DisputeTableHolder>
            </div>
          </div>

          <div className="row mt-2">
            <div className="col">
              <div>
                <textarea rows="3" className="form-control" value={this.state.text} onChange={this.onChange} />
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col">
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <VisibleToUmpireCheckbox checked={this.state.visibleToUmpire} onChange={this.toggleVisibleToUmpire} />
                  Show Comment to Umpire
                </label>
              </div>
            </div>
          </div>
        </ModalBody>

        <ModalFooter>
          <button type="submit" className="btn btn-primary" disabled={this.state.text.length === 0}>
            Save
          </button>
          <ZeLightButton onClick={toggleModal}>{this.getCloseButtonText(text)}</ZeLightButton>
        </ModalFooter>
      </form>
    );
  }
}

export default inject("rootStore")(observer(DisputeCommentFormAdmin));
