import React from "react";
import { inject, observer } from "mobx-react";
import ZeTableHeader from "../../elements/table/ZeTableHeader";
import Moment from "moment";
import styled from "styled-components";
import { Link } from "react-router-dom";
import SortColumnHeader from "../../common/form/SortColumnHeader";
import { DateConstants } from "../../common/constants/DateConstants";

const ScheduleTable = styled.table.attrs({
  className: "table table-bordered table-hover"
})`
  td {
    padding: 0.5rem !important;
  }
`;

const ScheduleTableHolder = styled.div.attrs({
  className: "table-responsive"
})`
  min-height: 350px !important;
  overflow: visible !important;
`;

const CenteredTd = styled.td`
  text-align: center;
`;

class ScheduleSuperUmpireResults extends React.Component {
  render() {
    const {
      rootStore: { scheduleStore }
    } = this.props;
    return (
      <div className="row mt-4">
        <div className="col-12">
          <ScheduleTableHolder>
            <ScheduleTable>
              <ZeTableHeader>
                <tr>
                  <SortColumnHeader store={scheduleStore} sortKey="game" name="Game" />
                  <SortColumnHeader store={scheduleStore} sortKey="date" name="Date" />
                  <SortColumnHeader store={scheduleStore} sortKey="umpire" name="Umpire" />
                  <SortColumnHeader store={scheduleStore} sortKey="scoresheet" name="Scoresheet" />
                </tr>
              </ZeTableHeader>
              <tbody>
                {scheduleStore.games.map(game => (
                  <tr key={game.gamePk}>
                    <CenteredTd>
                      <Link to={"/gameviewer?gamePk=" + game.gamePk + "&umpireId=" + game.umpire.id}>
                        {game.displayName}
                      </Link>
                    </CenteredTd>
                    <CenteredTd>{Moment(game.displayTime).format(DateConstants.DATE_FORMAT_WITH_TIME)}</CenteredTd>
                    <CenteredTd>{game.umpire ? game.umpire.name : ""}</CenteredTd>
                    <CenteredTd>
                      {game.scoresheetReleased ? (
                        <Link to={"/scoresheet?gamePk=" + game.gamePk + "&umpireId=" + game.umpire.id}>View</Link>
                      ) : null}
                    </CenteredTd>
                  </tr>
                ))}
              </tbody>
            </ScheduleTable>
          </ScheduleTableHolder>
        </div>
      </div>
    );
  }
}

export default inject("rootStore")(observer(ScheduleSuperUmpireResults));
