import React from "react";
import Media from "react-media";
import ComponentContainer from "./ComponentContainer";
import Protected from "../components/protected/Protected";
import GameViewer from "../components/gameviewer/GameViewer";
import { inject, observer } from "mobx-react";
import { GlobalHotKeys } from "react-hotkeys";

class GameViewerContainer extends React.Component {
  render() {
    const {
      rootStore: { gameViewerStore }
    } = this.props;

    return (
      <Media query="(max-width: 1520px)">
        {matches =>
          matches ? (
            <ComponentContainer grey={true} nomargin={true} nopadding={true} width={1024}>
              <GlobalHotKeys keyMap={gameViewerStore.hotkeyMap} handlers={gameViewerStore.hotkeyHandlers} />
              <GameViewer />
            </ComponentContainer>
          ) : (
            <ComponentContainer grey={true} nomargin={true} nopadding={true} width={1504}>
              <GlobalHotKeys keyMap={gameViewerStore.hotkeyMap} handlers={gameViewerStore.hotkeyHandlers} />
              <GameViewer />
            </ComponentContainer>
          )
        }
      </Media>
    );
  }
}

export default inject("rootStore")(Protected(observer(GameViewerContainer)));
