import React from "react";
import styled from "styled-components";

const CardBody = styled.div.attrs({
  className: "card-body"
})`
  ${props => (props.padding ? "" : "padding: 0 !important;")};
  border-left: 1px solid rgba(0, 0, 0, 0.125) !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
  border-right: 1px solid rgba(0, 0, 0, 0.125) !important;
  overflow-x: scroll;
`;

class AccordionBody extends React.Component {
  render() {
    const bodyClass = this.props.open ? "collapse show" : "collapse";
    return (
      <div id={this.props.title} className={bodyClass} aria-labelledby={this.props.title}>
        <CardBody padding={this.props.padding}>{this.props.children}</CardBody>
      </div>
    );
  }
}

export default AccordionBody;
