import React from "react";

class ErrorCatcher extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    console.log(error);
  }

  componentDidCatch(error, info) {
    // Here we can log to a service now or email/slack if we need to see the errors that happen and crash prod if it ever happens
    console.log(error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <div>There was an error with ZE, please contact ZE Support</div>;
    }

    return this.props.children;
  }
}

export default ErrorCatcher;
