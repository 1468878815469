import React from "react";
import styled from "styled-components";
import { inject, observer } from "mobx-react";
import DatePickerInput from "../common/form/DatePickerInput";
import ReportFooterButtons from "./ReportFooterButtons";

const DateHolder = styled.div`
  &&& {
    width: 16rem;
  }
`;

const ReportDateRange = ({ rootStore: { reportStore } }) => (
  <div>
    <form
      onSubmit={event => {
        event.preventDefault();
        reportStore.runReport();
      }}
    >
      <div className="d-flex">
        <DateHolder>
          <label className="col-form-label">Game Start Date</label>
          <DatePickerInput date={reportStore.dateRange.start} onChange={reportStore.setStartDate} />
        </DateHolder>
        <DateHolder>
          <label className="col-form-label">Game End Date</label>
          <DatePickerInput date={reportStore.dateRange.end} onChange={reportStore.setEndDate} />
        </DateHolder>
      </div>
      <div>
        <ReportFooterButtons />
      </div>
    </form>
  </div>
);

export default inject("rootStore")(observer(ReportDateRange));
