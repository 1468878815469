import React from "react";
import styled from "styled-components";
import ReactAnimationFrame from "react-animation-frame";
import { inject } from "mobx-react/index";
import { CanvasUtilities } from "../../../../utilities/CanvasUtilities";
import { szCanvas } from "../../../../stores/gameViewer/GameViewerStoreConstants";

const Canvas = styled.canvas`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
`;

const plateCF = [
  [250, 82],
  [250, 152]
];
const plateHH = [
  [172, 82],
  [137, 82],
  [102, 117],
  [137, 152],
  [172, 152]
];
const plateHHBuffer = [
  [172, 82],
  [172, 73.76],
  [137, 73.76],
  [93.76, 117],
  [137, 160.24],
  [172, 160.24],
  [172, 152]
];
const strikeZone1BBuffer = [
  [88, 82],
  [88, 73.76],
  [172, 73.76],
  [172, 82]
];
const strikeZone3BBuffer = [
  [88, 152],
  [88, 160.24],
  [172, 160.24],
  [172, 152]
];
const pitchCircleRadius = 6.05;

class GameViewerBatterCanvas extends React.Component {
  clear() {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
  }

  componentDidMount() {
    this.ctx = this.canvas.getContext("2d");

    setTimeout(this.draw(), 2000);
  }

  constructor(props) {
    super(props);

    this.onCanvasClick = this.onCanvasClick.bind(this);
    this.onCanvasMouseMove = this.onCanvasMouseMove.bind(this);
  }

  draw() {
    const gameViewerStore = this.props.rootStore.gameViewerStore;
    const { cartoonAngle } = gameViewerStore;
    switch (cartoonAngle) {
      case "centerfield":
        this.drawCenterfield();
        break;
      case "high_home":
        this.drawHighHome();
        break;
      case "high_first":
        this.drawHighFirst();
        break;
      default:
        break;
    }
  }

  calculateStrikeZoneBox() {
    const gameViewerStore = this.props.rootStore.gameViewerStore;
    const { pitchList, selectedPitch } = gameViewerStore;
    let width = 17;
    if (selectedPitch && selectedPitch.szWidth) {
      width = selectedPitch.szWidth;
    } else if (pitchList.length && pitchList[0] && pitchList[0].szWidth) {
      width = pitchList[0].szWidth;
    }
    return [
      [88, 117 - ((1 / 2) * width * 70) / 17],
      [172, 117 - ((1 / 2) * width * 70) / 17],
      [172, 117 + ((1 / 2) * width * 70) / 17],
      [88, 117 + ((1 / 2) * width * 70) / 17]
    ];
  }

  drawBackgroundCF() {
    const gameViewerStore = this.props.rootStore.gameViewerStore;
    const { isMilbGame } = gameViewerStore;
    let strikeZoneBox = this.calculateStrikeZoneBox();
    CanvasUtilities.drawLinePointToPoint(this.ctx, ...plateCF, CanvasUtilities.COLORS.BLACK);
    for (let idx = 0; idx < strikeZoneBox.length; idx++) {
      CanvasUtilities.drawLinePointToPoint(
        this.ctx,
        strikeZoneBox[idx],
        strikeZoneBox[(idx + 1) % strikeZoneBox.length],
        CanvasUtilities.COLORS.BLACK
      );
    }
    if (!isMilbGame) {
      for (let idx = 0; idx < strikeZone1BBuffer.length - 1; idx++) {
        CanvasUtilities.drawLinePointToPoint(
          this.ctx,
          strikeZone1BBuffer[idx],
          strikeZone1BBuffer[idx + 1],
          CanvasUtilities.COLORS.RED,
          1,
          3
        );
      }
      for (let idx = 0; idx < strikeZone3BBuffer.length - 1; idx++) {
        CanvasUtilities.drawLinePointToPoint(
          this.ctx,
          strikeZone3BBuffer[idx],
          strikeZone3BBuffer[idx + 1],
          CanvasUtilities.COLORS.RED,
          1,
          3
        );
      }
    }
  }

  drawBackgroundHF() {
    CanvasUtilities.drawLinePointToPoint(this.ctx, ...plateCF, CanvasUtilities.COLORS.BLACK);
    let strikeZoneBox = this.calculateStrikeZoneBox();
    let depth = null;
    const gameViewerStore = this.props.rootStore.gameViewerStore;
    const { pitchList, selectedPitch } = gameViewerStore;
    if (selectedPitch && selectedPitch.szDepth) {
      depth = selectedPitch.szDepth;
    } else if (pitchList.length && pitchList[0] && pitchList[0].szDepth) {
      depth = pitchList[0].szDepth;
    }
    if (depth) {
      let change = depth - 8.5 !== 0 ? ((depth - 8.5) / 2) * (70 / 17) : 0;
      strikeZoneBox = [
        [88, 117 - change],
        [172, 117 - change]
      ];
    }
    for (let idx = 0; idx < strikeZoneBox.length; idx++) {
      CanvasUtilities.drawLinePointToPoint(
        this.ctx,
        strikeZoneBox[idx],
        strikeZoneBox[(idx + 1) % strikeZoneBox.length],
        CanvasUtilities.COLORS.BLACK
      );
    }
  }

  drawBackgroundHH() {
    const gameViewerStore = this.props.rootStore.gameViewerStore;
    const { isMilbGame } = gameViewerStore;
    let depth = null;
    const { pitchList, selectedPitch } = gameViewerStore;
    if (selectedPitch && selectedPitch.szDepth) {
      depth = selectedPitch.szDepth;
    } else if (pitchList.length && pitchList[0] && pitchList[0].szDepth) {
      depth = pitchList[0].szDepth;
    }
    if (depth) {
      let change = depth !== 0 ? depth * (70 / 17) : 0;
      CanvasUtilities.drawLinePointToPoint(
        this.ctx,
        [172 - change, 82],
        [172 - change, 152],
        CanvasUtilities.COLORS.BLACK
      );
    } else {
      for (let idx = 0; idx < plateHH.length; idx++) {
        CanvasUtilities.drawLinePointToPoint(this.ctx, plateHH[idx], plateHH[(idx + 1) % plateHH.length], CanvasUtilities.COLORS.BLACK);
      }
    }
    if (!isMilbGame) {
      for (let idx = 0; idx < plateHHBuffer.length - 1; idx++) {
        CanvasUtilities.drawLinePointToPoint(
          this.ctx,
          plateHHBuffer[idx],
          plateHHBuffer[(idx + 1) % plateHHBuffer.length],
          CanvasUtilities.COLORS.RED,
          1,
          3
        );
      }
    } else {
      for (let idx = 0; idx < plateHH.length - 1; idx++) {
        CanvasUtilities.drawLinePointToPoint(
          this.ctx,
          plateHH[idx],
          plateHH[(idx + 1) % plateHH.length],
          CanvasUtilities.COLORS.LIGHT_RED,
          1,
          3
        );
      }
    }
  }

  drawCenterfield() {
    const { pitchListFiltered, selectedPitch, isMilbGame } = this.props.rootStore.gameViewerStore;
    this.drawBackgroundCF();
    const verticalPixelsPerInch = this.getVerticalPixelsPerInch(selectedPitch);
    if (selectedPitch) {
      if (!isMilbGame) {
        this.drawLowBuffer(selectedPitch, verticalPixelsPerInch);
      }
      this.drawPitchCF(selectedPitch, true);
    } else {
      pitchListFiltered.forEach(p => this.drawPitchCF(p, false));
    }
  }

  drawHighFirst() {
    const { selectedPitch, isMilbGame } = this.props.rootStore.gameViewerStore;
    const verticalPixelsPerInch = this.getVerticalPixelsPerInch(selectedPitch);
    this.drawPitchHF();
    if (!isMilbGame) {
      this.drawLowBuffer(selectedPitch, verticalPixelsPerInch);
    }
    this.drawBackgroundHF();
  }

  drawHighHome() {
    this.drawBackgroundHH();
    this.drawPitchHH();
  }

  drawLowBuffer(pitch, verticalPixelsPerInch) {
    const lowBufferPixels = verticalPixelsPerInch * 1.45;
    const lowBufferLine = this.getLowBufferLineCoordinates(lowBufferPixels);
    CanvasUtilities.drawLinePointToPoint(this.ctx, ...lowBufferLine, CanvasUtilities.COLORS.RED, 1, 3);
  }

  getLowBufferLineCoordinates(lowBufferPixels) {
    let strikeZoneBox = this.calculateStrikeZoneBox();
    return [
      [strikeZoneBox[1][0] - lowBufferPixels, strikeZoneBox[1][1]],
      [strikeZoneBox[2][0] - lowBufferPixels, strikeZoneBox[2][1]]
    ];
  }

  drawPitchCF(pitch, selected) {
    const { functions, hoverPitches, pitchListFiltered } = this.props.rootStore.gameViewerStore;
    const coord = pitch.canvasPositions.centerfield;
    if (!coord) {
      return;
    }
    const color = functions.getPitchColor(pitch);
    if (pitchListFiltered.length > 1) {
      for (let idx = 0; idx < hoverPitches.length; idx++) {
        if (hoverPitches[idx].playId === pitch.playId) {
          selected = true;
        }
      }
    }
    const fillColor = selected ? color : null;
    if (coord.row < 8) {
      CanvasUtilities.drawEquilateralTriangle(this.ctx, 8, coord.col, 8, color, fillColor, 90);
    } else if (coord.row > plateCF[0][0]) {
      CanvasUtilities.drawEquilateralTriangle(this.ctx, plateCF[0][0], coord.col, 8, color, fillColor, -90);
    } else if (coord.col < 8) {
      CanvasUtilities.drawEquilateralTriangle(this.ctx, plateCF[0][0], coord.col, 8, color, fillColor, 0);
    } else if (coord.col > 227) {
      CanvasUtilities.drawEquilateralTriangle(this.ctx, plateCF[0][0], coord.col, 8, color, fillColor, 180);
    } else {
      CanvasUtilities.drawCircle(this.ctx, coord.row, coord.col, pitchCircleRadius, color, fillColor);
    }
  }

  drawPitchHF() {
    const { functions, selectedPitch } = this.props.rootStore.gameViewerStore;
    const coords = selectedPitch.canvasPositions.highFirst;
    if (!coords || coords.length !== 2) {
      return;
    }
    const color = functions.getPitchColor(selectedPitch);
    CanvasUtilities.drawLinePointToPointRowCol(this.ctx, ...coords, color, pitchCircleRadius * 2);
  }

  drawPitchHH() {
    const { functions, selectedPitch } = this.props.rootStore.gameViewerStore;
    const coords = selectedPitch.canvasPositions.highHome;
    if (!coords || coords.length !== 2) {
      return;
    }
    const color = functions.getPitchColor(selectedPitch);
    CanvasUtilities.drawLinePointToPointRowCol(this.ctx, ...coords, color, pitchCircleRadius * 2);
  }

  getVerticalPixelsPerInch(pitch) {
    let strikeZoneBox = this.calculateStrikeZoneBox();
    if (!pitch) {
      return (strikeZoneBox[1][0] - strikeZoneBox[0][0]) / 18;
    }
    const { szTop, szBottom } = pitch;
    const szHeightInInches = szTop && szBottom ? (szTop - szBottom) * 12 : 18;
    return (strikeZoneBox[1][0] - strikeZoneBox[0][0]) / szHeightInInches;
  }

  onAnimationFrame(time) {
    if (this.ctx) {
      this.clear();
      this.draw();
    }
  }

  onCanvasClick(event) {
    const gameViewerStore = this.props.rootStore.gameViewerStore;
    const { cartoonAngle, hoverPitches, selectedPitch } = gameViewerStore;
    if (cartoonAngle !== "centerfield") {
      return;
    }
    if (!hoverPitches.length) {
      return;
    }
    if (selectedPitch) {
      hoverPitches.forEach(hp => {
        if (hp.playId === selectedPitch.playId) {
          gameViewerStore.setSelectedPitch(null);
        }
      });
    } else if (hoverPitches.length === 1) {
      gameViewerStore.setSelectedPitch(hoverPitches[0], true);
    } else if (hoverPitches.length > 1) {
      gameViewerStore.setPitchListFilter(hoverPitches.map(hp => hp.pitchNumber).join(","));
    }
  }

  onCanvasMouseMove(event) {
    const gameViewerStore = this.props.rootStore.gameViewerStore;
    const { pitchListFiltered } = gameViewerStore;
    const { x, y } = event.currentTarget.getBoundingClientRect();
    const { clientX, clientY } = event;
    let canvasPosition = {
      row: clientY - y,
      col: clientX - x
    };
    let hoverPitches = [];
    pitchListFiltered.forEach(p => {
      const distance = CanvasUtilities.distanceBetweenPoints(p.canvasPositions.centerfield, canvasPosition);
      if (distance > -1 && distance < szCanvas.pitchCircleRadius) {
        hoverPitches.push(p);
      }
    });
    gameViewerStore.setHoverPitches(hoverPitches);
  }

  render() {
    return (
      <Canvas
        {...this.props}
        innerRef={c => {
          this.canvas = c;
        }}
        {...{
          onClick: this.onCanvasClick,
          onMouseMove: this.onCanvasMouseMove
        }}
      />
    );
  }
}

export default inject("rootStore")(ReactAnimationFrame(GameViewerBatterCanvas));
