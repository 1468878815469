import React from "react";
import ReactTooltip from "react-tooltip";
import { inject, observer } from "mobx-react";
import styled from "styled-components";

const CommentBubbleHolder = styled.span.attrs({
  className: "fa-stack fa-sm dispute-comment"
})`
  &:hover {
    cursor: pointer;
    color: #266cd6 !important;
  }
`;

const CommentBubble = styled.i`
  color: #2b77eb;
  &:hover {
    cursor: pointer;
    color: #266cd6 !important;
  }
`;

const CommentCount = styled.strong.attrs({
  className: "fa-stack-1x"
})`
  color: white;
`;

class DisputeCommentBubble extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false, text: "" };
  }

  getVisibleComment() {
    const { comments } = this.props;

    if (!comments) {
      return "";
    }
    for (let i = 0; i < comments.length; i++) {
      let comment = comments[i];
      if (comment.visibleToUmpire) {
        return comment.message;
      }
    }
    return "";
  }

  getCommentBubbleClass(comments) {
    return comments && comments.length > 0 ? "fas fa-stack-2x fa-comment" : "far fa-stack-2x fa-comment";
  }

  render() {
    const { comments } = this.props;
    const total = comments && comments.length ? comments.length : "";

    return (
      <div data-tip={this.getVisibleComment()}>
        <ReactTooltip effect="solid" place="left" type="light" />
        <CommentBubbleHolder>
          <CommentBubble className={this.getCommentBubbleClass(comments)} />
          <CommentCount total={total}>{total}</CommentCount>
        </CommentBubbleHolder>
      </div>
    );
  }
}

export default inject("rootStore")(observer(DisputeCommentBubble));
