import React from "react";
import { inject, observer } from "mobx-react";
import ZeLightButton from "../../../elements/form/ZeLightButton";
import Moment from "moment-timezone";

const VideoButton = ZeLightButton.extend`
  ${props => (props.isActive ? "background-color: #fdec98 !important;" : "")};
  ${props => (props.disabled ? "background-color: #f7f7f7 !important;" : "")};
  ${props => (props.disabled ? "border: 1px solid #ececec !important;" : "")};
  color: ${props => (props.disabled ? "#d2d2d2" : "#666666")} !important;
  font-size: 11px !important;
  font-weight: bold !important;
  height: 36px !important;
  letter-spacing: -0.4px !important;
  &:hover {
    ${props => (props.disabled ? "border: 1px solid #ececec !important;" : "")};
  }
`;

class GameViewerVideoButtons extends React.Component {
  mediaButton(active, disabled, label, onClick, rightMargin) {
    const className = "flex-grow-1" + (rightMargin ? " mr-1" : "");
    return (
      <VideoButton className={className} disabled={disabled} isActive={active} onClick={onClick}>
        {label}
      </VideoButton>
    );
  }

  render() {
    const { isMilbGame } = this.props.rootStore.gameViewerStore;
    const { displayTime } = this.props.rootStore.gameViewerStore.game;
    const year = Moment(displayTime).year();
    return (
      <div className="d-flex flex-row flex-fill justify-content-between">
        {this.videoButtonCfOrPitchcast()}
        {this.videoButton("TV", "TV", true)}
        {year < 2020 ? this.videoButton("Side", "Side", true) : null}
        {!isMilbGame ? this.szButton(false) : null}
      </div>
    );
  }
  szButton() {
    const gameViewerStore = this.props.rootStore.gameViewerStore;
    const { selectedPitch, displaySz } = gameViewerStore;
    const disabled = !(selectedPitch && selectedPitch.pathToSZKeyframe);
    const onClick = () => gameViewerStore.setDisplaySz(true);
    return this.mediaButton(displaySz, disabled, "SZ", onClick);
  }

  videoButton(label, type, rightMargin) {
    const { gameViewerStore } = this.props.rootStore;
    const { currentVideo, displaySz, functions } = gameViewerStore;
    if (!type) {
      type = label;
    }
    const typeVideo = functions.getVideo(type);
    const active = !displaySz && currentVideo && currentVideo.rank === typeVideo.rank;
    const disabled = typeVideo.rank === -1 || !typeVideo.show;
    const onClick = () => {
      gameViewerStore.setCurrentVideo(typeVideo);
    };
    return disabled ? null : this.mediaButton(active, disabled, label, onClick, rightMargin);
  }

  videoButtonCfOrPitchcast() {
    const { currentPitchCoordinates, functions } = this.props.rootStore.gameViewerStore;
    const cf = functions.getVideo("Centerfield");
    if (cf.rank === -1) {
      return this.videoButton("Centerfield", "Pitchcast", true);
    }
    const pitchcast = functions.getVideo("Pitchcast");
    if (pitchcast.rank === -1 || currentPitchCoordinates.centerfield) {
      return this.videoButton("Centerfield", "Centerfield", true);
    }
    return this.videoButton("Centerfield", "Pitchcast", true);
  }
}

export default inject("rootStore")(observer(GameViewerVideoButtons));
