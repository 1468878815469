import React from "react";
import { Provider } from "mobx-react";
import RootStore from "../stores/RootStore";
import { AuthContextProvider } from "./AuthContext";
import { Router } from "react-router-dom";
import HttpInterceptor from "../httpClients/HttpInterceptor";

const rootStore = new RootStore();

const WrapperProvider = ({ children }) => (
  <Provider rootStore={rootStore}>
    <Router history={rootStore.history}>
      <AuthContextProvider rootStore={rootStore}>
        <HttpInterceptor>{children}</HttpInterceptor>
      </AuthContextProvider>
    </Router>
  </Provider>
);

export default WrapperProvider;
