import BaseApi from "./BaseApi";

const SERVICES_URL = process.env.REACT_APP_SERVICES_URL + "/api";
const OAUTH_URL = process.env.REACT_APP_SERVICES_URL + "/oauth/token";

class AuthApi extends BaseApi {
  getToken = (body, refresh) => {
    const client = btoa("ze:secret");
    let request = {
      url: OAUTH_URL,
      method: "post",
      headers: {
        Accept: "application/json",
        Authorization: `Basic ${client}`,
        "Content-Type": "application/x-www-form-urlencoded; charset=utf-8"
      },
      data: body
    };
    if (refresh) {
      request.headers.addToken = true;
    }
    return this.axiosWrapper(request)
      .then(response => response.data)
      .catch(error => error.response);
  };

  getUserInfo = () => {
    const url = SERVICES_URL + "/login/user";
    return this.axiosWrapper
      .get(url)
      .then(response => response.data)
      .catch(error => error.response);
  };
}

export default AuthApi;
