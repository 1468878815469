import React from "react";
import { inject, observer } from "mobx-react";
import HrTitle from "../../../common/title/HrTitle";
import ZeLabel from "../../../elements/form/ZeLabel";

class AuditZoomSettings extends React.Component {
  render() {
    const auditStore = this.props.rootStore.auditStore;
    return (
      <div>
        <HrTitle title="Video Settings" bg="FFFFFF" />
        <div className="row">
          <div className="col">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <ZeLabel>Pan Down (px)</ZeLabel>
                  <input
                    type="number"
                    className="form-control"
                    value={auditStore.videoZoomTop}
                    onChange={auditStore.setVideoZoomTop}
                    min="-269"
                    max="269"
                    disabled={false}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <ZeLabel>Pan Left (px)</ZeLabel>
                  <input
                    type="number"
                    className="form-control"
                    value={auditStore.videoZoomLeft}
                    onChange={auditStore.setVideoZoomLeft}
                    min="-479"
                    max="479"
                    disabled={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default inject("rootStore")(observer(AuditZoomSettings));
