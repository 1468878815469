let AWS = require("aws-sdk");

//These credentials only have access as read only to the ze-audit-graphics bucket
const awsConfig = {
  region: "us-east-1",
  accessKeyId: process.env.REACT_APP_TM_S3_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_TM_S3_SECRET
};
AWS.config.update(awsConfig);
const sts = new AWS.STS();

class AuditS3Api {
  constructor(s3Api) {
    this.s3Api = s3Api;
    this.getToken();
    this.refreshToken();
  }

  getImage(bucket, path) {
    return this.s3Api.getImage(this.s3, bucket, path);
  }

  getToken() {
    const _this = this;
    const params = {
      RoleArn: "arn:aws:iam::612458735632:role/mlb-it-ze-system",
      RoleSessionName: "s3Session"
    };
    sts.assumeRole(params, function(err, data) {
      const awsConfigAssumedRole = {
        region: "us-east-1",
        accessKeyId: data.Credentials.AccessKeyId,
        secretAccessKey: data.Credentials.SecretAccessKey,
        sessionToken: data.Credentials.SessionToken
      };
      AWS.config.update(awsConfigAssumedRole);
      _this.s3 = new AWS.S3();
    });
  }

  refreshToken() {
    setInterval(this.getToken, 1800000);
  }
}

export default AuditS3Api;
