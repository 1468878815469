import _ from "lodash";
import React from "react";
import styled from "styled-components";
import { inject, observer } from "mobx-react";
import AuditPitchVideo from "./AuditPitchVideo";
import VideoControls from "../../common/video/VideoControls";

const Wrapper = styled.div`
  width: 960px;
  height: 540px;
`;

const NoPitchSelected = styled.div`
  font-weight: bold;
  font-size: 18px;
  margin: auto;
  text-align: center;
  height: 540px;
  line-height: 540px;
  vertical-align: middle;
`;

class AuditSelectedPitch extends React.Component {
  render() {
    const {
      rootStore: { auditStore, routerStore },
      videoRef
    } = this.props;
    const cameraCalMarkers = auditStore.cameraCalMarkers;

    return (
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="col-12">
              <Wrapper>
                {_.isEmpty(auditStore.selectedPitch) ? (
                  <NoPitchSelected>No pitch is selected</NoPitchSelected>
                ) : (
                  <AuditPitchVideo
                    auditStore={auditStore}
                    routerStore={routerStore}
                    videoRef={videoRef}
                    cameraCalMarkers={cameraCalMarkers}
                  />
                )}
              </Wrapper>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <VideoControls
                videoRef={auditStore.videoRef}
                currentTime={auditStore.videoCurrentTime}
                duration={auditStore.videoDuration}
                onChange={auditStore.onSeekChange}
                showTime={true}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default inject("rootStore")(observer(AuditSelectedPitch));
