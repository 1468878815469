import React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import ZeDropdownItem from "../../elements/DropdownItem";

const Dropdown = styled.div.attrs({
  className: "dropdown"
})`
  &.show {
    button {
      color: #2b77eb !important;
      background-color: #fff !important;
    }
  }
`;

const DropdownMenu = styled.div.attrs({
  className: "dropdown-menu"
})``;

const DropdownButton = styled.button.attrs({
  className: "btn btn-sm btn-primary dropdown-toggle",
  id: "gameActions",
  "data-toggle": "dropdown",
  "aria-haspopup": "true",
  "aria-expanded": "false"
})`
  background-color: #4c8cee !important;
  height: 26px;
`;

class ScheduleActions extends React.Component {
  constructor(props) {
    super(props);
    this.openStances = this.openStances.bind(this);
    this.isValidDateYear = this.isValidDateYear.bind(this);
  }

  gameHasNoData(game) {
    return game.status === "NO_DATA";
  }

  gameIsInitialized(game) {
    return game.status === "INITIALIZED";
  }

  gameIsAnalyzing(game) {
    return game.status === "ANALYZING";
  }

  gameIsFinalized(game) {
    return game.status === "FINALIZED";
  }

  openStances(game) {
    this.props.rootStore.scheduleStore.showUmpireStancesAction(game);
  }

  isValidDateYear() {
    return new Date(this.props.rootStore.scheduleStore.date).getFullYear() >= 2017;
  }

  render() {
    const {
      game,
      rootStore: { authStore, scheduleStore }
    } = this.props;
    let umpireId = game.umpire ? game.umpire.id : -1;
    let { isAuditor, isAuditorAdmin, isSuperAdmin } = authStore;
    return (
      <Dropdown>
        <DropdownButton disabled={!this.isValidDateYear()} />
        <DropdownMenu aria-labelledby="gameActions">
          {isAuditor ? (
            <ZeDropdownItem href={"/auditv2/strike-zone?gamePk=" + game.gamePk} target="_blank">
              Audit Game
            </ZeDropdownItem>
          ) : null}
          {isAuditor ? (
            <ZeDropdownItem color="#ff0000" href={"/audit/strike-zone?gamePk=" + game.gamePk} target="_blank">
              Legacy Audit
            </ZeDropdownItem>
          ) : null}
          {
            <ZeDropdownItem href={"/gameviewer?gamePk=" + game.gamePk + "&umpireId=" + umpireId} target="_blank">
              ZE2 Game Viewer
            </ZeDropdownItem>
          }
          {!this.gameIsAnalyzing(game) ? <div className="dropdown-divider" /> : null}
          {this.gameHasNoData(game) && isAuditor ? (
            <ZeDropdownItem onClick={() => scheduleStore.initializeGame(game.gamePk)}>Initialize Game</ZeDropdownItem>
          ) : null}
          {this.gameIsAnalyzing(game) && (isAuditorAdmin || isSuperAdmin) ? (
            <React.Fragment>
              <div className="dropdown-divider" />
              <ZeDropdownItem onClick={() => scheduleStore.moveBackToInitialized(game.gamePk)}>
                Move Back To Initialized
              </ZeDropdownItem>
            </React.Fragment>
          ) : null}
          {this.gameIsInitialized(game) && isAuditor ? (
            <ZeDropdownItem onClick={() => scheduleStore.initializeGame(game.gamePk)}>Reset Game</ZeDropdownItem>
          ) : null}
          {this.gameIsInitialized(game) && isAuditorAdmin ? (
            <ZeDropdownItem onClick={() => scheduleStore.releaseGame(game.gamePk, game.umpire.id, false)}>
              Release Game
            </ZeDropdownItem>
          ) : null}
          {this.gameIsFinalized(game) && isAuditorAdmin ? (
            <ZeDropdownItem onClick={() => scheduleStore.unreleaseGame(game.gamePk, game.umpire.id)}>
              Unrelease Game
            </ZeDropdownItem>
          ) : null}
          {isAuditorAdmin && !this.gameHasNoData(game) ? <div className="dropdown-divider" /> : null}
          {this.gameIsInitialized(game) && isAuditor ? (
            <ZeDropdownItem onClick={() => scheduleStore.analyzeAllPitches(game)}>Analyze All Pitches</ZeDropdownItem>
          ) : null}
          {this.gameIsInitialized(game) && isAuditor ? (
            <ZeDropdownItem onClick={() => scheduleStore.analyzePitchesModal(game.gamePk)}>
              Analyze Pitches
            </ZeDropdownItem>
          ) : null}
          {this.gameIsInitialized(game) && isAuditor ? (
            <ZeDropdownItem onClick={() => scheduleStore.retrieveVideo(game)}>Retrieve Video</ZeDropdownItem>
          ) : null}
          {this.gameIsInitialized(game) ? <div className="dropdown-divider" /> : null}
          {!this.gameHasNoData(game) && isAuditor ? (
            <ZeDropdownItem onClick={() => this.openStances(game)}>Update Stances</ZeDropdownItem>
          ) : null}
          {isAuditorAdmin ? <div className="dropdown-divider" /> : null}
          {isAuditor ? (
            <ZeDropdownItem onClick={() => scheduleStore.cutKeyframes(game.gamePk)}>Cut Keyframes</ZeDropdownItem>
          ) : null}
        </DropdownMenu>
      </Dropdown>
    );
  }
}

export default inject("rootStore")(observer(ScheduleActions));
