import React from "react";
import styled from "styled-components";
import { inject, observer } from "mobx-react";
import { CanvasUtilities } from "../../../utilities/CanvasUtilities";

const CanvasHolder = styled.div`
  position: relative;
  z-index: 1;
`;

const Canvas = styled.canvas`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
`;

const FieldCanvas = styled.canvas`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
`;

const StrikeZoneLinesWidth = 200;
const StrikeZonePullLeft = 180;
const StrikeZonePullRight = -20;

class PitchCanvas extends React.Component {
  constructor(props) {
    super(props);
    this.draw = this.draw.bind(this);
    this.drawLine = this.drawLine.bind(this);
    this.drawStrikeZone = this.drawStrikeZone.bind(this);
    this.drawCameraCalibration = this.drawCameraCalibration.bind(this);
    this.drawMarkerBox = this.drawMarkerBox.bind(this);
    this.drawCameraMarker = this.drawCameraMarker.bind(this);
    this.drawHomePlateSecondLine = this.drawHomePlateSecondLine.bind(this);
    this.drawPlateOutline = this.drawPlateOutline.bind(this);
  }

  componentDidMount() {
    this.ctx = this.canvas.getContext("2d");
    this.fieldCtx = this.fieldCanvas.getContext("2d");
    setTimeout(this.draw(), 2000);
  }

  componentDidUpdate() {
    this.clear();
    this.draw();
  }

  draw() {
    const { auditStore, routerStore } = this.props.rootStore;
    const { fieldTranslationVertical, fieldTranslationHorizontal } = this.props;
    let videoZoomTop = auditStore.videoZoomTop;
    let videoZoomLeft = auditStore.videoZoomLeft;
    if (auditStore.selectedPitch && auditStore.selectedPitch.cameraPan) {
      videoZoomTop = auditStore.selectedPitch.cameraPan.videoZoomTop;
      videoZoomLeft = auditStore.selectedPitch.cameraPan.videoZoomLeft;
    }

    if (this.ctx && this.fieldCtx) {
      if (routerStore.isAuditStrikeZoneTab && auditStore.fieldOutlineImage) {
        if (auditStore.videoZoomed) {
          let drawWidth = 960;
          let drawnHeight = 540;
          let sourceWidth = drawWidth / auditStore.videoZoomFactor;
          let sourceHeight = drawnHeight / auditStore.videoZoomFactor;
          let sourceX = sourceWidth / 2 - videoZoomLeft / 2 - fieldTranslationHorizontal / 2;
          let sourceY = sourceHeight / 2 - videoZoomTop / 2 - fieldTranslationVertical / 2;
          //Timeout is needed to let the browser load the image on first draw
          setTimeout(() => {
            if (auditStore.fieldOutlineImage) {
              this.fieldCtx.drawImage(
                auditStore.fieldOutlineImage,
                sourceX,
                sourceY,
                sourceWidth,
                sourceHeight,
                0,
                0,
                drawWidth,
                drawnHeight
              );
            }
          }, 0);
        } else {
          setTimeout(() => {
            this.fieldCtx.drawImage(
              auditStore.fieldOutlineImage,
              fieldTranslationHorizontal / 2,
              fieldTranslationVertical / 2
            );
          }, 0);
        }
        if (auditStore.selectedPitch && auditStore.selectedPitch.plateCoordinates) {
          this.drawPlateOutline(auditStore.plateCoordinatesAdjusted);
        }
      }

      // Mobx will not track "not-yet-existing" indices in an observable array
      // By performing a length check on the array, we ensure that all of our elements are tracked.
      // See https://github.com/mobxjs/mobx/blob/gh-pages/docs/best/react.md#incorrect-access-out-of-bounds-indices-in-tracked-function
      if (auditStore.isStrikeZone || auditStore.cameraCalStrikeZone) {
        if (auditStore.strikeZoneTopLeftAdj.length === 2 && auditStore.strikeZoneBottomRightAdj.length === 2) {
          this.drawStrikeZone(
            auditStore.strikeZoneTopLeftAdj,
            auditStore.strikeZoneBottomRightAdj,
            CanvasUtilities.COLORS.GREEN
          );
          if (auditStore.selectedPitch.batterSide === "R") {
            this.drawLine(
              auditStore.strikeZoneBottomRightAdj[1] + StrikeZonePullRight,
              auditStore.waistRowAdj,
              StrikeZoneLinesWidth
            );
            this.drawLine(
              auditStore.strikeZoneBottomRightAdj[1] + StrikeZonePullRight,
              auditStore.strikeZoneLowerAdj,
              StrikeZoneLinesWidth
            );
          } else {
            this.drawLine(
              auditStore.strikeZoneTopLeftAdj[1] - StrikeZonePullLeft,
              auditStore.waistRowAdj,
              StrikeZoneLinesWidth
            );
            this.drawLine(
              auditStore.strikeZoneTopLeftAdj[1] - StrikeZonePullLeft,
              auditStore.strikeZoneLowerAdj,
              StrikeZoneLinesWidth
            );
          }
        }
        if (auditStore.pitchCoordinatesAdj && auditStore.trackLine) {
          const { before, over, after, midpoint, release, frontOfPlate } = auditStore.pitchCoordinatesAdj;
          if (before && before.length > 1) {
            for (let idx = 1; idx < before.length; idx++) {
              let pointA = before[idx - 1];
              let pointB = before[idx];
              CanvasUtilities.drawLinePointToPoint(this.ctx, pointA, pointB, CanvasUtilities.COLORS.CYAN, 2);
            }
          }
          //Drawing from the last before point to the first over point to ensure no gaps in the line
          if (before && before.length > 1 && over && over.length > 1) {
            let pointA = before[before.length - 1];
            CanvasUtilities.drawLinePointToPoint(this.ctx, pointA, over[0], CanvasUtilities.COLORS.CYAN, 2);
          }
          if (over && over.length > 1) {
            for (let idx = 1; idx < over.length; idx++) {
              let pointA = over[idx - 1];
              let pointB = over[idx];
              CanvasUtilities.drawLinePointToPoint(this.ctx, pointA, pointB, CanvasUtilities.COLORS.YELLOW, 2);
            }
          }
          //Drawing from the last over point to the first after point to ensure no gaps in the line
          if (over && over.length > 1 && after && after.length > 1) {
            let pointA = over[over.length - 1];
            CanvasUtilities.drawLinePointToPoint(this.ctx, pointA, after[0], CanvasUtilities.COLORS.YELLOW, 2);
          }
          if (after && after.length > 1) {
            for (let idx = 1; idx < after.length; idx++) {
              let pointA = after[idx - 1];
              let pointB = after[idx];
              CanvasUtilities.drawLinePointToPoint(this.ctx, pointA, pointB, CanvasUtilities.COLORS.RED, 2);
            }
          }
          if (release) {
            this.drawCircle(...release, 5, CanvasUtilities.COLORS.YELLOW);
          }
          if (midpoint) {
            this.drawCircle(...midpoint, 5, CanvasUtilities.COLORS.YELLOW);
          }
          if (frontOfPlate) {
            this.drawCircle(...frontOfPlate, 5, CanvasUtilities.COLORS.YELLOW);
          }
        }
        const topLineColor = auditStore.dragLine === "top" ? CanvasUtilities.COLORS.RED : CanvasUtilities.COLORS.GREEN;
        const bottomLineColor =
          auditStore.dragLine === "bottom" ? CanvasUtilities.COLORS.RED : CanvasUtilities.COLORS.GREEN;
        if (auditStore.strikeZoneTopLeftAdj.length === 2 && auditStore.strikeZoneBottomRightAdj.length === 2) {
          this.drawStrikeZone(
            auditStore.strikeZoneTopLeftAdj,
            auditStore.strikeZoneBottomRightAdj,
            CanvasUtilities.COLORS.GREEN
          );
          if (auditStore.selectedPitch.batterSide === "R") {
            this.drawLine(
              auditStore.strikeZoneBottomRightAdj[1] + StrikeZonePullRight,
              auditStore.waistRowAdj,
              StrikeZoneLinesWidth,
              topLineColor
            );
            this.drawLine(
              auditStore.strikeZoneBottomRightAdj[1] + StrikeZonePullRight,
              auditStore.strikeZoneLowerAdj,
              StrikeZoneLinesWidth,
              bottomLineColor
            );
          } else {
            this.drawLine(
              auditStore.strikeZoneTopLeftAdj[1] - StrikeZonePullLeft,
              auditStore.waistRowAdj,
              StrikeZoneLinesWidth,
              topLineColor
            );
            this.drawLine(
              auditStore.strikeZoneTopLeftAdj[1] - StrikeZonePullLeft,
              auditStore.strikeZoneLowerAdj,
              StrikeZoneLinesWidth,
              bottomLineColor
            );
          }
        }
      }
      if (routerStore.isAuditCameraCalibrationTab) {
        this.drawCameraCalibration(
          auditStore.cameraCalMarkersSequence,
          auditStore.cameraCalibration.markers,
          auditStore.cameraMarkers,
          auditStore.selectedCameraCal,
          auditStore.dirtyCamCalMarkers,
          auditStore.marker.normal,
          auditStore.selectedPitch
        );

        if (auditStore.drawSecondLine) {
          this.drawHomePlateSecondLine(auditStore.secondBaseLineCoordinates);
        }
      }
    }
  }

  drawCircle(row, col, radius, color) {
    if (row === null || col === null) {
      return;
    }
    this.ctx.beginPath();
    this.ctx.arc(col, row, radius, 0, 2 * Math.PI);
    this.ctx.strokeStyle = color ? color : CanvasUtilities.COLORS.BLACK;
    this.ctx.stroke();
  }

  drawLine(col, row, lineLength, color) {
    if (row === null) return;
    this.ctx.beginPath();
    this.ctx.moveTo(col, row);
    this.ctx.lineTo(col + lineLength, row);
    this.ctx.strokeStyle = color ? color : CanvasUtilities.COLORS.BLACK;
    this.ctx.stroke();
  }

  drawStrikeZone(topLeft, bottomRight, color) {
    const width = bottomRight[1] - topLeft[1];
    const height = bottomRight[0] - topLeft[0];
    this.ctx.strokeStyle = color ? color : CanvasUtilities.COLORS.BLACK;
    this.ctx.strokeRect(topLeft[1], topLeft[0], width, height);
  }

  drawMarkerBox(y, x, color, marker, size) {
    //draw center point
    const pointWidth = 0;
    const finalColor = color ? color : CanvasUtilities.COLORS.BLACK;

    //draw marker box
    let width = marker.width * size;
    let height = marker.height * size;
    let boxX = x - width / 2;
    let boxY = y - height / 2;
    this.ctx.beginPath();
    this.ctx.strokeStyle = finalColor;
    this.ctx.strokeRect(boxX, boxY, width, height);

    //draw calibration lines
    const pointMove = pointWidth / 2;
    CanvasUtilities.drawLinePointToPoint(this.ctx, [y - pointMove, x], [y - height / 2, x], finalColor);
    CanvasUtilities.drawLinePointToPoint(this.ctx, [y + pointMove, x], [y + height / 2, x], finalColor);
    CanvasUtilities.drawLinePointToPoint(this.ctx, [y, x - pointMove], [y, x - width / 2], finalColor);
    CanvasUtilities.drawLinePointToPoint(this.ctx, [y, x + pointMove], [y, x + width / 2], finalColor);
  }

  drawCameraCalibration(
    cameraCalMarkersSequence,
    calibration,
    cameraCalMarkers,
    selectedCameraCal,
    dirtyCamCalMarkers,
    marker,
    selectedPitch
  ) {
    if (calibration && cameraCalMarkers) {
      let worstMarker = this.props.rootStore.auditStore.worstCalibrationRMSE;
      cameraCalMarkersSequence.toJS().forEach(c => {
        let currMarker = cameraCalMarkers[c];
        if (currMarker && calibration) {
          let data = calibration[currMarker.key];
          if (currMarker.keyIndex > -1) {
            data = data[currMarker.keyIndex];
          }
          this.drawCameraMarker(
            c,
            data,
            selectedPitch,
            cameraCalMarkers,
            selectedCameraCal,
            dirtyCamCalMarkers,
            marker,
            worstMarker
          );
        }
      });
    }
  }

  drawCameraMarker(
    id,
    value,
    selectedPitch,
    cameraCalMarkers,
    selectedCameraCal,
    dirtyCamCalMarkers,
    marker,
    worstMarker
  ) {
    if (value && cameraCalMarkers[id].visible) {
      let dirtyObj = dirtyCamCalMarkers[selectedPitch.pitchNumber];
      let dirty = dirtyObj ? dirtyObj[id] : false;
      let color =
        id === selectedCameraCal
          ? CanvasUtilities.COLORS.RED
          : dirty
          ? CanvasUtilities.COLORS.GREY
          : id === worstMarker.id
          ? CanvasUtilities.COLORS.GREEN
          : CanvasUtilities.COLORS.BLACK;
      let size = id === selectedCameraCal ? 3 : 1;
      let y = value[0];
      let x = value[1];
      this.drawMarkerBox(y, x, color, marker, size);
    }
  }

  drawHomePlateSecondLine(coordinates) {
    if (coordinates && coordinates.length > 1) {
      CanvasUtilities.drawLinePointToPoint(this.ctx, coordinates[0], coordinates[1], CanvasUtilities.COLORS.WHITE);
    }
  }

  drawPlateOutline(fieldPlateOutline) {
    if (fieldPlateOutline) {
      CanvasUtilities.drawLinePointToPoint(
        this.fieldCtx,
        fieldPlateOutline.backtip,
        fieldPlateOutline.midLeft,
        CanvasUtilities.COLORS.BLUE
      );
      CanvasUtilities.drawLinePointToPoint(
        this.fieldCtx,
        fieldPlateOutline.backtip,
        fieldPlateOutline.midRight,
        CanvasUtilities.COLORS.BLUE
      );
      CanvasUtilities.drawLinePointToPoint(
        this.fieldCtx,
        fieldPlateOutline.midLeft,
        fieldPlateOutline.frontLeft,
        CanvasUtilities.COLORS.BLUE
      );
      CanvasUtilities.drawLinePointToPoint(
        this.fieldCtx,
        fieldPlateOutline.midRight,
        fieldPlateOutline.frontRight,
        CanvasUtilities.COLORS.BLUE
      );
      CanvasUtilities.drawLinePointToPoint(
        this.fieldCtx,
        fieldPlateOutline.frontLeft,
        fieldPlateOutline.frontRight,
        CanvasUtilities.COLORS.BLUE
      );
    }
  }

  clear() {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.fieldCtx.clearRect(0, 0, this.canvas.width, this.canvas.height);
  }

  render() {
    const auditStore = this.props.rootStore.auditStore;
    if (!this.props.loading) {
      this.draw();
    }
    return (
      <CanvasHolder>
        <FieldCanvas
          {...this.props}
          innerRef={c => {
            this.fieldCanvas = c;
          }}
        />
        <Canvas
          {...this.props}
          innerRef={c => {
            this.canvas = c;
          }}
          zoomed={auditStore.videoZoomed}
          {...(!(auditStore.hoverAndClick && auditStore.strikeZoneEditable)
            ? {
                draggable: "true",
                onDragStart: auditStore.strikeZoneDragStart,
                onDrag: auditStore.strikeZoneDrag,
                onDragEnd: auditStore.strikeZoneDragEnd
              }
            : {})}
          {...((auditStore.hoverAndClick && auditStore.strikeZoneEditable)
            ? {
                onClick: auditStore.clickStrikeZone,
                onMouseMove: auditStore.strikeZoneDragStart
              }
            : {})}
        />
      </CanvasHolder>
    );
  }
}

export default inject("rootStore")(observer(PitchCanvas));
