import React from "react";
import ScheduleUmpireFilters from "./ScheduleUmpireFilters";
import ScheduleUmpireResults from "./ScheduleUmpireResults";

class ScheduleUmpire extends React.Component {
  render() {
    return (
      <div className="row">
        <div className="col">
          <ScheduleUmpireFilters />
          <ScheduleUmpireResults />
        </div>
      </div>
    );
  }
}

export default ScheduleUmpire;
