import React from "react";
import { inject, observer } from "mobx-react";
import GameViewerAtBat from "./GameViewerAtBat";
import styled from "styled-components";

const InningHolder = styled.td.attrs({
  className: "p-1"
})`
  &&& {
    font-weight: bold;
    letter-spacing: -0.3px;
    background-color: #002e6d;
    color: white;
  }
`;

const Logo = styled.img`
  height: 22px;
  width: 22px;
  margin-right: 10px;
`;

class GameViewInning extends React.Component {

  defaultImage(event, teamId) {
    const primaryURL = `https://www.mlbstatic.com/team-logos/team-cap-on-dark/${teamId}.svg`;
    const firstBackupURL = `https://www.mlbstatic.com/team-logos/team-cap-on-light/${teamId}.svg`;
    if (event.target.src && event.target.src === primaryURL) {
      event.target.src = firstBackupURL;
    } else {
      event.target.src = "https://www.mlbstatic.com/team-logos/league-on-dark/1.svg";
    }
  }

  render() {
    const { gameViewerStore } = this.props.rootStore;
    const { inning, fullInning } = this.props;
    const inningKey = (inning.top ? "T" : "B").concat(inning.inning);
    const isTop = inning.title.includes("TOP");
    const halfInningSelected = inningKey === gameViewerStore.selectedBoxScore;
    const showAllAtbats = halfInningSelected && this.showAllAtbats();
    const showAllPitches = halfInningSelected && this.showAllPitches();

    let fullInningAtbats = {};
    if (fullInning.atbats) {
      fullInning.atbats.forEach(ab => (fullInningAtbats[ab.id] = ab));
    }
    let inningAtbats = {};
    if (inning.atbats) {
      inning.atbats.forEach(ab => (inningAtbats[ab.id] = ab));
    }
    const inningInUse = showAllAtbats ? fullInning : inning;
    return (
      <React.Fragment>
        {inningInUse.atbats && inningInUse.atbats.length ? (
          <tr>
            <InningHolder>
              <div className="d-flex align-items-center">
                {isTop ? (
                  <React.Fragment>
                    {gameViewerStore.game.awayTeam ? (
                      <Logo
                        src={`https://www.mlbstatic.com/team-logos/team-cap-on-dark/${
                          gameViewerStore.game.awayTeam.id
                        }.svg`}
                        onError={(event) => this.defaultImage(event,gameViewerStore.game.awayTeam.id)}
                        alt="Away Logo"
                      />
                    ) : null}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {gameViewerStore.game.homeTeam ? (
                      <Logo
                        src={`https://www.mlbstatic.com/team-logos/team-cap-on-dark/${
                          gameViewerStore.game.homeTeam.id
                        }.svg`}
                        onError={(event) => this.defaultImage(event,gameViewerStore.game.homeTeam.id)}
                        alt="Home Logo"
                      />
                    ) : null}
                  </React.Fragment>
                )}
                {inningInUse.title}
              </div>
            </InningHolder>
          </tr>
        ) : null}
        {inningInUse.atbats.map(ab => (
          <GameViewerAtBat
            key={ab.id}
            atbat={inningAtbats[ab.id]}
            fullAtbat={fullInningAtbats[ab.id]}
            open={gameViewerStore.openAtBats.has(ab.id) ? gameViewerStore.openAtBats.get(ab.id) : false}
            toggleHidden={
              showAllPitches ||
              (inningAtbats[ab.id] && inningAtbats[ab.id].pitches.length === fullInningAtbats[ab.id].pitches.length)
            }
          />
        ))}
      </React.Fragment>
    );
  }

  showAllAtbats() {
    const filtersInUse = this.props.rootStore.gameViewerStore.filtersInUse;
    return !(filtersInUse.batter || filtersInUse.hand || filtersInUse.pitcher || filtersInUse.text);
  }

  showAllPitches() {
    const filtersInUse = this.props.rootStore.gameViewerStore.filtersInUse;
    return !(
      filtersInUse.batter ||
      filtersInUse.called ||
      filtersInUse.correctness ||
      filtersInUse.hand ||
      filtersInUse.pitcher ||
      filtersInUse.text
    );
  }
}

export default inject("rootStore")(observer(GameViewInning));
