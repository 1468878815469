import React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import ZeTableHeader from "../../../elements/table/ZeTableHeader";
import ZeTable from "../../../elements/table/ZeTable";

const TableHolder = styled.div`
  height: 450px;
  overflow: scroll;
`;

const CheckBoxHolder = styled.div.attrs({
  className: "form-check"
})`
  text-align: center;
  margin: auto auto auto auto;
`;

class AnalyzePitchTable extends React.Component {
  render() {
    const { scheduleStore } = this.props.rootStore;
    return (
      <TableHolder>
        <ZeTable>
          <ZeTableHeader>
            <tr>
              <th />
              <th>Inning</th>
              <th>Call</th>
              <th>Play ID</th>
              <th>Stance</th>
              <th>Pitch Type</th>
              <th>Pitch</th>
              <th>Pitcher</th>
              <th>Batter</th>
            </tr>
          </ZeTableHeader>
          <tbody>
            {!!scheduleStore.pitchList
              ? scheduleStore.pitchList.map(pitch => (
                  <tr key={pitch.playId}>
                    <td>
                      <CheckBoxHolder>
                        <input
                          type="checkbox"
                          className="form-check-input"
                          checked={pitch.selected}
                          onChange={() => {
                            pitch.selected = !pitch.selected;
                          }}
                        />
                      </CheckBoxHolder>
                    </td>
                    <td>
                      {pitch.topOfInning ? "T" : "B"}
                      {pitch.inning}&nbsp;&nbsp;{pitch.atBatNumber}-{pitch.pitchOfAtBat}
                    </td>
                    <td>{pitch.umpireCallCode}</td>
                    <td>{pitch.playId}</td>
                    <td>{pitch.batterSide}</td>
                    <td>{pitch.pitchType}</td>
                    <td>{pitch.pitchNumber}</td>
                    <td>{pitch.pitcherName}</td>
                    <td>{pitch.batterName}</td>
                  </tr>
                ))
              : null}
          </tbody>
        </ZeTable>
      </TableHolder>
    );
  }
}

export default inject("rootStore")(observer(AnalyzePitchTable));
