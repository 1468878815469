import React from "react";
import YearPickerSelect from "../../common/form/YearPickerSelect";
import { inject, observer } from "mobx-react";
import Select from "react-select";
import styled from "styled-components";

const DropdownSelect = styled(Select)`
  & .Select-menu-outer {
    z-index: 999 !important;
  }

  & .Select-placeholder {
    color: #d2d2d2;
    font-size: 16px;
    font-style: italic;
    font-weight: 300;
  }
`;

class ScheduleSuperUmpireFilters extends React.Component {
  constructor(props) {
    super(props);
    const {
      rootStore: { scheduleStore }
    } = this.props;
    this.umpireUpdated = this.umpireUpdated.bind(this);
    this.yearUpdated = this.yearUpdated.bind(this);
    scheduleStore.getUmpireOptions();
  }

  umpireUpdated(event) {
    this.props.rootStore.scheduleStore.setUmpires(event);
    this.props.rootStore.scheduleStore.updateUmpireUrl();
  }

  yearUpdated(event) {
    this.props.rootStore.scheduleStore.setSeason(event.value);
    this.props.rootStore.scheduleStore.updateUmpireUrl();
  }

  render() {
    const {
      rootStore: {
        scheduleStore: { season, umpireOptions, umpires }
      }
    } = this.props;
    let value = umpires ? umpires.slice() : null;

    return (
      <div className="row">
        <div className="col-md-3 col-sm-6 col-xs-12">
          <label className="col-form-label">Season</label>
          <YearPickerSelect startYear={2011} value={season} disabled={false} onChange={this.yearUpdated} />
        </div>
        <div className="col-md-3 col-sm-6 col-xs-12">
          <label className="col-form-label">Umpire(s)</label>
          <DropdownSelect
            name="umpires"
            value={value}
            onChange={this.umpireUpdated}
            options={umpireOptions.toJS()}
            placeholder="Umpire(s)"
            isMulti={true}
          />
        </div>
      </div>
    );
  }
}

export default inject("rootStore")(observer(ScheduleSuperUmpireFilters));
