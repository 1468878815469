import React from "react";
import { Popover, PopoverBody } from "reactstrap";
import Moment from "moment-timezone";
import { DateConstants } from "../../common/constants/DateConstants";

class ScheduleAdminResultGameStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
    this.toggle = this.toggle.bind(this);
    this.id = "game-status-" + props.id;
  }

  toggle() {
    this.setState({
      open: !this.state.open
    });
  }

  render() {
    const { status, modifiedDate } = this.props;
    return (
      <div>
        <span id={this.id}>{status}</span>
        {modifiedDate && (
          <Popover
            placement="top"
            isOpen={this.state.open}
            target={this.id}
            toggle={this.toggle}
            trigger={"click hover focus"}
          >
            <PopoverBody>
              Last Modified:{" "}
              {Moment.utc(modifiedDate)
                .local()
                .format(DateConstants.DATE_FORMAT_WITH_TIME)}
            </PopoverBody>
          </Popover>
        )}
      </div>
    );
  }
}

export default ScheduleAdminResultGameStatus;
