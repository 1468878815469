import React from "react";
import styled from "styled-components";
import GameViewerPitch from "./GameViewerPitch";
import { inject, observer } from "mobx-react/index";

const AtBatRow = styled.tr`
  &:hover {
    cursor: pointer;
  }
`;

const AtBat = styled.td`
  background-color: #cccccc !important;
  color: #000000;
  letter-spacing: -0.3px;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
`;

const Player = styled.span`
  margin-left: ${props => (props.toggleHidden ? "26px" : "0")};
  color: #000000;
  font-weight: bold;
`;

const ToggleIcon = styled.i`
  color: #4c8cee;
  margin-right: 14px;

  &:hover {
    cursor: pointer;
  }
`;

const Outs = styled.span`
  float: right;
  font-size: 12px;
  color: #000000;
`;

class GameViewerAtBat extends React.Component {
  onClick(value) {
    const {
      rootStore: { gameViewerStore },
      toggleHidden
    } = this.props;
    if (!toggleHidden) {
      gameViewerStore.setOpenAtBat(value);
    }
  }

  render() {
    let {
      atbat,
      fullAtbat,
      open,
      toggleHidden,
      rootStore: { gameViewerStore }
    } = this.props;
    let atbatInUse = open ? fullAtbat : atbat;
    let hasPitches = atbatInUse && atbatInUse.pitches;
    return (
      <React.Fragment>
        <AtBatRow
          onClick={() => {
            this.onClick(fullAtbat.id);
          }}
        >
          <AtBat>
            {!toggleHidden ? (
              open ? (
                <ToggleIcon className="fa fa-minus" />
              ) : (
                <ToggleIcon className="fa fa-plus" />
              )
            ) : null}
            <Player toggleHidden={toggleHidden}>{fullAtbat.title}</Player>
            <Outs>{fullAtbat.outs} outs</Outs>
          </AtBat>
        </AtBatRow>
        {hasPitches
          ? atbatInUse.pitches.map((p, index) => {
              let active = gameViewerStore.selectedPitch && gameViewerStore.selectedPitch.pitchNumber === p.pitchNumber;
              return (
                <GameViewerPitch
                  id={p.pitchNumber}
                  key={index}
                  pitch={p}
                  active={active}
                  onClick={() => {
                    if (active) {
                      gameViewerStore.setSelectedPitch(null);
                    } else {
                      gameViewerStore.setSelectedPitch(p.pitchObj, true);
                    }
                  }}
                />
              );
            })
          : null}
      </React.Fragment>
    );
  }
}

export default inject("rootStore")(observer(GameViewerAtBat));
