import React from "react";
import styled from "styled-components";

const Checkbox = styled.input.attrs({
  type: "checkbox"
})`
  width: 100%;
`;

class CheckboxCellFormatter extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    const { value } = this.props;
    const { setDisputeSelected } = this.props.dependentValues;
    setDisputeSelected(!value);
  }

  render() {
    const { value } = this.props;
    return <Checkbox value={value} onClick={this.onClick} />;
  }
}

export default CheckboxCellFormatter;
