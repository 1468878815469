import styled from "styled-components";

const ZeLightButton = styled.button.attrs({
  className: "btn"
})`
  color: #666666;
  border-radius: 4px !important;
  background-color: ${props => (props.isActive ? "#fdec98 !important" : "#ffffff !important")};
  border: solid 1px #e6e6e6 !important;
  ${props => (props.grid ? "padding: 0.25rem 0.75rem !important" : "")};
  &:hover {
    ${props => (props.disabled ? "" : "background-color: #e6e6e6 !important")};
    ${props => (props.disabled ? "" : "border: solid 1px #c2c2c2 !important")};
  }
  ${props => (props.disabled ? "opacity: 1 !important" : "")};
`;

export default ZeLightButton;
