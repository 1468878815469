import React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import StringUtils from "../../../utilities/StringUtil";

const LinkButton = styled.button.attrs({
  className: "btn btn-link btn-sm "
})`
  &&& {
    padding-left: 0;
  }
`;

const Stance = styled.div.attrs({
  className: "mr-2 px-3 py-1"
})`
  font-size: 0.8rem;
  background-color: #ececec;
  border: 1px solid #cccccc;
`;

const UmpireUpdateLink = ({ stances, rootStore: { gameViewerStore } }) => (
  <div>
    <LinkButton
      disabled={false}
      id="showStances"
      type="button"
      onClick={() => gameViewerStore.showUmpireStancesAction(gameViewerStore.game)}
    >
      Edit Stances
    </LinkButton>
    <div className={"d-flex"}>
      {stances
        ? stances.map(stance => (
            <React.Fragment key={stance}>{stance && <Stance>{StringUtils.toTitleCase(stance)}</Stance>}</React.Fragment>
          ))
        : null}
    </div>
  </div>
);

UmpireUpdateLink.defaultProps = {
  stances: []
};

export default inject("rootStore")(observer(UmpireUpdateLink));
