import React from "react";
import Pagination from "react-js-pagination";

class ZePagination extends React.Component {
  render() {
    return (
      <Pagination
        activePage={this.props.activePage}
        itemsCountPerPage={this.props.itemsCountPerPage}
        totalItemsCount={this.props.totalItemsCount}
        pageRangeDisplayed={5}
        onChange={this.props.onChange}
        itemClass="page-item"
        linkClass="page-link"
        prevPageText="Previous"
        nextPageText="Next"
        hideDisabled={true}
      />
    );
  }
}

export default ZePagination;
