import React from "react";
import GameViewerHeader from "./header/GameViewerHeader";
import GameViewerContent from "./content/GameViewerContent";

class GameViewer extends React.Component {
  render() {
    return (
      <div>
        <GameViewerHeader />
        <GameViewerContent />
      </div>
    );
  }
}

export default GameViewer;
