import axios from "axios";
import axiosRetry from "axios-retry";
import React, { useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";

const HttpInterceptor = ({ children }) => {
  const { logout } = useAuth();

  const createInterceptors = () => {
    axiosRetry(axios, {
      retries: 3, //attempts to retry
      retryCondition: error => {
        console.log("Error fetching from API will retry...");
        return !!error?.response && (error.response.status === 401 || error.response.status === 403);
      }, // only retry on unauthenticated errors to prevent retrying 500s unless that is wanted
      retryDelay: retryCount => retryCount * 1000 // wait 1 second between retrys
    });

    axios.interceptors.request.use(config => {
      const oktaTokenStr = localStorage.getItem("okta-token-storage") || '{"accessToken": "", "idToken": "" }';

      const { accessToken = {}, idToken = {} } = JSON.parse(oktaTokenStr);

      if (config.url.indexOf("/oauth/token") < 0 || config.headers.addToken) {
        config.headers.Authorization = `Bearer ${accessToken.accessToken}`;
        config.headers["x-id-token"] = idToken.idToken;
      }
      return config;
    });

    axios.interceptors.response.use(
      resp => {
        if (!resp) return null;
        return resp;
      },
      err => {
        if (!!err.response && (err.response.status === 401 || err.response.status === 403)) {
          logout();
        }
        return Promise.reject(err);
      }
    );
  };

  useEffect(() => {
    createInterceptors();
  }, []);

  return <div>{children}</div>;
};

export default HttpInterceptor;
