import React from "react";
import { inject, observer } from "mobx-react";
import Modal from "../../../common/modal/Modal";
import ModalFooter from "../../../common/modal/ModalFooter";
import ModalBody from "../../../common/modal/ModalBody";
import ModalHeaderWithTitleAndClose from "../../../common/modal/ModalHeaderWithTitleAndClose";
import ZeLightButton from "../../../elements/form/ZeLightButton";
import ReleaseErrorsTable from "./ReleaseErrorsTable";

const ScheduleReleaseErrorModal = ({ rootStore: { scheduleStore } }) => {
  let closeModal = event => {
    event.preventDefault();
    scheduleStore.toggleReleaseErrorsModal();
  };

  let analyze = event => {
    event.preventDefault();
    scheduleStore.analyzeReleaseErrors();
  };

  let override = event => {
    event.preventDefault();
    scheduleStore.releaseGame(scheduleStore.releaseErrorGamePk, scheduleStore.releaseErrorUmpireId, true);
  };

  return (
    <Modal show={scheduleStore.showReleaseErrorsModal} onClose={() => {}} size="lg">
      <ModalHeaderWithTitleAndClose title="Game Release Errors" close={closeModal} />

      <ModalBody>
        <div className="row">
          <div className="col">
            <ReleaseErrorsTable pitches={scheduleStore.releaseErrorPitches} />
          </div>
        </div>
      </ModalBody>

      <ModalFooter>
        <button className="btn btn-danger" onClick={override}>
          Override
        </button>
        <button className="btn btn-primary" onClick={analyze}>
          Analyze
        </button>
        <ZeLightButton onClick={closeModal}>Close</ZeLightButton>
      </ModalFooter>
    </Modal>
  );
};

export default inject("rootStore")(observer(ScheduleReleaseErrorModal));
