import React from "react";
import DateUtil from "../../../../utilities/DateUtil";

class DateCellFormatter extends React.Component {
  render() {
    return DateUtil.getFormattedDateString(this.props.value);
  }
}

export default DateCellFormatter;
