import React from "react";
import styled from "styled-components";
import AccordionHeader from "./AccordionHeader";
import AccordionBody from "./AccordionBody";

const AccordionDiv = styled.div`
  width: 100%;
  background-color: #ffffff !important;
  color: #000000 !important;
`;

class Accordion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.startOpen
    };
  }

  render() {
    return (
      <AccordionDiv>
        <AccordionHeader
          title={this.props.title}
          headerColor={this.props.headerColor}
          onClick={this.toggle}
          open={this.state.open}
        />
        <AccordionBody title={this.props.title} padding={this.props.padding} open={this.state.open}>
          {this.props.children}
        </AccordionBody>
      </AccordionDiv>
    );
  }

  toggle = event => {
    event.stopPropagation();
    event.preventDefault();
    this.setState(prevState => ({
      open: !prevState.open
    }));
  };
}

export default Accordion;
