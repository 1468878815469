import React from "react";
import { inject, observer } from "mobx-react";
import ConfirmModal from "../common/action-modals/ConfirmModal";
import ZePrimaryButton from "../elements/form/ZePrimaryButton";
import { DisputeConstants } from "./DisputeConstants";

class DisputeFooterButtons extends React.Component {
  constructor(props) {
    super(props);
    this.resolveDisputesModal = this.resolveDisputesModal.bind(this);
  }

  confirmModal(resolved) {
    const { disputeStore } = this.props.rootStore;
    const title = resolved ? "Unresolve Disputes" : "Resolve Disputes";
    return (
      <ConfirmModal
        show={disputeStore.showConfirmationModal}
        title={title}
        body={this.modalBody(resolved)}
        action={disputeStore.modalAction}
        onClose={() => (disputeStore.showConfirmationModal = false)}
      />
    );
  }

  modalBody(resolved) {
    const { disputeStore } = this.props.rootStore;
    const counts = resolved ? disputeStore.resolvedCounts : disputeStore.unresolvedCounts;

    let body = ["You are ", resolved ? "unresolving" : "resolving", ": "];

    counts.forEach(c => body.push(...this.modalEntry(c)));
    return body;
  }

  modalEntry(elem) {
    return [<br />, <b>{elem.value}</b>, " dispute", elem.value > 1 ? "s" : "", " with status " + elem.key + "."];
  }

  resolveDisputesModal(action) {
    const { disputeStore } = this.props.rootStore;
    disputeStore.setModalAction(action);
    disputeStore.setShowConfirmationModal(true);
  }

  resolveButton() {
    const { disputeStore } = this.props.rootStore;

    return (
      <div className="float-right mr-2">
        {this.confirmModal(false)}
        <ZePrimaryButton
          disabled={!disputeStore.isAnyActionSelected}
          onClick={() => this.resolveDisputesModal(disputeStore.resolveDisputes)}
        >
          Resolve Disputes
        </ZePrimaryButton>
      </div>
    );
  }

  unresolveButton() {
    const { disputeStore } = this.props.rootStore;
    return (
      <div className="float-right mr-2">
        {this.confirmModal(true)}
        <ZePrimaryButton
          disabled={!disputeStore.isAnyResolvedDisputeSelected}
          onClick={() => this.resolveDisputesModal(disputeStore.unresolveDisputes)}
        >
          Unresolve Disputes
        </ZePrimaryButton>
      </div>
    );
  }

  render() {
    const { disputeStore } = this.props.rootStore;
    const resolved = disputeStore.getFilterValue(DisputeConstants.KEYS.ACTIVE_RESOLVED) === "resolved";
    return (
      <div className="col-12 mb-1">
        <div className="float-right">{resolved ? this.unresolveButton() : this.resolveButton()}</div>
      </div>
    );
  }
}

export default inject("rootStore")(observer(DisputeFooterButtons));
