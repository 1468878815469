import React from "react";
import { inject, observer } from "mobx-react";

import ReportFooterButtons from "./ReportFooterButtons";
import RequiredFieldLabel from "../common/form/RequiredFieldLabel";
import DatePickerInput from "../common/form/DatePickerInput";
import Select from "react-select";

class ResetAuditResultContent extends React.Component {
  render() {
    let reportStore = this.props.rootStore.reportStore;
    return (
      <div className="row">
        <div className="col">
          <form
            onSubmit={event => {
              event.preventDefault();
              reportStore.runReport();
            }}
          >
            <div className="row">
              <div className="col-md-2 col-xs-4">
                <label className="col-form-label">Game Start Date</label>
                <DatePickerInput date={reportStore.dateRange.start} onChange={reportStore.setStartDate} />
              </div>
              <div className="col-md-2 col-xs-4">
                <label className="col-form-label">Game End Date</label>
                <DatePickerInput date={reportStore.dateRange.end} onChange={reportStore.setEndDate} />
              </div>
              <div className="col-md-2 col-xs-4">
                <label className="col-form-label">Use Hawkeye</label>
                <Select
                  name="useHawkeye"
                  value={reportStore.useHawkeye}
                  onChange={reportStore.setUseHawkeye}
                  options={reportStore.useHawkeyeOptions}
                />
              </div>
              <div className="col-md-6 col-xs-4">
                <label className="col-form-label">
                  Send To The Following Email
                  <RequiredFieldLabel />
                </label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Comma separated email addresses"
                  value={reportStore.userStr}
                  onChange={reportStore.setUsers}
                />
              </div>
            </div>
            <ReportFooterButtons />
          </form>
        </div>
      </div>
    );
  }
}

export default inject("rootStore")(observer(ResetAuditResultContent));
