import React from "react";
import Moment from "moment";
import DatePicker from "react-datepicker";
import styled from "styled-components";
import "react-datepicker/dist/react-datepicker.css";

const DATE_FORMAT_STR = "M/D/YYYY";

const StyledInput = styled.input`
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
`;

const Icon = styled.span`
  background-color: #ffffff !important;
  color: #4c8cee !important;
  &:hover {
    cursor: pointer;
  }
`;

export class DatePickerInput extends React.Component {
  constructor(props) {
    super(props);
    this.iconClicked = this.iconClicked.bind(this);
  }

  iconClicked(event) {
    event.preventDefault();
    this.datepicker.focus();
  }

  render() {
    const startOf2011 = Moment(new Date("1/1/2011").valueOf());
    const { date, onChange } = this.props;

    return (
      <div className="input-group mb-3">
        <DatePicker
          selected={Moment(date)}
          onChange={onChange}
          dateFormat={DATE_FORMAT_STR}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          minDate={startOf2011}
          customInput={<StyledInput innerRef={input => (this.datepicker = input)} value={date} />}
        />

        <div className="input-group-append" onClick={this.iconClicked}>
          <Icon className="input-group-text">
            <i className="fa fa-calendar" />
          </Icon>
        </div>
      </div>
    );
  }
}

export default DatePickerInput;
