import React from "react";
import styled from "styled-components";
import { inject, observer } from "mobx-react";
import Moment from "moment";
import AuditHeaderBadgeButton from "../../audit/common/AuditHeaderBadgeButton";
import AuditPcHeaderCallCorrectness from "./AuditPcHeaderCallCorrectness";
import AuditPcNavigation from "./AuditPcNavigation";
import { DateConstants } from "../../common/constants/DateConstants";

const GameInfo = styled.div.attrs({
  className: "ml-2"
})`
  float: left;
`;

const Time = styled.div.attrs({
  className: "ml-2"
})`
  margin-top: 5px;
  float: left;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: normal;
  text-align: left;
  color: #666666;AuditPcHeader
`;

const AutoSave = styled.div.attrs({
  className: "ml-2"
})`
  margin-top: 5px;
  float: left;
  font-size: 12px;
  color: #666666;
  text-align: left;
  font-weight: 300;
`;

class AuditPcHeader extends React.Component {
  render() {
    const {
      rootStore: { auditPcStore, authStore }
    } = this.props;
    return (
      <div className="d-flex justify-content-between">
        <div>
          <GameInfo>{auditPcStore.gameDisplayName}</GameInfo>
          <Time>
            {auditPcStore.gameDisplayTime
              ? Moment(auditPcStore.gameDisplayTime).format(DateConstants.DATE_FORMAT_WITH_TIME)
              : ""}
            {auditPcStore.gamePk ? (
              <a
                href={"https://lookup-service-prod.mlb.com/named.trackman_game.bam?game_pk=" + auditPcStore.gamePk}
                target="_blank"
                className="ml-1"
              >
                <i className="fa fa-external-link" />
              </a>
            ) : null}
          </Time>
        </div>
        <div>
          {auditPcStore.problemPitchList.map(pp => {
            const disabled = auditPcStore.pitches &&
              pp.total === auditPcStore.pitches.length - auditPcStore.problemPitchInfo["withoutGuid"].length;
            return (
              <span className="mr-1" key={pp.key}>
              <AuditHeaderBadgeButton
                key={pp.key}
                title={pp.title}
                called={pp.called}
                total={disabled ? "all" : pp.total}
                disabled={disabled}
                active={auditPcStore.problemPitchFilter[pp.key]}
                onClick={() => {
                  auditPcStore.toggleProblemPitchFilter(pp.key);
                }}
              />
            </span>
            );
          })}
          {auditPcStore.oobPitches.length ? (
            <span className="mr-1">
              <AuditHeaderBadgeButton
                title={"Out of Bounds SZ"}
                total={auditPcStore.oobPitches.length}
                called={auditPcStore.oobPitches.filter(p => auditPcStore.functions.isUmpireCallCodeCalled(p)).length}
                active={auditPcStore.oobPitchFilter}
                onClick={() => {
                  auditPcStore.toggleOobPitchFilter();
                }}
              />
            </span>
          ) : null}
          {authStore.isAuditorAdmin ? (
            <span className="mr-1">
              <AuditHeaderBadgeButton
                title={"Called Pitches"}
                total={auditPcStore.calledPitches.length}
                hideCalled={true}
                active={auditPcStore.calledFilter}
                onClick={() => {
                  auditPcStore.toggleCalledFilter();
                }}
              />
            </span>
          ) : null}
          {auditPcStore.problemPitchList ? (
            <button
              type="button"
              className={"btn btn-sm btn-light"}
              onClick={() => {
                auditPcStore.functions.copyProblemPitchesToClipboard();
              }}
            >
              {"Copy"}
            </button>
          ) : null}
        </div>
        {auditPcStore.isAdmin && auditPcStore.callCorrectnessPopulated ? (
          <div>
            <AuditPcHeaderCallCorrectness />
          </div>
        ) : null}
        {auditPcStore.autoSaveDate ? (
          <AutoSave>
            {auditPcStore.autoSaveDate ? (
              <span>
                Last Auto Save: {Moment(auditPcStore.autoSaveDate).format(DateConstants.DATE_FORMAT_WITH_TIME)}
              </span>
            ) : null}
          </AutoSave>
        ) : null}
        <div>
          <AuditPcNavigation match={this.props.match} />
        </div>
      </div>
    );
  }
}

export default inject("rootStore")(observer(AuditPcHeader));
