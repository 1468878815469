import React from "react";
import { inject, observer } from "mobx-react";
import Protected from "../components/protected/Protected";
import ComponentContainer from "./ComponentContainer";
import GameSummaryTable from "../components/gamesummary/GameSummaryTable";

const GameSummaryContainer = () => (
  <ComponentContainer>
    <GameSummaryTable />
  </ComponentContainer>
);

export default inject("rootStore")(Protected(observer(GameSummaryContainer)));
