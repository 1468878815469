import React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";

const SortColumnHeaderTh = styled.th`
  &:hover {
    ${props => (props.canSort || props.onClick ? "cursor: pointer;" : "")};
  }
  ${props => (props.width ? "width: " + props.width : null)};
`;

class SortColumnHeader extends React.Component {
  constructor(props) {
    super(props);
    this.sortColumn = this.sortColumn.bind(this);
    this.getSortIcon = this.getSortIcon.bind(this);
  }

  canSort(sortKey) {
    return sortKey !== "dont-sort";
  }

  sortColumn() {
    const { onClick, sortKey } = this.props;
    if (onClick) {
      onClick();
    } else if (sortKey && this.canSort(sortKey)) {
      this.props.store.setSort(sortKey);
    }
  }

  getSortIcon() {
    if (this.props.store && this.props.store.sort.col === this.props.sortKey) {
      if (this.props.store.sort.asc) {
        return (
          <span>
            {"\u00A0"}
            <i className="fa fa-caret-up" />
          </span>
        );
      } else {
        return (
          <span>
            {"\u00A0"}
            <i className="fa fa-caret-down" />
          </span>
        );
      }
    }
  }

  render() {
    const { name, sortKey, width } = this.props;

    return (
      <SortColumnHeaderTh onClick={this.sortColumn} canSort={this.canSort(sortKey)} width={width}>
        {name}
        {this.getSortIcon()}
      </SortColumnHeaderTh>
    );
  }
}

export default inject("rootStore")(observer(SortColumnHeader));
