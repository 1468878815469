import React from "react";
import DisputeFilters from "../components/dispute/DisputeFilters";
import DisputeResults from "../components/dispute/DisputeResults";
import Protected from "../components/protected/Protected";
import ComponentContainer from "./ComponentContainer";

class DisputeContainer extends React.Component {
  componentDidMount() {
    this.props.rootStore.loadingStore.setWindowTitle("ZE - Disputes");
  }

  componentWillUnmount() {
    this.props.rootStore.loadingStore.setWindowTitle("Zone Evaluator");
  }

  render() {
    return (
      <ComponentContainer>
        <DisputeFilters />
        <DisputeResults />
      </ComponentContainer>
    );
  }
}

export default Protected(DisputeContainer);
