import _ from "lodash";

export default class StringUtil {
  static copyToClipboard(text) {
    let textarea = document.createElement("textarea");
    textarea.setAttribute("type", "hidden");
    textarea.textContent = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    return true;
  }

  static isBlank(str) {
    return !str || /^\s*$/.test(str);
  }

  static nth(i) {
    let iMod10 = i % 10;
    let iMod100 = i % 100;
    if (iMod100 >= 10 && iMod100 <= 20) {
      return i + "th";
    } else if (iMod10 === 1) {
      return i + "st";
    } else if (iMod10 === 2) {
      return i + "nd";
    } else if (iMod10 === 3) {
      return i + "rd";
    } else {
      return i + "th";
    }
  }

  static toTitleCase(text) {
    return _.startCase(_.toLower(text));
  }

  static removeLeadingAndTrailingWhiteSpaces(str) {
    return str.replace(/^\s+|\s+$/g, "");
  }
}
