import React from "react";
import Accordion from "../../common/accordion/Accordion";
import styled from "styled-components";
import AuditPcVideoHotKeys from "./AuditPcVideoHotKeys";
import AuditPcEditPitchHotKeys from "../strikeZone/AuditPcEditPitchHotKeys";
import AuditPcMiscHotKeys from "./AuditPcMiscHotKeys";

const Body = styled.div`
  padding-left: 10px;
  padding-right: 10px;
`;

class AuditPcHotKeys extends React.Component {
  render() {
    return (
      <div>
        <Accordion title="Hot Keys" padding={false}>
          <Body>
            <div className="row m-0">
              <div className="col-4">
                <AuditPcVideoHotKeys />
              </div>
              <div className="col-4">
                <AuditPcEditPitchHotKeys />
              </div>
              <div className="col-4">
                <AuditPcMiscHotKeys />
              </div>
            </div>
          </Body>
        </Accordion>
      </div>
    );
  }
}

export default AuditPcHotKeys;
