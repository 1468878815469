import React from "react";
import { inject, observer } from "mobx-react";
import ZeLightButton from "../elements/form/ZeLightButton";
import CancelModal from "../common/action-modals/CancelModal";
import ZePrimaryButton from "../elements/form/ZePrimaryButton";
import { AuthConstants } from "../login/AuthConstants";

const auditorAdminRoles = [AuthConstants.USER_ROLES.ZE_SUPER_ADMIN, AuthConstants.USER_ROLES.ZE_AUDITOR_ADMIN];

const ScoresheetActions = ({ rootStore: { scoreSheetStore, routerStore, authStore } }) => {
  let scoresheet = scoreSheetStore.scoreSheet;
  let isAuditorAdmin = authStore.containsAuthorities(auditorAdminRoles);

  let close = () => {
    scoreSheetStore.goToScheduleForGameDate();
  };

  let edit = () => {
    scoreSheetStore.editing = true;
  };

  let release = () => {
    scoreSheetStore.releaseScoresheet();
  };

  let unrelease = () => {
    scoreSheetStore.unreleaseScoresheet();
  };

  let save = () => {
    scoreSheetStore.updateScoresheet();
  };

  let cancelModalToggle = () => {
    scoreSheetStore.cancelModalOpen = !scoreSheetStore.cancelModalOpen;
  };

  let cancel = () => {
    cancelModalToggle();
    scoreSheetStore.editing = false;
    scoreSheetStore.getScoresheet();
  };

  return (
    <div className="row">
      <div className="col">
        <CancelModal show={scoreSheetStore.cancelModalOpen} closeFunc={cancelModalToggle} cancelFunc={cancel} />

        <div className="float-right">
          {isAuditorAdmin && !scoresheet.released ? (
            <ZePrimaryButton className="ml-2" onClick={release} disabled={scoreSheetStore.needCategory > 0}>
              Release
            </ZePrimaryButton>
          ) : null}

          {isAuditorAdmin && scoresheet.released ? (
            <button className="btn btn-primary ml-2" onClick={unrelease}>
              Unrelease
            </button>
          ) : null}

          {isAuditorAdmin && !scoreSheetStore.editing && !scoresheet.released ? (
            <ZeLightButton className="ml-2" onClick={edit}>
              Edit
            </ZeLightButton>
          ) : null}

          {!scoreSheetStore.editing ? (
            <ZeLightButton className="ml-2" onClick={close}>
              Close
            </ZeLightButton>
          ) : null}

          {isAuditorAdmin && scoreSheetStore.editing ? (
            <ZeLightButton className="ml-2" onClick={save}>
              Save
            </ZeLightButton>
          ) : null}

          {scoreSheetStore.editing ? (
            <ZeLightButton className="ml-2" onClick={cancelModalToggle}>
              Cancel
            </ZeLightButton>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default inject("rootStore")(observer(ScoresheetActions));
