import React from "react";
import StringUtil from "../../../../utilities/StringUtil";

class TitleCaseCellFormatter extends React.Component {
  render() {
    return StringUtil.toTitleCase(this.props.value);
  }
}

export default TitleCaseCellFormatter;
