import React from "react";
import { GlobalHotKeys } from "react-hotkeys";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import AuditPcHeader from "../components/auditpc/common/AuditPcHeader";
import Protected from "../components/protected/Protected";
import AuditPcStrikeZoneContainer from "../components/auditpc/strikeZone/AuditPcStrikeZoneContainer";

const AuditHolder = styled.div`
  margin: auto;
  min-width: 1310px;
  max-width: 1875px;
`;

const AuditCard = styled.div.attrs({
  className: "card"
})`
  background-color: #f7f7f7 !important;
`;
const AuditCardHeader = styled.div.attrs({
  className: "card-header"
})`
  background-color: #ffffff !important;
  padding: 5px 10px 5px !important;
`;
const AuditCardBody = styled.div.attrs({
  className: "card-body"
})`
  overflow-x: hidden;
  padding: 10px !important;
`;

class AuditPcContainer extends React.Component {
  componentWillUnmount() {
    this.props.rootStore.loadingStore.setWindowTitle("Zone Evaluator");
  }

  render() {
    const {
      match,
      rootStore: { auditPcStore, routerStore }
    } = this.props;

    return (
      <AuditHolder>
        <GlobalHotKeys keyMap={auditPcStore.hotkeyMap} handlers={auditPcStore.hotkeyHandlers} />
        <AuditCard>
          <AuditCardHeader>
            <AuditPcHeader gamePk={auditPcStore.gamePk} match={match} />
          </AuditCardHeader>
          <AuditCardBody>{routerStore.isAuditV2StrikeZoneTab ? <AuditPcStrikeZoneContainer /> : null}</AuditCardBody>
        </AuditCard>
      </AuditHolder>
    );
  }
}

export default inject("rootStore")(Protected(observer(AuditPcContainer)));
