import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "react-rangeslider/lib/index.css";
import "jquery/dist/jquery";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "./index.css";
import "./styles/React-Data-Grid-Overrides.css";
import WrapperProvider from "./contexts/WrapperProvider";

ReactDOM.render(
  <WrapperProvider>
    <App />
  </WrapperProvider>,
  document.getElementById("ze-react-app")
);
