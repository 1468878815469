export const base64ToImage = function(encoded) {
  var image = new Image();
  var prefix = "data:image/png;base64,";
  if (encoded.startsWith(prefix)) {
    image.src = encoded;
  } else {
    image.src = prefix + encoded;
  }
  return image;
};
