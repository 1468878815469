import { DateConstants } from "../components/common/constants/DateConstants";
import Moment from "moment/moment";

export default class DateUtil {
  static getFormattedDateString(date) {
    if (date) {
      return Moment(date).format(DateConstants.DATE_FORMAT_WITH_TIME);
    } else {
      return "";
    }
  }

  static getIsYesterdayOrTodayDate(date) {
    if (date) {
      const today = Moment()
        .startOf("day")
        .format(DateConstants.DATE_FORMAT);
      const yesterday = Moment()
        .startOf("day")
        .subtract(1, "days")
        .startOf("day")
        .format(DateConstants.DATE_FORMAT);
      const formattedDate = Moment(date).format(DateConstants.DATE_FORMAT);
      return Moment(formattedDate).isBetween(yesterday, today, null, []);
    }
  }
}
