import React from "react";
import ZeNumberFormatter from "../../common/ZeNumberFormatter";

class RmseDisplay extends React.Component {
  render() {
    const colorStyle = this.props.color ? {color: this.props.color} : {};
    return (
      <div style={colorStyle}>
        <strong>{this.props.title}: </strong>
        <ZeNumberFormatter value={this.props.value} precision={4} />
      </div>
    );
  }
}

export default RmseDisplay;
