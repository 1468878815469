import { AlertConstants } from "../../components/common/alert/AlertConstants";
import StringUtil from "../../utilities/StringUtil";

export default class AuditStoreFunctions {


  static copyProblemPitchesToClipboard(auditStore) {
    let _this = this;
    let { alertStore, gamePk, gameDisplayName, pitchNumbersMap, problemPitchInfo, problemPitchList } = auditStore;

    let gameSummary = gamePk + "\n" + gameDisplayName + "\n\n";
    let problemSummaries = [];
    problemPitchList.forEach(function(problem) {
      let summary = problem.title + "\t" + problem.total + " / " + problem.called + "\n";
      let info = problemPitchInfo[problem.key];
      info.forEach(function(pitchInfo) {
        let pitch = pitchNumbersMap[pitchInfo.pitchNumber];
        if (pitch) {
          summary = summary.concat(
            _this.getSequenceString(pitch),
            "\t",
            pitch.playId,
            "\t",
            pitch.umpireCallCode,
            "\n"
          );
        }
      });
      summary = summary.concat("\n");
      problemSummaries.push(summary);
    });
    let problemSummary = "".concat(gameSummary, ...problemSummaries).trim();

    StringUtil.copyToClipboard(problemSummary);
    alertStore.addAlert({
      type: AlertConstants.TYPES.SUCCESS,
      text: "Copied problem pitch data to clipboard."
    });
  }

  static getSequenceString(pitch) {
    return (pitch.topOfInning ? "T" : "B") + pitch.inning + "  " + pitch.atBatNumber + "-" + pitch.pitchOfAtBat;
  }
}
