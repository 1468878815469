import React from "react";
import { inject, observer } from "mobx-react";
import AuditPcPitchList from "../common/AuditPcPitchList";
import AuditPcSelectedPitch from "../common/AuditPcSelectedPitch";
import AuditVideoContainer from "../../audit/common/AuditVideoContainer";
import AuditPcStrikeZoneInfo from "./AuditPcStrikeZoneInfo";
import AuditPcHotKeys from "../common/AuditPcHotKeys";
import styled from "styled-components";
import AuditPcFrameOffset from "../common/AuditPcFrameOffset";
import AuditPcFooterButtons from "../common/AuditPcFooterButtons";

const SZHolder = styled.div`
  overflow-x: hidden;
`;

const SettingsHolder = styled.div`
  min-width: 960px;
  max-width: 960px;
`;

class AuditPcStrikeZoneContainer extends React.Component {
  render() {
    const auditPcStore = this.props.rootStore.auditPcStore;

    return (
      <SZHolder>
        <div className="d-flex flex-row justify-content-center">
          <AuditPcPitchList pitches={auditPcStore.sortedPitches} theight={580} />
          <AuditVideoContainer>
            <AuditPcSelectedPitch videoRef={v => auditPcStore.setVideoRef(v)} />
          </AuditVideoContainer>
        </div>
        <div className="d-flex flex-row justify-content-center mt-1">
          <AuditPcStrikeZoneInfo />
          <SettingsHolder>
            <AuditPcHotKeys />
          </SettingsHolder>
        </div>
        <div className="d-flex flex-row mt-1">
          <div className="d-flex col-6 justify-content-start">
            <AuditPcFrameOffset />
          </div>
          <div className="d-flex col-6 justify-content-end">
            <AuditPcFooterButtons />
          </div>
        </div>
      </SZHolder>
    );
  }
}

export default inject("rootStore")(observer(AuditPcStrikeZoneContainer));
