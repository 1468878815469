import React from "react";
import Select from "react-select";
import styled from "styled-components";

const SelectHolder = styled.div`
  min-width: 235px;
  max-width: 235px;
`;

const DropdownSelect = styled(Select)`
  & .react-select__menu {
    z-index: 999 !important;
  }

  & .Select-placeholder {
    color: #d2d2d2;
    font-size: 16px;
    font-style: italic;
    font-weight: 300;
  }
`;

class GameViewFilterDropdown extends React.Component {
  render() {
    let value = this.props.value ? this.props.value.slice() : null;

    return (
      <SelectHolder>
        <DropdownSelect
          name="season"
          value={value}
          onChange={this.props.onChange}
          options={this.props.options}
          placeholder={this.props.placeholder}
          disabled={this.props.isDisabled}
          isMulti={this.props.multi}
          styles={{
            menu: base => ({ ...base, zIndex: 9999 })
          }}
        />
      </SelectHolder>
    );
  }
}

export default GameViewFilterDropdown;
