import { extendObservable, action, autorun } from "mobx";
import queryString from "query-string";
import moment from "moment";

class GameSummaryStore {
  constructor(routerStore, zeApi, loadingStore, alertStore, authStore) {
    this.routerStore = routerStore;
    this.zeApi = zeApi;
    this.loadingStore = loadingStore;
    this.alertStore = alertStore;
    this.authStore = authStore;

    let date = moment()
      .add(-1, "days")
      .format("YYY-MM-DD");
    this.defaults = {
      date: date,
      includeUnreleased: false,
      gameRows: []
    };

    extendObservable(this, {
      date: this.defaults["date"],
      includeUnreleased: this.defaults["includeUnreleased"],
      gameRows: this.defaults["gameRows"],
      updateFromUrlParams: action(search => {
        const params = queryString.parse(search);
        if (params["date"]) {
          this.date = params["date"];
        } else {
          this.date = this.defaults["date"];
        }
        if (params["includeUnreleased"]) {
          this.includeUnreleased = params["includeUnreleased"];
        }
      }),
      setGameRows: action(rows => {
        this.gameRows = rows;
      })
    });

    autorun(() => {
      if (authStore.isLoggedIn && this.routerStore.isGameSummaryTab) {
        this.updateFromUrlParams(this.routerStore.location.search);
        this.getGames(this.date, this.includeUnreleased);
      }
    });
  }

  getGames(date, includeUnreleased) {
    this.loadingStore.setLoading(true, "Loading", "Loading Game Report", 50);
    this.zeApi.getGameSummaries(date, includeUnreleased).then(data => {
      this.setGameRows(data);
      this.loadingStore.setLoading(false);
    });
  }
}

export default GameSummaryStore;
