import React from "react";
import styled from "styled-components";
import * as ReactDOM from "react-dom";

const Pitch = styled.tr`
  &&& {
    &:hover {
      cursor: pointer;
    }
  }
`;

const PitchData = styled.td`
  padding: 0.125rem 0.25rem !important;
  &&& {
    ${props => {
      if (props.isActive) {
        return "background-color: #fdec98; color: #000000";
      }
      if (props.dispute && !props.dispute.resolution) {
        return "background-color: #9b30ff; color: #FFFFFF;";
      }
      if (props.deleted) {
        return "background-color: #787a7e; color: #FFFFFF";
      }
      switch (props.callCorrectness) {
        case "CORRECT":
          return "background-color: #058a15; color: #FFFFFF;";
        case "ACCEPTABLE":
          return "background-color: #2b77eb; color: #FFFFFF;";
        case "HIGH_BUFFER":
        case "LOW_CATCH":
        case "LOW_BUFFER":
        case "CATCHER_INFLUENCE":
          return "background-color: #dbbf2d; color: #FFFFFF;";
        case "INCORRECT":
          return "background-color: #ed1c34; color: #FFFFFF;";
        default:
          return "background-color: #787a7e; color: #FFFFFF";
      }
    }};
  }
`;

const PitchNumberNew = styled.div`
  border: 0;
  border-radius: 50%;
  font-size: 12px;
  font-weight: normal;
  height: 26px;
  letter-spacing: 0px;
  line-height: 12px;
  padding: 7.5px 0;
  text-align: center;
  vertical-align: middle;
  width: 26px;
  ${props => (props.isActive ? "color: #000000" : "color: #ffffff")};
`;

const PitchHolder = styled.div`
  width: 100%;
  color: #000000;
  ${props => {
    if (props.deleted) {
      return "background-color: #787a7e; color: #FFFFFF";
    }
    switch (props.callCorrectness) {
      case "CORRECT":
        return "background-color: #058a15; color: #FFFFFF;";
      case "ACCEPTABLE":
        return "background-color: #2b77eb; color: #FFFFFF;";
      case "HIGH_BUFFER":
      case "LOW_CATCH":
      case "LOW_BUFFER":
      case "CATCHER_INFLUENCE":
        return "background-color: #dbbf2d; color: #FFFFFF;";
      case "INCORRECT":
        return "background-color: #ed1c34; color: #FFFFFF;";
      default:
        return "background-color: #787a7e; color: #FFFFFF";
    }
  }};
`;

const Call = styled.div`
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: ${props =>
    props.call && (props.call.toUpperCase() === "STRIKE" || props.call.toUpperCase() === "ball") ? 300 : "normal"};

  letter-spacing: -0.3px;
  margin-left: 10px;
`;

const Count = styled.div.attrs({
  className: "ml-auto"
})`
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 300;
  margin-right: 8px !important;
`;

const CallCorrectness = styled.span`
  font-weight: bold;
`;

const OverturnedBox = styled.rect`
  margin-left: 12px;
  padding: 2px 5px 2px 5px;
  border-radius: 4px;
  border: solid 1.5px;
  border-color: black;
  background-color: #ffde00;
`;

const Overturned = styled.span`
  font-family: Helvetica;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.25px;
  text-align: right;
  color: black;
`;

const AbsComments = styled.i.attrs({
  className: "fa fa-comment"
})`
  color: white;
  margin-right: 4px;
  margin-top: 2px;
`;

class GameViewerPitch extends React.Component {
  getCall(call) {
    let umpireCall = call ? call.toUpperCase() : "";
    switch (umpireCall) {
      case "BLOCKED_BALL":
      case "BALL":
        return "Ball";
      case "CALLED_STRIKE":
        return "Strike";
      case "SWINGING_STRIKE":
      case "SWINGING_STRIKE_BLOCKED":
      default:
        return "No Call";
    }
  }

  getCallCorrectnessAbbr(callCorrectness) {
    switch (callCorrectness) {
      case "HIGH_BUFFER":
        return "HB";
      case "LOW_CATCH":
        return "LC";
      case "LOW_BUFFER":
        return "LB";
      case "CATCHER_INFLUENCE":
        return "CI";
      default:
        return null;
    }
  }

  render() {
    const { pitch, active, onClick } = this.props;
    const call = this.getCall(pitch.call);
    const isOverturned = pitch.pitchObj.isOverturned;
    const callCorrectnessAbbr = this.getCallCorrectnessAbbr(pitch.pitchObj.callCorrectness);
    return (
      <Pitch id={this.props.id} onClick={() => onClick(ReactDOM.findDOMNode(this))}>
        <PitchData
          isActive={active}
          deleted={pitch.pitchObj.deleted}
          callCorrectness={pitch.pitchObj.callCorrectness}
          dispute={pitch.pitchObj.dispute}
        >
          <div className="d-flex align-items-center">
            <PitchNumberNew isActive={active}>{pitch.pitchNumber}</PitchNumberNew>
            <PitchHolder deleted={pitch.pitchObj.deleted} callCorrectness={pitch.pitchObj.callCorrectness} call={call}>
              <div className="d-flex justify-content-between">
                <Call callCorrectness={pitch.pitchObj.callCorrectness} call={call}>
                  {call} - {pitch.pitch}{" "}
                  {callCorrectnessAbbr ? (
                    <span>
                      {" "}
                      - <CallCorrectness>{callCorrectnessAbbr}</CallCorrectness>
                    </span>
                  ) : null}
                  {isOverturned && (
                    <OverturnedBox>
                      <Overturned>Overturned</Overturned>
                    </OverturnedBox>
                  )}
                  {isOverturned === false ? (
                    <OverturnedBox>
                      <Overturned>Confirmed</Overturned>
                    </OverturnedBox>
                  ) : null}
                </Call>
                <div className="d-flex">
                  {pitch.pitchObj.absFeedback ? <AbsComments /> : undefined}
                  <Count>{pitch.count}</Count>
                </div>
              </div>
            </PitchHolder>
          </div>
        </PitchData>
      </Pitch>
    );
  }
}

export default GameViewerPitch;
