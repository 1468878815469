import React from "react";
import DisputeFilter from "./DisputeFilter";
import { observer, inject } from "mobx-react";
import { DisputeConstants } from "./DisputeConstants";

class DisputeFilters extends React.Component {
  constructor(props) {
    super(props);
    const {
      rootStore: { disputeStore }
    } = this.props;
    disputeStore.fillSeasons();
    disputeStore.getTeams();
    this.createActiveTitle = this.createActiveTitle.bind(this);
  }

  createActiveTitle() {
    const { disputeCount } = this.props.rootStore.disputeStore;
    const active = disputeCount ? disputeCount.active : 0;
    const resolved = disputeCount ? disputeCount.resolved : 0;
    return "Active (" + active + ") / Resolved (" + resolved + ")";
  }

  render() {
    const {
      rootStore: {
        authStore: { isSuperUmpire, isUmpire },
        disputeStore: { activeResolvedOptions, seasonOptions, umpireOptions, resolutionOptions }
      }
    } = this.props;

    return (
      <div className="row">
        <DisputeFilter
          title="Season"
          cacheKey={DisputeConstants.KEYS.SEASON}
          colWidth="2"
          selectOptions={seasonOptions.toJS()}
          isDisabled={false}
        />
        <DisputeFilter
          title={this.createActiveTitle()}
          cacheKey={DisputeConstants.KEYS.ACTIVE_RESOLVED}
          colWidth="2"
          selectOptions={activeResolvedOptions.toJS()}
          isDisabled={false}
        />
        {!isUmpire || isSuperUmpire ? (
          <DisputeFilter
            title="Umpire"
            cacheKey={DisputeConstants.KEYS.UMPIRE}
            colWidth="3"
            selectOptions={umpireOptions.toJS()}
            isClearable={true}
            isDisabled={false}
          />
        ) : null}
        {!isUmpire || isSuperUmpire ? (
          <DisputeFilter
            title="Resolution"
            cacheKey={DisputeConstants.KEYS.RESOLUTION}
            colWidth="3"
            selectOptions={resolutionOptions.toJS()}
            isMulti={true}
            isDisabled={true}
          />
        ) : null}
      </div>
    );
  }
}

export default inject("rootStore")(observer(DisputeFilters));
