import React from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";

import AuditContainer from "./containers/AuditContainer";
import AuditPcContainer from "./containers/AuditPcContainer";
import Footer from "./components/footer/Footer";
import NavbarNarrow from "./components/navbar/NavbarNarrow";

import DisputeContainer from "./containers/DisputeContainer";
import styled from "styled-components";
import ScheduleContainer from "./containers/ScheduleContainer";
import LoadingModal from "./components/common/action-modals/LoadingModal";
import AlertHolder from "./components/common/alert/AlertHolder";
import ScoresheetContainer from "./containers/ScoresheetContainer";
import GameViewerContainer from "./containers/GameViewerContainer";
import ReportsContainer from "./containers/ReportsContainer";
import SuperUmpireContainer from "./containers/SuperUmpireContainer";
import GameSummaryContainer from "./containers/GameSummaryContainer";
import ErrorCatcher from "./components/error/ErrorCatcher";
import ReactGA from "react-ga";
import { inject, observer } from "mobx-react";
import history from "history";

const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;
const GA_TRACKING_DEBUG = process.env.REACT_APP_GA_TRACKING_DEBUG;

ReactGA.initialize(GA_TRACKING_ID, {
  debug: GA_TRACKING_DEBUG
});

const ZEContainer = styled.div`
  min-height: calc(100vh - 129px);
`;

const ZEContentHolder = styled.div`
  padding: 0 15px 0px 15px !important;
  position: static !important;
`;

const App = ({ rootStore }) => (
  <div className="ze-body">
    <ErrorCatcher>
      <LoadingModal />
      <AlertHolder />
      <NavbarNarrow rootStore={rootStore} />
      <ZEContainer className="container-fluid">
        <div className="row">
          <ZEContentHolder className="col-12">
            <Switch>
              <Route
                path="/"
                exact
                component={() => {
                  window.location.href = window.location.origin + "/v2/schedule";
                }}
              />
              <Route
                path="/schedule"
                exact
                component={() => {
                  window.location.href = window.location.origin + "/v2/schedule";
                  return null;
                }}
              />
              <Route path="/audit" component={AuditContainer} />
              <Route path="/auditv2" component={AuditPcContainer} />
              <Route
                  path="/dispute"
                  exact
                  component={() => {
                    window.location.href = window.location.origin + "/v2/dispute";
                    return null;
                  }}
              />
              <Route path="/scoresheet" component={ScoresheetContainer} />
              <Route path="/gameSummary" component={GameSummaryContainer} />
              <Route path="/gameviewer" component={GameViewerContainer} />
              <Route exact path="/reports" component={ReportsContainer} />
              <Route
                  path="/visualizer"
                  exact
                  component={() => {
                    window.location.href = window.location.origin + "/v2/visualizer";
                    return null;
                  }}
              />
              {/* <Route exact path="/superUmpire" component={SuperUmpireContainer} /> */}
            </Switch>
          </ZEContentHolder>
        </div>
      </ZEContainer>
      <Footer />
    </ErrorCatcher>
  </div>
);

export default inject("rootStore")(observer(App));
