import React from "react";
import styled from "styled-components";
import ZeTableHeader from "../../../elements/table/ZeTableHeader";
import ZeTable from "../../../elements/table/ZeTable";

const TableHolder = styled.div`
  height: 450px;
  overflow: scroll;
`;

const ReleaseErrorsTable = ({ pitches }) => {
  let getCall = call => {
    let umpireCall = call ? call.toUpperCase() : "";
    switch (umpireCall) {
      case "BLOCKED_BALL":
      case "BALL":
        return "Ball";
      case "CALLED_STRIKE":
        return "Strike";
      case "SWINGING_STRIKE":
      case "SWINGING_STRIKE_BLOCKED":
      default:
        return "No Call";
    }
  };

  let getCallCorrectness = callCorrectness => {
    switch (callCorrectness) {
      case "CORRECT":
        return "Correct";
      case "ACCEPTABLE":
        return "Acceptable";
      case "INCORRECT":
        return "Incorrect";
      case "CATCHER_INFLUENCE":
        return "Catcher Influence";
      case "HIGH_BUFFER":
        return "High Buffer";
      case "LOW_BUFFER":
        return "Low Buffer";
      case "LOW_CATCH":
        return "Low Catch";
      case "MISMATCH":
        return "Mismatch";
      default:
        return "Unknown";
    }
  };

  return (
    <TableHolder>
      <ZeTable>
        <ZeTableHeader>
          <tr>
            <th>Pitch Number</th>
            <th>Play ID</th>
            <th>Call</th>
            <th>Evaluation</th>
            <th>ZE SZ Top</th>
            <th>Stats SZ Top</th>
            <th>ZE SZ Bottom</th>
            <th>Stats SZ Bottom</th>
          </tr>
        </ZeTableHeader>
        <tbody>
          {pitches &&
            pitches.map(pitch => (
              <tr key={pitch.playId}>
                <td>
                  {pitch.topOfInning ? "T" : "B"}
                  {pitch.inning}&nbsp;{pitch.atBatNumber}-{pitch.pitchOfAtBat}
                </td>
                <td>{pitch.playId}</td>
                <td>{getCall(pitch.umpireCall)}</td>
                <td>{getCallCorrectness(pitch.callCorrectness)}</td>
                <td>{pitch.zeSzTop}</td>
                <td>{pitch.statsSzTop}</td>
                <td>{pitch.zeSzBottom}</td>
                <td>{pitch.statsSzBottom}</td>
              </tr>
            ))}
        </tbody>
      </ZeTable>
    </TableHolder>
  );
};

export default ReleaseErrorsTable;
