import styled from "styled-components";

const NumberNoSpinner = styled.input.attrs({
  type: "number",
  className: "form-control"
})`
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export default NumberNoSpinner;
