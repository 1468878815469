import React from "react";
import { inject, observer } from "mobx-react";
import DisputeFooterButtons from "../DisputeFooterButtons";
import { DisputeConstants } from "../DisputeConstants";
import DisputeCommentFormAdmin from "./DisputeCommentFormAdmin";
import Modal from "../../common/modal/Modal";
import DataTable from "../../elements/table/DataTable";
import DateCellFormatter from "../../elements/table/formatters/DateCellFormatter";
import UmpireCellFormatter from "../../elements/table/formatters/UmpireCellFormatter";
import DisputeCommentCellFormatter from "../../elements/table/formatters/DisputeCommentCellFormatter";
import TitleCaseCellFormatter from "../../elements/table/formatters/TitleCaseCellFormatter";
import CheckboxCellFormatter from "../../elements/table/formatters/CheckboxCellFormatter";
import DropDownCellFormatter from "../../elements/table/formatters/DropDownCellFormatter";
import ZE2LinkCellFormatter from "../../elements/table/formatters/ZE2LinkCellFormatter";

const DISPUTE_TABLE_SCROLL_LOCAL_STORAGE = "disputeTableScrollPos";
const TABLE_HTML_ELEMENT = ".react-grid-Canvas";

class DisputeResultsAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.commentBubbleClicked = this.commentBubbleClicked.bind(this);
    this.toggleCommentModal = this.toggleCommentModal.bind(this);
    this.scrollEvent = this.scrollEvent.bind(this);

    this.columns = [
      {
        name: "Unresolve",
        key: "selected",
        width: 100,
        formatter: <CheckboxCellFormatter />,
        getRowMetaData: row => row,
        visible: "resolved"
      },
      {
        name: "Pitch #",
        key: "pitch",
        width: 90,
        formatter: ZE2LinkCellFormatter,
        cellClass: "text-center",
        visible: true,
        sortable: true
      },
      { name: "Game", key: "gameDisplayName", width: 125, visible: true, sortable: true },
      {
        name: "Date",
        key: "gameDisplayTime",
        width: 150,
        formatter: DateCellFormatter,
        visible: true,
        sortable: true
      },
      {
        name: "Umpire",
        key: "umpire",
        width: 200,
        formatter: UmpireCellFormatter,
        visible: true,
        sortable: true
      },
      {
        name: "Disputed Date",
        key: "disputedDateAsString",
        width: 150,
        formatter: DateCellFormatter,
        visible: true,
        sortable: true
      },
      { name: "Reason", key: "disputeReason", visible: true, sortable: true },
      {
        name: "Resolved Date",
        key: "resolvedDateAsString",
        width: 150,
        formatter: DateCellFormatter,
        visible: "resolved",
        sortable: true
      },
      {
        name: "Resolution",
        key: "resolution",
        width: 165,
        formatter: DropDownCellFormatter,
        cellClass: "editing",
        getRowMetaData: row => row,
        visible: "active",
        sortable: true
      },
      {
        name: "Resolution",
        key: "resolution",
        width: 145,
        formatter: TitleCaseCellFormatter,
        visible: "resolved",
        sortable: true
      },
      {
        name: "",
        key: "comments",
        width: 55,
        formatter: DisputeCommentCellFormatter,
        cellClass: "editing",
        events: {
          onClick: this.commentBubbleClicked
        },
        visible: true
      }
    ];
  }

  commentBubbleClicked(event, args) {
    const { disputeStore } = this.props.rootStore;
    const dispute = disputeStore.disputeRows[args.rowIdx];
    disputeStore.setSelectedDispute(dispute);
    disputeStore.setCommentModalOpen(!disputeStore.commentModalOpen);
  }

  commentModalClosed() {
    const { disputeStore } = this.props.rootStore;
    disputeStore.setSelectedDispute({});
    disputeStore.setCommentModalOpen(false);
  }

  getColumns() {
    const { disputeStore } = this.props.rootStore;
    const disputeStatusFilter = disputeStore.getFilterValue(DisputeConstants.KEYS.ACTIVE_RESOLVED);
    return this.columns.filter(c => {
      return c.visible === true || c.visible === disputeStatusFilter[0].value;
    });
  }

  toggleCommentModal() {
    const { disputeStore } = this.props.rootStore;
    disputeStore.setCommentModalOpen(!disputeStore.commentModalOpen);
  }

  scrollEvent(e) {
    const target = e.target;
    const { disputeStore } = this.props.rootStore;
    disputeStore.setDisputeTableScrollPos(target.scrollTop);
  }

  componentDidUpdate() {
    const scrollPosFromStorage = localStorage.getItem(DISPUTE_TABLE_SCROLL_LOCAL_STORAGE);
    if (scrollPosFromStorage) {
      const gridElement = document.querySelector(TABLE_HTML_ELEMENT);
      if (gridElement && gridElement.scrollTop !== scrollPosFromStorage) {
        if (scrollPosFromStorage > 525) {
          gridElement.scrollTop = 525;
        } else {
          gridElement.scrollTop = scrollPosFromStorage;
        }
        localStorage.removeItem(DISPUTE_TABLE_SCROLL_LOCAL_STORAGE);
      }
    }
  }

  render() {
    const {
      rootStore: { authStore, disputeStore }
    } = this.props;
    const { asc, col } = disputeStore.sort;
    return (
      <div className="mt-4">
        <div className="row">{authStore.isDisputesResolver ? <DisputeFooterButtons /> : null}</div>
        <Modal show={disputeStore.commentModalOpen} onClose={this.commentModalClosed} size="lg">
          <DisputeCommentFormAdmin toggleModal={this.toggleCommentModal} />
        </Modal>
        <div className="row" onScroll={this.scrollEvent}>
          <DataTable
            data={disputeStore.disputeRows}
            columns={this.getColumns()}
            height={525}
            sortFunc={disputeStore.setSort}
            sortColumn={col}
            sortDirection={asc ? "ASC" : "DESC"}
          />
        </div>
      </div>
    );
  }
}

export default inject("rootStore")(observer(DisputeResultsAdmin));
