import React from "react";
import styled from "styled-components";

const KeyHolder = styled.div`
  height: 30px;
  width: 30px;
  background-color: #ececec;
  font-weight: bold;
  padding-top: 3px;
  color: #000000;
  text-align: center;
  float: left;
`;

class HotKeyWrapper extends React.Component {
  render() {
    return (
      <div className="d-flex mb-1">
        <KeyHolder>{this.props.hotkey}</KeyHolder>
        <div className="ml-3 align-self-center">{this.props.children}</div>
      </div>
    );
  }
}

export default HotKeyWrapper;
