import React from "react";
import styled from "styled-components";

const FooterWrapper = styled.div.attrs({
  className: "d-flex flex-col justify-content-center"
})`
  width: 100%;
  color: #666666;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 11px;
  font-weight: 300;
  height: 40px;
  text-align: center;
`;

class Footer extends React.Component {
  render() {
    return (
      <FooterWrapper>
        <div className="align-self-center">
          © 2001-{new Date().getFullYear()} MLB Advanced Media, L.P. All rights reserved.
        </div>
      </FooterWrapper>
    );
  }
}

export default Footer;
