import React, { useState, useContext, createContext, useEffect } from "react";
import PropTypes from "prop-types";
import { Observer } from "mobx-react";
import { getUserInfoV2 } from "../httpClients/AuthApiV2";

const AuthContext = createContext({
  userInfo: null,
  setUserInfo: null,
  loggedIn: false,
  login: null,
  logout: null,
  containsAuthorities: null
});

const initialState = {
  userInfo: {
    accessToken: "",
    idToken: "",
    email: "",
    name: "",
    username: "",
    authorities: [],
    umpireId: ""
  },
  loggedIn: false,
  authToken: "",
  email: "",
  isAdmin: false,
  isSuperAdmin: false
};

const LocalStorageConstants = {
  OKTA_TOKEN: "okta-token-storage"
};

const AuthContextProvider = ({ rootStore, children }) => {
  const [userInfo, setUserInfo] = useState(initialState.userInfo);
  const [loggedIn, setLoggedIn] = useState(initialState.loggedIn);
  const { setZEUser, setLocalStorage } = rootStore.authStore;

  const login = userData => {
    if (userData) {
      setUserInfo(userData);
      setLoggedIn(true);
      setZEUser(userData);
      setLocalStorage(userData);
    }
  };

  const logout = () => {
    localStorage.clear();
    setUserInfo(initialState.userInfo);
    setLoggedIn(false);
    rootStore.authStore.logout();
  };

  useEffect(() => {
    const oktaToken = localStorage.getItem(LocalStorageConstants.OKTA_TOKEN);

    if (oktaToken) {
      const { accessToken, idToken } = JSON.parse(oktaToken);
      getUserInfoV2()
        .then(userData => {
          login({
            accessToken: accessToken.accessToken,
            idToken: idToken.idToken,
            email: idToken.claims.email,
            username: userData.username ? userData.username : initialState.userInfo.username,
            name: userData.name ? userData.name : initialState.userInfo.name,
            umpireId: userData.umpireId ? userData.umpireId : initialState.userInfo.umpireId,
            authorities: userData.authorities ? userData.authorities : initialState.userInfo.authorities
          });
        })
        .catch(e => {
          window.location.href = window.location.origin + "/v2";
        });
    } else {
      window.location.href = window.location.origin + "/v2";
    }
  }, []);

  const containsAuthorities = (authorities, authOptions) => {
    if (!authorities || !Array.isArray(authorities) || !authOptions || !Array.isArray(authOptions)) {
      return false;
    }

    let contains = false;

    authOptions.forEach(authority => {
      if (authorities.findIndex(a => a && authority && a.toUpperCase() === authority.toUpperCase()) >= 0) {
        contains = true;
      }
    });
    return contains;
  };

  return (
    <Observer>
      {() => (
        <AuthContext.Provider
          value={{
            userInfo,
            setUserInfo,
            loggedIn,
            login,
            logout,
            containsAuthorities
          }}
        >
          {children}
        </AuthContext.Provider>
      )}
    </Observer>
  );
};

const useAuth = () => {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error("useAuth must be used within AuthContextProvider");
  }
  return authContext;
};

AuthContextProvider.propTypes = {
  children: PropTypes.element
};

export { AuthContext, AuthContextProvider, useAuth };
