import styled from "styled-components";

const ZeTable = styled.table.attrs({
  className: "table table-bordered"
})`
  margin: 0 !important;
  font-weight: 100;
  font-size: 12px;
  th {
    background-color: #f2f3f4;
  }
  td {
    background-color: #ffffff;
  }
`;

export default ZeTable;
