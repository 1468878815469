import React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import AuditPitchList from "../common/AuditPitchList";

const ResearchImage = styled.img.attrs({
  className: "mr-3"
})`
  height: 540px;
  float: left;
`;

const Loading = styled.div`
  z-index: 4;
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
  height: 540px;
  width: 450px;
`;

const Spinner = styled.i.attrs({
  className: "fa fa-3x fa-pulse fa-spinner"
})`
  left: 210px;
  top: 222px;
  color: #ffffff;
  position: absolute;
`;

class AuditResearchContainer extends React.Component {
  render() {
    const auditStore = this.props.rootStore.auditStore;
    return (
      <div>
        <div className="d-flex flex-row justify-content-center">
          <AuditPitchList pitches={auditStore.sortedPitches} theight={540} />
          {auditStore.selectedPitch && auditStore.selectedPitch.playId ? (
            <div>
              {auditStore.loading ? (
                <Loading>
                  <Spinner />
                </Loading>
              ) : null}
              {!!auditStore.researchImage ? <ResearchImage src={auditStore.researchImage} /> : null}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default inject("rootStore")(observer(AuditResearchContainer));
