import React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import NoBold from "../../../elements/NoBold";
import ScoresheetCallEdit from "./ScoresheetCallEdit";

const EditHolder = styled.div`
  border: 1px solid #d2d2d2;
`;

const BorderLeft = styled.div`
  border-left: 1px solid #d2d2d2;
`;

const ScoresheetEditHeader = styled.div.attrs({
  className: "text-center"
})`
  background-color: #f7f7f7;
  font-size: 18px;
  font-weight: bold;
  padding-top: 11px;
  padding-bottom: 11px;
  border-bottom: 1px solid #d2d2d2;
  ${props => (props.borderLeft ? "border-left: 1px solid #d2d2d2;" : "")};
`;

const ScoresheetEditSubheadText = styled.div`
  font-size: 14px;
  font-weight: bold;
  padding-top: 6px;
  text-align: center;
`;

class ScoresheetBodyEditing extends React.Component {
  render() {
    const {
      rootStore: { scoreSheetStore }
    } = this.props;
    const scoresheet = scoreSheetStore.scoreSheet;
    return (
      <div className="row">
        <div className="col">
          <div className="row">
            <div className="col">
              <EditHolder>
                <div className="row m-0">
                  <ScoresheetEditHeader className="col-md-2" />
                  <ScoresheetEditHeader className="col-md-5" borderLeft={true}>
                    Ball <NoBold> called as </NoBold> Strike
                  </ScoresheetEditHeader>
                  <ScoresheetEditHeader className="col-md-5" borderLeft={true}>
                    Strike <NoBold> called as </NoBold> Ball
                  </ScoresheetEditHeader>
                </div>

                <div className="row m-0">
                  <div className="col-md-2">
                    <div className="row">
                      <ScoresheetEditSubheadText className="col-md-6">Pitch</ScoresheetEditSubheadText>
                      <ScoresheetEditSubheadText className="col-md-6">Side</ScoresheetEditSubheadText>
                    </div>
                  </div>
                  <BorderLeft className="col-md-5">
                    <div className="row">
                      <ScoresheetEditSubheadText className="col-md-2">Outside</ScoresheetEditSubheadText>
                      <ScoresheetEditSubheadText className="col-md-2">Inside</ScoresheetEditSubheadText>
                      <ScoresheetEditSubheadText className="col-md-2">High</ScoresheetEditSubheadText>
                      <ScoresheetEditSubheadText className="col-md-2">Low</ScoresheetEditSubheadText>
                      <ScoresheetEditSubheadText className="col-md-2">H.Buffer</ScoresheetEditSubheadText>
                      <ScoresheetEditSubheadText className="col-md-2">L.Buffer</ScoresheetEditSubheadText>
                    </div>
                  </BorderLeft>
                  <BorderLeft className="col-md-5">
                    <div className="row">
                      <ScoresheetEditSubheadText className="col-md-2">Outside</ScoresheetEditSubheadText>
                      <ScoresheetEditSubheadText className="col-md-2">Inside</ScoresheetEditSubheadText>
                      <ScoresheetEditSubheadText className="col-md-2">High</ScoresheetEditSubheadText>
                      <ScoresheetEditSubheadText className="col-md-2">Low</ScoresheetEditSubheadText>
                      <ScoresheetEditSubheadText className="col-md-2">Catcher</ScoresheetEditSubheadText>
                      <ScoresheetEditSubheadText className="col-md-2">L.Catch</ScoresheetEditSubheadText>
                    </div>
                  </BorderLeft>
                </div>
                {scoresheet.gameAnalysis
                  ? scoresheet.gameAnalysis.callQualitySummary.incorrectCalls.map((call, index) => (
                      <ScoresheetCallEdit call={call} key={index} index={index} scoreSheetStore={scoreSheetStore} />
                    ))
                  : null}
              </EditHolder>
            </div>
          </div>

          <div className="row mt-2">
            <div className="col">Please classify all the highlighted calls (if there is any) before releasing.</div>
          </div>
        </div>
      </div>
    );
  }
}

export default inject("rootStore")(observer(ScoresheetBodyEditing));
