export const DisputeConstants = {
  KEYS: {
    SEASON: "season",
    UMPIRE: "umpire",
    ACTIVE_RESOLVED: "activeResolved",
    RESOLUTION: "resolution",
    SORT_COL: "sortCol",
    ASC: "asc"
  },
  DISPUTE_ACTIONS: {
    NONE: "None",
    DENY: "Deny",
    CORRECT: "Correct",
    CATCHER_INFLUENCE: "Catcher Influence",
    HIGH_BUFFER: "High Buffer",
    LOW_BUFFER: "Low Buffer",
    LOW_CATCH: "Low Catch"
  }
};
