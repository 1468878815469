import React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import AuditPitch from "./AuditPitch";
import AuditPitchColumnHeader from "./AuditPitchColumnHeader";

const AuditPitchContainer = styled.div`
  width: 100%;
  min-width: 270px;
  max-width: 735px;
  overflow-y: hidden;
  overflow-x: scroll;
  margin-right: 10px;
  border: 1px solid #d2d2d2;
  height: ${props => props.theight}px;
`;

const AuditPitchTable = styled.table.attrs({
  className: ""
})`
  text-align: center;
  background-color: #ffffff !important;
  height: ${props => props.theight}px;

  thead {
    display: block;
  }

  tbody {
    display: block;
    overflow-y: scroll;
    max-height: ${props => props.theight - 50}px;
  }

  tr {
    display: table;
  }

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: #d2d2d2 !important;
    border: solid 1px #b3b0b0;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
  }

  ::-webkit-scrollbar-thumb {
    width: 8px;
    height: 55px;
    border-radius: 5px;
    background-color: #f7f7f7;
    border: solid 1px #d2d2d2;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #e5e5e5;
  }
`;

const AuditPitchTableHeader = styled.thead`
  background-color: #f7f7f7;
  border-bottom: solid 1px #d2d2d2;
  height: 32px;
`;

class AuditPitchList extends React.Component {
  constructor(props) {
    super(props);
    this.sortHandler = this.sortHandler.bind(this);
    this.pitchSelectedHandler = this.pitchSelectedHandler.bind(this);
  }

  pitchSelectedHandler(pitch, evt) {
    const { auditStore } = this.props.rootStore;
    auditStore.nextPitchFromClick(pitch);
  }

  sortHandler(colName, evt) {
    this.props.rootStore.auditStore.setSort(colName);
  }

  columns = [
    { key: "inning", name: "Sequence" },
    { key: "call", name: "Call" },
    { key: "playId", name: "Play ID" },
    { key: "callCorrectness", name: "Call Correctness" },
    { key: "stance", name: "Stance" },
    { key: "pitchType", name: "Pitch Type" },
    { key: "pitch", name: "Pitch" },
    { key: "pitcher", name: "Pitcher" },
    { key: "batter", name: "Batter" }
  ];

  columnWidths = {
    inning: "80px; padding-left: 10px;",
    call: "80px;",
    pitch: "35px",
    pitcher: "150px;",
    batter: "150px;",
    stance: "50px;",
    pitchType: "40px;",
    playId: "290px;",
    callCorrectness: "125px"
  };

  getColumns(showCallCorrectness) {
    if (showCallCorrectness) {
      return this.columns;
    }
    let filteredColumns = [];
    this.columns.forEach(c => {
      if (c.key !== "callCorrectness") {
        filteredColumns.push(c);
      }
    });
    return filteredColumns;
  }

  render() {
    const {
      rootStore: { auditStore }
    } = this.props;
    const height = this.props.theight || 500; //default table height

    return (
      <AuditPitchContainer theight={height}>
        <AuditPitchTable theight={height} innerRef={value => auditStore.setPitchListRef(value)}>
          <AuditPitchTableHeader>
            <tr>
              {this.getColumns(auditStore.callCorrectnessFilterChecked).map(column => (
                <AuditPitchColumnHeader
                  key={column.key}
                  name={column.name}
                  width={this.columnWidths[column.key]}
                  onClickHandler={this.sortHandler}
                  sort={auditStore.sort}
                />
              ))}
            </tr>
          </AuditPitchTableHeader>
          <tbody>
            {this.props.pitches.map((pitch, index) => (
              <AuditPitch
                key={index}
                pitch={pitch}
                index={index}
                widths={this.columnWidths}
                pitchSelectedHandler={this.pitchSelectedHandler}
              />
            ))}
          </tbody>
        </AuditPitchTable>
      </AuditPitchContainer>
    );
  }
}

export default inject("rootStore")(observer(AuditPitchList));
