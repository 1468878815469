import React from "react";
import styled from "styled-components";
import ZeLightButton from "../elements/form/ZeLightButton";

const ControlsHolder = styled.div.attrs({
  className: "float-right mt-1"
})``;

class SzFrameToggle extends React.Component {
  render() {
    return <ControlsHolder>{this.sizeButton()}</ControlsHolder>;
  }

  sizeButton() {
    const { expanded, toggleSize } = this.props;
    return toggleSize ? (
      <ZeLightButton onClick={this.props.toggleSize} className="ml-1">
        {expanded ? <i className="fa fa-compress" /> : <i className="fa fa-expand" />}
      </ZeLightButton>
    ) : null;
  }
}

export default SzFrameToggle;
