import React from "react";
import ReactAnimationFrame from "react-animation-frame";
import styled from "styled-components";
import { inject, observer } from "mobx-react";
import { CanvasUtilities } from "../../../utilities/CanvasUtilities";

const CanvasHolder = styled.div`
  position: relative;
  z-index: 1;
`;

const Canvas = styled.canvas.attrs({
  style: props => ({
    top: props.panDown + "px",
    bottom: props.panDown + "px",
    left: props.panRight + "px",
    right: props.panRight + "px"
  })
})`
  position: absolute;
  z-index: 1;
`;

const CAL_REFERENCE = process.env.REACT_APP_CAL_REFERENCE ? process.env.REACT_APP_CAL_REFERENCE === "true" : false;

class PcPitchCanvas extends React.Component {
  clear() {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
  }

  clickStrikeZone(event) {
    const { auditPcStore } = this.props.rootStore;
    if (!auditPcStore || !auditPcStore.selectedLine) {
      return;
    }
    const { functions, selectedPitch } = auditPcStore;
    const waist = auditPcStore.selectedLine === "waist";
    const index = this.getFrameIndex();
    if (!selectedPitch || !functions.isUmpireCallCodeCalled(selectedPitch) || !index) {
      return;
    }
    let xyWaistKnee = functions.getXyWaistKneeByIndex(selectedPitch, index);
    if (!xyWaistKnee) {
      return false;
    }
    const { y } = event.currentTarget.getBoundingClientRect();
    const { clientY } = event;
    const canvasY = clientY - y;
    const videoY = canvasY * 2;
    if (this.isValidStrikeZoneMove(videoY, xyWaistKnee, waist)) {
      // const xyWaistKneeBounds = selectedPitch.xyWaistKneeBoundsMap[index];
      //Removing this check due to UMP-532
      let inbounds = true; //this.isStrikeZoneMoveInbounds(canvasY, xyWaistKneeBounds, waist);
      if (inbounds) {
        const lineY = waist ? xyWaistKnee.waist[0].y : xyWaistKnee.knee[0].y;
        const delta = videoY - lineY;
        waist ? auditPcStore.setDeltaWaist(selectedPitch, delta) : auditPcStore.setDeltaKnee(selectedPitch, delta);
        auditPcStore.setKeyframe();
        auditPcStore.markPitchDirty(selectedPitch, true);
      } else {
        console.log("Not moving strikezone because out of bounds");
      }
    }
  }

  componentDidMount() {
    this.ctx = this.canvas.getContext("2d");

    setTimeout(this.draw(), 2000);
  }

  componentWillUnmount() {
    this.props.endAnimation();
  }

  constructor(props) {
    super(props);
    this.clickStrikeZone = this.clickStrikeZone.bind(this);
    this.onCanvasMouseMove = this.onCanvasMouseMove.bind(this);
  }

  draw() {
    const { functions, selectedPitch, showPitchArc } = this.props.rootStore.auditPcStore;
    let index = this.getFrameIndex();
    if (typeof index === "number" && selectedPitch) {
      const xyCoordinatesMap = selectedPitch.xyCoordinatesMap;
      if (xyCoordinatesMap) {
        let xyCoordinates = xyCoordinatesMap[index];
        if (!xyCoordinates) {
          index = functions.getClosestCalibrationIndexWithCoordinates(xyCoordinatesMap, index);
        }
      }
    }
    if (this.ctx && typeof index === "number") {
      if (showPitchArc) {
        this.drawPitch(index);
        this.drawPitchLandmarks(index);
      }
      this.drawPlate(index);
      this.drawBattersBoxes(index);
      this.drawFoulLines(index);
      if (CAL_REFERENCE) {
        // this.drawMoundCircle(index);
        this.drawHomeSecondAxisMap(index);
      }
      this.drawStrikeZone(index);
      this.drawWaistKnee(index);
      this.drawGhostRow(index);
    }
  }

  drawBattersBoxes(index) {
    const auditPcStore = this.props.rootStore.auditPcStore;
    if (!(auditPcStore.selectedPitch && auditPcStore.selectedPitch.battersBoxesMap)) {
      return;
    }
    const battersBoxesMap = auditPcStore.selectedPitch.battersBoxesMap;
    const battersBoxes = battersBoxesMap[index];
    if (battersBoxes) {
      const boxRH = battersBoxes.slice(0, 4);
      for (let idx = 0; idx < boxRH.length; idx++) {
        // get the index of the coordinate for the other end of the line
        const otherIdx = (idx + 1) % boxRH.length;
        const xy1 = CanvasUtilities.scaleCoordinateXy(boxRH[idx], 0.5);
        const xy2 = CanvasUtilities.scaleCoordinateXy(boxRH[otherIdx], 0.5);
        CanvasUtilities.drawLinePointToPointXY(this.ctx, xy1, xy2, CanvasUtilities.COLORS.GREEN);
      }
      const boxLH = battersBoxes.slice(4, 8);
      for (let idx = 0; idx < boxRH.length; idx++) {
        // get the index of the coordinate for the other end of the line
        const otherIdx = (idx + 1) % boxRH.length;
        const xy1 = CanvasUtilities.scaleCoordinateXy(boxLH[idx], 0.5);
        const xy2 = CanvasUtilities.scaleCoordinateXy(boxLH[otherIdx], 0.5);
        CanvasUtilities.drawLinePointToPointXY(this.ctx, xy1, xy2, CanvasUtilities.COLORS.GREEN);
      }
    }
  }

  drawFoulLines(index) {
    const auditPcStore = this.props.rootStore.auditPcStore;
    if (!(auditPcStore.selectedPitch && auditPcStore.selectedPitch.foulLineCoordinatesMap)) {
      return;
    }
    const foulLineCoordinatesMap = auditPcStore.selectedPitch.foulLineCoordinatesMap;
    const foulLineCoordinates = foulLineCoordinatesMap[index];
    if (foulLineCoordinates) {
      const lineRF = foulLineCoordinates.slice(0, 2);
      CanvasUtilities.drawLinePointToPointXYScaled(this.ctx, ...lineRF, CanvasUtilities.COLORS.GREEN, false, 0.5);
      const lineLF = foulLineCoordinates.slice(2, 4);
      CanvasUtilities.drawLinePointToPointXYScaled(this.ctx, ...lineLF, CanvasUtilities.COLORS.GREEN, false, 0.5);
    }
  }

  drawGhostRow(index) {
    const { functions, ghostRow, selectedPitch } = this.props.rootStore.auditPcStore;
    if (!ghostRow || !selectedPitch) {
      return;
    }
    const xyWaistKneeCoordinates = functions.getXyWaistKneeByIndex(selectedPitch, index);
    if (!xyWaistKneeCoordinates) {
      return;
    }
    const row = ghostRow.waist ? xyWaistKneeCoordinates.waist : xyWaistKneeCoordinates.knee;
    const xy1 = CanvasUtilities.scaleCoordinateXy(
      {
        x: row[0].x,
        y: row[0].y + ghostRow.delta
      },
      0.5
    );
    const xy2 = CanvasUtilities.scaleCoordinateXy(
      {
        x: row[1].x,
        y: row[1].y + ghostRow.delta
      },
      0.5
    );
    CanvasUtilities.drawDashedLinePointToPointXY(this.ctx, xy1, xy2, CanvasUtilities.COLORS.RED);
  }

  drawHomeSecondAxisMap(index) {
    const auditPcStore = this.props.rootStore.auditPcStore;
    if (!(auditPcStore.selectedPitch && auditPcStore.selectedPitch.homeSecondAxisMap)) {
      return;
    }
    const homeSecondAxisMap = auditPcStore.selectedPitch.homeSecondAxisMap;
    const homeSecondCoordinates = homeSecondAxisMap[index];
    if (homeSecondCoordinates) {
      for (let idx = 0; idx < homeSecondCoordinates.length - 1; idx++) {
        // get the index of the coordinate for the other end of the line
        const otherIdx = (idx + 1) % homeSecondCoordinates.length;
        const xy1 = CanvasUtilities.scaleCoordinateXy(homeSecondCoordinates[idx], 0.5);
        const xy2 = CanvasUtilities.scaleCoordinateXy(homeSecondCoordinates[otherIdx], 0.5);
        CanvasUtilities.drawLinePointToPointXY(this.ctx, xy1, xy2, CanvasUtilities.COLORS.GREEN);
      }
    }
  }

  drawMoundCircle(index) {
    const auditPcStore = this.props.rootStore.auditPcStore;
    if (!(auditPcStore.selectedPitch && auditPcStore.selectedPitch.moundCircleMap)) {
      return;
    }
    const moundCircleMap = auditPcStore.selectedPitch.moundCircleMap;
    const moundCircleCoordinates = moundCircleMap[index];
    if (moundCircleCoordinates) {
      for (let idx = 0; idx < moundCircleCoordinates.length; idx++) {
        // get the index of the coordinate for the other end of the line
        const otherIdx = (idx + 1) % moundCircleCoordinates.length;
        const xy1 = CanvasUtilities.scaleCoordinateXy(moundCircleCoordinates[idx], 0.5);
        const xy2 = CanvasUtilities.scaleCoordinateXy(moundCircleCoordinates[otherIdx], 0.5);
        CanvasUtilities.drawLinePointToPointXY(this.ctx, xy1, xy2, CanvasUtilities.COLORS.GREEN);
      }
    }
  }

  drawPitch(index) {
    const {
      auditPcStore: { selectedPitch }
    } = this.props.rootStore;
    if (!(selectedPitch && selectedPitch.xyCoordinatesMap)) {
      return;
    }
    const xyzCoordinates = selectedPitch.xyzCoordinates;
    const xyCoordinatesMap = selectedPitch.xyCoordinatesMap;
    let xyCoordinates = xyCoordinatesMap[index];
    if (xyCoordinates) {
      for (let idx = 0; idx < xyCoordinates.length - 1; idx++) {
        const xy1 = CanvasUtilities.scaleCoordinateXy(xyCoordinates[idx], 0.5);
        const xy2 = CanvasUtilities.scaleCoordinateXy(xyCoordinates[idx + 1], 0.5);
        const color = this.getPitchArcColor(xyzCoordinates.positions[idx]);
        CanvasUtilities.drawLinePointToPointXY(this.ctx, xy1, xy2, color, 2);
      }
    }
  }

  drawPitchLandmarks(index) {
    const {
      auditPcStore: { selectedPitch }
    } = this.props.rootStore;
    if (!(selectedPitch && selectedPitch.xyTargetDepthCoordinatesMap)) {
      return;
    }
    const xyTargetDepthCoordinatesMap = selectedPitch.xyTargetDepthCoordinatesMap;
    const xyTargetDepthCoordinates = xyTargetDepthCoordinatesMap[index];
    if (xyTargetDepthCoordinates) {
      const xy = CanvasUtilities.scaleCoordinateXy(xyTargetDepthCoordinates, 0.5);
      CanvasUtilities.drawCircleXy(this.ctx, xy, 5, CanvasUtilities.COLORS.YELLOW);
    }
  }

  drawPlate(index) {
    const auditPcStore = this.props.rootStore.auditPcStore;
    if (!(auditPcStore.selectedPitch && auditPcStore.selectedPitch.plateCoordinatesMap)) {
      return;
    }
    const plateCoordinatesMap = auditPcStore.selectedPitch.plateCoordinatesMap;
    const plateCoordinates = plateCoordinatesMap[index];
    if (plateCoordinates) {
      for (let idx = 0; idx < plateCoordinates.length; idx++) {
        // get the index of the coordinate for the other end of the line
        const otherIdx = (idx + 1) % plateCoordinates.length;
        const xy1 = CanvasUtilities.scaleCoordinateXy(plateCoordinates[idx], 0.5);
        const xy2 = CanvasUtilities.scaleCoordinateXy(plateCoordinates[otherIdx], 0.5);
        CanvasUtilities.drawLinePointToPointXY(this.ctx, xy1, xy2, CanvasUtilities.COLORS.GREEN);
      }
    }
  }

  drawStrikeZone(index) {
    const auditPcStore = this.props.rootStore.auditPcStore;
    if (!auditPcStore.selectedPitch) {
      return;
    }
    const selectedPitch = auditPcStore.selectedPitch;
    if (!selectedPitch) {
      return;
    }
    const { deltaWaist, deltaKnee } = selectedPitch;
    const xySzCoordinatesMap = selectedPitch.xySzCoordinatesMap;
    if (!xySzCoordinatesMap) {
      return;
    }
    const xySzCoordinates = xySzCoordinatesMap[index];
    if (xySzCoordinates) {
      const { bottomLeft, topLeft, topRight, bottomRight } = xySzCoordinates;
      const bottomLeftTranslated = deltaKnee
        ? CanvasUtilities.translateCoordinateXy(bottomLeft, 0, deltaKnee)
        : bottomLeft;
      const bottomLeftScaled = CanvasUtilities.scaleCoordinateXy(bottomLeftTranslated, 0.5);
      const topLeftTranslated = deltaWaist ? CanvasUtilities.translateCoordinateXy(topLeft, 0, deltaWaist) : topLeft;
      const topLeftScaled = CanvasUtilities.scaleCoordinateXy(topLeftTranslated, 0.5);
      const topRightTranslated = deltaWaist ? CanvasUtilities.translateCoordinateXy(topRight, 0, deltaWaist) : topRight;
      const topRightScaled = CanvasUtilities.scaleCoordinateXy(topRightTranslated, 0.5);
      const bottomRightTranslated = deltaKnee
        ? CanvasUtilities.translateCoordinateXy(bottomRight, 0, deltaKnee)
        : bottomRight;
      const bottomRightScaled = CanvasUtilities.scaleCoordinateXy(bottomRightTranslated, 0.5);

      CanvasUtilities.drawLinePointToPointXY(this.ctx, bottomLeftScaled, topLeftScaled, CanvasUtilities.COLORS.GREEN);
      CanvasUtilities.drawLinePointToPointXY(this.ctx, topLeftScaled, topRightScaled, CanvasUtilities.COLORS.GREEN);
      CanvasUtilities.drawLinePointToPointXY(this.ctx, topRightScaled, bottomRightScaled, CanvasUtilities.COLORS.GREEN);
      CanvasUtilities.drawLinePointToPointXY(
        this.ctx,
        bottomRightScaled,
        bottomLeftScaled,
        CanvasUtilities.COLORS.GREEN
      );
    }
  }

  drawWaistKnee(index) {
    const { auditPcStore } = this.props.rootStore;
    const { functions, selectedPitch } = auditPcStore;
    const xyWaistKneeCoordinates = functions.getXyWaistKneeByIndex(selectedPitch, index);
    if (xyWaistKneeCoordinates) {
      const { waist, knee } = xyWaistKneeCoordinates;
      const waistLineColor =
        auditPcStore.selectedLine === "waist" ? CanvasUtilities.COLORS.RED : CanvasUtilities.COLORS.GREEN;
      const kneeLineColor =
        auditPcStore.selectedLine === "knee" ? CanvasUtilities.COLORS.RED : CanvasUtilities.COLORS.GREEN;
      if (waist) {
        const deltaWaist = selectedPitch.deltaWaist;
        const translated = deltaWaist
          ? waist.map(coord => CanvasUtilities.translateCoordinateXy(coord, 0, deltaWaist))
          : waist;
        const scaled = translated.map(coord => CanvasUtilities.scaleCoordinateXy(coord, 0.5));
        CanvasUtilities.drawLinePointToPointXY(this.ctx, ...scaled, waistLineColor);
      }
      if (knee) {
        const deltaKnee = selectedPitch.deltaKnee;
        const translated = deltaKnee
          ? knee.map(coord => CanvasUtilities.translateCoordinateXy(coord, 0, deltaKnee))
          : knee;
        const scaled = translated.map(coord => CanvasUtilities.scaleCoordinateXy(coord, 0.5));
        CanvasUtilities.drawLinePointToPointXY(this.ctx, ...scaled, kneeLineColor);
      }
    }
  }

  getFrameIndex() {
    const { functions, selectedPitch } = this.props.rootStore.auditPcStore;
    let index = functions.getCurrentFrameIndex();
    if (typeof index === "number" && selectedPitch) {
      const xyCoordinatesMap = selectedPitch.xyCoordinatesMap;
      if (xyCoordinatesMap) {
        let xyCoordinates = xyCoordinatesMap[index];
        if (!xyCoordinates) {
          index = functions.getClosestCalibrationIndexWithCoordinates(xyCoordinatesMap, index);
        }
      }
    }
    return index;
  }

  getPitchArcColor(xyz) {
    if (xyz.y <= 0) {
      return CanvasUtilities.COLORS.RED;
    } else if (xyz.y <= 1.417) {
      return CanvasUtilities.COLORS.YELLOW;
    } else {
      return CanvasUtilities.COLORS.CYAN;
    }
  }

  isStrikeZoneClose(pitch, index, x, y, waist) {
    if (!pitch || !index) {
      return false;
    }
    const { functions } = this.props.rootStore.auditPcStore;
    let xyWaistKnee = functions.getXyWaistKneeByIndex(pitch, index);
    if (!xyWaistKnee) {
      return false;
    }
    let waistOrKnee = (waist ? xyWaistKnee.waist : xyWaistKnee.knee).map(xy =>
      CanvasUtilities.scaleCoordinateXy(xy, 0.5)
    );
    let lineLength = Math.abs(waistOrKnee[1].x - waistOrKnee[0].x);
    let lineX = waistOrKnee[0].x;
    let lineY = waistOrKnee[0].y;
    if (pitch.batterSide === "R") {
      return x - lineX < lineLength && x > lineX && Math.abs(lineY - y) < 5;
    } else {
      return lineX - x < lineLength && lineX > x && Math.abs(lineY - y) < 5;
    }
  }

  isStrikeZoneMoveInbounds(row, bounds, isWaist) {
    let upper, lower;
    if (isWaist) {
      upper = CanvasUtilities.scaleCoordinateXy(bounds.waistUpper[0], 0.5).y;
      lower = CanvasUtilities.scaleCoordinateXy(bounds.waistLower[0], 0.5).y;
    } else {
      upper = CanvasUtilities.scaleCoordinateXy(bounds.kneeUpper[0], 0.5).y;
      lower = CanvasUtilities.scaleCoordinateXy(bounds.kneeLower[0], 0.5).y;
    }
    return row > upper && row < lower;
  }

  isValidStrikeZoneMove(y, xyWaistKnee, waist) {
    if (waist) {
      return y < xyWaistKnee.knee[0].y - 5;
    } else {
      return y > xyWaistKnee.waist[0].y + 5;
    }
  }

  onAnimationFrame(time) {
    if (this.ctx) {
      this.clear();
      this.draw();
    }
  }

  onCanvasMouseMove(event) {
    const { auditPcStore, routerStore } = this.props.rootStore;
    const { selectedPitch } = auditPcStore;
    if (routerStore.isAuditV2StrikeZoneTab) {
      const { x, y } = event.currentTarget.getBoundingClientRect();
      const { clientX, clientY } = event;
      let canvasX = clientX - x;
      let canvasY = clientY - y;
      // moves outside the canvas bounds should not update the selected line
      if (canvasY > 0 && canvasY < 540 && canvasX > 0 && canvasX < 960) {
        let index = this.getFrameIndex();
        if (this.isStrikeZoneClose(selectedPitch, index, canvasX, canvasY, true)) {
          auditPcStore.setSelectedLine("waist");
        } else if (this.isStrikeZoneClose(selectedPitch, index, canvasX, canvasY, false)) {
          auditPcStore.setSelectedLine("knee");
        }
      }
    }
  }

  render() {
    const { selectedPitch } = this.props.rootStore.auditPcStore;
    return (
      <CanvasHolder>
        <Canvas
          {...this.props}
          innerRef={c => {
            this.canvas = c;
          }}
          panDown={selectedPitch ? selectedPitch.calibrationPanDown : 0}
          panRight={selectedPitch ? selectedPitch.calibrationPanRight : 0}
          {...{
            onClick: this.clickStrikeZone,
            onMouseMove: this.onCanvasMouseMove
          }}
        />
      </CanvasHolder>
    );
  }
}

export default inject("rootStore")(ReactAnimationFrame(observer(PcPitchCanvas)));
