import React from "react";
import { inject, observer } from "mobx-react";
import Modal from "../../common/modal/Modal";
import ModalFooter from "../../common/modal/ModalFooter";
import ModalBody from "../../common/modal/ModalBody";
import ScheduleUmpireStanceOption from "./ScheduleUmpireStanceOption";
import ModalHeaderWithTitleAndClose from "../../common/modal/ModalHeaderWithTitleAndClose";
import ZeLightButton from "../../elements/form/ZeLightButton";

class ScheduleUmpireStancesModal extends React.Component {
  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
    this.saveStances = this.saveStances.bind(this);
    this.stances = ["Square", "Slot", "Scissors", "Knee"];
  }

  closeModal(event) {
    event.preventDefault();
    this.props.rootStore.scheduleStore.closeUmpireStances();
  }

  saveStances(event) {
    event.preventDefault();
    this.props.rootStore.scheduleStore.updateUmpireStances();
  }

  render() {
    const { scheduleStore } = this.props.rootStore;
    return (
      <Modal show={scheduleStore.showUmpireStances} onClose={() => {}} size="sm">
        <ModalHeaderWithTitleAndClose title="Umpire Stances" close={this.closeModal} />

        <ModalBody>
          <div className="row">
            <div className="col">
              <div className="form-group">
                <div className="row">
                  {this.stances.map(s => (
                    <ScheduleUmpireStanceOption key={s} stance={s} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </ModalBody>

        <ModalFooter>
          <button className="btn btn-primary" onClick={this.saveStances}>
            Save
          </button>
          <ZeLightButton onClick={this.closeModal}>Cancel</ZeLightButton>
        </ModalFooter>
      </Modal>
    );
  }
}

export default inject("rootStore")(observer(ScheduleUmpireStancesModal));
