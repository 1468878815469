import React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";

const CalledButton = styled.button.attrs(() => ({
  className: "btn"
}))`
  &&& {
    border: solid 1px #d2d2d2;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: -0.4px;
    height: 22px;
    margin: 0 2px;
    padding: 0 8px;
    width: 100%;
    ${props => (props.selected ? "background-color: #fdec98;" : "")};
  }
`;

class GameViewerFilterCalled extends React.Component {
  onChange = () => {
    if (!this.props.selected) {
      const gameViewerStore = this.props.rootStore.gameViewerStore;
      gameViewerStore.setCalledFilter(this.props.value);
    }
  };

  render() {
    return (
      <CalledButton onClick={this.onChange.bind(this)} selected={this.props.selected}>
        {this.props.label}
      </CalledButton>
    );
  }
}

export default inject("rootStore")(observer(GameViewerFilterCalled));
