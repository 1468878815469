import React from "react";
import Media from "react-media";
import styled from "styled-components";
import { inject, observer } from "mobx-react";
import GameViewerVideo from "./GameViewerVideo";
import GameViewerSz from "./GameViewerSz";
import VideoControls from "../../../common/video/VideoControls";
import GameViewerPitchcastSz from "./GameViewerPitchcastSz";
import SzFrameToggle from "../../../common/SzFrameToggle";

const VideoHolder = styled.div`
  &&& {
    height: ${props => (props.expanded ? "540px" : "270px")};
    width: ${props => (props.expanded ? "960px" : "480px")};
  }
`;
class GameViewerDisplay extends React.Component {
  render() {
    const { gameViewerStore } = this.props.rootStore;
    const {
      currentCenterField,
      currentSideView,
      currentPitchCoordinates,
      currentSzTopLeft,
      currentSzBottomRight,
      currentVideo,
      currentVideoZoomLeft,
      currentVideoZoomTop,
      displaySz,
      functions,
      isZoomed,
      setVideoRef,
      showCanvas,
      toggleShowCanvas,
      videoCurrentTime,
      videoDuration,
      videoExpanded,
      videoZoomFactor
    } = gameViewerStore;
    const cfOrPitchcastVideo = functions.getVideoCfOrPitchcast();
    const cfKeyframe = cfOrPitchcastVideo && cfOrPitchcastVideo.typeName === "Centerfield";
    if (cfOrPitchcastVideo)
      return (
        <Media query="(max-width: 1520px)">
          {matches => (
            <div>
              {displaySz ? (
                <div>
                  {this.szFrame(cfKeyframe, matches)}
                  {matches ? this.szFrameToggle() : null}
                </div>
              ) : currentVideo && currentVideo.link && currentPitchCoordinates ? (
                <div>
                  <GameViewerVideo
                    isZoomed={isZoomed}
                    zoomFactor={videoZoomFactor}
                    zoomTopAdj={currentVideoZoomTop}
                    zoomLeftAdj={currentVideoZoomLeft}
                    videoSrc={currentVideo.link}
                    centerfield={currentCenterField}
                    sideview={currentSideView}
                    szTopLeft={currentSzTopLeft}
                    szBottomRight={currentSzBottomRight}
                    currentVideo={currentVideo}
                    videoRef={v => setVideoRef(v)}
                    expanded={matches ? videoExpanded : true}
                  />
                  {this.videoControls(videoCurrentTime, videoDuration)}
                </div>
              ) : (
                this.videoHolder()
              )}
            </div>
          )}
        </Media>
      );
  }

  szFrame(cfKeyframe, matches) {
    const { videoExpanded } = this.props.rootStore.gameViewerStore;
    if (cfKeyframe) {
      return <GameViewerSz expanded={matches ? videoExpanded : true} />;
    } else {
      return <GameViewerPitchcastSz expanded={matches ? videoExpanded : true} />;
    }
  }

  szFrameToggle() {
    const { gameViewerStore } = this.props.rootStore;
    const { videoExpanded } = this.props.rootStore.gameViewerStore;
    return <SzFrameToggle toggleSize={gameViewerStore.toggleVideoExpanded} expanded={videoExpanded} />;
  }

  videoControls(currentTime, duration) {
    const { gameViewerStore } = this.props.rootStore;
    const { onSeekChange, videoExpanded, videoRef, showCanvas, toggleShowCanvas } = gameViewerStore;
    return (
      <Media query="(max-width: 1520px)">
        {matches => (
          <VideoControls
            videoRef={videoRef}
            currentTime={currentTime}
            duration={duration}
            onChange={onSeekChange}
            toggleSize={matches ? gameViewerStore.toggleVideoExpanded : null}
            expanded={matches ? videoExpanded : null}
            showTime={false}
            showCanvas={showCanvas}
            toggleShowCanvas={toggleShowCanvas}
          />
        )}
      </Media>
    );
  }

  videoHolder() {
    const { expanded } = this.props;
    return (
      <Media query="(max-width: 1520px)">
        {matches => (matches ? <VideoHolder large={expanded} /> : <VideoHolder large={true} />)}
      </Media>
    );
  }
}

export default inject("rootStore")(observer(GameViewerDisplay));
