import React from "react";
import Modal from "../../common/modal/Modal";
import ModalBody from "../../common/modal/ModalBody";
import ModalFooter from "../../common/modal/ModalFooter";
import ZeLightButton from "../../elements/form/ZeLightButton";
import ModalHeaderWithTitleAndClose from "../../common/modal/ModalHeaderWithTitleAndClose";

class ConfirmModal extends React.Component {
  render() {
    const title = this.props.title || "Confirm Changes";
    const body = this.props.body || "Are you sure you want to make these changes?";
    return (
      <Modal show={this.props.show} onClose={() => {}} size="lg">
        <ModalHeaderWithTitleAndClose title={title} close={this.props.onClose} />
        <ModalBody>{body}</ModalBody>
        <ModalFooter>
          <button className="btn btn-primary" onClick={this.props.action}>
            Confirm
          </button>
          <ZeLightButton onClick={this.props.onClose}>Cancel</ZeLightButton>
        </ModalFooter>
      </Modal>
    );
  }
}

export default ConfirmModal;
