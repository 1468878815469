import { szCanvas } from "../../../../../stores/gameViewer/GameViewerStoreConstants";
import StringUtil from "../../../../../utilities/StringUtil";
import _ from "lodash";
import { CoordinateUtility } from "../../../../../utilities/CoordinateUtility";
import * as observable from "mobx";
import { plateCorners } from "../../../../../stores/gameViewer/GameViewerStoreConstants";
import ReactGA from "react-ga";
import { VideoConstants } from "../../../../common/constants/VideoConstants";
import { AlertConstants } from "../../../../common/alert/AlertConstants";

export default class GameViewerFunctions {
  constructor(authStore, alertStore, gameViewerStore, loadingStore, zeApi) {
    this.authStore = authStore;
    this.alertStore = alertStore;
    this.gameViewerStore = gameViewerStore;
    this.loadingStore = loadingStore;
    this.zeApi = zeApi;

    this.checkAutoplay = this.checkAutoplay.bind(this);
    this.disputePitch = this.disputePitch.bind(this);
    this.googleAnalyticsEvent = this.googleAnalyticsEvent.bind(this);
    this.moveKeyframe = this.moveKeyframe.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
    this.saveAbsFeedback = this.saveAbsFeedback.bind(this);
  }

  adjustCenterfieldCoordinates(centerfield) {
    if (centerfield) {
      centerfield.before = CoordinateUtility.adjustCoordinatesArrayForZoom(
        centerfield.before,
        this.gameViewerStore.videoZoomFactor,
        this.gameViewerStore.videoCenter,
        this.gameViewerStore.currentVideoZoomLeft,
        this.gameViewerStore.currentVideoZoomTop
      );
      centerfield.over = CoordinateUtility.adjustCoordinatesArrayForZoom(
        centerfield.over,
        this.gameViewerStore.videoZoomFactor,
        this.gameViewerStore.videoCenter,
        this.gameViewerStore.currentVideoZoomLeft,
        this.gameViewerStore.currentVideoZoomTop
      );
      centerfield.midpoint = CoordinateUtility.adjustCoordinatesArrayForZoom(
        centerfield.midpoint,
        this.gameViewerStore.videoZoomFactor,
        this.gameViewerStore.videoCenter,
        this.gameViewerStore.currentVideoZoomLeft,
        this.gameViewerStore.currentVideoZoomTop
      );
      centerfield.after = CoordinateUtility.adjustCoordinatesArrayForZoom(
        centerfield.after,
        this.gameViewerStore.videoZoomFactor,
        this.gameViewerStore.videoCenter,
        this.gameViewerStore.currentVideoZoomLeft,
        this.gameViewerStore.currentVideoZoomTop
      );
    }
    return centerfield;
  }

  batterFilterResults(pitch, filter) {
    if (!filter.length) {
      return true;
    }
    for (let idx = 0; idx < filter.length; idx++) {
      let filterElem = filter[idx];
      if (filterElem.value === pitch.batterId) {
        return true;
      }
    }
    return false;
  }

  calculateDistanceAverage(pitchList, key, batterSide) {
    let sum = 0;
    if (pitchList.length > 0) {
      let filteredPitchList = pitchList
        .filter(p => p.errorXDirection === key || p.errorZDirection === key)
        .filter(p => !batterSide || p.batterSide === batterSide);
      filteredPitchList.forEach(p => {
        let val = p.errorX ? p.errorX : p.errorZ;
        if (val) {
          sum += val;
        }
      });
      return {
        number: filteredPitchList.length,
        average: filteredPitchList.length ? (sum / filteredPitchList.length).toFixed(2) : "0.00"
      };
    }
    return {
      number: pitchList.length,
      average: "0.00"
    };
  }

  calculateInnings(pitchList) {
    let finalHalfInnings = [];
    if (pitchList && pitchList.length > 0) {
      let innings = _.groupBy(pitchList, "inning");
      for (let inningKey in innings) {
        let inning = innings[inningKey];
        let top = inning.filter(p => p.topOfInning);
        let topAtBats = _.groupBy(top, "atBatNumber");
        let topOfInningData = this.createInningData(topAtBats);
        let topOfInningHasIncorrects = !!topOfInningData.find(d => d.hasIncorrects);
        let topOfInning = {
          inning: inningKey * 1,
          top: true,
          title: "TOP - " + StringUtil.nth(inningKey * 1),
          atbats: topOfInningData,
          hasIncorrects: topOfInningHasIncorrects
        };
        let bottom = inning.filter(p => !p.topOfInning);
        let bottomAtBats = _.groupBy(bottom, "atBatNumber");
        let bottomOfInningData = this.createInningData(bottomAtBats);
        let bottomOfInningHasIncorrects = !!bottomOfInningData.find(d => d.hasIncorrects);
        let bottomOfInning = {
          inning: inningKey * 1,
          top: false,
          title: "BOTTOM - " + StringUtil.nth(inningKey * 1),
          atbats: bottomOfInningData,
          hasIncorrects: bottomOfInningHasIncorrects
        };
        finalHalfInnings.push(topOfInning, bottomOfInning);
      }
    }
    return finalHalfInnings;
  }

  calculateScoresheet(pitchList) {
    let total = 0,
      correct = 0,
      deleted = 0,
      acceptable = 0,
      incorrect = 0,
      challenges = 0,
      callsConfirmed = 0,
      callsOverturned = 0,
      adjusted = 0;
    pitchList.forEach(p => {
      if (p.deleted) {
        deleted++;
      } else {
        total++;
        if (p.reviewType) {
          challenges++;
          if (p.isOverturned) {
            callsOverturned++;
          } else {
            callsConfirmed++;
          }
        }
        switch (p.callCorrectness.toUpperCase()) {
          case "CORRECT":
            correct++;
            break;
          case "ACCEPTABLE":
            acceptable++;
            break;
          case "INCORRECT":
            incorrect++;
            break;
          case "ADJUSTED":
          case "CATCHER_INFLUENCE":
          case "LOW_BUFFER":
          case "HIGH_BUFFER":
          case "LOW_CATCH":
            adjusted++;
            break;
          default:
            break;
        }
      }
    });
    return {
      total: total,
      correct: correct,
      acceptable: acceptable,
      incorrect: incorrect,
      adjusted: adjusted,
      challenges: challenges,
      callsConfirmed: callsConfirmed,
      callsOverturned: callsOverturned,
      deleted: deleted
    };
  }

  calculateSZ() {
    let startY = 0,
      endY = 0;
    const { currentPitchCoordinates, selectedPitch, szLineLength } = this.gameViewerStore;
    if (
      currentPitchCoordinates.szBottomRight &&
      currentPitchCoordinates.szBottomRight.length > 0 &&
      currentPitchCoordinates.szTopLeft &&
      currentPitchCoordinates.szTopLeft.length > 0
    ) {
      if (selectedPitch.batterSide === "R") {
        startY = currentPitchCoordinates.szBottomRight[1];
        endY = currentPitchCoordinates.szBottomRight[1] + szLineLength;
      } else {
        startY = currentPitchCoordinates.szTopLeft[1];
        endY = currentPitchCoordinates.szTopLeft[1] - szLineLength;
      }
    }
    return { startY: startY, endY: endY };
  }

  checkAutoplay() {
    let { autoplay, videoRef } = this.gameViewerStore;
    if (autoplay) {
      videoRef.play();
    } else {
      this.gameViewerStore.setAutoplay(true);
    }
  }

  completePitchFields(pitch) {
    if (pitch.videos && pitch.videos.length > 0) {
      pitch.videos.forEach(v => {
        v.typeName = this.getVideoTypeName(v.cameraAngle);
        v.rank = this.getVideoRank(v.typeName);
      });
      pitch.videos = pitch.videos.sort((a, b) => a.rank - b.rank);
    }
    this.setCanvasPositions(pitch);
  }

  convertToTopLeftOrigin(xy) {
    /*return {
      x: 960 - 960 * xy.x,
      y: 540 - 540 * xy.y
    };*/
    return {
      x: 960 + 960 * xy.x,
      y: 540 - 540 * xy.y
    };
  }

  createDummyHalfInning(inningKey) {
    const top = inningKey.charAt(0) === "T";
    const inningNum = parseInt(inningKey.substring(1), 10);
    return {
      inning: inningNum,
      top: top,
      title: (top ? "TOP" : "BOTTOM") + " - " + StringUtil.nth(inningNum),
      atbats: [],
      hasIncorrects: false
    };
  }

  createInningData(atBats) {
    let inning = [];
    for (let atbatKey in atBats) {
      let ab = atBats[atbatKey];
      let pitches = ab.map(p => {
        let call = p.umpireCall ? p.umpireCall : p.umpireCallCode;
        return {
          pitchObj: p,
          pitchNumber: p.pitchNumber,
          call: call,
          pitch: p.pitchType,
          count: p.balls + "-" + p.strikes,
          callCorrectness: p.callCorrectness,
          deleted: p.deleted
        };
      });
      let hasIncorrects = !!pitches.find(p => this.pitchIsIncorrectOrAdjusted(p));
      inning.push({
        id: ab[0].atBatNumber,
        title: "AB " + ab[0].atBatNumber + "  - " + ab[0].batterName,
        pitches: pitches,
        outs: ab[0].outs,
        hasIncorrects: hasIncorrects
      });
    }
    return inning;
  }

  disputePitch(pitch) {
    const { gamePk, umpireId } = this.gameViewerStore;
    const dispute = {
      disputeReason: this.gameViewerStore.disputeReason,
      gamePk: pitch.gamePk,
      pitchNumber: pitch.pitchNumber,
      playId: pitch.playId,
      umpire: { id: umpireId }
    };
    this.loadingStore.setLoading(true, "Loading", "Disputing pitch", 75);
    this.zeApi
      .saveDispute(dispute)
      .then(data => {
        this.loadingStore.setLoading(true, "Loading", "Updating pitch information", 75);
        this.zeApi.getGameViewerPitch(gamePk, umpireId, pitch.playId).then(pitch => {
          this.gameViewerStore.setPitchInList(pitch);
          this.loadingStore.setLoading(false);
          this.gameViewerStore.toggleDisputePopover();
        });
      })
      .catch(e => {
        this.alertStore.addAlert({ type: AlertConstants.TYPES.DANGER, text: e.response.data });
        this.zeApi.getGameViewerPitch(gamePk, umpireId, pitch.playId).then(pitch => {
          this.gameViewerStore.setPitchInList(pitch);
          this.loadingStore.setLoading(false);
          this.gameViewerStore.toggleDisputePopover();
        });
      });
  }

  getAllCoordinates(pitch) {
    if (pitch.xyzCoordinates) {
      if (!pitch.xyCoordinatesMap) {
        this.getXyCoordinates(pitch);
      }
    } else {
      this.zeApi.getPitch(this.gameViewerStore.gamePk, pitch.playId).then(pitchWithTrajectory => {
        if (pitch && pitchWithTrajectory) {
          const { lastMeasuredData, releaseData, trajectory } = pitchWithTrajectory;
          pitch.xyzCoordinates = this.getXyzPitchArc(pitch, releaseData, trajectory, lastMeasuredData);
          this.getXyCoordinates(pitch);
        }
      });
    }
  }

  getCall(call) {
    let umpireCall = call ? call.toUpperCase() : "";
    switch (umpireCall) {
      case "BLOCKED_BALL":
      case "BALL":
        return "Ball";
      case "CALLED_STRIKE":
        return "Strike";
      case "SWINGING_STRIKE":
      case "SWINGING_STRIKE_BLOCKED":
      default:
        return "No Call";
    }
  }

  getCfPitchCanvasCoordinate(pitch) {
    const { strikeZoneBox, strikeZoneBoxCenter } = szCanvas;
    if (!(pitch.szBottom && pitch.plateX && pitch.plateZ)) {
      return null;
    } else if (pitch.szBottom > pitch.plateZ) {
      return this.getCfPitchCanvasCoordinateBelowSzBottom(pitch);
    }
    const szTop = pitch.szTop * 12;
    const szBottom = pitch.szBottom * 12;
    const plateX = pitch.plateX * 12;
    const plateZ = pitch.plateZ * 12;
    const row =
      ((szTop - plateZ) / (szTop - szBottom)) * (strikeZoneBox[1][0] - strikeZoneBox[0][0]) + strikeZoneBox[0][0];
    const col = strikeZoneBoxCenter[1] - (70 / 17) * plateX;
    return {
      row: row,
      col: col
    };
  }

  getCfPitchCanvasCoordinateBelowSzBottom(pitch) {
    const { plateCF, strikeZoneBox } = szCanvas;
    const szBottom = pitch.szBottom * 12;
    const plateX = pitch.plateX * 12;
    const plateZ = pitch.plateZ * 12;
    const row = ((szBottom - plateZ) / szBottom) * (plateCF[0][0] - strikeZoneBox[1][0]) + strikeZoneBox[1][0];
    const col = 117 - (70 / 17) * plateX;
    return {
      row: row,
      col: col
    };
  }

  getClosestFrameIndexWithCoordinates(xyCoordinatesMap, currentIndex) {
    if (!xyCoordinatesMap || !currentIndex || !Object.keys(xyCoordinatesMap)) {
      return currentIndex;
    }
    let distance = Number.MAX_SAFE_INTEGER;
    let index = currentIndex;
    Object.keys(xyCoordinatesMap).forEach(key => {
      let abs = Math.abs(key - currentIndex);
      if (abs < distance) {
        distance = abs;
        index = key;
      }
    });
    return parseInt(index, 10);
  }

  getCurrentVideoPan() {
    return {
      up: this.getCurrentVideoPanUp(),
      left: this.getCurrentVideoPanLeft()
    };
  }

  getCurrentVideoPanLeft() {
    const { cameraSettings, currentPitchCoordinates, currentVideo, displaySz, videoZoomLeft } = this.gameViewerStore;
    if (displaySz || currentVideo.typeName === "Centerfield") {
      if (currentPitchCoordinates && currentPitchCoordinates.cameraPan) {
        return currentPitchCoordinates.cameraPan.videoZoomLeft;
      }
      return cameraSettings.videoZoomLeft ? cameraSettings.videoZoomLeft : videoZoomLeft;
    } else {
      return currentPitchCoordinates.sideview ? currentPitchCoordinates.sideview.videoPan[1] : videoZoomLeft;
    }
  }

  getCurrentVideoPanUp() {
    const { cameraSettings, currentPitchCoordinates, currentVideo, displaySz, videoZoomTop } = this.gameViewerStore;
    if (displaySz || currentVideo.typeName === "Centerfield") {
      if (currentPitchCoordinates && currentPitchCoordinates.cameraPan) {
        return currentPitchCoordinates.cameraPan.videoZoomTop;
      }
      return cameraSettings.videoZoomTop ? cameraSettings.videoZoomTop : this.videoZoomTop;
    } else {
      return currentPitchCoordinates.sideview ? currentPitchCoordinates.sideview.videoPan[0] : videoZoomTop;
    }
  }

  getFrameIndex() {
    const { videoRef } = this.gameViewerStore;
    if (!videoRef) {
      return;
    }
    let timeInSeconds = videoRef.currentTime;
    return Math.floor(timeInSeconds * 60);
  }

  getGameViewInformation(gamePk, umpireId) {
    this.loadingStore.setLoading(true, "Loading", "Loading pitch information", 75);
    this.zeApi.getGameViewerData(gamePk, umpireId).then(responses => {
      if (responses && responses.length > 3) {
        this.gameViewerStore.setPitchList(responses[0].data.entities);
        if (responses[0].data.entities) {
          this.gameViewerStore.setIsMilbGame(responses[0].data.entities[0].isMilb);
        }
        this.gameViewerStore.setUmpire(responses[1].data.entity);
        this.gameViewerStore.setGame(responses[2].data.entity);
        if (responses[2].data.entity.absMode && responses[2].data.entity.absMode === "fullABS") {
          this.gameViewerStore.setCorrectnessFilter({
            acceptable: false,
            correct: false,
            incorrect: false,
            edge: false
          });
        }
        this.gameViewerStore.setPitchCoordinates(responses[3].data.entity);
        if (responses[4].data.entity) {
          this.gameViewerStore.setCameraSettings(responses[4].data.entity.cameraPan);
        }
        if (responses[5]) {
          this.gameViewerStore.setHiddenVideos(responses[5]);
        }
      }
      this.loadingStore.setLoading(false, this.gameViewerStore.windowText);
      document.title = windowText;
    });
    this.zeApi.getAuditConfiguration(gamePk).then(data => {
      this.gameViewerStore.setAuditConfiguration(data);
    });
    this.zeApi.getAbsFeedbackReasons().then(data => {
      this.gameViewerStore.setAbsFeedback("reasons", data);
    });
  }

  getHhPitchCanvasCoordinates(pitch) {
    const { plateHHRowValues } = szCanvas;
    const xyzs = this.getPitchPositionsXYZ(pitch, [2, -0.5]);
    const colStart = 117 - (70 / 17) * (xyzs[0].x * 12);
    const colEnd = 117 - (70 / 17) * (xyzs[1].x * 12);
    return [
      {
        row: plateHHRowValues.start,
        col: colStart
      },
      {
        row: plateHHRowValues.end,
        col: colEnd
      }
    ];
  }

  getHfPitchCanvasCoordinates(pitch) {
    const { hfColValues, strikeZoneBox } = szCanvas;
    if (!pitch || !pitch.szBottom) {
      return;
    }
    const xyzs = this.getPitchPositionsXYZ(pitch, [2, -0.5]);
    const szTopInches = pitch.szTop * 12;
    const szBottomInches = pitch.szBottom * 12;
    const rows = xyzs.map(xyz => {
      if (!xyz) {
        return -1;
      } else if (xyz.z < pitch.szBottom) {
        return this.getHfPitchCanvasRowCoordinateBelowSzBottom(pitch, xyz);
      } else {
        const zInches = xyz.z * 12;
        return (
          ((szTopInches - zInches) / (szTopInches - szBottomInches)) * (strikeZoneBox[1][0] - strikeZoneBox[0][0]) +
          strikeZoneBox[0][0]
        );
      }
    });
    return [
      {
        row: rows[0],
        col: hfColValues.start
      },
      {
        row: rows[1],
        col: hfColValues.end
      }
    ];
  }

  getHfPitchCanvasRowCoordinateBelowSzBottom(pitch, xyz) {
    const { plateHF, strikeZoneBox } = szCanvas;
    const szBottom = pitch.szBottom * 12;
    const z = xyz.z * 12;
    return ((szBottom - z) / szBottom) * (plateHF[0][0] - strikeZoneBox[1][0]) + strikeZoneBox[1][0];
  }

  getPitchColor(pitch, defaultColor) {
    if (pitch.dispute && !pitch.dispute.resolution) {
      return "#9b30ff";
    } else if (pitch.deleted) {
      return "#000000";
    }
    switch (pitch.callCorrectness) {
      case "CORRECT":
        return "#058a15";
      case "ACCEPTABLE":
        return "#2b77eb";
      case "HIGH_BUFFER":
      case "LOW_CATCH":
      case "LOW_BUFFER":
      case "CATCHER_INFLUENCE":
        return "#fec11d";
      case "INCORRECT":
        return "#d5192e";
      default:
        return defaultColor ? defaultColor : "#000000";
    }
  }

  getPitchPositionsXYZ(pitch, yPositions) {
    let positions = [];
    if (!(pitch && yPositions)) {
      return positions;
    }
    yPositions.forEach(y => {
      const time = this.polynomialSolveForTime(pitch.initAccelY, pitch.initVelY, pitch.initPosY, y);
      positions.push(this.getPitchPositionXYZ(pitch, time));
    });
    return positions;
  }

  getPitchPositionXYZ(pitch, t, fixedY) {
    let position = {};
    position.x = this.polynomial(pitch.initAccelX, pitch.initVelX, pitch.initPosX, t);
    if (fixedY) {
      position.y = fixedY;
    } else {
      position.y = this.polynomial(pitch.initAccelY, pitch.initVelY, pitch.initPosY, t);
    }
    position.z = this.polynomial(pitch.initAccelZ, pitch.initVelZ, pitch.initPosZ, t);
    return position;
  }

  getPitchPositionXYZTrajectory(trajectory, t, fixedY) {
    let position = {};
    position.x = this.polynomial(trajectory.polynomialX[2], trajectory.polynomialX[1], trajectory.polynomialX[0], t);
    if (fixedY) {
      position.y = fixedY;
    } else {
      position.y = this.polynomial(trajectory.polynomialY[2], trajectory.polynomialY[1], trajectory.polynomialY[0], t);
    }
    position.z = this.polynomial(trajectory.polynomialZ[2], trajectory.polynomialZ[1], trajectory.polynomialZ[0], t);
    return position;
  }

  getVideo(type) {
    const { selectedPitch } = this.gameViewerStore;
    let currentVideo =
      selectedPitch && selectedPitch.videos ? selectedPitch.videos.find(v => v.typeName === type) : null;
    return currentVideo ? currentVideo : { rank: -1 };
  }

  getVideoRank(type) {
    switch (type) {
      case "Centerfield":
        return 1;
      case "TV":
        return 2;
      case "Side":
        return 3;
      case "SZ Frame":
        return 4;
      default:
        return 5;
    }
  }

  getVideoTypeName(type) {
    switch (type) {
      case "CAMERA_A":
      case "CAMERA_B":
        return "Side";
      case "BROADCAST":
        return "TV";
      case "CAMERA_C":
      case "CENTERFIELD":
      case "CF":
        return "Centerfield";
      case "PITCHCAST":
        return "Pitchcast";
      default:
        return type;
    }
  }

  getXyCoordinates(pitch) {
    if (!pitch.xyCoordinatesMap) {
      this.getXyPitch(pitch);
      this.getXySzBox(pitch, this.auditPcStore);
    }
  }

  getXyPitch(pitch) {
    const { rollDegrees, smoothingFrames } = this.gameViewerStore.auditConfiguration;
    this.zeApi
      .forwardProjectV2(
        this.gameViewerStore.gamePk,
        pitch.playId,
        pitch.xyzCoordinates.positions,
        smoothingFrames,
        rollDegrees
      )
      .then(xyCoordinatesMap => {
        if (xyCoordinatesMap) {
          let xyCoordinatesTransformed = {};
          Object.keys(xyCoordinatesMap).forEach(key => {
            xyCoordinatesTransformed[key] = xyCoordinatesMap[key].map(xy => this.convertToTopLeftOrigin(xy));
          });
          this.gameViewerStore.setXyCoordinatesMap({
            pitch: pitch,
            xyCoordinatesMap: xyCoordinatesTransformed
          });
        }
      });
  }

  getXySzBox(pitch) {
    const { rollDegrees, smoothingFrames } = this.gameViewerStore.auditConfiguration;
    let xyzSzCoordinates = this.getXyzSzCoordinates(pitch.szTop, pitch.szBottom);
    this.zeApi
      .forwardProjectV2(this.gameViewerStore.gamePk, pitch.playId, xyzSzCoordinates, smoothingFrames, rollDegrees)
      .then(xySzCoordinatesMap => {
        if (xySzCoordinatesMap) {
          let xySzConverted = {};
          Object.keys(xySzCoordinatesMap).forEach(key => {
            let xySzTransformed = xySzCoordinatesMap[key].map(xy => this.convertToTopLeftOrigin(xy));
            xySzConverted[key] = {
              bottomLeft: xySzTransformed[0],
              topLeft: xySzTransformed[1],
              topRight: xySzTransformed[2],
              bottomRight: xySzTransformed[3]
            };
          });
          this.gameViewerStore.setXySzCoordinatesMap({
            pitch: pitch,
            xySzCoordinatesMap: xySzConverted
          });
        }
      });
  }

  getXyzPitchArc(pitch, release, trajectory, lastMeasuredData) {
    let positions = [];
    if (!(pitch && release && trajectory)) {
      return positions;
    }

    let lastMeasuredY = 1.417;
    if (lastMeasuredData && lastMeasuredData.position) {
      lastMeasuredY = lastMeasuredData.position.y;
    }
    if (this.isCalledPitch(pitch) && lastMeasuredY >= -1) {
      lastMeasuredY = -1;
    }
    let end = {
      time: this.polynomialSolveForTime(
        trajectory.polynomialY[2],
        trajectory.polynomialY[1],
        trajectory.polynomialY[0],
        lastMeasuredY
      )
    };
    end.position = this.getPitchPositionXYZTrajectory(trajectory, end.time);
    let frontOfPlate = {
      time: this.polynomialSolveForTime(
        trajectory.polynomialY[2],
        trajectory.polynomialY[1],
        trajectory.polynomialY[0],
        1.417
      )
    };
    frontOfPlate.position = this.getPitchPositionXYZTrajectory(trajectory, frontOfPlate.time, 1.417);
    let backOfPlate = {
      time: this.polynomialSolveForTime(
        trajectory.polynomialY[2],
        trajectory.polynomialY[1],
        trajectory.polynomialY[0],
        0
      )
    };
    backOfPlate.position = this.getPitchPositionXYZTrajectory(trajectory, backOfPlate.time, 0);

    const interval = end.time / 50;
    let t = 0;
    let previousPosition;
    for (let i = 0; i <= 50; i++) {
      t += interval;
      let position = this.getPitchPositionXYZTrajectory(trajectory, t);
      if (previousPosition) {
        if (this.isInBetween(previousPosition.y, position.y, frontOfPlate.position.y)) {
          positions.push(frontOfPlate.position);
        } else if (this.isInBetween(previousPosition.y, position.y, backOfPlate.position.y)) {
          positions.push(backOfPlate.position);
        }
      }
      positions.push(position);
      previousPosition = position;
    }

    return {
      positions: positions,
      frontOfPlate: frontOfPlate,
      backOfPlate: backOfPlate
    };
  }

  getXyzSzCoordinates(szTop, szBottom) {
    const { firstBaseFront, thirdBaseFront } = plateCorners;

    let coordinates = [];
    coordinates.push(
      {
        x: firstBaseFront.x,
        y: firstBaseFront.y,
        z: szBottom
      },
      {
        x: firstBaseFront.x,
        y: firstBaseFront.y,
        z: szTop
      },
      {
        x: thirdBaseFront.x,
        y: thirdBaseFront.y,
        z: szTop
      },
      {
        x: thirdBaseFront.x,
        y: thirdBaseFront.y,
        z: szBottom
      }
    );
    return coordinates;
  }

  handPitchFilter(pitch) {
    switch (this.gameViewerStore.handFilter) {
      case "Right":
        return pitch.batterSide === "R" || pitch.batterSide === "Right";
      case "Left":
        return pitch.batterSide === "L" || pitch.batterSide === "Left";
      default:
        return true;
    }
  }

  gameContextFilter(pitch) {
    const { gameContexts } = this.gameViewerStore;
    if (gameContexts.length) {
      return gameContexts.every(
        gc =>
          (gc.value === "closeAndLate" && this.closeAndLate(pitch)) ||
          (gc.value === "decidesAtBat" && this.decidesAtBat(pitch))
      );
    }
    return true;
  }

  closeAndLate(pitch) {
    if (pitch.inning >= 7) {
      const runDifference = Math.abs(
        pitch.topOfInning ? pitch.awayScore - pitch.homeScore : pitch.homeScore - pitch.awayScore
      );
      return runDifference <= 1 || pitch.runnersOnBase + 1 >= runDifference;
    }
    return false;
  }

  decidesAtBat(pitch) {
    return (
      (pitch.eventType === "ball" && pitch.balls === 3) || (pitch.eventType === "called_strike" && pitch.strikes === 2)
    );
  }

  getSzPredict(selectedPitch) {
    const { gamePk, umpireId, isMilbGame } = this.gameViewerStore;
    if (!selectedPitch || isMilbGame) {
      return;
    }
    const request = {
      balls: selectedPitch.balls,
      gamePk: gamePk,
      strikes: selectedPitch.strikes,
      umpireId: umpireId,
      batterSide: selectedPitch.batterSide,
      playId: selectedPitch.playId,
      plateX: selectedPitch.plateX,
      plateZ: selectedPitch.plateZ,
      szBottom: selectedPitch.szBottom,
      szTop: selectedPitch.szTop
    };
    this.zeApi.getSzPredict(request).then(data => {
      const result = {
        predict: data.predict,
        overallPredict: data.overallPredict
      };
      this.gameViewerStore.setSzPredict(selectedPitch.playId, result);
    });
  }

  googleAnalyticsEvent() {
    const isUmpire = this.authStore.isRegularUmpire;
    if (isUmpire && this.gameViewerStore.currentVideo) {
      ReactGA.event({
        category: "Game Viewer",
        action: "videoSelect - " + this.gameViewerStore.currentVideo.typeName
      });
    }
  }

  hexToRgba(hex, opacity) {
    hex = hex.replace("#", "");
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return "rgba(" + r + "," + g + "," + b + "," + opacity + ")";
  }

  hotkeyFocusFilter() {
    return document.activeElement.tagName !== "INPUT";
  }

  isAdjusted(pitch) {
    switch (pitch.callCorrectness) {
      case "HIGH_BUFFER":
      case "LOW_CATCH":
      case "LOW_BUFFER":
      case "CATCHER_INFLUENCE":
        return true;
      default:
        return false;
    }
  }

  isCalledPitch(pitch) {
    return pitch && (pitch.umpireCall === "B" || pitch.umpireCall === "*B" || pitch.umpireCall === "C");
  }

  isInBetween(boundA, boundB, position) {
    return (boundA <= position && position <= boundB) || (boundB <= position && position <= boundA);
  }

  mapIncorrects(boxScore, inningsWithPitches) {
    let returnObj = {};
    if (boxScore) {
      returnObj.awayTotals = boxScore.teams.away;
      returnObj.homeTotals = boxScore.teams.home;
    }
    if (inningsWithPitches && inningsWithPitches.length) {
      returnObj.innings = boxScore.innings.map(bs => {
        let top = inningsWithPitches.find(i => i.inning === bs.num && i.top);
        let bottom = inningsWithPitches.find(i => i.inning === bs.num && !i.top);
        bs.away.hasIncorrects = top ? top.hasIncorrects : false;
        bs.home.hasIncorrects = bottom ? bottom.hasIncorrects : false;
        bs.away.atbats = top ? top.atbats : [];
        bs.home.atbats = bottom ? bottom.atbats : [];
        return bs;
      });
    } else {
      returnObj.innings = boxScore.innings.map(bs => {
        bs.home.hasIncorrects = false;
        bs.away.hasIncorrects = false;
        bs.away.atbats = [];
        bs.home.atbats = [];
        return bs;
      });
    }
    return returnObj;
  }

  moveKeyframe() {
    const { gamePk, keyframeOffset, selectedPitch } = this.gameViewerStore;
    this.alertStore.addAlert({
      type: AlertConstants.TYPES.SUCCESS,
      text: "Cutting new keyframe."
    });
    const feedType = this.getVideo("Pitchcast").rank >= 0 ? "PITCHCAST" : "CF";
    this.zeApi.moveKeyframe(gamePk, selectedPitch.playId, keyframeOffset, feedType).then(
      () => {
        this.alertStore.addAlert({
          type: AlertConstants.TYPES.SUCCESS,
          text: "Keyframe successfully cut."
        });
        this.gameViewerStore.setKeyframeUpdateTs(Date.now());
      },
      () => {
        this.alertStore.addAlert({
          type: AlertConstants.TYPES.WARNING,
          text: "An unexpected error occurred while cutting the new keyframe."
        });
      }
    );
  }

  nameMatch(name, filter) {
    let lowercase = name.toLowerCase();
    let split = lowercase.split(" ");
    let first = split[0];
    let last = split[1];
    let fullNameMatch = this.startOfStringMatch(filter, lowercase);
    let firstNameMatch = this.startOfStringMatch(filter, first);
    let lastNameMatch = this.startOfStringMatch(filter, last);
    return fullNameMatch || firstNameMatch || lastNameMatch;
  }

  nextPitch() {
    const { pitchListFiltered, selectedPitchIndex } = this.gameViewerStore;
    const index = selectedPitchIndex + 1;
    if (index < pitchListFiltered.length) {
      this.gameViewerStore.setSelectedPitch(pitchListFiltered[index], true);
    }
  }

  pitchCallCorrectnessFilterResults(pitch) {
    const { incorrect, acceptable, correct, edge } = this.gameViewerStore.correctnessFilter;
    if (!(incorrect || acceptable || correct || edge)) {
      return true;
    }
    if (pitch.deleted) {
      return false;
    }
    if (edge) {
      if (pitch.edgeDistance && pitch.edgeDistance < 3) {
        return true;
      }
    }
    switch (pitch.callCorrectness) {
      case "INCORRECT":
      case "CATCHER_INFLUENCE":
      case "HIGH_BUFFER":
      case "LOW_BUFFER":
      case "LOW_CATCH":
        return incorrect;
      case "ACCEPTABLE":
        return acceptable;
      case "CORRECT":
        return correct;
      default:
        return false;
    }
  }

  pitchCalledFilterResults(pitch) {
    switch (this.gameViewerStore.calledFilter) {
      case "all":
        return true;
      case "challenged":
        if (pitch.reviewType) {
          return true;
        } else {
          break;
        }
      case "called":
        switch (pitch.umpireCall) {
          case "BALL":
          case "BLOCKED_BALL":
          case "CALLED_STRIKE":
          case "PITCHOUT":
            return true;
          default:
            break;
        }
        break;
      case "feedback":
        return pitch.absFeedback;
      default:
        return false;
    }
  }

  pitcherFilterResults(pitch, filter) {
    if (!filter.length) {
      return true;
    }
    for (let idx = 0; idx < filter.length; idx++) {
      let filterElem = filter[idx];
      if (filterElem.value === pitch.pitcherId) {
        return true;
      }
    }
    return false;
  }

  pitchIsIncorrectOrAdjusted(pitch) {
    const incorrectCalls = ["INCORRECT", "LOW_BUFFER", "LOW_CATCH", "HIGH_BUFFER", "CATCHER_INFLUENCE"];
    return incorrectCalls.indexOf(pitch.callCorrectness) >= 0 && !pitch.deleted;
  }

  pitchTextFilterResults(pitch) {
    const { pitchListFilter } = this.gameViewerStore;
    if (pitchListFilter.length) {
      let filters = pitchListFilter.toLowerCase().split(",");
      let match = false;
      filters.forEach(searchTerm => {
        searchTerm = StringUtil.removeLeadingAndTrailingWhiteSpaces(searchTerm);
        if (searchTerm.includes("ab")) {
          let atBatNumber = searchTerm.substring(2);
          let atBatNumberMatch = pitch.atBatNumber.toString() === atBatNumber;
          if (atBatNumberMatch) {
            match = true;
          }
        } else if (!isNaN(searchTerm)) {
          let pitchNumberMatch = pitch.pitchNumber.toString() === searchTerm;
          if (pitchNumberMatch) {
            match = true;
          }
        } else if (searchTerm.length === 1 || searchTerm.length === 2) {
          let callMatch = this.startOfStringMatch(searchTerm, this.getCall(pitch.umpireCall).toLowerCase());
          let typeMatch = pitch.pitchType.toLowerCase() === searchTerm;
          if (callMatch || typeMatch) {
            match = true;
          }
        } else if (searchTerm.length >= 3) {
          let batterNameMatch = this.nameMatch(pitch.batterName, searchTerm);
          let pitcherNameMatch = this.nameMatch(pitch.pitcherName, searchTerm);
          let evaluationMatch = this.startOfStringMatch(searchTerm, pitch.callCorrectness.toLowerCase());
          if (batterNameMatch || pitcherNameMatch || evaluationMatch) {
            match = true;
          }
        }
      });
      return match;
    } else {
      return true;
    }
  }

  playerFilterOptions(game, players) {
    const away = {
      label: game.awayTeam ? game.awayTeam.name : "Away",
      options: Object.values(players)
        .filter(b => b.away)
        .sort((a, b) => ("" + a.label).localeCompare(b.label))
    };
    const home = {
      label: game.homeTeam ? game.homeTeam.name : "Away",
      options: Object.values(players)
        .filter(b => !b.away)
        .sort((a, b) => ("" + a.label).localeCompare(b.label))
    };
    return [away, home];
  }

  playPauseHandler() {
    const { videoRef } = this.gameViewerStore;
    if (videoRef) {
      videoRef.paused ? videoRef.play() : videoRef.pause();
    }
  }

  polynomial(a, b, c, t) {
    return a * Math.pow(t, 2) + b * t + c;
  }

  polynomialSolveForTime(a, b, c, y) {
    // convert to form a(t^2) + bt + c = 0
    c -= y;
    // now solve for t
    return -((b + Math.sqrt(Math.pow(b, 2) - 4 * a * c)) / (2 * a));
  }

  preload() {
    const { pitchListFiltered, selectedPitchIndex } = this.gameViewerStore;
    if (selectedPitchIndex < 0 || selectedPitchIndex >= pitchListFiltered.length - 1) {
      return;
    }
    let count = 1;
    for (let idx = selectedPitchIndex + 1; count <= 2 && idx < pitchListFiltered.length; idx++) {
      const pitch = pitchListFiltered[idx];
      this.getAllCoordinates(pitch);
      count++;
    }
  }

  previousPitch() {
    const { pitchListFiltered, selectedPitchIndex } = this.gameViewerStore;
    const index = selectedPitchIndex - 1;
    if (index >= 0) {
      this.gameViewerStore.setSelectedPitch(pitchListFiltered[index], true);
    }
  }

  resetFilters() {
    this.gameViewerStore.setBatterFilter(this.gameViewerStore.defaults["batterFilter"]);
    this.gameViewerStore.setPitcherFilter(this.gameViewerStore.defaults["pitcherFilter"]);
    this.gameViewerStore.setCalledFilter(this.gameViewerStore.defaults["calledFilter"]);
    this.gameViewerStore.setCorrectnessFilter(this.gameViewerStore.defaults["correctnessFilter"]);
    this.gameViewerStore.setOpenAtBats(observable.map());
    this.gameViewerStore.setPitchListFilter(this.gameViewerStore.defaults["pitchListFilter"]);
    this.gameViewerStore.setHandFilter(this.gameViewerStore.defaults["handFilter"]);
    this.gameViewerStore.setSelectedBoxScore(this.gameViewerStore.defaults["selectedBoxScore"]);
  }

  saveAbsFeedback() {
    let pitch = this.gameViewerStore.selectedPitch;
    const feedback = {
      id: pitch?.absFeedback?.id,
      gamePk: this.gameViewerStore.gamePk,
      playId: this.gameViewerStore.selectedPitch.playId,
      statsapiUmpireId: this.gameViewerStore.umpireId,
      description: this.gameViewerStore.absFeedback.description.trim(),
      reasonId: this.gameViewerStore.absFeedback.reasonId
    };
    this.loadingStore.setLoading(true, "Saving", "Saving feedback", 75);
    this.zeApi
      .saveAbsFeedback(feedback)
      .then(savedFeedback => {
        if (this.gameViewerStore.showFeedbackPopover) {
          this.gameViewerStore.toggleFeedbackPopover();
        }
        pitch = {
          ...pitch,
          absFeedback: savedFeedback
        };
        this.gameViewerStore.setPitchInList(pitch);
        // re-select pitch to trigger re-render of pitch list elements on-screen
        this.gameViewerStore.setSelectedPitch(pitch);
        this.gameViewerStore.setAbsFeedback("reasonId", this.gameViewerStore.defaults["absFeedback"].reasonId);
        this.gameViewerStore.setAbsFeedback("description", this.gameViewerStore.defaults["absFeedback"].description);
      })
      .finally(() => {
        this.loadingStore.setLoading(false);
      });
  }

  selectedBoxScoreFilter(pitch) {
    if (this.gameViewerStore.selectedBoxScore.length) {
      let isTop = this.gameViewerStore.selectedBoxScore.includes("T");
      let inning = parseInt(this.gameViewerStore.selectedBoxScore.substring(1), 10);
      return pitch.topOfInning === isTop && pitch.inning === inning;
    } else {
      return true;
    }
  }

  setCanvasPositions(pitch) {
    pitch.canvasPositions = {
      centerfield: this.getCfPitchCanvasCoordinate(pitch),
      highFirst: this.getHfPitchCanvasCoordinates(pitch),
      highHome: this.getHhPitchCanvasCoordinates(pitch)
    };
  }

  startOfStringMatch(filter, value) {
    let substring = value.substring(0, filter.length);
    return filter === substring;
  }

  stepBackward() {
    let { videoRef } = this.gameViewerStore;
    if (videoRef && videoRef.duration) {
      videoRef.currentTime = Math.max(videoRef.currentTime - VideoConstants.ONE_FRAME, 0);
    }
  }

  stepForward() {
    let { videoRef } = this.gameViewerStore;
    if (videoRef && videoRef.duration) {
      videoRef.currentTime = Math.min(videoRef.currentTime + VideoConstants.ONE_FRAME, videoRef.duration);
    }
  }

  updateCallCorrectness(gamePk, playId, callCorrectness) {
    this.zeApi.updateCallCorrectness(gamePk, playId, callCorrectness).then(data => {
      this.gameViewerStore.updatePitchFromCallCorrectness(data);
      this.gameViewerStore.adjustPitchPopoverOpen = false;
    });
  }

  getVideoCfOrPitchcast() {
    const { currentPitchCoordinates } = this.gameViewerStore;
    const cf = this.getVideo("Centerfield");
    const pitchcast = this.getVideo("Pitchcast");
    if (cf.rank === -1) {
      return pitchcast;
    } else if (pitchcast.rank === -1 || currentPitchCoordinates.centerfield) {
      return cf;
    } else {
      return pitchcast;
    }
  }

  getVideoBroadcast() {
    return this.getVideo("TV");
  }
}
