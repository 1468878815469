import React from "react";
import styled from "styled-components";
import { inject, observer } from "mobx-react";
import { CanvasUtilities } from "../../../../utilities/CanvasUtilities";
import ReactAnimationFrame from "react-animation-frame";

const CanvasHolder = styled.div`
  position: relative;
  z-index: 1;
`;

const ContentHolder = styled.div`
  &&& {
    height: ${props => props.height}px;
    width: ${props => props.width}px;
    position: relative;
    overflow: hidden;
  }
`;

const SzHolder = styled.div`
  height: ${props => props.height}px;
  width: ${props => props.width}px;
`;

const SZ = styled.img`
  &&& {
    height: ${props => props.height}px;
    width: ${props => props.width}px;
    position: absolute;
  }
`;

const Canvas = styled.canvas.attrs({
  style: props => ({
    top: props.panDown + "px",
    bottom: props.panDown + "px",
    left: props.panRight + "px",
    right: props.panRight + "px"
  })
})`
  position: absolute;
  z-index: 1;
`;

class GameViewerPitchcastSz extends React.Component {
  constructor(props) {
    super(props);
    this.draw = this.draw.bind(this);
  }

  componentDidMount() {
    this.ctx = this.canvas.getContext("2d");
  }

  clear() {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
  }

  draw() {
    const { expanded, rootStore } = this.props;
    const kneeWaist = rootStore.gameViewerStore.szPitchcastKneeWaist;
    if (!kneeWaist || !kneeWaist.kneeLine || !kneeWaist.waistLine) {
      return;
    }
    const width = expanded ? 960 : 480;
    const scale = width / 1920;
    CanvasUtilities.drawLinePointToPointXYScaled(
      this.ctx,
      ...kneeWaist.kneeLine,
      CanvasUtilities.COLORS.RED,
      false,
      scale
    );
    CanvasUtilities.drawLinePointToPointXYScaled(
      this.ctx,
      ...kneeWaist.waistLine,
      CanvasUtilities.COLORS.RED,
      false,
      scale
    );
  }

  onAnimationFrame(time) {
    if (this.ctx) {
      this.clear();
      this.draw();
    }
  }

  render() {
    const { expanded, rootStore } = this.props;
    const { gameViewerStore } = rootStore;
    const { selectedPitch, keyframeUpdateTs } = gameViewerStore;
    const width = expanded ? 960 : 480;
    const height = expanded ? 540 : 270;
    const scale = width / 960;
    let panDown = selectedPitch && selectedPitch.keyframe ? selectedPitch.keyframe.calibrationPanDown : 0;
    if (Number.isNaN(panDown)) {
      panDown = 0;
    }
    let panRight = selectedPitch && selectedPitch.keyframe ? selectedPitch.keyframe.calibrationPanRight : 0;
    if (Number.isNaN(panRight)) {
      panRight = 0;
    }
    return (
      <div>
        <CanvasHolder width={width} height={height}>
          <Canvas
            {...this.props}
            innerRef={c => {
              this.canvas = c;
            }}
            panDown={panDown * scale}
            panRight={panRight * scale}
            height={height}
            width={width}
          />
          {selectedPitch && selectedPitch.pathToSZKeyframe ? (
            <ContentHolder width={width} height={height}>
              <SZ
                src={selectedPitch.pathToSZKeyframe + "?nocache=" + Date.now()}
                keyframeUpdateTs={keyframeUpdateTs}
                height={height}
                width={width}
              />
            </ContentHolder>
          ) : (
            <SzHolder />
          )}
        </CanvasHolder>
      </div>
    );
  }
}

export default inject("rootStore")(ReactAnimationFrame(observer(GameViewerPitchcastSz)));
