import { action, autorun, observable, extendObservable, computed } from "mobx";
import queryString from "query-string";
import { AlertConstants } from "../components/common/alert/AlertConstants";
import Moment from "moment";
import { DateConstants } from "../components/common/constants/DateConstants";

class ScoreSheetStore {
  constructor(routerStore, zeApi, loadingStore, alertStore, authStore) {
    this.routerStore = routerStore;
    this.zeApi = zeApi;
    this.loadingStore = loadingStore;
    this.alertStore = alertStore;
    this.authStore = authStore;

    this.defaults = {
      scoreSheet: observable({}),
      gamePk: -1,
      umpireId: -1,
      editing: false,
      needCategory: -1,
      cancelModalOpen: false
    };

    extendObservable(this, {
      scoreSheet: this.defaults["scoreSheet"],
      gamePk: this.defaults["gamePk"],
      umpireId: this.defaults["umpireId"],
      editing: this.defaults["editing"],
      needCategory: this.defaults["needCategory"],
      cancelModalOpen: this.defaults["cancelModalOpen"],
      setScoresheet: action(data => {
        if (!data.comments) {
          data.comments = "";
        }
        this.scoreSheet = observable(data);
      }),
      setGamePk: action(gamePk => {
        this.gamePk = gamePk;
      }),
      getScoresheet: action(checkMode => {
        this.zeApi.getScoresheet(this.gamePk, this.umpireId).then(data => {
          this.setScoresheet(data);
          this.recalculateTotals();
          this.loadingStore.setLoading(false);
          this.loadingStore.setWindowTitle(this.windowText);
          if (checkMode) {
            document.title = this.windowText;
            this.editing = !data.released && this.authStore.isAuditorAdmin;
          }
        });
      }),
      updateFromUrlParams: action(search => {
        this.setScoresheet({});
        this.loadingStore.setLoading(true, "Loading", "Loading Scoresheet", 75);
        const params = queryString.parse(search);
        this.gamePk = params["gamePk"] || this.defaults["gamePk"];
        this.umpireId = params["umpireId"] || this.defaults["umpireId"];
        this.getScoresheet(true);
      }),
      updateCall: action((type, index) => {
        this.scoreSheet.gameAnalysis.callQualitySummary.incorrectCalls[index].call = type;
        this.recalculateTotals();
      }),
      updateScoresheet: action(() => {
        this.loadingStore.setLoading(true, "Saving", "Saving Scoresheet", 75);
        this.zeApi.updateScoresheet(this.scoreSheet).then(data => {
          this.editing = false;
          this.alertStore.addAlert({
            type: AlertConstants.TYPES.SUCCESS,
            text: "Successfully Saved Scoresheet"
          });
          this.getScoresheet(false);
        });
      }),
      updateComments: action(comments => {
        this.scoreSheet.comments = comments;
      }),
      releaseScoresheet: action(() => {
        this.loadingStore.setLoading(true, "Loading", "Releasing Scoresheet", 75);
        this.zeApi.releaseScoresheet(this.gamePk, this.umpireId).then(data => {
          this.editing = false;
          this.getScoresheet(false);
          this.alertStore.addAlert({
            type: AlertConstants.TYPES.SUCCESS,
            text: "Successfully Released Scoresheet"
          });
          this.goToScheduleForGameDate();
        });
      }),
      unreleaseScoresheet: action(() => {
        this.loadingStore.setLoading(true, "Loading", "Unreleasing Scoresheet", 75);
        this.zeApi.unreleaseScoresheet(this.gamePk, this.umpireId).then(data => {
          this.editing = true;
          this.getScoresheet(false);
          this.alertStore.addAlert({
            type: AlertConstants.TYPES.SUCCESS,
            text: "Successfully Unreleased Scoresheet"
          });
        });
      }),
      recalculateTotals: action(() => {
        if (this.scoreSheet.gameAnalysis) {
          let adjusted = 0;
          let needCategory = 0;

          let incorrectCalls = this.scoreSheet.gameAnalysis.callQualitySummary.incorrectCalls || [];
          incorrectCalls.forEach(call => {
            if (call.call) {
              if (
                call.call === "strike_highBuffer" ||
                call.call === "strike_lowBuffer" ||
                call.call === "ball_catcherInfluence" ||
                call.call === "ball_lowCatch"
              ) {
                adjusted++;
              }
            } else {
              needCategory++;
            }
          });
          this.scoreSheet.gameAnalysis.callQualitySummary.pitchesAdjusted = adjusted;
          this.needCategory = needCategory;
        }
      }),
      windowText: computed(() => {
        return (
          "SC " +
          this.scoreSheet.homeTeamAbbrev +
          " " +
          (this.scoreSheet.displayGameTime
            ? Moment(this.scoreSheet.displayGameTime).format(DateConstants.DATE_FORMAT_WITH_TIME)
            : "")
        );
      }),
      scores: computed(() => {
        if (!this.scoreSheet.gameAnalysis) {
          return {
            raw: 0,
            adjusted: 0
          };
        }
        let raw =
          (this.scoreSheet.gameAnalysis.callQualitySummary.correctPitchesCalled +
            this.scoreSheet.gameAnalysis.callQualitySummary.acceptablePitchesCalled) /
          this.scoreSheet.gameAnalysis.callQualitySummary.totalPitchesCalled;

        let adjusted =
          (this.scoreSheet.gameAnalysis.callQualitySummary.correctPitchesCalled +
            this.scoreSheet.gameAnalysis.callQualitySummary.acceptablePitchesCalled +
            this.scoreSheet.gameAnalysis.callQualitySummary.pitchesAdjusted) /
          this.scoreSheet.gameAnalysis.callQualitySummary.totalPitchesCalled;
        return {
          raw: (raw * 100).toFixed(2),
          adjusted: (adjusted * 100).toFixed(2)
        };
      })
    });

    autorun(() => {
      const { isLoggedIn } = this.authStore;
      if (isLoggedIn && this.routerStore.location.pathname === "/scoresheet") {
        this.updateFromUrlParams(this.routerStore.location.search);
      }
    });
  }

  goToScheduleForGameDate = () => {
    let gameDateStr = Moment(this.scoreSheet.displayGameTime).format(DateConstants.SCHEDULE_DATE_FORMAT);
    this.routerStore.history.push({
      pathname: "/schedule",
      search: "date=" + gameDateStr
    });
  };
}

export default ScoreSheetStore;
