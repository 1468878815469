import React from "react";
import { inject, observer } from "mobx-react";
import DatePickerInput from "../../common/form/DatePickerInput";

class ScheduleFilters extends React.Component {
  constructor(props) {
    super(props);
    this.setDate = this.setDate.bind(this);
  }

  setDate(event) {
    if (event) {
      this.props.rootStore.scheduleStore.updateUrl(event._d);
    }
  }

  render() {
    const {
      rootStore: { scheduleStore }
    } = this.props;

    return (
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-4">
          <div className="row">
            <label className="col-12 col-form-label">Date</label>
            <div className="col-12">
              <DatePickerInput date={scheduleStore.date} onChange={this.setDate} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default inject("rootStore")(observer(ScheduleFilters));
