import React from "react";
import styled from "styled-components";

const ModalHeaderDiv = styled.div.attrs({
  className: "modal-header"
})`
  background-color: #ececec;
`;

class ModalHeader extends React.Component {
  render() {
    return <ModalHeaderDiv>{this.props.children}</ModalHeaderDiv>;
  }
}

export default ModalHeader;
