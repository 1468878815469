import React from "react";
import styled from "styled-components";
import GameViewerHeaderUmpireDetails from "./GameViewerHeaderUmpireDetails";
import GameViewerHeaderUmpireTable from "./GameViewerHeaderUmpireTable";

const ContentHolder = styled.div`
  overflow: auto;
  border-left: 1px solid #d2d2d2;
  min-width: 487px;
`;

const UmpireDetailsDiv = styled.div`
  margin-left: 28px;
  margin-top: 8px;
`;

const UmpireTableDiv = styled.div.attrs({
  className: "mr-3"
})`
  padding-top: 9px;
`;

const GameViewerHeaderUmpireInfo = () => (
  <ContentHolder className="align-middle d-flex flex-row justify-content-between">
    <UmpireDetailsDiv>
      <GameViewerHeaderUmpireDetails />
    </UmpireDetailsDiv>
    <UmpireTableDiv>
      <GameViewerHeaderUmpireTable />
    </UmpireTableDiv>
  </ContentHolder>
);

export default GameViewerHeaderUmpireInfo;
