import createHistory from "history/createBrowserHistory";
import { syncHistoryWithStore } from "mobx-react-router";
import DisputeStore from "./DisputeStore";
import AuditStore from "./audit/AuditStore";
import AuditPcStore from "./audit/AuditPcStore";
import AuthStore from "./AuthStore";
import AuthApi from "../httpClients/AuthApi";
import ZeApi from "../httpClients/ZeApi";
import LoadingStore from "./LoadingStore";
import AlertStore from "./AlertStore";
import ScheduleStore from "./ScheduleStore";
import zeRouterStore from "./zeRouterStore";
import ScoreSheetStore from "./ScoreSheetStore";
import GameViewerStore from "./gameViewer/GameViewerStore";
import ReportStore from "./ReportStore";
import AuditS3Api from "../httpClients/AuditS3Api";
import S3Api from "../httpClients/S3Api";
import GameSummaryStore from "./GameSummaryStore";
import CommonStore from "./CommonStore";
import ReactGA from "react-ga";

class RootStore {
  constructor() {
    this.constructApis();
    this.constructStores();
  }

  constructApis() {
    this.s3Api = new S3Api();
    this.auditS3Api = new AuditS3Api(this.s3Api);
    this.authApi = new AuthApi();
    this.zeApi = new ZeApi(this.auditS3Api);
  }

  constructStores() {
    this.routerStore = new zeRouterStore();
    const routerHistory = createHistory();
    routerHistory.listen((location, action) => {
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);
    });
    this.history = syncHistoryWithStore(routerHistory, this.routerStore);
    this.loadingStore = new LoadingStore();
    this.alertStore = new AlertStore();
    this.commonStore = new CommonStore();
    this.authStore = new AuthStore(this.routerStore, this.authApi);
    this.scheduleStore = new ScheduleStore(
      this.routerStore,
      this.zeApi,
      this.loadingStore,
      this.authStore,
      this.alertStore
    );
    this.disputeStore = new DisputeStore(this.routerStore, this.zeApi, this.loadingStore, this.authStore);
    this.auditStore = new AuditStore(
      this.routerStore,
      this.zeApi,
      this.loadingStore,
      this.authStore,
      this.alertStore,
      this.tmS3Api
    );
    this.auditPcStore = new AuditPcStore(
      this.routerStore,
      this.zeApi,
      this.loadingStore,
      this.authStore,
      this.alertStore
    );
    this.scoreSheetStore = new ScoreSheetStore(
      this.routerStore,
      this.zeApi,
      this.loadingStore,
      this.alertStore,
      this.authStore
    );
    this.gameViewerStore = new GameViewerStore(
      this.authStore,
      this.routerStore,
      this.zeApi,
      this.loadingStore,
      this.alertStore
    );
    this.reportStore = new ReportStore(this.routerStore, this.zeApi, this.loadingStore, this.alertStore);
    this.gameSummaryStore = new GameSummaryStore(
      this.routerStore,
      this.zeApi,
      this.loadingStore,
      this.alertStore,
      this.authStore
    );
  }
}

export default RootStore;
