import { observer } from "mobx-react";
import React from "react";
import styled from "styled-components";
import PcPitchCanvas from "../strikeZone/PcPitchCanvas";
import OnOff from "../../common/display/OnOff";
import ZeLightButton from "../../elements/form/ZeLightButton";
const ContentWrapper = styled.div`
  width: 960px;
  height: 540px;
  position: relative;
  overflow: hidden;
`;

const Flag = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

const FlagInfo = styled.div`
  left: 865px;
  top: 0;
  width: 110px;
  z-index: 3;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 10px;
  font-size: 12px;
  color: white;
`;

const Loading = styled.div`
  z-index: 4;
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
`;

const Spinner = styled.i.attrs({
  className: "fa fa-3x fa-pulse fa-spinner"
})`
  left: 492px;
  top: 222px;
  color: #ffffff;
  position: absolute;
`;

const StepIcon = ZeLightButton.extend.attrs({
    className: "btn-sm"
})`
  z-index: 3;
  font-color: #2b77eb !important;
  position: absolute;
  bottom: 10px;
  right: 70px;
  color: #2b77eb;
`;

const Video = styled.video`
  position: absolute;
  left: 0;
  background-color: #666666;
`;

const VideoInfo = styled.div`
  left: 15px;
  top: 0;
  z-index: 3;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 10px;
  color: white;
  font-size: 12px;
`;

const Wrapper = styled.div`
  width: 960px;
  height: 540px;
`;

class AuditPcPitchVideo extends React.Component {
  render() {
    const { auditPcStore, videoRef } = this.props;
    let selectedPitch = auditPcStore.selectedPitch;
    let { szTop, szBottom } = selectedPitch;
    let pitchCoordinatesMap = selectedPitch ? selectedPitch.xyCoordinatesMap : {};

    return (
      <Wrapper>
        {szTop && szBottom ? (
          <VideoInfo>
            <div>
              <div>SZ Top: {Number.parseFloat(szTop).toPrecision(4)}</div>
              <div>SZ Bottom: {Number.parseFloat(szBottom).toPrecision(4)}</div>
            </div>
          </VideoInfo>
        ) : null}
        <FlagInfo
          onClick={event => {
            event.preventDefault();
            event.stopPropagation();
          }}
        >
          <Flag onClick={auditPcStore.toggleAutoPaste}>
            Auto Paste: <OnOff check={auditPcStore.autoPaste} />
          </Flag>
          <Flag onClick={auditPcStore.toggleAutoSave}>
            Auto Save: <OnOff check={auditPcStore.autoSave} />
          </Flag>
        </FlagInfo>
        <ContentWrapper>
          {auditPcStore.loading ? (
            <Loading>
              <Spinner />
            </Loading>
          ) : null}

          <PcPitchCanvas id="c" height="540" width="960" loading={auditPcStore.loading} />
          {selectedPitch && selectedPitch.videos && selectedPitch.videos[0].link ? (
            <Video
              controls={false}
              innerRef={videoRef}
              src={
                selectedPitch && selectedPitch.videos && selectedPitch.videos[0].link
                  ? selectedPitch.videos[0].link
                  : null
              }
              timeupdate={auditPcStore.updateProgressBar}
              pitchCoordinatesMap={pitchCoordinatesMap}
              height="540"
              width="960"
            />
          ) : null}
        <StepIcon onClick={auditPcStore.increaseStepSize}>{auditPcStore.stepSize}px Step</StepIcon>
        </ContentWrapper>
        {auditPcStore.preloadVideos.map(video => (
          <video src={video.link} preload="auto" hidden key={video.link} />
        ))}
      </Wrapper>
    );
  }
}

export default observer(AuditPcPitchVideo);
