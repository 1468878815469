import React from "react";
import { inject, observer } from "mobx-react";
import ScoresheetBodyView from "./view/ScoresheetBodyView";
import ScoresheetBodyEditing from "./edit/ScoresheetBodyEditing";
import ZeLabel from "../../elements/form/ZeLabel";
import styled from "styled-components";
import { AuthConstants } from "../../login/AuthConstants";

const auditorAdminRoles = [AuthConstants.USER_ROLES.ZE_SUPER_ADMIN, AuthConstants.USER_ROLES.ZE_AUDITOR_ADMIN];

const ScoresheetComments = styled.textarea.attrs({
  className: "form-control",
  rows: "3"
})`
  &:disabled {
    background-color: #f7f7f7 !important;
  }
`;

class ScoresheetBody extends React.Component {
  constructor(props) {
    super(props);
    this.updateComments = this.updateComments.bind(this);
  }

  updateComments(event) {
    this.props.rootStore.scoreSheetStore.updateComments(event.target.value);
  }

  render() {
    const {
      rootStore: { authStore, scoreSheetStore }
    } = this.props;
    const scoresheet = scoreSheetStore.scoreSheet;
    const isAuditorAdmin = authStore.containsAuthorities(auditorAdminRoles);
    return (
      <div className="row">
        <div className="col">
          <div className="row">
            <div className="col">{scoreSheetStore.editing ? <ScoresheetBodyEditing /> : <ScoresheetBodyView />}</div>
          </div>

          {isAuditorAdmin ? (
            <div className="row mt-1">
              <div className="col">
                <div className="form-group">
                  <ZeLabel>Comments</ZeLabel>
                  <ScoresheetComments
                    disabled={!scoreSheetStore.editing}
                    value={scoresheet.comments}
                    onChange={this.updateComments}
                  />
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default inject("rootStore")(observer(ScoresheetBody));
