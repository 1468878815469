import React from "react";
import ZeLightButton from "../../../elements/form/ZeLightButton";

class GameViewCameraButtons extends React.Component {
  render() {
    return (
      <div className="flex-column">
        <ZeLightButton className="btn-sm mr-1">Center Field</ZeLightButton>
        <ZeLightButton className="btn-sm mr-1">High First</ZeLightButton>
        <ZeLightButton className="btn-sm mr-1">High Home</ZeLightButton>
      </div>
    );
  }
}

export default GameViewCameraButtons;
