import React from "react";
import { inject, observer } from "mobx-react";
import AuditPitchList from "../common/AuditPitchList";
import AuditSelectedPitch from "../common/AuditSelectedPitch";
import AuditStrikeZoneActions from "./AuditStrikeZoneActions";
import AuditVideoContainer from "../common/AuditVideoContainer";
import AuditCalibrationSettings from "../cameraCalibration/AuditCalibrationSettings";
import AuditStrikeZoneInfo from "./AuditStrikeZoneInfo";
import AuditStrikeZoneHotKeys from "./AuditStrikeZoneHotKeys";
import styled from "styled-components";

const SZHolder = styled.div`
  overflow-x: hidden;
`;

const SettingsHolder = styled.div`
  min-width: 960px;
  max-width: 960px;
`;

class AuditStrikeZoneContainer extends React.Component {
  render() {
    const auditStore = this.props.rootStore.auditStore;

    return (
      <SZHolder>
        <div className="d-flex flex-row justify-content-center">
          <AuditPitchList pitches={auditStore.sortedPitches} theight={580} />
          <AuditVideoContainer>
            <AuditSelectedPitch videoRef={v => auditStore.setVideoRef(v)} />
          </AuditVideoContainer>
        </div>
        <div className="d-flex flex-row mt-1">
          <AuditStrikeZoneInfo />
          <SettingsHolder>
            <AuditCalibrationSettings />
            <AuditStrikeZoneHotKeys />
          </SettingsHolder>
        </div>
        <div className="d-flex flex-row justify-content-end mt-1">
          <AuditStrikeZoneActions />
        </div>
      </SZHolder>
    );
  }
}

export default inject("rootStore")(observer(AuditStrikeZoneContainer));
