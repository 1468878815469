import { action, extendObservable } from "mobx";
import _ from "lodash";
import { AlertConstants } from "../components/common/alert/AlertConstants";

class AlertStore {
  getAlertId() {
    return (
      "_" +
      Math.random()
        .toString(36)
        .substr(2, 9)
    );
  }

  constructor() {
    this.defaults = {
      alerts: [],
      lastErrorShownMillis: 0,
      lastErrorShownText: ""
    };

    extendObservable(this, {
      alerts: this.defaults["alerts"],
      addAlert: action(alert => {
        alert.id = this.getAlertId();
        this.alerts.push(alert);
      }),
      lastErrorShownMillis: this.defaults["lastErrorShownMillis"],
      lastErrorShownText: this.defaults["lastErrorShownText"],
      removeAlert: action(id => {
        if (id) {
          this.alerts = _.reject(this.alerts, function(a) {
            return a.id === id;
          });
        }
      })
    });
  }

  createResponseAlerts(alerts) {
    alerts.forEach(alert => {
      let type;
      switch (alert.severity) {
        case "SUCCESS":
          type = AlertConstants.TYPES.SUCCESS;
          break;
        case "INFO":
          type = AlertConstants.TYPES.INFO;
          break;
        case "ERROR":
          type = AlertConstants.TYPES.DANGER;
          break;
        case "WARNING":
        default:
          type = AlertConstants.TYPES.WARNING;
          break;
      }
      const currentTime = Date.now();
      console.log("currentTime: " + currentTime + ", lastErrorShownTs: " + this.lastErrorShownMillis);
      if (
        (type === AlertConstants.TYPES.DANGER || type === AlertConstants.TYPES.WARNING) &&
        alert.message === this.lastErrorShownText &&
        currentTime < this.lastErrorShownMillis + 1000
      ) {
        return;
      }
      this.lastErrorShownMillis = currentTime;
      this.lastErrorShownText = alert.message;
      this.addAlert({
        type: type,
        text: alert.message
      });
    });
  }
}

export default AlertStore;
