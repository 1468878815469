import React from "react";
import styled from "styled-components";
import { inject, observer } from "mobx-react";

const AuditPitchRow = styled.tr`
  ${props => (props.isSelected && props.playId ? "background-color: #fdec98" : "")};
  ${props => (props.playId ? "" : "opacity: .3;")};
  ${props => (props.isDirty ? "font-weight: 700;" : "font-weight: 200;")};
  ${props => (!props.playId || props.tracked ? "" : "color: #ff0000;")};
  ${props => (props.incompleteTrack ? "color: #00bb00;" : "")};
  border-bottom: 1px solid #d2d2d2;
  &:hover {
    cursor: ${props =>
      props.callCorrectness !== "UNKNOWN" || (props.playId && props.playId !== "") ? "pointer" : "not-allowed"};
  }
`;

const AuditData = styled.td`
  ${props => (props.colWidth ? `min-width: ${props.colWidth}` : "min-width: 60px;")};
  ${props => (props.colWidth ? `max-width: ${props.colWidth}` : "max-width: 60px;")};
  font-size: 13px;
  text-align: left;
  height: 30px;
`;

const AuditedHolder = styled.div`
  width: 5px;
  float: left;
`;

const InningHolder = styled.div`
  float: left;
`;

class AuditPitch extends React.Component {
  formatCallCorrectness(callCorrectness) {
    if (!callCorrectness) {
      return "";
    }
    let callCorrectnessNoUnderscore = callCorrectness.replace("_", " ");
    let callCorrectnessSplit = callCorrectnessNoUnderscore.split(" ");
    for (let i = 0; i < callCorrectnessSplit.length; i++) {
      callCorrectnessSplit[i] = callCorrectnessSplit[i].toLowerCase();
      callCorrectnessSplit[i] = callCorrectnessSplit[i].charAt(0).toUpperCase() + callCorrectnessSplit[i].substring(1);
    }
    return callCorrectnessSplit.join(" ");
  }

  formatUmpireCallCode(umpireCallCode) {
    switch (umpireCallCode) {
      case "Ball":
        return "B";
      case "Called Strike":
        return "C";
      default:
        return umpireCallCode;
    }
  }

  render() {
    const {
      rootStore: { auditStore },
      pitch,
      pitchSelectedHandler
    } = this.props;
    const selectedPitch = auditStore.selectedPitch;
    const isSelected = pitch.playId === selectedPitch.playId;
    const isDirty = auditStore.isPitchDirty(pitch.playId);
    const hasBeenAudited = !!pitch.previouslyAudited;
    const accuracy = pitch.accuracy;
    const incompleteTrack = accuracy.tracked && !(accuracy.opticalTracked && accuracy.spin);
    return (
      <AuditPitchRow
        key={pitch.playId}
        isSelected={isSelected}
        playId={pitch.playId}
        isDirty={isDirty}
        tracked={accuracy.tracked}
        callCorrectness={pitch.callCorrectness}
        onClick={e => pitchSelectedHandler(pitch, e)}
        incompleteTrack={incompleteTrack}
      >
        <AuditData colWidth={this.props.widths["inning"]}>
          {hasBeenAudited ? <AuditedHolder>*</AuditedHolder> : <AuditedHolder>&nbsp;</AuditedHolder>}
          <InningHolder>
            {pitch.topOfInning ? "T" : "B"}
            {pitch.inning}&nbsp;&nbsp;{pitch.atBatNumber}-{pitch.pitchOfAtBat}
          </InningHolder>
        </AuditData>
        <AuditData colWidth={this.props.widths["call"]}>{this.formatUmpireCallCode(pitch.umpireCallCode)}</AuditData>
        <AuditData colWidth={this.props.widths["playId"]}>{pitch.playId}</AuditData>
        {auditStore.callCorrectnessFilterChecked ? (
          <AuditData colWidth={this.props.widths["callCorrectness"]}>
            {this.formatCallCorrectness(pitch.callCorrectness)}
          </AuditData>
        ) : null}
        <AuditData colWidth={this.props.widths["stance"]}>{pitch.batterSide}</AuditData>
        <AuditData colWidth={this.props.widths["pitchType"]}>{pitch.pitchType}</AuditData>
        <AuditData colWidth={this.props.widths["pitch"]}>{pitch.pitchNumber}</AuditData>
        <AuditData colWidth={this.props.widths["pitcher"]}>{pitch.pitcherName}</AuditData>
        <AuditData colWidth={this.props.widths["batter"]}>{pitch.batterName}</AuditData>
      </AuditPitchRow>
    );
  }
}

export default inject("rootStore")(observer(AuditPitch));
