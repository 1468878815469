import React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import ZeLightButton from "../../elements/form/ZeLightButton";

const CallForm = styled.form`
  padding-left: 30px;
  padding-top: 5px;
`;

const TextHolder = styled.span`
  &:hover {
    cursor: pointer;
  }
`;

const CallCorrectnessHolder = styled.div.attrs({
  className: "dropdown-menu",
  "aria-labelledby": "dropdownMenuButton"
})`
  width: 150px;
`;

const callCorrectnessFilterOptions = [
  {
    key: "ACCEPTABLE",
    label: "Acceptable"
  },
  {
    key: "CORRECT",
    label: "Correct"
  },
  {
    key: "INCORRECT",
    label: "Incorrect"
  },
  {
    key: "UNKNOWN",
    label: "Unknown"
  }
];

class AuditPcHeaderCallCorrectness extends React.Component {
  render() {
    const { auditPcStore } = this.props.rootStore;
    const { callCorrectnessFilterTotals } = auditPcStore;
    return (
      <div>
        <ZeLightButton
          className="btn-sm dropdown-toggle"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Call Correctness
        </ZeLightButton>
        <CallCorrectnessHolder>
          <CallForm>
            {callCorrectnessFilterOptions.map(function(opt, index) {
              return (
                <div className="form-group" key={opt.key}>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="dropdownCheck2"
                    checked={auditPcStore.callCorrectness[opt.key]}
                    onChange={() => auditPcStore.toggleCallCorrectness(opt.key)}
                  />
                  <div
                    className="d-flex justify-content-start"
                    onClick={() => auditPcStore.toggleCallCorrectness(opt.key)}
                  >
                    <TextHolder>{opt.label}</TextHolder>
                    <span className="align-items-center badge badge-light d-flex ml-1">
                      {callCorrectnessFilterTotals[opt.key]}
                    </span>
                  </div>
                </div>
              );
            })}
          </CallForm>
        </CallCorrectnessHolder>
      </div>
    );
  }
}

export default inject("rootStore")(observer(AuditPcHeaderCallCorrectness));
