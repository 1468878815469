import React from "react";
import GameViewBatterSideButtons from "./GameViewBatterSideButtons";
import GameViewerBatterGraphic from "./GameViewerBatterGraphic";
import GameViewBatterSelectedPitch from "./GameViewBatterSelectedPitch";
import GameViewCameraButtons from "./GameViewCameraButtons";
import GameViewBatterPitchInfo from "./GameViewBatterPitchInfo";

class GameViewBatterSmallScreen extends React.Component {
  render() {
    return (
      <div className="d-flex flex-row">
        <div className="flex-column">
          <GameViewerBatterGraphic />
        </div>
        <div className="flex-column">
          <div className="d-flex flex-row">
            <GameViewCameraButtons />
          </div>
          <div className="d-flex flex-row mt-2">
            <GameViewBatterSideButtons />
          </div>
          <div className="d-flex flex-row mt-4">
            <GameViewBatterPitchInfo />
          </div>
          <div className="d-flex flex-row mt-4">
            <GameViewBatterSelectedPitch />
          </div>
        </div>
      </div>
    );
  }
}

export default GameViewBatterSmallScreen;
