import React from "react";
import HotKeyWrapper from "../../audit/common/HotKeyWrapper";
import HrTitle from "../../common/title/HrTitle";

class AuditPcVideoHotKeys extends React.Component {
  render() {
    return (
      <div>
        <HrTitle title="Navigation" bg="FFFFFF" />
        <HotKeyWrapper hotkey="S">
          <strong>Next</strong> Pitch
        </HotKeyWrapper>
        <HotKeyWrapper hotkey="A">
          <strong>Previous</strong> Pitch
        </HotKeyWrapper>
        <HotKeyWrapper hotkey="space">
          <strong>Play/Pause</strong> video
        </HotKeyWrapper>
        <HotKeyWrapper hotkey="X">
          Step <strong>Forward</strong> One Frame
        </HotKeyWrapper>
        <HotKeyWrapper hotkey="Z">
          Step <strong>Backwards</strong> One Frame
        </HotKeyWrapper>
        <HotKeyWrapper hotkey="D">
          Jump to <strong>Keyframe</strong>
        </HotKeyWrapper>
        <HotKeyWrapper hotkey="F">
          Jump to <strong>Plate</strong>
        </HotKeyWrapper>
      </div>
    );
  }
}

export default AuditPcVideoHotKeys;
