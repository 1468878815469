import React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import { Popover, PopoverHeader, PopoverBody } from "reactstrap";

const ActionButton = styled.button.attrs({
  className: "btn"
})`
  border: solid 1px #d2d2d2 !important;
  font-size: 11px !important;
  font-weight: bold !important;
  letter-spacing: -0.4px !important;
  max-height: 22px !important;
  min-height: 22px !important;
  max-width: ${props => props.width + "px"};
  min-width: ${props => props.width + "px"};
  padding: 0 !important;
  ${props => (props.selected ? "background-color: #fdec98 !important;" : "")};
`;

class MarkedPitchesPopover extends React.Component {
  render() {
    const { gameViewerStore, authStore } = this.props.rootStore;
    if (!authStore.isAuditorAdmin) {
      return null;
    }
    return (
      <div>
        <ActionButton
          width={150}
          disabled={gameViewerStore.selectedPitch}
          id="markedPitchesList"
          type="button"
          onClick={gameViewerStore.toggleMarkPitchesListPopoverOpen}
        >
          View Marked Pitches
        </ActionButton>

        <Popover
          placement="bottom-start"
          isOpen={gameViewerStore.markPitchesListPopoverOpen}
          target="markedPitchesList"
          toggle={gameViewerStore.toggleMarkPitchesListPopoverOpen}
        >
          <PopoverHeader>Hidden Pitches</PopoverHeader>
          <PopoverBody>
            <ul>
              {gameViewerStore.markedPitches.map(pitch => {
                return (
                  <li key={pitch.pitchNumber}>
                    Pitch: AB{pitch.atBatNumber}-{pitch.pitchOfAtBat}
                  </li>
                );
              })}
            </ul>
          </PopoverBody>
        </Popover>
      </div>
    );
  }
}

export default inject("rootStore")(observer(MarkedPitchesPopover));
