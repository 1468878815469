import React from "react";
import styled from "styled-components";

const Card = styled.div.attrs({
  className: "card"
})`
  max-width: ${props => (props.width ? `${props.width}px !important` : "")};
  background-color: ${props => (props.grey ? " #f7f7f7 !important" : "")};
  margin: auto;
  position: static !important;
`;

const CardBody = styled.div.attrs({
  className: "card-body pb-3"
})`
  overflow-x: auto;
  min-height: 70vh;
  ${props => (props.nopadding ? "padding-top: 0 !important" : "")};
  ${props => (props.nopadding ? "padding-left: 0 !important" : "")};
  ${props => (props.nopadding ? "padding-right: 0 !important" : "")};
  position: static !important;
`;

const Col12Static = styled.div.attrs({
  className: "col-12"
})`
  ${props => (props.nopadding ? "padding: 0 !important" : "")};
  position: static !important;
`;

const Row = styled.div.attrs({
  className: "row"
})`
  ${props => (props.nomargin ? "margin: 0 !important" : "")};
`;

class ComponentContainer extends React.Component {
  render() {
    const grey = !!this.props.grey;
    const nopadding = !!this.props.nopadding;
    const nomargin = !!this.props.nomargin;
    const width = this.props.width;
    return (
      <Card grey={grey} width={width}>
        <CardBody nopadding={nopadding}>
          <Row nomargin={nomargin}>
            <Col12Static nopadding={nomargin && nopadding}>{this.props.children}</Col12Static>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

export default ComponentContainer;
