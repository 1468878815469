import React from "react";
import { inject, observer } from "mobx-react";
import HrTitle from "../../../common/title/HrTitle";

class AuditGeneralSettings extends React.Component {
  render() {
    const auditStore = this.props.rootStore.auditStore;
    return (
      <div>
        <HrTitle title="General Settings" bg="FFFFFF" />
        <div className="row">
          <div className="col">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="autopaste"
                onChange={auditStore.toggleAutoPaste}
                checked={auditStore.autoPaste}
              />
              <label className="form-check-label" htmlFor="autopaste">
                Auto Paste
              </label>
            </div>
          </div>
          <div className="col">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="preload"
                disabled={!auditStore.preloadAllowed}
                onChange={auditStore.togglePreload}
                checked={auditStore.preload}
              />
              <label className="form-check-label" htmlFor="preload">
                Preload
              </label>
            </div>
          </div>
        </div>
        {auditStore.strikeZoneEditable && <div className="row">
          <div className="col">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="autosave"
                onChange={auditStore.toggleDoAutoSave}
                checked={auditStore.doAutoSave}
              />
              <label className="form-check-label" htmlFor="autosave">
                Auto Save
              </label>
            </div>
          </div>
          <div className="col">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="hoverAndClick"
                onChange={auditStore.toggleHoverAndClick}
                checked={auditStore.hoverAndClick}
              />
              <label className="form-check-label" htmlFor="hoverAndClick">
                Hover & Click
              </label>
            </div>
          </div>
        </div>}
      </div>
    );
  }
}

export default inject("rootStore")(observer(AuditGeneralSettings));
