import React from "react";
import { inject, observer } from "mobx-react";
import Moment from "moment";
import styled from "styled-components";
import ScheduleActions from "./ScheduleActions";
import ScheduleUmpireStancesModal from "./ScheduleUmpireStancesModal";
import { Link } from "react-router-dom";
import { AuthConstants } from "../../login/AuthConstants";
import ZeTableHeader from "../../elements/table/ZeTableHeader";
import { DateConstants } from "../../common/constants/DateConstants";
import ScheduleAnalyzePitchesModal from "./analyze/ScheduleAnalyzePitchesModal";
import StringUtil from "../../../utilities/StringUtil";
import ScheduleAdminResultGameStatus from "./ScheduleAdminResultGameStatus";
import ScheduleReleaseErrorModal from "./release/ScheduleReleaseErrorModal";
import { GreenLED, RedLED, BlueLED, YellowLED, GrayLED } from "../../elements/led/LEDelement";
import ZeNumberFormatter from "../../common/ZeNumberFormatter";

const ScheduleTableHolder = styled.div.attrs({
  className: "table-responsive"
})`
  min-height: 350px !important;
  overflow: visible !important;
`;

const ScheduleTable = styled.table.attrs({
  className: "w-100 table table-hover"
})`
  border: 1px solid rgba(0, 0, 0, 0.125);

  td {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    padding-left: 0.25rem !important;
  }
`;

const ALikeButton = styled.a`
  &&& {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
    &:hover {
      color: #0056b3;
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

const GREY = "#888888";
const BLACK = "#000000";

const TdColor = styled.td`
  color: ${props => props.color};
`;

const StyledTableHeader = styled.th.attrs(() => ({
  className: "pt-2 pb-2"
}))`
  &&& {
    padding-left: 0.25rem !important;
  }
`;

class ScheduleAdminResults extends React.Component {
  createKey(game) {
    let key = game.gamePk + "_";
    if (game.umpire) {
      key += game.umpire.id;
    }
    return key;
  }

  gameIsInitializedOrFinalized(game) {
    return game.status === "INITIALIZED" || game.status === "FINALIZED";
  }

  incidentLink(gameStatus, incidentIds) {
    return (
      <ALikeButton
        onClick={() => {
          incidentIds.forEach(id =>
            window.open(
              "https://status.mlb.com/incidents/" + id,
              "_blank" // <- This is what makes it open in a new window.
            )
          );
        }}
      >
        {gameStatus}
      </ALikeButton>
    );
  }

  mapGameStatus(status) {
    if (!status) {
      return status;
    }
    switch (status) {
      case "FINALIZED":
        return "Released";
      default:
        return StringUtil.toTitleCase(status);
    }
  }

  render() {
    let {
      rootStore: { scheduleStore, authStore }
    } = this.props;
    const admin = authStore.containsAuthorities([
      AuthConstants.USER_ROLES.ZE_SUPER_ADMIN,
      AuthConstants.USER_ROLES.ZE_AUDITOR_ADMIN
    ]);

    const displayGameStatusCol =
      authStore.containsAuthorities([
        AuthConstants.USER_ROLES.ZE_SUPER_ADMIN,
        AuthConstants.USER_ROLES.ZE_AUDITOR_ADMIN,
        AuthConstants.USER_ROLES.ZE_AUDITOR
      ]) && scheduleStore.displayGameStatusCol;

    return (
      <div className="row">
        <div className="col">
          <ScheduleReleaseErrorModal />
          <ScheduleUmpireStancesModal />
          <ScheduleAnalyzePitchesModal />
          <ScheduleTableHolder>
            <ScheduleTable>
              <ZeTableHeader>
                <tr>
                  <StyledTableHeader />
                  <StyledTableHeader>Game</StyledTableHeader>
                  <StyledTableHeader>Time</StyledTableHeader>
                  <StyledTableHeader>Umpire</StyledTableHeader>
                  {displayGameStatusCol ? <StyledTableHeader>Game Status</StyledTableHeader> : null}
                  <StyledTableHeader>Stances</StyledTableHeader>
                  <StyledTableHeader>ZE Status</StyledTableHeader>
                  {admin ? <StyledTableHeader>Scoresheet</StyledTableHeader> : null}
                </tr>
              </ZeTableHeader>
              <tbody>{scheduleStore.sortedGames.map((game, index) => this.row(game, index))}</tbody>
            </ScheduleTable>
          </ScheduleTableHolder>
        </div>
      </div>
    );
  }

  researchTool(gamePk, gameStatus) {
    return (
      <ALikeButton
        onClick={() => {
          window.open(
            "https://research.mlb.com/games/" + gamePk + "/quality",
            "_blank" // <- This is what makes it open in a new window.
          );
        }}
      >
        {gameStatus}
      </ALikeButton>
    );
  }

  row(game, index) {
    let {
      rootStore: { scheduleStore, authStore }
    } = this.props;
    const admin = authStore.containsAuthorities([
      AuthConstants.USER_ROLES.ZE_SUPER_ADMIN,
      AuthConstants.USER_ROLES.ZE_AUDITOR_ADMIN
    ]);
    const displayGameStatusCol =
      authStore.containsAuthorities([
        AuthConstants.USER_ROLES.ZE_SUPER_ADMIN,
        AuthConstants.USER_ROLES.ZE_AUDITOR_ADMIN,
        AuthConstants.USER_ROLES.ZE_AUDITOR
      ]) && scheduleStore.displayGameStatusCol;
    const { gamePk, incidentIds, pitchcastVideoPct, scheduleStatus } = game;
    const reason = scheduleStatus ? scheduleStatus.reason : null;
    const incomplete =
      reason && scheduleStatus.detailedState !== "Final" && scheduleStatus.detailedState !== "Completed Early";
    const textColor = incomplete ? GREY : BLACK;

    const getGameStatusIndicator = gameStatus => {
      switch (gameStatus) {
        case "Ready":
        case "Pitchcast Ready":
          return (
            <div className="d-flex align-items-center">
              <GreenLED />
              <div className="ml-2">{this.researchTool(gamePk, gameStatus)}</div>
            </div>
          );
        case "Pitchcast In Progress":
          return (
            <div className="d-flex align-items-center">
              <BlueLED />
              <div className="ml-2">
                {this.researchTool(gamePk, gameStatus)}{" "}
                <ZeNumberFormatter value={pitchcastVideoPct} precision="3" percentage={true} />
              </div>
            </div>
          );
        case "Open Incident":
          return (
            <div className="d-flex align-items-center">
              <RedLED />
              <div className="ml-2">{this.incidentLink(gameStatus, incidentIds)}</div>
            </div>
          );
        case "Upload In Progress":
        case "Upload Delayed":
          return (
            <div className="d-flex align-items-center">
              <YellowLED />
              <div className="ml-2">{this.researchTool(gamePk, gameStatus)}</div>
            </div>
          );
        case null:
          return (
            <div className="d-flex align-items-center">
              <GrayLED />
              <div className="ml-2">{this.researchTool(gamePk, "Unknown")}</div>
            </div>
          );
        default:
          return (
            <div className="d-flex align-items-center">
              <GrayLED />
              <div className="ml-2">{this.researchTool(gamePk, gameStatus)}</div>
            </div>
          );
      }
    };

    return (
      <tr key={this.createKey(game)}>
        <td>{incomplete ? null : <ScheduleActions game={game} />}</td>

        <TdColor color={textColor}>
          {this.gameIsInitializedOrFinalized(game) && !incomplete ? (
            <Link to={"/gameviewer?gamePk=" + game.gamePk + "&umpireId=" + game.umpire.id}>{game.displayName}</Link>
          ) : (
            game.displayName
          )}
        </TdColor>
        <TdColor color={textColor}>{Moment(game.displayTime).format(DateConstants.TIME_FORMAT)}</TdColor>
        <TdColor color={textColor}>
          {incomplete || authStore.isAuditorNonAdmin ? (
            <div>{game.umpire ? game.umpire.name : ""}</div>
          ) : (
            <ALikeButton
              onClick={() => {
                scheduleStore.updateUrlForUmpire(game.umpire.id);
              }}
            >
              {game.umpire ? game.umpire.name : ""}
            </ALikeButton>
          )}
        </TdColor>
        {displayGameStatusCol ? <TdColor color={textColor}>{getGameStatusIndicator(game.gameStatus)}</TdColor> : null}
        <TdColor color={textColor}>{game.umpireStances ? game.umpireStances.join(", ") : ""}</TdColor>
        <TdColor color={textColor}>
          <ScheduleAdminResultGameStatus
            id={index}
            status={incomplete ? scheduleStatus.detailedState : this.mapGameStatus(game.status)}
            modifiedDate={game.lastModified}
          />
        </TdColor>
        {admin ? <TdColor color={textColor}>{this.scoresheet(game, incomplete)}</TdColor> : null}
      </tr>
    );
  }

  scoresheet(game, incomplete) {
    if (!game.umpire) {
      return null;
    }
    if (incomplete) {
      return incomplete;
    } else if (game.scoresheetReleased) {
      return (
        <a href={"/scoresheet?gamePk=" + game.gamePk + "&umpireId=" + game.umpire.id} target="_blank">
          Released
        </a>
      );
    } else {
      return (
        <a href={"/scoresheet?gamePk=" + game.gamePk + "&umpireId=" + game.umpire.id} target="_blank">
          Not Released
        </a>
      );
    }
  }
}

export default inject("rootStore")(observer(ScheduleAdminResults));
