import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1050;
`;

class Modal extends React.Component {
  getClassName() {
    let className = "modal-dialog";

    switch (this.props.size) {
      case "lg":
        className += " modal-lg";
        break;
      case "md":
        className += " modal-md";
        break;
      case "sm":
      default:
        className += " modal-sm";
        break;
    }

    return className;
  }

  render() {
    if (!this.props.show) {
      return null;
    }

    return (
      <Backdrop>
        <div className={this.getClassName()}>
          <div className="modal-content">{this.props.children}</div>
        </div>
      </Backdrop>
    );
  }
}

Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
  children: PropTypes.node,
  size: PropTypes.string
};

export default Modal;
