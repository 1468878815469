import styled from "styled-components";

const ZeDropdownItem = styled.a.attrs({
  className: "dropdown-item"
})`
  background-color: #ffffff !important;
  &:hover {
    cursor: pointer;
    color: #2b77eb !important;
  }
  z-index: 1001;
`;

export default ZeDropdownItem;
