class S3Api {
  _get = (s3, bucket, key) => {
    return s3
      .getObject({
        Bucket: bucket,
        Key: key,
        ResponseCacheControl: "no-cache"
      })
      .promise()
      .then(data => {
        return Promise.resolve(data);
      })
      .catch(error => {
        if (error.statusCode === 404) {
          console.log("s3 get miss:", key);
          console.log(error.message);
        } else {
          console.log("s3 get fail:", key);
          console.log(error, error.stack);
        }

        return Promise.reject(error);
      });
  };

  getImage(s3, bucket, path) {
    return this._get(s3, bucket, path)
      .then(data => {
        let string = data.Body.toString("base64");
        if (!string) {
          return null;
        }
        return `data:image/png;base64,${string}`;
      })
      .catch(error => {
        console.log(error);
        return Promise.resolve(null);
      });
  }

  get(s3, bucket, key) {
    return this._get(s3, bucket, key)
      .then(data => {
        let json = {};

        try {
          json = data && data.Body && JSON.parse(data.Body.toString());
        } catch (e) {
          console.log("json parse error", e);
        }

        return json;
      })
      .catch(error => {
        console.log(error);
        return Promise.resolve({});
      });
  }

  createVideoReadStream(s3, bucket, key) {
    return s3
      .getObject({
        Bucket: bucket,
        Key: key
      })
      .createReadStream();
  }
}

export default S3Api;
