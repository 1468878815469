import React from "react";
import { observer, inject } from "mobx-react";

export default WrappedComponent => {
  class ProtectedComponent extends React.Component {
    componentWillMount() {
      const { authStore } = this.props.rootStore;
      authStore.checkLocalStorage();
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return inject("rootStore")(observer(ProtectedComponent));
};
