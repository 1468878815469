import React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import ZeTable from "../../../../elements/table/ZeTable";
import GameViewInning from "./GameViewInning";

const InfoColumn = styled.div.attrs({
  className: "flex-column"
})`
  border-bottom: 1px solid #dee2e6;
  border-top: 1px solid #dee2e6;
  width: ${props => props.width}px;
`;

const TableHolder = styled.div`
  height: ${props => props.height}px;
  overflow-y: scroll;
`;

const Table = styled(ZeTable)`
  border: 0;
  &&& {
    td,
    th {
      border: 1px solid #000000 !important;
    }
  }
`;

class GameViewerFilterPitchList extends React.Component {
  componentDidUpdate() {
    this.scrollPitchList();
  }

  componentDidMount() {
    this.scrollPitchList();
  }

  scrollPitchList = () => {
    const { gameViewerStore } = this.props.rootStore;
    let pitch = gameViewerStore.selectedPitch;
    if (pitch) {
      let childrenArray = Array.from(this.tableRef.children);
      let row = childrenArray.find(cs => cs.id === pitch.pitchNumber.toString());
      if (row) {
        this.tableHolder.scrollTop = Math.max(row.offsetTop - 100, 0);
      }
    }
  };

  render() {
    const { gameViewerStore } = this.props.rootStore;
    const height = this.props.height || 398;
    const width = this.props.width || 235;
    let innings = gameViewerStore.getInnings;
    if (!(innings && innings.length) && gameViewerStore.selectedBoxScore) {
      innings = [gameViewerStore.functions.createDummyHalfInning(gameViewerStore.selectedBoxScore)];
    }
    const inningsNotFiltered = gameViewerStore.getInningsNotFiltered;
    const fullInningMap = {};
    inningsNotFiltered.forEach(i => (fullInningMap[i.title] = i));
    return (
      <InfoColumn width={width}>
        <TableHolder
          id="game-viewer-pitch-list"
          height={height}
          innerRef={ref => {
            this.tableHolder = ref;
            gameViewerStore.setPitchListRef(ref);
          }}
        >
          <Table>
            <tbody
              ref={ref => {
                this.tableRef = ref;
                gameViewerStore.setPitchTableRef(ref);
              }}
            >
              {innings.map((i, index) => (
                <GameViewInning key={index} inning={i} fullInning={fullInningMap[i.title]} />
              ))}
            </tbody>
          </Table>
        </TableHolder>
      </InfoColumn>
    );
  }
}

export default inject("rootStore")(observer(GameViewerFilterPitchList));
