import React from "react";
import styled from "styled-components";
import { inject, observer } from "mobx-react";

const BoxScoreColumn = styled.div.attrs({
  className: "d-flex flex-column align-items-center justify-content-center"
})`
  ${props => (props.gap ? "margin-left: 4px;" : null)}
`;

const LogoHolder = styled.div.attrs({
  className: "align-items-center"
})`
  height: 34px;
  padding: 4px;
  width: 34px;
`;

const LogosHolder = styled.div`
  margin-left: 4px;
  margin-top: 25px;
  margin-right: 14px;
`;

const Logo = styled.img`
  height: 26px;
  width: 26px;
`;

const Inning = styled.div.attrs({
  className: "align-self-center text-center"
})`
  width: 25px;
  height: 25px;
  border: solid 1px #002e6d;
  color: #002e6d;
  background-color: #f7f7f7;
  font-size: 10px;
  font-weight: 300;
  padding-top: 4px;
  text-align: center;
`;

const ResultHolder = styled.div.attrs({
  className: "align-self-center text-center"
})`
  width: 25px;
  height: 34px;
  border: solid 1px #002e6d;
  color: #002e6d;
  font-size: 14px;
  font-weight: 300;
  background-color: #e2e2e2;
  padding-top: 7px;
`;

const ScoreHolder = styled.div.attrs({
  className: "align-self-center text-center"
})`
  width: 25px;
  height: 34px;
  background-color: #e2e2e2;
  border: solid 1px #002e6d;
  color: #002e6d;
  font-size: 14px;
  font-weight: 300;
  background-color: ${props =>
    props.isDisabled ? "#e2e2e2" : props.hasIncorrects ? "#ed1c34" : props.isSelected ? "#fdec98" : "#f7f7f7"};
  outline: ${props => (props.isDisabled ? "" : props.hasIncorrects && props.isSelected ? "4px solid #fdec98" : "")};
  outline-offset: ${props => (props.isDisabled ? "" : props.hasIncorrects && props.isSelected ? "-5px" : "")};
  padding-top: 7px;
  &:hover {
    ${props => (props.isDisabled ? "" : "cursor: pointer;")};
  }
`;

const ScrollableBoxScore = styled.div`
  overflow-x: auto;
`;

class GameViewerHeaderScoreboard extends React.Component {
  defaultImage(event) {
    event.target.src = "https://www.mlbstatic.com/team-logos/league-on-light/1.svg";
  }

  onClick(key) {
    this.props.rootStore.gameViewerStore.setSelectedBoxScore(key);
  }

  render() {
    const gameViewerStore = this.props.rootStore.gameViewerStore;
    const boxScore = gameViewerStore.getBoxScore;
    const { awayTotals, homeTotals, innings } = boxScore;
    return (
      <div className="d-flex flex-row mt-2">
        <LogosHolder>
          <LogoHolder>
            {gameViewerStore.game.awayTeam ? (
              <Logo
                src={`https://www.mlbstatic.com/team-logos/team-cap-on-light/${gameViewerStore.game.awayTeam.id}.svg`}
                onError={this.defaultImage}
                alt="A"
              />
            ) : null}
          </LogoHolder>
          <LogoHolder>
            {gameViewerStore.game.homeTeam ? (
              <Logo
                src={`https://www.mlbstatic.com/team-logos/team-cap-on-light/${gameViewerStore.game.homeTeam.id}.svg`}
                onError={this.defaultImage}
                alt="H"
              />
            ) : null}
          </LogoHolder>
        </LogosHolder>
        <ScrollableBoxScore className="d-flex flex-row">
          {innings
            ? innings.map(i => {
                let topKey = "T" + i.num.toString();
                let bottomKey = "B" + i.num.toString();
                return (
                  <BoxScoreColumn key={i.num}>
                    <Inning>{i.num}</Inning>
                    <ScoreHolder
                      hasIncorrects={i.away.hasIncorrects}
                      onClick={() => {
                        if (i.away.atbats.length > 0) {
                          this.onClick(topKey);
                        }
                      }}
                      isSelected={topKey === gameViewerStore.selectedBoxScore}
                      isDisabled={i.away.atbats.length === 0}
                    >
                      {i.away.runs}
                    </ScoreHolder>
                    <ScoreHolder
                      hasIncorrects={i.home.hasIncorrects}
                      onClick={() => {
                        if (i.home.atbats.length > 0) {
                          this.onClick(bottomKey);
                        }
                      }}
                      isSelected={bottomKey === gameViewerStore.selectedBoxScore}
                      isDisabled={i.home.atbats.length === 0}
                    >
                      {i.home.runs}
                    </ScoreHolder>
                  </BoxScoreColumn>
                );
              })
            : null}
          <BoxScoreColumn gap={true} key={"r"}>
            <Inning>R</Inning>
            <ResultHolder>{awayTotals ? awayTotals.runs : "#"}</ResultHolder>
            <ResultHolder>{homeTotals ? homeTotals.runs : "#"}</ResultHolder>
          </BoxScoreColumn>
          <BoxScoreColumn key={"h"}>
            <Inning>H</Inning>
            <ResultHolder>{awayTotals ? awayTotals.hits : "#"}</ResultHolder>
            <ResultHolder>{homeTotals ? homeTotals.hits : "#"}</ResultHolder>
          </BoxScoreColumn>
          <BoxScoreColumn key={"e"}>
            <Inning>E</Inning>
            <ResultHolder>{awayTotals ? awayTotals.errors : "#"}</ResultHolder>
            <ResultHolder>{homeTotals ? homeTotals.errors : "#"}</ResultHolder>
          </BoxScoreColumn>
        </ScrollableBoxScore>
      </div>
    );
  }
}

export default inject("rootStore")(observer(GameViewerHeaderScoreboard));
