import React from "react";
import styled from "styled-components";
import ZeLabel from "../../../elements/form/ZeLabel";
import ZeFieldBox from "../../../elements/form/ZeFieldBox";
import ZeFieldText from "../../../elements/form/ZeFieldText";

const ScoresheetRow = styled.div.attrs({
  className: "row"
})`
  margin-bottom: 3px;
  margin-left: 0;
`;

class ScoresheetBodyViewItem extends React.Component {
  render() {
    let number = this.props.children;
    if (number && number.props) {
      number = number.props.children;
    }
    let pitchNumbers = this.props.desc && this.props.pitchNumbers ? this.props.pitchNumbers.join(", ") : null;
    return (
      <ScoresheetRow>
        <ZeLabel className="col-md-4">{this.props.title}</ZeLabel>
        <ZeFieldBox className="col-md-2">
          <ZeFieldText className="align-middle">{this.props.children}</ZeFieldText>
        </ZeFieldBox>
        <div className="col-md-7">
          {number ? (
            <ZeFieldText className="align-middle">
              {this.props.desc}
              {pitchNumbers}
            </ZeFieldText>
          ) : null}
        </div>
      </ScoresheetRow>
    );
  }
}

export default ScoresheetBodyViewItem;
