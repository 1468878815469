import React from "react";
import { GlobalHotKeys } from "react-hotkeys";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import AuditHeader from "../components/audit/common/AuditHeader";
import Protected from "../components/protected/Protected";
import AuditStrikeZoneContainer from "../components/audit/strikeZone/AuditStrikeZoneContainer";
import AuditCameraCalContainer from "../components/audit/cameraCalibration/AuditCameraCalContainer";
import AuditResearchContainer from "../components/audit/research/AuditResearchContainer";

const AuditHolder = styled.div`
  margin: auto;
  min-width: 1310px;
`;

const AuditCard = styled.div.attrs({
  className: "card"
})`
  background-color: #f7f7f7 !important;
  position: static !important;
`;
const AuditCardHeader = styled.div.attrs({
  className: "card-header"
})`
  background-color: #ffffff !important;
  padding: 10px !important;
  position: static !important;
`;
const AuditCardBody = styled.div.attrs({
  className: "card-body"
})`
  overflow-x: hidden;
  padding: 10px !important;
  position: static !important;
`;

class AuditContainer extends React.Component {
  componentWillUnmount() {
    this.props.rootStore.loadingStore.setWindowTitle("Zone Evaluator");
  }

  render() {
    const {
      match,
      rootStore: { auditStore, routerStore }
    } = this.props;

    return (
      <AuditHolder>
        <GlobalHotKeys keyMap={auditStore.hotkeyMap} handlers={auditStore.hotkeyHandlers} />
        <AuditCard>
          <AuditCardHeader>
            <AuditHeader gamePk={auditStore.gamePk} match={match} />
          </AuditCardHeader>
          <AuditCardBody>
            {routerStore.isAuditStrikeZoneTab ? <AuditStrikeZoneContainer /> : null}
            {routerStore.isAuditCameraCalibrationTab ? <AuditCameraCalContainer /> : null}
            {routerStore.isAuditResearchTab ? <AuditResearchContainer /> : null}
          </AuditCardBody>
        </AuditCard>
      </AuditHolder>
    );
  }
}

export default inject("rootStore")(Protected(observer(AuditContainer)));
