export const callCorrectnessDisplayText = {
  ACCEPTABLE: "Acceptable",
  CATCHER_INFLUENCE: "Catcher Influence",
  CORRECT: "Correct",
  HIGH_BUFFER: "High Buffer",
  INCORRECT: "Incorrect",
  LOW_BUFFER: "Low Buffer",
  LOW_CATCH: "Low Catch"
};

export const hotkeyMap = {
  nextPitch: "s",
  playPauseHandler: "space",
  prevPitch: "a",
  stepBackward: "z",
  stepForward: "x"
};

export const queryParams = {
  playId: "playId",
  pitchNumber: "pitchNumber"
};

export const plateCorners = {
  firstBaseFront: {
    x: 0.70833,
    y: 1.41667,
    z: 0
  },
  firstBaseBack: {
    x: 0.70833,
    y: 0.70833,
    z: 0
  },
  origin: {
    x: 0,
    y: 0,
    z: 0
  },
  thirdBaseBack: {
    x: -0.70833,
    y: 0.70833,
    z: 0
  },
  thirdBaseFront: {
    x: -0.70833,
    y: 1.41667,
    z: 0
  }
};

export const szCanvas = {
  hfColValues: {
    start: 180.82,
    end: 57.29
  },
  plateCF: [[250, 82], [250, 152]],
  plateHF: [[250, 82], [250, 152]],
  plateHH: [[172, 82], [137, 82], [102, 117], [137, 152], [172, 152]],
  plateHHBuffer: [[172, 82], [172, 73.76], [137, 73.76], [93.76, 117], [137, 160.24], [172, 160.24], [172, 152]],
  plateHHRowValues: {
    start: 200.82,
    end: 77.29
  },
  strikeZoneBox: [[88, 82], [172, 82], [172, 152], [88, 152]],
  strikeZoneBoxCenter: [130, 117],
  strikeZone1BBuffer: [[88, 82], [88, 73.76], [172, 73.76], [172, 82]],
  strikeZone3BBuffer: [[88, 152], [88, 160.24], [172, 160.24], [172, 152]],
  pitchCircleRadius: 6.05,
  pitchTriangleRadius: 8
};

export const video = {
  videoCenter: [270, 480],
  szVideoCenter: [540, 960]
};
