import React from "react";
import { inject, observer } from "mobx-react";
import ModalHeaderWithTitleAndClose from "../../../common/modal/ModalHeaderWithTitleAndClose";
import ModalBody from "../../../common/modal/ModalBody";
import ModalFooter from "../../../common/modal/ModalFooter";
import Modal from "../../../common/modal/Modal";
import AnalyzePitchTable from "./AnalyzePitchTable";
import ZeLightButton from "../../../elements/form/ZeLightButton";

class ScheduleAnalyzePitchesModal extends React.Component {
  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
  }

  closeModal(event) {
    event.preventDefault();
    const { scheduleStore } = this.props.rootStore;
    scheduleStore.setPitchList(scheduleStore.defaults["pitchList"]);
    scheduleStore.setShowAnalyzePitches(false);
  }

  render() {
    const { scheduleStore } = this.props.rootStore;
    return (
      <Modal show={scheduleStore.showAnalyzePitches} onClose={() => {}} size="lg">
        <ModalHeaderWithTitleAndClose title="Select Pitches to Analyze" close={this.closeModal} />

        <ModalBody>
          <div className="row">
            <div className="col">
              <div className="form-group">
                <div className="row" />
                <AnalyzePitchTable />
              </div>
            </div>
          </div>
        </ModalBody>

        <ModalFooter>
          <button
            className="btn btn-primary"
            onClick={scheduleStore.analyzePitches}
            disabled={scheduleStore.selectedPitchList.length === 0}
          >
            Analyze
          </button>
          <ZeLightButton onClick={scheduleStore.togglePitchListSelected}>
            {scheduleStore.pitchListAllSelected ? <span>Deselect All</span> : <span>Select All</span>}
          </ZeLightButton>
          <ZeLightButton onClick={this.closeModal}>Cancel</ZeLightButton>
        </ModalFooter>
      </Modal>
    );
  }
}

export default inject("rootStore")(observer(ScheduleAnalyzePitchesModal));
