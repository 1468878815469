import React from "react";
import styled from "styled-components";
import { inject, observer } from "mobx-react";
import AuditNavigation from "./AuditNavigation";
import Moment from "moment";
import { DateConstants } from "../../common/constants/DateConstants";
import AuditHeaderBadgeButton from "./AuditHeaderBadgeButton";
import AuditHeaderCallCorrectness from "./AuditHeaderCallCorrectness";
import AuditStoreFunctions from "../../../stores/audit/AuditStoreFunctions";

const GameInfo = styled.div.attrs({
  className: "ml-2"
})`
  float: left;
`;

const Time = styled.div.attrs({
  className: "ml-2"
})`
  margin-top: 5px;
  float: left;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: normal;
  text-align: left;
  color: #666666;
`;

const AutoSave = styled.div.attrs({
  className: "ml-2"
})`
  margin-top: 5px;
  float: left;
  font-size: 12px;
  color: #666666;
  font-size: 12px;
  text-align: left;
  font-weight: 300;
`;

class AuditHeader extends React.Component {
  render() {
    const {
      rootStore: { auditStore, authStore }
    } = this.props;
    return (
      <div className="d-flex justify-content-between">
        <div>
          <GameInfo>{auditStore.gameDisplayName}</GameInfo>
          <Time>
            {auditStore.gameDisplayTime
              ? Moment(auditStore.gameDisplayTime).format(DateConstants.DATE_FORMAT_WITH_TIME)
              : ""}
            {auditStore.gamePk ? (
              <a
                href={"https://lookup-service-prod.mlb.com/named.trackman_game.bam?game_pk=" + auditStore.gamePk}
                target="_blank"
                className="ml-1"
              >
                <i className="fa fa-external-link" />
              </a>
            ) : null}
          </Time>
          <AutoSave>
            {auditStore.autoSaveDate ? (
              <span>Last Auto Save: {Moment(auditStore.autoSaveDate).format(DateConstants.DATE_FORMAT_WITH_TIME)}</span>
            ) : null}
          </AutoSave>
        </div>
        <div>
          {auditStore.problemPitchList.map(pp => (
            <span className="mr-1" key={pp.key}>
              <AuditHeaderBadgeButton
                key={pp.key}
                title={pp.title}
                called={pp.called}
                total={pp.total}
                active={auditStore.problemPitchFilter[pp.key]}
                onClick={() => {
                  auditStore.toggleProblemPitchFilter(pp.key);
                }}
              />
            </span>
          ))}
          {auditStore.oobPitches.length ? (
            <span className="mr-1">
              <AuditHeaderBadgeButton
                title={"Out of Bounds SZ"}
                total={auditStore.oobPitches.length}
                called={
                  auditStore.oobPitches.filter(p => {
                    return p && (p.umpireCallCode === "C" || p.umpireCallCode === "B" || p.umpireCallCode === "*B");
                  }).length
                }
                active={auditStore.oobPitchFilter}
                onClick={() => {
                  auditStore.toggleOobPitchFilter();
                }}
              />
            </span>
          ) : null}
          {authStore.isAuditorAdmin ? (
            <span className="mr-1">
              <AuditHeaderBadgeButton
                title={"Called Pitches"}
                total={auditStore.calledPitches.length}
                hideCalled={true}
                active={auditStore.calledFilter}
                onClick={() => {
                  auditStore.toggleCalledFilter();
                }}
              />
            </span>
          ) : null}
          {auditStore.problemPitchList ? (
            <button
              type="button"
              className={"btn btn-light"}
              onClick={() => {
                AuditStoreFunctions.copyProblemPitchesToClipboard(auditStore);
              }}
            >
              {"Copy"}
            </button>
          ) : null}
        </div>
        {auditStore.isAdmin && auditStore.callCorrectnessPopulated ? (
          <div>
            <AuditHeaderCallCorrectness />
          </div>
        ) : null}
        <div>
          <AuditNavigation match={this.props.match} />
        </div>
      </div>
    );
  }
}

export default inject("rootStore")(observer(AuditHeader));
